// import React, { useEffect, useState } from "react";
import React, { useState } from "react";

import AgentAuthorizeByHedgeMAP from "../../components/AgentHedgeMAPAuthorization/AgentAuthorizeByHedgeMAP/AgentAuthorizeByPolicyProducer";
import AgentAuthorizeByUser from "../../components/AgentHedgeMAPAuthorization/AgentAuthorizeByUser/AgentAuthorizeByUser";

const toggleTypes = {
  HedgeMAP: "HedgeMAP",
  User: "User",
};
const AgentHedgeMAPAuthorization = () => {
  const [toggleType, setToggleType] = useState(toggleTypes["User"]);

  return (
    <div
      className="container-fluid ps-4 pe-4 pl-md-1 pr-md-1"
      style={{ maxWidth: 1140 }}
    >
      <h5>Authorize HedgeMAP User</h5>
      <hr className="mb-4"></hr>

      <button
        className="btn btn-primary me-2"
        onClick={() => setToggleType(toggleTypes["User"])}
      >
        By User
      </button>

      <button
        className="btn btn-success"
        onClick={() => setToggleType(toggleTypes["HedgeMAP"])}
      >
        By HedgeMAP
      </button>

      <div>
        {toggleType === toggleTypes["HedgeMAP"] ? (
          <AgentAuthorizeByHedgeMAP />
        ) : (
          <AgentAuthorizeByUser />
        )}
      </div>
    </div>
  );
};
export default AgentHedgeMAPAuthorization;
