import adminService from "./adminService";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getAuthorizedAgentsList = createAsyncThunk(
  "drp/getAuthorizedAgentsList",
  async (_: void, thunkAPI) => {
    try {
      const response = await adminService.getAuthorizedAgentsList();
      const payload = await response.data;

      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to fetch authorized agents list. Please try again later.",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to fetch authorized agents list. Please try again later.",
      );
    }
  },
);

export const authorizeAgent = createAsyncThunk(
  "drp/authorizeAgent",
  async (data: any, thunkAPI) => {
    try {
      const response = await adminService.authorizeAgent(data);
      const payload = await response.data;

      if (response.status === 200) {
        try {
          thunkAPI.dispatch(getAuthorizedAgentsList());
        } catch (error) {
          console.error(error);
        } finally {
          return payload;
        }
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to authorize agent. Please try again later",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to authorize agent. Please try again later",
      );
    }
  },
);
export const getSupportedEmailQuoteProjects = createAsyncThunk(
  "drp/getSupportedEmailQuoteProjects",
  async (_: void, thunkAPI) => {
    try {
      const response =
        await adminService.sendGetSupportedEmailQuoteProjectsRequest();
      const payload = await response.data;

      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to get supported email quote projects. Please try again later.",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to get supported email quote projects. Please try again later.",
      );
    }
  },
);

export const getSubscriptionsForProject = createAsyncThunk(
  "drp/getSubscriptionsForProject",
  async (project: string, thunkAPI) => {
    try {
      const response =
        await adminService.sendGetSubscriptionsForProject(project);
      const payload = await response.data;
      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue(
          `Unable to get subscription data for ${project}. Please try again later.`,
        );
      }
    } catch (error) {
      //TODO: get error from repsonmse if it was returned
      return thunkAPI.rejectWithValue(
        `Unable to get subscription data for ${project}. Please try again later.`,
      );
    }
  },
);

export const submitSubscriptionUpdate = createAsyncThunk(
  "drp/submitSubscriptionUpdate",
  async (data: Object, thunkAPI) => {
    try {
      const response =
        await adminService.sendSubmitSubscriptionUpdateRequest(data);
      const payload = await response.data;
      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue(
          `Unable to update subscription data for ${data["project"]}. Please try again later.`,
        );
      }
    } catch (error) {
      //TODO: get error from repsonmse if it was returned
      return thunkAPI.rejectWithValue(
        `Unable to update subscription data for ${data["project"]}. Please try again later.`,
      );
    }
  },
);
