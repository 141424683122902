export class Quarter {
  textstring = "";
  // class3_settle=16.43;
  // class4_settle=10.68;
  // butter_settle=2.35;
  // cheese_settle=1.71;
  // whey_settle=0.36;
  // nonfat_settle=0.34;

  // class3_settle = 0;
  // class4_settle = 0;
  // butter_settle = 0;
  // cheese_settle = 0;
  // whey_settle = 0;
  // nonfat_settle = 0;
  // expected_yield = 0;
  // expected_yield_standard_deviation = 0;
  // days_in_quarter = 0;
  // yield_per_day = 0;

  class3_settle = "";
  class4_settle = "";
  butter_settle = "";
  cheese_settle = "";
  whey_settle = "";
  nonfat_settle = "";
  expected_yield = "";
  expected_yield_standard_deviation = "";
  days_in_quarter = "";
  yield_per_day = "";

  constructor(
    private txt: string,
    c3,
    c4,
    b,
    c,
    w,
    nf,
    y,
    sd,
    diq,
  ) {
    this.textstring = txt;

    if (typeof c3 == typeof "") {
      this.class3_settle = c3;
    } else {
      this.class3_settle = c3.toFixed(2);
    }

    if (typeof c4 == typeof "") {
      this.class4_settle = c4;
    } else {
      this.class4_settle = c4.toFixed(2);
    }

    if (typeof b == typeof "") {
      this.butter_settle = b;
    } else {
      this.butter_settle = b.toFixed(4);
    }

    if (typeof c == typeof "") {
      this.cheese_settle = c;
    } else {
      this.cheese_settle = c.toFixed(4);
    }

    if (typeof w == typeof "") {
      this.whey_settle = w;
    } else {
      this.whey_settle = w.toFixed(4);
    }

    if (typeof nf == typeof "") {
      this.nonfat_settle = nf;
    } else if (nf === undefined) {
      this.nonfat_settle = "-";
    } else {
      this.nonfat_settle = nf.toFixed(4);
    }

    if (typeof y == typeof "") {
      this.expected_yield = y;
    } else {
      this.expected_yield = y.toLocaleString();
    }

    if (typeof sd == typeof "") {
      this.expected_yield_standard_deviation = sd;
    } else {
      this.expected_yield_standard_deviation = sd.toLocaleString();
    }

    if (typeof diq == typeof "") {
      this.days_in_quarter = diq;
    } else {
      this.days_in_quarter = diq.toLocaleString();
    }

    // this.yield_per_day = (this.expected_yield / this.days_in_quarter).toFixed(1);
    this.yield_per_day = (y / diq).toFixed(1);

    // this.setYieldPerDay(this.expected_yield,this.days_in_quarter);

    // this.class4_settle=c4.toFixed(2);
    // this.butter_settle=b.toFixed(4);
    // this.cheese_settle=c.toFixed(4);
    // this.whey_settle=w.toFixed(4);
    // this.nonfat_settle=nf;
  }

  setExpectedYield(val) {
    this.expected_yield = val.toLocaleString();
    // this.setYieldPerDay();
  }

  getExpectedYield(val) {
    return this.expected_yield;
  }

  setExpectedYieldStandardDeviation(val) {
    this.expected_yield_standard_deviation = val.toLocaleString();
  }

  getExpectedYieldStandardDeviation(val) {
    return this.expected_yield_standard_deviation;
  }

  setDaysInQuarter(val) {
    this.days_in_quarter = val.toLocaleString();
    // this.setYieldPerDay();
  }

  getDaysInQuarter(val) {
    return this.days_in_quarter;
  }

  setYieldPerDay(_yield, _diq) {
    // console.log(this.yield_per_day);
    // let _yield = parseFloat(this.expected_yield.replace(',',''));
    // let _days = parseFloat(this.days_in_quarter.replace(',',''));

    // this.yield_per_day = (_yield / _days).toFixed(1);

    this.yield_per_day = (_yield / _diq).toFixed(1);
  }

  getQuarter() {
    return this.textstring;
  }
}
