import React from "react";
// import { reset, setUserType } from "../../../features/auth/authSlice";
import { reset } from "../../features/authSlice";
import { logout } from "../../features/authActions";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import "../../styles/authStyles.scss";

import company_logo from "../../../app/assets/csc_logo_transparent.png";
import company_logo_blue from "../../../app/assets/csc_logo_transparent_blue.png";

function Logout() {
  const dispatch = useAppDispatch();
  const { userType } = useAppSelector((state) => state.auth);
  const onClick = () => {
    dispatch(logout());
    dispatch(reset());
    // dispatch(setUserType(""));
  };

  return (
    <div className="card mx-auto auth-card">
      <div className="card-body">
        <div className="w-100 text-center mb-2">
          <img
            src={userType === "AGENT" ? company_logo : company_logo_blue}
            alt="CSC AG Logo"
            className="mt-2 mb-4 mx-auto company-logo"
          />
        </div>
        <div className="text-center">
          <h5 className="card-title mb-4 px-3">
            {userType === "AGENT" ? (
              <div>
                Welcome to the
                <br />
                Agent Portal
              </div>
            ) : (
              <div>
                Welcome to the
                <br />
                Producer Dashboard
              </div>
            )}
          </h5>
        </div>
        <div className="my-4 text-center">
          <p>
            <div>
              Are you sure you want to
              <br />
              log out?
            </div>
          </p>
        </div>
        <div className="text-center w-100 mb-4">
          <button className="btn btn-secondary w-50" onClick={onClick}>
            Log Out
          </button>
        </div>
      </div>
    </div>
  );
}

export default Logout;
