import { sendResourcesRequest } from "../../app/utils/sendRequest";
// import * as globals from "../../../globals";

const downloadLGMvsDRPComparison = async () => {
  const apifunc = "downloadLGMvsDRP";
  const args = {};
  return sendResourcesRequest(apifunc, args);
};

const downloadLGMvsLRPCattle = async () => {
  const apifunc = "downloadLGMvsLRPCattle";
  const args = {};

  return sendResourcesRequest(apifunc, args);
};

const downloadLGMvsLRPSwine = async () => {
  const apifunc = "downloadLGMvsLRPSwine";
  const args = {};
  return sendResourcesRequest(apifunc, args);
};

const downloadFile = async (target: any) => {
  const apifunc = "downloadFileTarget";
  const args = {
    target: target,
  };

  return sendResourcesRequest(apifunc, args);
};

const resourcesService = {
  downloadLGMvsDRPComparison,
  downloadLGMvsLRPCattle,
  downloadLGMvsLRPSwine,
  downloadFile,
};

export default resourcesService;
