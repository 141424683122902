import podcastService from "./podcastsService";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getPodcasts = createAsyncThunk(
  "drp/getPodcasts",
  async (_: any, thunkAPI) => {
    try {
      const response = await podcastService.sendGetPodcastsRequest();
      const payload = await response.data;

      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to fetch podcasts. Please try again later.",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to fetch podcasts. Please try again later.",
      );
    }
  },
);

export const downloadPodcast = createAsyncThunk(
  "drp/downloadPodcast",
  async (data: any, thunkAPI) => {
    try {
      const response = await podcastService.sendPodcastDownloadRequest(
        data,
        thunkAPI.dispatch,
      );
      const payload = await response;
      if (response.status === 200) {
        return { data: payload, name: data };
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to download podcast. Please try again later.",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to download podcast. Please try again later.",
      );
    }
  },
);

// onDownloadProgress: (progressEvent) => {
//   let percentCompleted = Math.round(
//     (progressEvent.loaded * 100) / progressEvent.total
//   );
//   console.info(percentCompleted);
// },
