import { sendLRPRequest } from "../../../app/utils/sendRequest";

const sendLRPQuoteRequest = async (payload: string) => {
  // const apifunc = "getlrpquoteauth";
  const apifunc = "getlrpquoteauthdomain";

  const args = {
    lrp_quote_params: payload,
  };
  return sendLRPRequest(apifunc, args);
};

const sendMostRecentDateLRPRequest = async () => {
  const apifunc = "getmostrecentdatesecurelrp";
  const args = {};

  return sendLRPRequest(apifunc, args);
};

const lrpService = {
  sendLRPQuoteRequest,
  sendMostRecentDateLRPRequest,
};

export default lrpService;
