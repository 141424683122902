import { LGMCommodityType, LGMQuoteType } from "@agdeveloper/domain";

const AllHedgemapTypes = Object.freeze({
  LGM: {
    DAIRY_CATTLE: {
      NO_TYPE_SPECIFIED: true,
    },
    SWINE: {
      FARROW_TO_FINISH: true,
      FINISHING: true,
      SEW_PIG_FINISHING: true,
    },
    CATTLE: {
      CALF_FINISHING: true,
      YEARLING_FINISHING: true,
    },
  },
  LRP: {
    FED_CATTLE: {
      STEERS_AND_HEIFERS: true,
    },
    SWINE: {
      NO_TYPE_SPECIFIED: true,
      UNBORN_SWINE: true,
    },
    FEEDER_CATTLE: {
      STEERS_WEIGHT_1: true,
      STEERS_WEIGHT_2: true,
      HEIFERS_WEIGHT_1: true,
      HEIFERS_WEIGHT_2: true,
      BRAHMAN_WEIGHT_1: true,
      BRAHMAN_WEIGHT_2: true,
      DAIRY_WEIGHT_1: true,
      DAIRY_WEIGHT_2: true,
      UNBORN_STEERS_AND_HEIFERS: true,
      UNBORN_BRAHMAN: true,
      UNBORN_DAIRY: true,
    },
  },
  DRP: {
    NO_TYPE_SPECIFIED: {
      NO_TYPE_SPECIFIED: true,
    },
  },
});

const setValuesToFalse = (obj) => {
  let newObj = { ...obj };

  for (let key in newObj) {
    if (typeof newObj[key] === "object") {
      newObj[key] = setValuesToFalse(newObj[key]);
    } else {
      newObj[key] = false;
    }
  }

  return newObj;
};

const cloneAllHedgemapTypes = () => {
  return Object.freeze({ ...AllHedgemapTypes });
  // JSON.parse(JSON.stringify(AllHedgemapTypes));
};

const notEmpty = (obj) => {
  return Object.keys(obj).length !== 0;
};

const deepEqual = (obj1, obj2) => {
  // Get keys of each object
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // If number of keys is different, they're not equal
  if (keys1.length !== keys2.length) return false;

  return keys1.every((key) => {
    const val1 = obj1[key];
    const val2 = obj2[key];

    // If both values are objects, recursively compare
    if (typeof val1 === "object" && typeof val2 === "object") {
      return deepEqual(val1, val2);
    }
    // Otherwise just compare values
    return val1 === val2;
  });
};
const policyDisplayMapping = {
  [LGMCommodityType.CATTLE]: "Cattle",
  [LGMCommodityType.SWINE]: "Swine",
  [LGMCommodityType.DAIRY_CATTLE]: "Dairy Cattle",
  FED_CATTLE: "Fed Cattle",
  FEEDER_CATTLE: "Feeder Cattle",
  [LGMQuoteType.NO_TYPE_SPECIFIED]: "No Type Selected",
};
const typeDisplayMapping = {
  [LGMQuoteType.YEARLING_FINISHING]: "Yearling Finishing",
  [LGMQuoteType.CALF_FINISHING]: "Calf Finishing",
  [LGMQuoteType.FINISHING]: "Finishing",
  [LGMQuoteType.FARROW_TO_FINISH]: "Farrow To Finish",
  [LGMQuoteType.SEW_PIG_FINISHING]: "SEW Pig Finishing",
  [LGMQuoteType.NO_TYPE_SPECIFIED]: "No Type Selected",
  STEERS_WEIGHT_1: "Steers Weight 1",
  STEERS_WEIGHT_2: "Steers Weight 2",
  HEIFERS_WEIGHT_1: "Heifers Weight 1",
  HEIFERS_WEIGHT_2: "Heifers Weight 2",
  BRAHMAN_WEIGHT_1: "Brahman Weight 1",
  BRAHMAN_WEIGHT_2: "Brahman Weight 2",
  DAIRY_WEIGHT_1: "Dairy Weight 1",
  DAIRY_WEIGHT_2: "Dairy Weight 2",
  UNBORN_STEERS_AND_HEIFERS: "Unborn Steers and Heifers",
  UNBORN_BRAHMAN: "Unborn Brahman",
  UNBORN_DAIRY: "Unborn Dairy",
  UNBORN_SWINE: "Unborn Swine",
  STEERS_AND_HEIFERS: "Steers and Heifers",
};

const dynamicallyDetermineTrueCount = (obj) => {
  let count = 0;
  for (const key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      //will recurse down into sub objects until it reaches a boolean
      count += dynamicallyDetermineTrueCount(obj[key]);
    } else if (typeof obj[key] === "boolean" && obj[key] === true) {
      //found our base case and add one if its true
      count++;
    }
  }

  return count;
};

export {
  AllHedgemapTypes,
  setValuesToFalse,
  cloneAllHedgemapTypes,
  notEmpty,
  deepEqual,
  policyDisplayMapping,
  typeDisplayMapping,
  dynamicallyDetermineTrueCount,
};
