import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { setLGMCattleCornTargetWeight } from "../features/LGMSlice";
import { LGMQuoteType } from "@agdeveloper/domain";

const TARGET_WEIGHT_MAP = {
  [LGMQuoteType.CALF_FINISHING]: {
    min: 50,
    max: 75,
  },
  [LGMQuoteType.YEARLING_FINISHING]: {
    min: 50,
    max: 85,
  },
};
function LGMCattleCornTargetWeight() {
  const dispatch = useAppDispatch();

  const { type } = useAppSelector((state) => state.lgm.input);

  const [bushels, setBushels] = useState(0);
  const [warningMessage, setWarningMessage] = useState("");
  const [touched, setTouched] = useState(false);

  const updateBushels = (event) => {
    setTouched(true);

    const val = event.target.value.replace(/[^0-9.]/g, "");
    setBushels(val);
  };

  useEffect(() => {
    dispatch(setLGMCattleCornTargetWeight(bushels));
  }, [bushels, dispatch]);

  useEffect(() => {
    const currentBushels = parseFloat(bushels.toString());
    if (
      (currentBushels < TARGET_WEIGHT_MAP[type.value]["min"] ||
        currentBushels > TARGET_WEIGHT_MAP[type.value]["max"]) &&
      touched
    ) {
      setWarningMessage(
        `Target Weight must be between ${TARGET_WEIGHT_MAP[type.value]["min"]} and ${TARGET_WEIGHT_MAP[type.value]["max"]} CWT.`,
      );
    } else {
      setWarningMessage("");
    }
    // dispatch(setCWT(current_CWT.toFixed(2)));
  }, [bushels, dispatch, type]);

  return (
    <div className="card w-100">
      <div style={{ minWidth: 300 }}>
        <div className="card-body">
          <h5 className="card-title">Corn Target Weight Quantity</h5>
          <p className="card-text">
            Enter a corn target weight between{" "}
            {TARGET_WEIGHT_MAP[type.value]["min"]} and{" "}
            {TARGET_WEIGHT_MAP[type.value]["max"]} bu.
          </p>
          <div className="input-group has-validation">
            <input
              type="text"
              name="cwt"
              id="cwt"
              className={`form-control ag-form-focus ${
                warningMessage !== "" && "is-invalid"
              }`}
              value={bushels}
              onChange={updateBushels}
            />
            <span className="input-group-text">bu</span>
            <div className="invalid-feedback">
              <p>{warningMessage}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LGMCattleCornTargetWeight;
