import React, { useEffect } from "react";
import { useState } from "react";
import { useAppDispatch } from "../../../app/redux/hooks";
import { resetDRPResults } from "../features/DRPSlice";

import DecisionToolContainer from "../../DecisionToolContainer";
import "./DRPDecisionTool.scss";
import DynamicDRPTool from "./DynamicDRPTool";

// import SingleQuote from "./SingleQuote/SingleQuote";
// import ClassVsComponent from "./ClassVsComponent/ClassVsComponent";
// import StripQuote from "./StripQuote/StripQuote";

function DRPDecisionTool() {
  const dispatch = useAppDispatch();

  const defaultButtonState = {
    single: false,
    class_vs_component: false,
    strip: false,
  };
  const [activeTab, setActiveTab] = useState(defaultButtonState);

  useEffect(() => {
    // On page load set first tab to active
    //clear quote results on page load as running classvscomponent/strip, navigating to another page then back would try to load thoe reuslts on single
    dispatch(resetDRPResults());

    setActiveTab((prevState) => ({
      ...prevState,
      single: true,
    }));
  }, [dispatch]);

  const setActive = (e) => {
    setActiveTab({
      ...defaultButtonState,
      [e.target.name]: true,
    });

    // Clear DRP Results
    dispatch(resetDRPResults());
  };

  return (
    <DecisionToolContainer title={"Dairy Revenue Protection Decision Tool"}>
      {/* Navigation  */}
      <div className="card-header">
        <ul role="tablist" className="nav nav-tabs card-header-tabs">
          <li className="nav-item dt-tabs-custom">
            <button
              role="tab"
              className={`nav-link ${activeTab.single && "active"}`}
              name="single"
              onClick={setActive}
              style={
                activeTab.single
                  ? { backgroundColor: "white" }
                  : { backgroundColor: "gainsboro", color: "black" }
              }
            >
              Single Quote
            </button>
          </li>
          <li className="nav-item dt-tabs-custom">
            <button
              role="tab"
              className={`nav-link ${activeTab.class_vs_component && "active"}`}
              name="class_vs_component"
              onClick={setActive}
              style={
                activeTab.class_vs_component
                  ? { backgroundColor: "white" }
                  : { backgroundColor: "gainsboro", color: "black" }
              }
            >
              Class vs Component
            </button>
          </li>
          <li className="nav-item dt-tabs-custom">
            <button
              role="tab"
              className={`nav-link ${activeTab.strip && "active"}`}
              name="strip"
              onClick={setActive}
              style={
                activeTab.strip
                  ? { backgroundColor: "white" }
                  : { backgroundColor: "gainsboro", color: "black" }
              }
            >
              Strip
            </button>
          </li>
        </ul>
      </div>
      {/* {activeTab.single && <SingleQuote />}
      {activeTab.class_vs_component && <ClassVsComponent />}
      {activeTab.strip && <StripQuote />} */}
      <DynamicDRPTool activeTab={activeTab} />

      {/* Footer */}
      <div className="w-100 px-0 py-0 mx-0" style={{ overflow: "hidden" }}>
        <div className="footer-custom row text-center w-100 mx-0 mb-3 mt-2">
          <div
            className="col-12 col-md-12 footer-custom text-center py-4 mx-auto px-4"
            style={{ fontSize: ".75em", maxWidth: "720px" }}
          >
            Patent Pending
            <br />
            &#169;2021 Ag Hedge Desk
            <br />
            All Rights Reserved
          </div>
        </div>
      </div>
    </DecisionToolContainer>
  );
}

export default DRPDecisionTool;
