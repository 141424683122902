import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../../Authentication/features/authSlice";
import DRPReducer from "../../DecisionTools/DRP/features/DRPSlice";
import LRPReducer from "../../DecisionTools/LRP/components/LRPSlice";
import LGMReducer from "../../DecisionTools/LGM/features/LGMSlice";
import hedgeMAPsReducer from "../../ViewHedgeMAPs/features/hedgeMAPsSlice";
import adminReducer from "../../Admin/features/adminSlice";
import resourcesReducer from "../../Resources/features/resourcesSlice";
import podcastsReducer from "../../Podcasts/features/podcastsSlice";
import producerHedgeMAPsReducer from "../../ProducerViewHedgeMAPs/features/producerHedgeMAPsSlice";
export const store = configureStore({
  reducer: {
    auth: authReducer,
    drp: DRPReducer,
    lrp: LRPReducer,
    lgm: LGMReducer,
    hedgeMAPs: hedgeMAPsReducer,
    admin: adminReducer,
    resources: resourcesReducer,
    podcasts: podcastsReducer,
    producerHedgeMAPs: producerHedgeMAPsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
