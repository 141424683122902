import React, { useState, useEffect } from "react";
import { useAppDispatch } from "../../../../../../app/redux/hooks";
import { setCWT } from "../../../LRPSlice";

const MIN_CWT = 1.4;
const MAX_CWT = 2.6;

function SwineTargetWeightSelection() {
  const dispatch = useAppDispatch();

  const [localCWT, setLocalCWT] = useState("0");
  const [warningMessage, setWarningMessage] = useState("");

  const updateCWT = (event) => {
    let val = String(event.target.value);

    if (Number.isNaN(Number(val))) {
      val = "0";
      setWarningMessage("Please enter a valid amount.");
    }
    setLocalCWT(val);
  };

  useEffect(() => {
    const parsed_CWT = parseFloat(localCWT);
    if (parsed_CWT < MIN_CWT || parsed_CWT > MAX_CWT) {
      setWarningMessage(
        `Target Weight must be between ${MIN_CWT} and ${MAX_CWT} CWT.`,
      );
    } else {
      setWarningMessage("");
    }
    dispatch(setCWT(parsed_CWT.toFixed(2)));
  }, [localCWT, dispatch]);

  useEffect(() => {
    setWarningMessage("");
  }, []);

  return (
    <div className="card w-100">
      <div className="mx-0 px-0" style={{ minWidth: 300 }}>
        <div className="card-body">
          <h5 className="card-title">Target Weight</h5>
          <p className="card-text">
            Enter a target weight between 1.4 and 2.6 CWT.
          </p>
          <div className="input-group has-validation">
            <input
              type="text"
              name="cwt"
              id="cwt"
              className={`form-control ag-form-focus ${
                warningMessage !== "" && "is-invalid"
              }`}
              value={localCWT}
              onChange={updateCWT}
            />
            <span className="input-group-text">CWT</span>
            <div className="invalid-feedback">
              <p>{warningMessage}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SwineTargetWeightSelection;
