import React, { useState, useEffect } from "react";
import { useAppDispatch } from "../../../../../../app/redux/hooks";
import { setHeadCount } from "../../../LRPSlice";

function SwineNumberHeadSelection() {
  const dispatch = useAppDispatch();
  const [numHeads, setNumHeads] = useState(0);

  const updateHeads = (event) => {
    const val = Number(event.target.value.replace(/\D/g, "")); // Replace non-digits with ''
    setNumHeads(val);
  };

  useEffect(() => {
    dispatch(setHeadCount(numHeads));
  }, [numHeads, dispatch]);

  return (
    <div className="card w-100">
      <div className="mx-0 px-0" style={{ minWidth: 300 }}>
        <div className="card-body">
          <h5 className="card-title">Number of Head</h5>
          <p className="card-text">Enter number of head.</p>
          <div className="input-group">
            <input
              type="text"
              name="num_heads"
              id="num_heads"
              className="form-control ag-form-focus"
              value={numHeads}
              onChange={updateHeads}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SwineNumberHeadSelection;
