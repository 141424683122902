import React, { useEffect, useState } from "react";
import { setComponentWeighting } from "../../features/DRPSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/redux/hooks";

interface sliderState {
  butterfat: { current: number; max: number };
  protein: { current: number; max: number };
  nonfat: { current: number; max: number };
}

function ComponentWeighting() {
  const dispatch = useAppDispatch();
  const restricted_message =
    "Nonfat percentage has been restricted to the indicated value for the selected Quarter / Sales Effective Date";

  const { selectedQuarter, componentWeighting, nonfatExtras } = useAppSelector(
    (state) => state.drp,
  );
  const [sliderValues, setSliderValues] = useState<sliderState>({
    ...componentWeighting.sliders,
  });
  const [isComponentRestricted, setIsComponentRestricted] = useState(false);

  //on change of quarter we need to check if that quarters nonfat is restricted or component restricted value is set
  useEffect(() => {
    console.info(selectedQuarter, nonfatExtras);
    setIsComponentRestricted(
      !isNaN(
        parseFloat(nonfatExtras.componentPriceWeightingFactorRestrictedValue),
      ) && nonfatExtras.isNonfatLocked,
      // && tool_in_use != "Strip"
    );

    if (
      !isNaN(
        parseFloat(nonfatExtras.componentPriceWeightingFactorRestrictedValue),
      ) &&
      nonfatExtras.isNonfatLocked
    ) {
      dispatch(
        setComponentWeighting({
          values: {
            butterfat: (
              3.25 +
              (2.25 / 45) * sliderValues.butterfat.current
            ).toFixed(2),
            protein: (
              2.75 +
              (1.75 / 35) * sliderValues.protein.current
            ).toFixed(2),
            nonfat:
              5 *
              (100 -
                parseFloat(
                  nonfatExtras.componentPriceWeightingFactorRestrictedValue,
                ) *
                  100),
            cmp_price_wf: (
              1 -
              0.05 *
                (100 -
                  parseFloat(
                    nonfatExtras.componentPriceWeightingFactorRestrictedValue,
                  ) *
                    100)
            ).toFixed(2),
          },
          sliders: {
            ...sliderValues,
          },
        }),
      );
    } else {
      dispatch(
        setComponentWeighting({
          values: {
            butterfat: (
              3.25 +
              (2.25 / 45) * sliderValues.butterfat.current
            ).toFixed(2),
            protein: (
              2.75 +
              (1.75 / 35) * sliderValues.protein.current
            ).toFixed(2),
            nonfat: 5 * sliderValues.nonfat.current,
            cmp_price_wf: (1 - 0.05 * sliderValues.nonfat.current).toFixed(2),
          },
          sliders: {
            ...sliderValues,
          },
        }),
      );
    }
  }, [
    selectedQuarter,
    nonfatExtras.componentPriceWeightingFactorRestrictedValue,
    nonfatExtras.isNonfatLocked,
  ]);
  useEffect(() => {
    // Input values validation
    // FIXME: Depth error when value out of range. Leads to app crashing.
    Object.keys(sliderValues).forEach((key) => {
      if (sliderValues[key].current > sliderValues[key].max) {
        setSliderValues((prevState) => {
          return {
            ...prevState,
            [key]: { ...prevState[key], current: prevState[key].max },
          };
        });

        return;
      }
      if (sliderValues[key].current < 0) {
        setSliderValues((prevState) => {
          return { ...prevState, [key]: { ...prevState[key], current: 0 } };
        });
      }
    });

    dispatch(
      setComponentWeighting({
        values: {
          butterfat: (
            3.25 +
            (2.25 / 45) * sliderValues.butterfat.current
          ).toFixed(2),
          protein: (2.75 + (1.75 / 35) * sliderValues.protein.current).toFixed(
            2,
          ),
          nonfat: 5 * sliderValues.nonfat.current,
          cmp_price_wf: (1 - 0.05 * sliderValues.nonfat.current).toFixed(2),
        },
        sliders: {
          ...sliderValues,
        },
      }),
    );
  }, [sliderValues, dispatch]);

  const updateSlider = (e) => {
    setSliderValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: {
          ...prevState[e.target.name],
          current: parseFloat(e.target.value),
        },
      };
    });
  };

  return (
    <div className="card h-100 w-100">
      <div className="card-body">
        <h5 className="card-title">Component-Based Weighting</h5>
        <p className="card-text">Select your component weighting.</p>
        <div className="form-group">
          {/* Butterfat */}
          <div className="mb-4">
            <div
              className="fw-bold d-inline-block"
              style={{ marginBottom: "1em" }}
            >
              Butterfat percentage
            </div>
            <div className="d-inline-block float-end fw-bold">
              {componentWeighting.values.butterfat}
            </div>
            <div className="form-group">
              <div className="d-inline-block align-text-top">
                3.25%
                <br />
              </div>
              <div className="d-inline-block">
                <input
                  onChange={updateSlider}
                  type="range"
                  min="0"
                  max="45"
                  id="butterfat"
                  name="butterfat"
                  value={sliderValues.butterfat.current}
                ></input>
              </div>
              <div className="d-inline-block align-text-top">
                5.5%
                <br />
              </div>
            </div>
          </div>
          {/* Protein */}
          <div className="mb-4">
            <div
              className="fw-bold d-inline-block"
              style={{ marginBottom: "1em" }}
            >
              Protein percentage
            </div>
            <div className="d-inline-block float-end fw-bold">
              {componentWeighting.values.protein}
            </div>
            <div className="form-group">
              <div className="d-inline-block align-text-top">
                2.75%
                <br />
              </div>
              <div className="d-inline-block">
                <input
                  onChange={updateSlider}
                  type="range"
                  min="0"
                  max="35"
                  id="protein"
                  name="protein"
                  value={sliderValues.protein.current}
                ></input>
              </div>
              <div className="d-inline-block align-text-top">
                4.5%
                <br />
              </div>
            </div>
          </div>
          {/* Non-Fat */}
          <div className="mb-4">
            <div
              className="fw-bold d-inline-block"
              style={{ marginBottom: "1em" }}
            >
              Nonfat percentage
            </div>
            <div className="d-inline-block float-end fw-bold">
              {isComponentRestricted
                ? 100 -
                  parseFloat(
                    nonfatExtras.componentPriceWeightingFactorRestrictedValue,
                  ) *
                    100
                : componentWeighting.values.nonfat}
              %
            </div>
            <div className="form-group">
              <div className="d-inline-block align-text-top">
                0%
                <br />
              </div>
              <div className="d-inline-block">
                <input
                  onChange={updateSlider}
                  type="range"
                  min="0"
                  max="20"
                  id="nonfat"
                  name="nonfat"
                  value={
                    isComponentRestricted
                      ? 100 -
                        parseFloat(
                          nonfatExtras.componentPriceWeightingFactorRestrictedValue,
                        ) *
                          100
                      : sliderValues.nonfat.current
                  }
                  disabled={isComponentRestricted}
                ></input>
              </div>
              <div className="d-inline-block align-text-top">
                100%
                <br />
              </div>
              <div className="d-inline-block text-center mt-2 w-100">
                <div className="d-inline-block m-auto">
                  <small>
                    <span className="fw-normal">
                      Component Price Weighting Factor:
                    </span>
                  </small>
                </div>
                <br />
                <div className="d-inline-block m-auto">
                  <small>
                    <span className="fw-bold">
                      {isComponentRestricted
                        ? nonfatExtras.componentPriceWeightingFactorRestrictedValue
                        : componentWeighting.values.cmp_price_wf}
                    </span>
                  </small>
                </div>
                {isComponentRestricted && (
                  <div
                    className="display-inline-block text-align-center"
                    style={{
                      fontStyle: "italic",
                      color: "#8e2736",
                      maxWidth: "250px",
                    }}
                  >
                    <small>{restricted_message}</small>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComponentWeighting;
