import React from "react";

type FormInputProps = {
  label: string;
  required: boolean;
  value: string;
  type: string;
  id: string;
  name: string;
  touched: boolean;
  message: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  [key: string]: any;
};
function EmailAddressInput({
  label,
  required,
  value,
  type,
  id,
  name,
  touched,
  message,
  onChange,
  ...props
}: FormInputProps) {
  return (
    <div className="form-group text-left small">
      <label htmlFor="email" className="form-label">
        {label}
        {required && <span style={{ color: "red" }}> *</span>}
      </label>
      <input
        {...props}
        className="form-control"
        value={value ? value : ""}
        onChange={onChange}
        type={type}
        name={name}
        id={id}
      />
      <p className="text-danger validation-error">{touched && message}</p>
    </div>
  );
}

export default EmailAddressInput;
