/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
// import { ListGroup } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../../app/redux/hooks";
import {
  authorizeProducerToViewHedgeMAP,
  getAllAgentsProducerHedgemapInfo,
  getPolicyProducersAssociatedUsers,
  unlinkProducerAggregatedToUser,
} from "../../../../ProducerViewHedgeMAPs/features/producerHedgeMAPsActions";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import SelectUserAuthorizedForPolicyProducer from "./SelectUserAuthorizedForPolicyProducer/SelectUserAuthorizedForPolicyProducer";
import SelectHedgeMAPTypes from "../AuthorizeHedgeMAPTypes/AuthorizeHedgeMAPTypes";
import {
  cloneAllHedgemapTypes,
  notEmpty,
} from "../../../utils/producerHedgeMAPsUtils";
import { resetPolicyProducerByUser } from "../../../../ProducerViewHedgeMAPs/features/producerHedgeMAPsSlice";
import SelectPolicyProducer from "./SelectPolicyProducer/SelectPolicyProducer";
import EditHedgeMAPVisibilityTableByPolicyProducer from "./EditHedgeMAPVisibilityTableByPolicyProducer/EditHedgeMAPVisibilityTableByPolicyProducer";

type ModalData = {
  userEmailAddress: string;
  policyProducerName: string;
  policyProducerAggregateID: string;
};

const AgentAuthorizeByHedgeMAP = () => {
  const dispatch = useAppDispatch();

  const { userData, policyProducerData, isError, isLoading, message } =
    useAppSelector((state) => state.producerHedgeMAPs.agentAuthorization);

  const userDataForSelectedPolicyProducerAggregate = useAppSelector(
    (state) => state.producerHedgeMAPs.agentAuthorizeProducerUser.userData,
  );

  useEffect(() => {
    dispatch(getAllAgentsProducerHedgemapInfo());

    return () => {
      //cleanup
      dispatch(resetPolicyProducerByUser());
    };
  }, []);

  const authorizeUserToViewPolicyProducer = () => {
    const hedgemap_info = {
      DisplayName: selectedPolicyProducer.DisplayName,
      PolicyProducerAggregateID:
        selectedPolicyProducer.PolicyProducerAggregateID,
      SelectedHedgemapTypes: cloneAllHedgemapTypes(),
    };

    dispatch(
      authorizeProducerToViewHedgeMAP({
        authorized_email: linkedUser,
        hedgemap_info: hedgemap_info,
      }),
    );
  };

  const [linkedUser, setLinkedUser] = useState("");

  const [selectedPolicyProducer, setSelectedPolicyProducer] = useState(
    {} as any,
  );

  const [showEditPermissionsModal, setShowEditPermissionsModal] =
    useState(false);

  const [selectedUser, setSelectedUser] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (selectedPolicyProducer && notEmpty(selectedPolicyProducer)) {
      dispatch(getPolicyProducersAssociatedUsers(selectedPolicyProducer));
    }
  }, [selectedPolicyProducer]);

  const [changesMade, setChangesMade] = useState(false);

  const [modalData, setModalData] = useState<ModalData | null>(null);
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);

  const handleUnlinkShow = (payload) => {
    setModalData({
      userEmailAddress: payload.authorized_email,
      policyProducerName: payload.hedgemap_info.DisplayName,
      policyProducerAggregateID:
        payload.hedgemap_info.PolicyProducerAggregateID,
    });
    setShowUnlinkModal(true);
  };
  return (
    <div>
      <div className="mt-4">
        {!isError ? (
          // !isLoading ? (
          policyProducerData &&
          policyProducerData.length > 0 &&
          userData &&
          userData.length > 0 ? (
            <div className="container">
              <div className="row g-3 mt-1">
                <div className="col-4">Select Policy Producer:</div>
                {selectedPolicyProducer.DisplayName && (
                  <div className="col-4">
                    Portal users that can view{" "}
                    {selectedPolicyProducer.DisplayName}'s hedgeMAPs:
                  </div>
                )}
              </div>
              <div className="row g-3 mt-1">
                <SelectPolicyProducer
                  policyProducerList={policyProducerData}
                  setSelectedPolicyProducer={setSelectedPolicyProducer}
                  selectedPolicyProducer={selectedPolicyProducer}
                />

                <SelectUserAuthorizedForPolicyProducer
                  usersList={userDataForSelectedPolicyProducerAggregate}
                  selectedUser={selectedUser}
                  setSelectedUser={setSelectedUser}
                  selectedPolicyProducerAggregate={selectedPolicyProducer}
                  handleShow={handleShow}
                  handleUnlinkShow={handleUnlinkShow}
                />

                {userDataForSelectedPolicyProducerAggregate &&
                  userDataForSelectedPolicyProducerAggregate.length > 0 &&
                  selectedUser &&
                  notEmpty(selectedUser) && (
                    <div className="col-4">
                      {userDataForSelectedPolicyProducerAggregate
                        .filter((email, index) => email === selectedUser)
                        .map((email, index) => {
                          return (
                            <SelectHedgeMAPTypes
                              user={email}
                              policyProducer={selectedPolicyProducer}
                              setChangesMade={setChangesMade}
                              showEditPermissionsModal={
                                showEditPermissionsModal
                              }
                              setShowEditPermissionsModal={
                                setShowEditPermissionsModal
                              }
                            />
                          );
                        })}
                      {/* );
                          })
                        )
                    )} */}
                    </div>
                  )}
              </div>

              {/* Modal */}
              <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>Confirm Authorization</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>
                    Linking this Policy Producer to a portal user will authorize
                    the portal user to view all HedgeMAPs associated with this
                    Policy Producer. To restrict this user to only certain types
                    of HedgeMAPs, continue with the authorization by clicking
                    'Confirm' and then restrict permissions on the next page by
                    clicking 'View Types' next to the User / Policy Producer
                    combination you would like to restrict.
                  </p>

                  <div className="row g-3 mt-1">
                    {/* Labels row */}
                    <div className="col-4 d-flex justify-content-center">
                      <label htmlFor="user-select">Producer</label>
                    </div>
                    <div className="col-4 d-flex align-items-center justify-content-center">
                      {/* This is just the space for the arrow. No label needed. */}
                    </div>
                    <div className="col-4 d-flex justify-content-center">
                      <label htmlFor="user-select">User</label>
                    </div>

                    <div className="col-4">
                      <div>{selectedPolicyProducer.DisplayName}</div>
                    </div>
                    <div className="col-4 d-flex align-items-center justify-content-center">
                      <span>⇄</span>
                    </div>
                    <div className="col-4">
                      <select
                        name="user-select"
                        id="user-select"
                        className="form-control ag-form-focus"
                        defaultValue=""
                        onChange={(e) => {
                          setLinkedUser(e.target.value);
                        }}
                      >
                        <option value="" disabled hidden>
                          Select User
                        </option>
                        {userData.map((email, index) => (
                          <option
                            key={index}
                            value={email}
                            disabled={
                              userDataForSelectedPolicyProducerAggregate.filter(
                                (item) => item === email,
                              ).length > 0
                                ? true
                                : false
                            }
                          >
                            {email}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    disabled={linkedUser ? false : true}
                    variant="primary"
                    onClick={() => {
                      authorizeUserToViewPolicyProducer();

                      handleClose();
                    }}
                  >
                    Confirm
                  </Button>
                </Modal.Footer>
              </Modal>

              {/* Modal for confiring they want to unlink producer */}
              <Modal
                show={showUnlinkModal}
                onHide={() => {
                  setShowUnlinkModal(false);

                  setModalData(null);
                }}
                size="lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Confirm Removal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>
                    If you proceed portal user{" "}
                    {modalData && modalData.userEmailAddress} will no longer be
                    able to view any HedgeMAPs for Policy Producer{" "}
                    {modalData && modalData.policyProducerName}. Are you sure
                    you want to <strong>remove</strong> all HedgeMAP permissions
                    for{" "}
                    <strong>{modalData && modalData.userEmailAddress}</strong>{" "}
                    on{" "}
                    <strong>{modalData && modalData.policyProducerName}</strong>
                    ?
                  </p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setShowUnlinkModal(false);
                      setModalData(null);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    // disabled={!notEmpty(linkedPolicyProducer)}
                    variant="primary"
                    onClick={() => {
                      const hedgemap_info = {
                        DisplayName: modalData && modalData.policyProducerName,
                        PolicyProducerAggregateID:
                          modalData && modalData.policyProducerAggregateID,
                      };
                      const payload = {
                        authorized_email:
                          modalData && modalData.userEmailAddress,
                        hedgemap_info: hedgemap_info,
                      };

                      dispatch(unlinkProducerAggregatedToUser(payload));

                      setModalData(null);
                      setShowUnlinkModal(false);
                    }}
                  >
                    Yes, remove
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          ) : (
            <div>
              {userData && userData.length < 1 ? (
                <div className="row g-3 mt-1">
                  <div className="col-12">
                    {isLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        {"  "}
                        <span>Loading Policy Producers...</span>
                      </>
                    ) : (
                      <p>
                        No users available to authorize. You may only authorize
                        users who have designated you as their Agent.
                      </p>
                    )}
                  </div>
                </div>
              ) : (
                <div className="row g-3 mt-1">
                  <div className="col-12">
                    {isLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        {"  "}
                        <span>Loading Policy Producers...</span>
                      </>
                    ) : (
                      <p>
                        You are not listed as an Agent on any available
                        HedgeMAPs.
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          )
        ) : (
          <div>{message}</div>
        )}
      </div>
      <EditHedgeMAPVisibilityTableByPolicyProducer
        changesMade={changesMade}
        setSelectedPolicyProducer={setSelectedPolicyProducer}
        setSelectedUser={setSelectedUser}
        setShowEditPermissionsModal={setShowEditPermissionsModal}
        handleUnlinkShow={handleUnlinkShow}
      />
    </div>
  );
};
export default AgentAuthorizeByHedgeMAP;
