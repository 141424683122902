import { createSlice } from "@reduxjs/toolkit";
import {
  authorizeAgent,
  getAuthorizedAgentsList,
  getSubscriptionsForProject,
  getSupportedEmailQuoteProjects,
  submitSubscriptionUpdate,
} from "./adminActions";

const initialState = {
  agentAuthorizationList: {
    authorizedEmails: [] as any[],
    isError: false,
    message: "",
    isLoading: false,
  },
  authorizeAgent: {
    isError: false,
    isSuccess: false,
    message: "",
    isLoading: false,
  },
  supportedEmailQuoteProjects: {
    isError: false,
    projects: {},
    message: "",
    isLoading: false,
  },
  targetProjectSubscriptions: {
    isError: false,
    data: {},
    errors: {},
    checks: {},
    message: "",
    isLoading: false,
  },
  targetProjectSubmittion: {
    isError: false,
    data: {},
    // errors: {},
    // checks: {},
    message: "",
    isLoading: false,
  },
};

export const hedgeMAPsSlice = createSlice({
  name: "admin",
  initialState,

  reducers: {},
  extraReducers: (builder) => {
    // Get HedgeMAP Listing`
    builder
      .addCase(getAuthorizedAgentsList.fulfilled, (state, action) => {
        state.agentAuthorizationList.authorizedEmails =
          action.payload.authorized_emails;
        state.agentAuthorizationList.isError = false;
        state.agentAuthorizationList.message = "";
        state.agentAuthorizationList.isLoading = false;
      })
      .addCase(getAuthorizedAgentsList.pending, (state, action) => {
        state.agentAuthorizationList.isError = false;
        state.agentAuthorizationList.message = "Loading...";
        state.agentAuthorizationList.isLoading = true;
      })
      .addCase(getAuthorizedAgentsList.rejected, (state, action) => {
        state.agentAuthorizationList.isError = true;
        state.agentAuthorizationList.message = action.payload as string;
        state.agentAuthorizationList.isLoading = false;
      });

    // Download HedgeMAP
    builder
      .addCase(authorizeAgent.fulfilled, (state, action) => {
        state.authorizeAgent.isError = false;
        state.authorizeAgent.isSuccess = true;
        state.authorizeAgent.message = `Successfully authorized ${action.payload.email}!`;
        state.authorizeAgent.isLoading = false;
      })
      .addCase(authorizeAgent.pending, (state, action) => {
        state.authorizeAgent.isError = false;
        state.authorizeAgent.message = "Loading...";
        state.authorizeAgent.isLoading = true;
      })
      .addCase(authorizeAgent.rejected, (state, action) => {
        state.authorizeAgent.isError = true;
        state.authorizeAgent.message = action.payload as string;
        state.authorizeAgent.isLoading = false;
      });

    //support email quotes projects
    builder
      .addCase(getSupportedEmailQuoteProjects.fulfilled, (state, action) => {
        state.supportedEmailQuoteProjects.isError = false;
        state.supportedEmailQuoteProjects.projects =
          action.payload.supported_projects;
        state.supportedEmailQuoteProjects.isLoading = false;
      })
      .addCase(getSupportedEmailQuoteProjects.pending, (state, action) => {
        state.supportedEmailQuoteProjects.isError = false;
        state.supportedEmailQuoteProjects.message = "Loading...";
        state.supportedEmailQuoteProjects.isLoading = true;
      })
      .addCase(getSupportedEmailQuoteProjects.rejected, (state, action) => {
        state.supportedEmailQuoteProjects.isError = true;
        state.supportedEmailQuoteProjects.message = action.payload as string;
        state.supportedEmailQuoteProjects.isLoading = false;
      });

    //support email quotes projects
    builder
      .addCase(getSubscriptionsForProject.fulfilled, (state, action) => {
        state.targetProjectSubscriptions.isError = false;
        state.targetProjectSubscriptions.data = JSON.parse(
          action.payload.subscription_data,
        );
        state.targetProjectSubscriptions.errors = JSON.parse(
          action.payload.subscription_errors,
        );

        state.targetProjectSubscriptions.checks = action.payload.checks;
        state.targetProjectSubscriptions.isLoading = false;
      })
      .addCase(getSubscriptionsForProject.pending, (state, action) => {
        state.targetProjectSubscriptions.isError = false;
        state.targetProjectSubscriptions.message = "Loading...";
        state.targetProjectSubscriptions.isLoading = true;
      })
      .addCase(getSubscriptionsForProject.rejected, (state, action) => {
        state.targetProjectSubscriptions.isError = true;
        state.targetProjectSubscriptions.message = action.payload as string;

        state.targetProjectSubscriptions.isLoading = false;
      });

    builder
      .addCase(submitSubscriptionUpdate.fulfilled, (state, action) => {
        state.targetProjectSubmittion.isError = false;
        state.targetProjectSubmittion.message = action.payload as string;
        state.targetProjectSubmittion.isLoading = false;
      })
      .addCase(submitSubscriptionUpdate.pending, (state, action) => {
        state.targetProjectSubmittion.isError = false;
        state.targetProjectSubmittion.message = "Submiting...";
        state.targetProjectSubmittion.isLoading = true;
      })
      .addCase(submitSubscriptionUpdate.rejected, (state, action) => {
        state.targetProjectSubmittion.isError = true;
        state.targetProjectSubmittion.message = action.payload as string;
        state.targetProjectSubmittion.isLoading = false;
      });
  },
});

// export const {} = hedgeMAPsSlice.actions;

export default hedgeMAPsSlice.reducer;
