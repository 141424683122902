import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";

import React, { useEffect, useState } from "react";
import {
  checkAgencyRegistration,
  checkAgentAuthorizedProducer,
  checkAgentRegistrationAuthorization,
  checkEmailVerification,
} from "../features/authActions";

function LoadingScreen() {
  const {
    isLoggedIn,
    userType,
    registrationAuthorization,
    emailVerification,
    agencyProfile,
  } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    if (
      userType === "AGENT" &&
      registrationAuthorization.hasCheckedRegistrationAuthorization &&
      emailVerification.hasCheckedEmailVerification &&
      agencyProfile.hasCheckedAgencyProfile
    ) {
      if (
        registrationAuthorization.isRegistrationAuthorized &&
        emailVerification.isEmailVerified &&
        agencyProfile.isAgencyProfileComplete
      ) {
        navigate("/lgm-decisiontool");
      } else {
        navigate("/manage-account");
      }
    } else if (
      userType === "USER" &&
      registrationAuthorization.hasCheckedRegistrationAuthorization &&
      emailVerification.hasCheckedEmailVerification
    ) {
      if (
        registrationAuthorization.isRegistrationAuthorized &&
        emailVerification.isEmailVerified
      ) {
        navigate("/lgm-decisiontool");
      } else {
        navigate("/manage-account");
      }
    }
  }, [
    registrationAuthorization,
    emailVerification,
    agencyProfile,
    navigate,
    userType,
  ]);
  useEffect(() => {
    if (isLoggedIn && userType === "AGENT" && !checked) {
      //check registration authorization
      dispatch(checkAgentRegistrationAuthorization());

      //check agency profile completed
      dispatch(checkAgencyRegistration());

      //check email verification

      dispatch(checkEmailVerification());
      setChecked(true);
    } else if (isLoggedIn && userType === "USER" && !checked) {
      //check email verification

      dispatch(checkEmailVerification());

      //check if agent has authorized them
      dispatch(checkAgentAuthorizedProducer());
      setChecked(true);
    } else {
      // console.info("Not logged in or not an agent");
    }
    // we are not adding isLoggedIn and userType to the dependency array because we only want to run this effect once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  return <div>Logging in..</div>;
}

export default LoadingScreen;
