import {
  sendDRPRequest,
  sendResourcesRequest,
} from "../../../app/utils/sendRequest";
import * as globals from "../../../globals";

const states_hash_reversed = globals.states_hash_reversed;

const sendMostRecentPricesRequest = async (state: string) => {
  const apifunc = "getmostrecentpricessecure";
  const args = {
    stateabbr: states_hash_reversed[state],
  };
  console.log("args", args);

  return sendDRPRequest(apifunc, args);
};

const sendMostRecentDateRequest = async (date: string = "") => {
  const apifunc = "getmostrecentdatesecure";
  const args = {
    date: date,
  };

  return sendDRPRequest(apifunc, args);
};

const sendHistoricalPricesRequest = async (products, quarters) => {
  const apifunc = "gethistoricalprices_v1";
  const args = {
    qtrs: quarters,
    products: products,
  };
  return sendDRPRequest(apifunc, args);
};

const sendDRPQuoteRequest = async (payload: string) => {
  const apifunc = "getdrpquotesecure";
  const args = {
    drp_quote_params: payload,
    return_domain_object: globals.use_domain_objects_drp,
    pass_domain_object: globals.use_domain_objects_drp,
  };

  return sendDRPRequest(apifunc, args);
};

const sendStripDRPQuoteRequest = async (payload: string) => {
  const apifunc = "drp_strip_quote_secure";
  const args = {
    drp_strip_quote_params: payload,
    return_domain_object: globals.use_domain_objects_drp,
    pass_domain_object: globals.use_domain_objects_drp,
  };

  return sendDRPRequest(apifunc, args);
};
const sendClassVsComponentDRPQuoteRequest = async (payload: string) => {
  const apifunc = "drpsecure_equalize_premiums";
  const args = {
    drp_quote_params: payload,
    return_domain_object: globals.use_domain_objects_drp,
    pass_domain_object: globals.use_domain_objects_drp,
  };

  return sendDRPRequest(apifunc, args);
};
const sendDownloadDRPQuoteRequest = async (download_data) => {
  const apifunc = "downloaddrpquotemulti";
  const args = {
    quoteres: download_data["quoteres"],
    market_conditions: download_data["market_conditions"],
    flag: download_data["tool_in_use"],
    coverage_type: download_data["coverage_type"],
  };
  return sendResourcesRequest(apifunc, args);
};

const sendYieldsRequest = async (state_abbr: string, sales_effective_date) => {
  const apifunc = "getyieldssecure";
  const args = {
    stateabbr: state_abbr,
    saleseffectivedate: sales_effective_date,
  };

  return sendDRPRequest(apifunc, args);
};

// const sendComponentPriceRestrictedValuesRequest = async (
//   sales_effective_date
// ) => {
//   const apifunc = "getcomponentpricerestrictedvalues";
//   const args = {
//     saleseffectivedate: sales_effective_date,
//   };

//   return sendDRPRequest(apifunc, args);
// };
const drpService = {
  sendMostRecentPricesRequest,
  sendMostRecentDateRequest,
  sendHistoricalPricesRequest,
  sendDRPQuoteRequest,
  sendDownloadDRPQuoteRequest,
  sendYieldsRequest,
  // sendComponentPriceRestrictedValuesRequest,
  sendClassVsComponentDRPQuoteRequest,
  sendStripDRPQuoteRequest,
};

export default drpService;
