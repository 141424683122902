// import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import {
  downloadHedgeMAP,
  // getAgentsAssociatedHedgeMAPs,
  getAllAgentsProducerHedgemapInfo,
  getPolicyProducersAssociatedUsers,
  // getAllAgentsUsersAssociatedPolicyProducerAggregates,
  getProducersHedgeMAPs,
  getUsersAssociatedPolicyProducerAggregates,
  unlinkProducerAggregatedToUser,
  getSpecificHedgemapInfo,
  authorizeProducerToViewHedgeMAP,
  getAllHedgemapInfoForAgent,
} from "./producerHedgeMAPsActions";

const initialState = {
  //AGENT ACCOUNT - used to store users registered under agent: userData, policy producers linked to agent: policyproducerData, and hedgemap info for the hedgemap types
  agentAuthorization: {
    hedgemapData: [] as any[],
    userData: [] as string[],
    policyProducerData: [] as string[],
    isError: false,
    message: "",
    isLoading: false,
  },

  //AGENT ACCOUNT - stores teh policy producer aggregated linked to a user by hedgemap
  agentAuthorizeUserProducer: {
    // userData: [] as any[],
    policyProducerData: [] as any[],
    isError: false,
    message: "",
    isLoading: false,
  },

  //AGENT ACCOUNT - stores teh user linked to a policy producer aggregated by hedgemap
  agentAuthorizeProducerUser: {
    userData: [] as any[],
    // policyProducerData: [] as any[],
    isError: false,
    message: "",
    isLoading: false,
  },

  //AGENT ACCOUNT - stores the data for bottom table for agent to view everything at once
  agentsAssociatedData: {
    data: {} as any,
    isError: false,
    message: "",
    isLoading: false,
  },
  selectedHedgemapTypes: {
    data: {},
    hedgemapFilesExist: {},
    isError: false,
    message: "",
    isLoading: false,
  },

  unlinkProducersFromUsers: {
    isError: false,
    message: "",
    isLoading: false,
  },

  //PRODUCER ACCOUNT - gets producers hedgemaps stores
  producerHedgeMAPs: {
    hedgemapData: [] as any[],
    isError: false,
    message: "",
    isLoading: false,
  },
  //PRODUCER ACCOUNT - object that manages the download of the hedgemaps
  downloadHedgeMAP: {
    isError: false,
    message: "",
    isLoading: false,
    fileName: "",
    id: "",
  },
};

export const ProducerHedgeMAPsSlice = createSlice({
  name: "ProducerHedgeMAPs",
  initialState,
  reducers: {
    resetDownloadHedgeMAP: (state) => {
      state.downloadHedgeMAP = initialState.downloadHedgeMAP;
    },
    resetPolicyProducerByUser: (state) => {
      state.agentAuthorizeUserProducer =
        initialState.agentAuthorizeUserProducer;
      state.agentAuthorizeProducerUser =
        initialState.agentAuthorizeProducerUser;

      state.downloadHedgeMAP = initialState.downloadHedgeMAP;
      state.agentAuthorization = initialState.agentAuthorization;
      state.producerHedgeMAPs = initialState.producerHedgeMAPs;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      authorizeProducerToViewHedgeMAP.fulfilled,
      (state, action) => {
        state.agentAuthorization.hedgemapData =
          action.payload.policy_producer_aggregates;
        state.agentAuthorization.policyProducerData =
          action.payload.policy_producers;

        state.agentAuthorizeUserProducer.policyProducerData =
          action.payload.policy_producer_aggregates;

        state.agentAuthorizeProducerUser.userData = action.payload.users.sort();

        state.agentAuthorization.isLoading = false;
        state.agentAuthorization.isError = false;
        state.agentAuthorization.message = "";
      },
    );
    builder.addCase(
      authorizeProducerToViewHedgeMAP.pending,
      (state, action) => {
        state.agentAuthorization.isLoading = true;
        state.agentAuthorization.isError = false;
        state.agentAuthorization.message = "";
      },
    );

    builder.addCase(
      authorizeProducerToViewHedgeMAP.rejected,
      (state, action) => {
        state.agentAuthorization.isLoading = false;
        state.agentAuthorization.isError = true;
        state.agentAuthorization.message = action.payload as string;
      },
    );

    builder.addCase(
      unlinkProducerAggregatedToUser.fulfilled,
      (state, action) => {
        state.agentAuthorization.hedgemapData =
          action.payload.policy_producer_aggregates;
        state.agentAuthorization.policyProducerData =
          action.payload.policy_producers;

        state.agentAuthorizeUserProducer.policyProducerData =
          action.payload.policy_producer_aggregates;

        state.unlinkProducersFromUsers.isError = false;
        state.unlinkProducersFromUsers.message = "";
        state.unlinkProducersFromUsers.isLoading = false;
      },
    );

    builder.addCase(unlinkProducerAggregatedToUser.pending, (state, action) => {
      state.unlinkProducersFromUsers.isError = false;
      state.unlinkProducersFromUsers.message = "";
      state.unlinkProducersFromUsers.isLoading = true;
    });

    builder.addCase(
      unlinkProducerAggregatedToUser.rejected,
      (state, action) => {
        state.unlinkProducersFromUsers.isError = true;
        state.unlinkProducersFromUsers.message = action.payload as string;
        state.unlinkProducersFromUsers.isLoading = false;
      },
    );
    // getProducersHedgeMAPs
    builder.addCase(getProducersHedgeMAPs.fulfilled, (state, action) => {
      state.producerHedgeMAPs.hedgemapData = action.payload.results;
      state.producerHedgeMAPs.isError = false;
      state.producerHedgeMAPs.message = "";
      state.producerHedgeMAPs.isLoading = false;
    });

    builder.addCase(getProducersHedgeMAPs.pending, (state, action) => {
      state.producerHedgeMAPs.isError = false;
      state.producerHedgeMAPs.message = "";
      state.producerHedgeMAPs.isLoading = true;
    });

    builder.addCase(getProducersHedgeMAPs.rejected, (state, action) => {
      state.producerHedgeMAPs.isError = true;
      state.producerHedgeMAPs.message = action.payload as string;
      state.producerHedgeMAPs.isLoading = false;
    });

    // getUsersAssociatedPolicyProducerAggregates
    builder.addCase(
      getUsersAssociatedPolicyProducerAggregates.fulfilled,
      (state, action) => {
        state.agentAuthorizeUserProducer.policyProducerData =
          action.payload.policy_producer_aggregates;
        // state.agentAuthorization.userData
        state.agentAuthorizeUserProducer.isError = false;
        state.agentAuthorizeUserProducer.message = "";
        state.agentAuthorizeUserProducer.isLoading = false;
      },
    );

    builder.addCase(
      getUsersAssociatedPolicyProducerAggregates.pending,
      (state, action) => {
        state.agentAuthorizeUserProducer.isError = false;
        state.agentAuthorizeUserProducer.message = "";
        state.agentAuthorizeUserProducer.isLoading = true;
      },
    );

    builder.addCase(
      getUsersAssociatedPolicyProducerAggregates.rejected,
      (state, action) => {
        state.agentAuthorizeUserProducer.isError = true;
        state.agentAuthorizeUserProducer.message = action.payload as string;
        state.agentAuthorizeUserProducer.isLoading = false;
      },
    );

    // getPolicyProducersAssociatedUsers
    builder.addCase(
      getPolicyProducersAssociatedUsers.fulfilled,
      (state, action) => {
        state.agentAuthorizeProducerUser.userData = action.payload.users.sort();
        state.agentAuthorizeProducerUser.isError = false;
        state.agentAuthorizeProducerUser.message = "";
        state.agentAuthorizeProducerUser.isLoading = false;
      },
    );

    builder.addCase(
      getPolicyProducersAssociatedUsers.pending,
      (state, action) => {
        state.agentAuthorizeProducerUser.isError = false;
        state.agentAuthorizeProducerUser.message = "";
        state.agentAuthorizeProducerUser.isLoading = true;
      },
    );

    builder.addCase(
      getPolicyProducersAssociatedUsers.rejected,
      (state, action) => {
        state.agentAuthorizeProducerUser.isError = true;
        state.agentAuthorizeProducerUser.message = action.payload as string;
        state.agentAuthorizeProducerUser.isLoading = false;
      },
    );

    //Gets the Policy producer Aggregated entries through the PPA mappin table associated with teh agent to
    //get list of Policy producer assoiated with agent
    //getAllAgentsProducerHedgemapInfo - Getss policy producers linked to agent as well as portal users
    builder.addCase(
      getAllAgentsProducerHedgemapInfo.fulfilled,
      (state, action) => {
        state.agentAuthorization.hedgemapData = action.payload.hedgemap_info;
        state.agentAuthorization.userData = action.payload.users.sort();
        state.agentAuthorization.policyProducerData =
          action.payload.policy_producers;

        state.agentAuthorization.isError = false;
        state.agentAuthorization.message = "";
        state.agentAuthorization.isLoading = false;
      },
    );

    builder.addCase(
      getAllAgentsProducerHedgemapInfo.pending,
      (state, action) => {
        state.agentAuthorization.isError = false;
        state.agentAuthorization.message = "";
        state.agentAuthorization.isLoading = true;
      },
    );

    builder.addCase(
      getAllAgentsProducerHedgemapInfo.rejected,
      (state, action) => {
        state.agentAuthorization.isError = true;
        state.agentAuthorization.message = action.payload as string;
        state.agentAuthorization.isLoading = false;
      },
    );

    builder
      .addCase(downloadHedgeMAP.fulfilled, (state, action: any) => {
        let regexp = /filename=(.*?)$/;
        let saving_file_name =
          action.payload.headers["content-disposition"].match(regexp)[1];

        const fileURL = window.URL.createObjectURL(action.payload.data);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = saving_file_name;
        alink.click();

        state.downloadHedgeMAP.isError = false;
        state.downloadHedgeMAP.message = "HedgeMAP downloaded successfully.";
        state.downloadHedgeMAP.isLoading = false;

        state.downloadHedgeMAP.fileName = "";
        state.downloadHedgeMAP.id = "";
      })
      .addCase(downloadHedgeMAP.pending, (state, action) => {
        state.downloadHedgeMAP.isError = false;
        state.downloadHedgeMAP.id = "";

        state.downloadHedgeMAP.message = "Loading...";
        state.downloadHedgeMAP.isLoading = true;
        state.downloadHedgeMAP.fileName = action.meta.arg["file_name"];
      })
      // .addCase(downloadHedgeMAP.rejected, (state, action:{payload:{id:string, message:string}}) => {
      // .addCase(downloadHedgeMAP.rejected, (state, action:PayloadAction) => {
      .addCase(downloadHedgeMAP.rejected, (state, action: any) => {
        state.downloadHedgeMAP.isError = true;
        state.downloadHedgeMAP.id = action.payload?.id as string;
        state.downloadHedgeMAP.message = action.payload?.message as string;
        state.downloadHedgeMAP.isLoading = false;
      });

    builder.addCase(getSpecificHedgemapInfo.fulfilled, (state, action) => {
      state.selectedHedgemapTypes.data = action.payload.permissions;
      state.selectedHedgemapTypes.hedgemapFilesExist =
        action.payload.hedgemap_files_exist;

      state.selectedHedgemapTypes.isError = false;
      state.selectedHedgemapTypes.message = "";
      state.selectedHedgemapTypes.isLoading = false;
    });

    builder.addCase(getSpecificHedgemapInfo.pending, (state, action) => {
      state.selectedHedgemapTypes.isError = false;
      state.selectedHedgemapTypes.message = "";
      state.selectedHedgemapTypes.isLoading = true;
    });

    builder.addCase(getSpecificHedgemapInfo.rejected, (state, action) => {
      state.selectedHedgemapTypes.isError = true;
      state.selectedHedgemapTypes.message = action.payload as string;
      state.selectedHedgemapTypes.isLoading = false;
    });

    builder.addCase(getAllHedgemapInfoForAgent.fulfilled, (state, action) => {
      console.log("getAllHedgemapInfoForAgent.fulfilled", new Date());
      state.agentsAssociatedData.data = action.payload;
      state.agentsAssociatedData.isError = false;
      state.agentsAssociatedData.message = "";
      state.agentsAssociatedData.isLoading = false;
    });

    builder.addCase(getAllHedgemapInfoForAgent.pending, (state, action) => {
      state.agentsAssociatedData.data = {};
      state.agentsAssociatedData.isError = false;
      state.agentsAssociatedData.message = "";
      state.agentsAssociatedData.isLoading = true;
    });

    builder.addCase(getAllHedgemapInfoForAgent.rejected, (state, action) => {
      state.agentsAssociatedData.isError = true;
      state.agentsAssociatedData.message = action.payload as string;
      state.agentsAssociatedData.isLoading = false;
    });
  },
});

export const { resetDownloadHedgeMAP, resetPolicyProducerByUser } =
  ProducerHedgeMAPsSlice.actions;

export default ProducerHedgeMAPsSlice.reducer;
