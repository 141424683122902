import { useAppSelector } from "../../app/redux/hooks";

import React, { useEffect } from "react";
import { getAgencyRegistration } from "../features/authActions";
import { useAppDispatch } from "../../app/redux/hooks";

const field_mapping = {
  agency_name: "Agency Name",
  agency_email: "Agency Email",
  primary_phone: "Agency Primary Phone",
  secondary_phone: "Agency Secondary Phone",
  email_address: "Email Address",
  first_name: "First Name",
  last_name: "Last Name",
  mobile_carrier_name: "Mobile Carrier",
  mobile_phone: "Mobile Phone",
};

const agencyInformation = {
  "Agency Name": "",
  "Agency Email": "",
  "Agency Primary Phone": "",
  "Agency Secondary Phone": "",
};

const agentInformation = {
  "Email Address": "",
  "First Name": "",
  "Last Name": "",
  "Mobile Phone": "",
  "Mobile Carrier": "",
};

const ViewAgencyProfile = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAgencyRegistration());
  }, [dispatch]);

  const { agencyProfile } = useAppSelector((state) => state.auth);

  const updateAgencyProfile = (agency) => {
    for (const key in agency) {
      if (key in field_mapping) {
        if (field_mapping[key] in agencyInformation) {
          agencyInformation[field_mapping[key]] = agency[key];
        }
      }
    }
  };

  const updateAgentProfile = (agent) => {
    for (const key in agent) {
      if (key in field_mapping) {
        if (field_mapping[key] in agentInformation) {
          agentInformation[field_mapping[key]] = agent[key];
        }
      }
    }
  };

  useEffect(() => {
    if (agencyProfile.agency_profile) {
      updateAgencyProfile(agencyProfile.agency_profile);
    }
    if (agencyProfile.agent_profile) {
      updateAgentProfile(agencyProfile.agent_profile);
    }
  }, [agencyProfile]);
  return (
    <div>
      <h4>Profile Information:</h4>

      <div className="mt-3">
        <table>
          <thead></thead>
          <tbody>
            {agencyInformation &&
              Object.entries(agencyInformation).map(([key, value]) => (
                <tr key={key}>
                  <td
                    style={{
                      fontWeight: "bold",
                      textAlign: "right",
                      verticalAlign: "top",
                    }}
                  >
                    {key}:
                  </td>
                  <td className="ps-2">{value}</td>
                </tr>
              ))}
            <tr>
              <td>&nbsp;</td>
            </tr>
            {agentInformation &&
              Object.entries(agentInformation).map(([key, value]) => (
                <tr key={key}>
                  <td
                    style={{
                      fontWeight: "bold",
                      textAlign: "right",
                      verticalAlign: "top",
                    }}
                  >
                    {key}:
                  </td>
                  <td className="ps-2">{value}</td>
                </tr>
              ))}
            <tr>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br></br>
    </div>
  );
};
export default ViewAgencyProfile;
