import React, { useEffect } from "react";
import SwinePracticeAndTypeSelection from "./SwinePracticeAndTypeSelection/SwinePracticeAndTypeSelection";
import SwineNumberHeadSelection from "./SwineNumberHeadSelection/SwineNumberHeadSelection";
import SwineTargetWeightSelection from "./SwinetargetWeightSelection/SwineTargetWeightSelection";

import { resetLRPParams } from "../../LRPSlice";

import { useAppDispatch } from "../../../../../app/redux/hooks";

const TYPES = ["No Type Specified", "Unborn Swine"];

function LRPSwineMarketingPlan() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetLRPParams);
  }, [dispatch]);

  return (
    <div>
      <div className="container-fluid">
        <div className="row my-2">
          <div className="col m-0 p-0">
            <SwinePracticeAndTypeSelection types={TYPES} />
          </div>
        </div>
        <div className="row my-2">
          <div className="col me-1 mt-1 p-0">
            <SwineNumberHeadSelection />
          </div>
          <div className="col m-0 mt-1 p-0">
            <SwineTargetWeightSelection />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LRPSwineMarketingPlan;
