import React, { useEffect, useState } from "react";
import { states_hash } from "../../../../globals";
import { useAppDispatch, useAppSelector } from "../../../../app/redux/hooks";
import { setCurrentState, setQuarterProduction } from "../../features/DRPSlice";
// import "../../DecisionToolStyles.scss";

function QuarterlyProductionAndState() {
  const states = Object.values(states_hash);

  const { currentState, quarterProduction } = useAppSelector(
    (state) => state.drp,
  );

  const [currentProduction, setCurrentProduction] = useState(quarterProduction);
  const [selectedState, setSelectedState] = useState(currentState);

  const dispatch = useAppDispatch();

  const changeProduction = (e) => {
    let value = e.target.value;
    let intValue = parseInt(value.replace(/,/g, ""));

    if (intValue < 0 || isNaN(intValue)) {
      intValue = 0;
    }

    setCurrentProduction(intValue.toLocaleString("en-US"));
  };

  const changeSelectedState = (e) => {
    setSelectedState(e.target.value);
  };

  useEffect(() => {
    dispatch(setCurrentState(selectedState));
  }, [selectedState, dispatch]);

  useEffect(() => {
    dispatch(setQuarterProduction(currentProduction));
  }, [currentProduction, dispatch]);

  return (
    <div className="card p-2 my-1">
      <div className="card-body">
        <h5 className="card-title">Production and State</h5>
        <p className="card-text">
          Please enter your Quarterly Production and your State.
        </p>
        <div className="row g-2 align-items-center">
          <div className="col-auto align-middle">
            <input
              type="string"
              name="qt-production"
              id="qt-production"
              className="form-control ag-form-focus"
              aria-describedby="qt-production-help"
              value={quarterProduction}
              onChange={changeProduction}
            />
          </div>
          <div className="col-auto ">
            <span id="qt-production-help" className="form-text ">
              lbs. per quarter
            </span>
          </div>
        </div>
        <div className="row g-3 mt-1">
          {/* Production and State */}
          <div>
            <select
              name="state-select"
              id="state-select"
              className="form-select text-white bg-secondary ag-form-focus col-4 pb-1"
              defaultValue={currentState}
              onChange={changeSelectedState}
              style={{ width: "30%", minWidth: "150px", maxWidth: "33%" }}
            >
              {states.map((state, index) => {
                return (
                  <option key={index} value={state as string}>
                    {state as string}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuarterlyProductionAndState;
