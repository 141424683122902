import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <div>
        <div className="text-center my-4">
          <h4>PRIVACY STATEMENT – MOBILE APPLICATION</h4>
        </div>
        <div>
          <p>
            At Ag Hedge Desk, we consider privacy to be fundamental to our
            relationship with our users. We are committed to maintaining the
            confidentiality, integrity and security of our users’ non-public
            information. Accordingly, we have developed internal policies to
            provide the highest level of confidentiality possible while still
            meeting the needs of our users. In order to achieve this goal, Ag
            Hedge Desk will strive to collect only the minimum amount of
            information necessary to provide you with the services you have
            requested, and to improve the quality of those services over time.
          </p>
          <p>
            The following sections describe what information we collect, why we
            collect it, and whether we share it with anyone.
          </p>
          <div>
            <div>
              What information do we collect?
              <div style={{ marginLeft: "30px" }}>
                <ul>
                  <li>Your name</li>
                  <li>Your email address </li>
                  <li>Your verified mobile phone number </li>
                  <li>Your designated Insurance Agent’s email address </li>
                  <li>
                    Device information (e.g., Device ID, operating system
                    version)
                  </li>
                  <li>
                    Information about how you interact with the app (e.g., page
                    loads, performance metrics, errors)
                  </li>
                </ul>
              </div>
              How do we use the information?
              <div style={{ marginLeft: "30px" }}>
                <ul>
                  <li>
                    Your personal contact information (name, email, mobile
                    phone) and your Insurance Agent’s email address will be used
                    for authentication and authorization, for any necessary
                    account-related communications, and to verify your identity.
                  </li>
                  <li>
                    Information that you enter into the app will be used to
                    provide you with the services that you are requesting (e.g.,
                    running an insurance quote). In certain circumstances, such
                    information may be shared with your Insurance Agent.
                  </li>
                  <li>
                    Device information may be used to provide you with services
                    (e.g., if you request push notifications for certain
                    products), or for general debugging, error correction,
                    performance or feature-related improvements.
                  </li>
                  <li>
                    Information about how you interact with the app may be used
                    for debugging, error correction, and general performance or
                    feature-related improvements.
                  </li>
                </ul>
              </div>
              What information do we share?
              <div style={{ marginLeft: "30px" }}>
                <ul>
                  <li>
                    We will not disclose any information that we receive from
                    you or collect through your usage of the mobile application
                    to any third parties, except to your designated Insurance
                    Agent.
                  </li>
                  <li>
                    Any party receiving any shared information as covered by
                    this policy will use it only for the services required and
                    as allowed by applicable law or regulation, and is not
                    permitted to share or use this information for any other
                    purpose.
                  </li>
                </ul>
              </div>
              Data retention and deletion
              <div style={{ marginLeft: "30px" }}>
                <ul>
                  <li>
                    You may request deletion of your account and all related
                    information at any time through the settings page of the
                    mobile application. Once an account deletion request has
                    been processed, all personal information and account-related
                    information will be purged from Ag Hedge Desk servers,
                    systems, and backups within 45 days.
                  </li>
                  <li>
                    Personal and account-related information for active accounts
                    will be retained indefinitely.
                  </li>
                </ul>
              </div>
              <p>
                Ag Hedge Desk reserves the right to change this privacy
                statement, and to apply changes to information previously
                collected, as permitted by law. Ag Hedge Desk will inform users
                of any such changes as required by law.
              </p>
              <p>
                Questions regarding this privacy policy may be addressed to
                &lt;privacy@aghedgedesk.com&gt;.
              </p>
            </div>
          </div>
        </div>
        <div className="text-center">
          &copy; 2024 Ag Hedge Desk
          <br />
          All rights reserved.
        </div>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
