import React, { useEffect } from "react";
import DecisionToolContainer from "../../DecisionToolContainer";
import LGMPolicySelector from "../components/LGMPolicySelector";
import LGMMarketingPlan from "../components/LGMMarketingPlan";
import LGMResultTableContainer from "../components/ResultTableContainer";

import "./LGMDecisionTool.scss";
import LGMTypeSelector from "../components/LGMTypeSelector";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { resetLGMQuoteResults } from "../features/LGMSlice";
import LGMCattleInputs from "../components/LGMCattleInputs";

function LGMDecisionTool() {
  const dispatch = useAppDispatch();

  const { sales_effective_date } = useAppSelector((state) => state.lgm);
  useEffect(() => {
    // On page load set first tab to active
    //clear quote results on page load as running classvscomponent/strip, navigating to another page then back would try to load thoe reuslts on single
    dispatch(resetLGMQuoteResults());
  }, [dispatch]);
  return (
    <DecisionToolContainer title={"Livestock Gross Margin Decision Tool"}>
      <div>
        <div className="col my-1" style={{ minWidth: 300 }}>
          <LGMPolicySelector />
        </div>
        <div className="col my-1" style={{ minWidth: 300 }}>
          <LGMTypeSelector />
        </div>
        <div className="col my-1" style={{ minWidth: 300 }}>
          <LGMMarketingPlan />
        </div>
        {new Date(sales_effective_date.value) >= new Date("2024-07-01") && (
          <div className="col my-1" style={{ minWidth: 300 }}>
            <LGMCattleInputs />
          </div>
        )}
        <LGMResultTableContainer />
      </div>
    </DecisionToolContainer>
  );
}

export default LGMDecisionTool;
