import { sendAuthRequest } from "../../app/utils/sendRequest";

// Interfaces
export interface authCredentials {
  email: string;
  password: string;
}
export interface authCredentialsProducer extends authCredentials {
  agent_email: string;
}
export interface resetValues {
  rcode: string;
  email: string;
  new_password: string;
}

// Auth functions used by reducers in authSlice

const doLoginProducer = async (userCredentials: authCredentials) => {
  return sendAuthRequest("auth/sessionLogin", {
    email: userCredentials.email,
    password: userCredentials.password,
  });
};
const doLoginAgent = async (userCredentials: authCredentials) => {
  return sendAuthRequest("auth/sessionLogin", {
    email: userCredentials.email,
    password: userCredentials.password,
  });
};
const doCheckLogin = async () => {
  return sendAuthRequest("auth/checkLogin", {});
};
const doLogin = async (userCredentials: authCredentials) => {
  return sendAuthRequest("auth/sessionLogin", {
    email: userCredentials.email,
    password: userCredentials.password,
  });
};
const doLogout = async () => {
  return sendAuthRequest("auth/sessionLogout", {});
};

const verifyUserType = async () => {
  return sendAuthRequest("auth/verify-user-type", {});
};
const doRegisterProducer = async (
  registerCredentials: authCredentialsProducer,
) => {
  return sendAuthRequest("auth/registerUser", {
    email: registerCredentials.email,
    password: registerCredentials.password,
    agent_email: registerCredentials.agent_email,
  });
};
const doRegisterAgent = async (registerCredentials: authCredentials) => {
  return sendAuthRequest("auth/registerAgent", {
    email: registerCredentials.email,
    password: registerCredentials.password,
  });
};
const doRequestPasswordReset = async (email: string) => {
  return sendAuthRequest("auth/send-password-reset", {
    email: email,
  });
};

const doPasswordReset = async (
  email: string,
  r_code: string,
  password: string,
) => {
  return sendAuthRequest("auth/password-reset-finalize", {
    new_password: password,
    rcode: r_code,
    email: email,
  });
};

const requestVerificationEmail = async () => {
  return sendAuthRequest("auth/send-email-verification", {});
};

const checkEmailVerification = async () => {
  return sendAuthRequest("auth/verifyEmailCheck", {});
};

//Agent Auth Producers
const getAllUsersForAgent = async () => {
  return sendAuthRequest("auth/get-agents-producers", {});
};
const authorizeOrDenyProducerAccess = async (params) => {
  const args = {
    is_authorized: params.is_authorized,
    user_email: params.user_email,
  };
  return sendAuthRequest("auth/authorize-or-deny-producer-access", args);
};
const checkAgentAuthorizedProducer = async () => {
  return sendAuthRequest(
    "auth/check-user-agent-registration-authorization",
    {},
  );
};
const createAgencyProfile = async (data) => {
  let args = {
    agencyData: data.agencyData,
    agencyPrimaryContactData: data.agencyPrimaryContactData,
  };

  return sendAuthRequest("auth/create-agency-profile", args);
};

const checkAgentRegistrationAuthorization = async () => {
  return sendAuthRequest("auth/check-authorized-registration", {});
};
const checkAgencyRegistration = async () => {
  return sendAuthRequest("auth/check-agency-registration", {});
};
const sendAgentAuthorizationEmail = async (params) => {
  const args = {
    producerEmailAddress: params.producerEmailAddress.trim(),
    agentEmailAddress: params.agentEmailAddress.trim(),
    is_after_registration: params.isAfterRegistration,
  };
  return sendAuthRequest("auth/send-agent-authorization-request-email", args);
};
const getLastEnteredAgentEmail = async () => {
  return sendAuthRequest(
    "auth/get-last-agent-email-entered-for-registration-authorization",
    {},
  );
};

const getAgencyRegistration = async () => {
  return sendAuthRequest("auth/get-agency-registration", {});
};
const getPermissions = async () => {
  return sendAuthRequest("auth/check-permissions", {});
};
const alertAccountDeletionRequest = async () => {
  return sendAuthRequest("auth/request-account-deletion", {});
};

const authService = {
  doLoginProducer,
  doLoginAgent,
  doLogout,
  verifyUserType,
  doRegisterProducer,
  doRegisterAgent,
  doRequestPasswordReset,
  doPasswordReset,
  requestVerificationEmail,
  checkEmailVerification,
  getAllUsersForAgent,
  authorizeOrDenyProducerAccess,
  checkAgentAuthorizedProducer,
  createAgencyProfile,
  checkAgentRegistrationAuthorization,
  checkAgencyRegistration,
  sendAgentAuthorizationEmail,
  getLastEnteredAgentEmail,
  getAgencyRegistration,
  getPermissions,
  alertAccountDeletionRequest,
  doLogin,
  doCheckLogin,
};

export default authService;
