// import { Level, Options } from './services/log.service';

// export const log_options: Options = {
//   // level: Level.DEBUG,
//   level: Level.ERROR,
//   // timestamp: false,
//   debug: (args: any[]) => {
//     // your custom logic, for example send log to server
//   },
//   log: undefined,
//   info: undefined,
//   error: undefined,
//   warning: undefined,
//   all: undefined,
// };

let deploy = "dev.localhost"; // No default value

if (import.meta.env.VITE_REACT_APP_DEPLOY_FOR) {
  deploy = import.meta.env.VITE_REACT_APP_DEPLOY_FOR;
}
export const deployfor = deploy;
