import { createSlice } from "@reduxjs/toolkit";
import * as globals from "../../../globals";
import {
  getMostRecentDateSecureLGM,
  getMonthsToShow,
  getLGMQuoteSecure,
  getInitalExpectedGrossMargins,
} from "./LGMActions";

import {
  SalesEffectiveDate,
  LGMQuoteResult,
  LGMCommodityType,
  LGMQuoteType,
  StateUSA,
} from "@agdeveloper/domain";
let currMonth = new Date().getMonth();
let target_months = new Array(10);
let months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
let base_marketing_plan: any = {};
let base_swine_marketing_plan: any = {};

for (let i = 0; i < 10; i++) {
  base_marketing_plan[months[(currMonth + 1 + i) % 12]] = 0;
}
for (let i = 0; i < 5; i++) {
  base_swine_marketing_plan[months[(currMonth + 1 + i) % 12]] = 0;
}
for (let i = 0; i < 10; i++) {
  target_months[i] = months[(currMonth + 1 + i) % 12];
}

const defaultLGMResults = {
  lgm_quote_results: null as any,
  monthly_gross_margins: null as any,
  loading: false,
  error: false,
  message: "",
  extra_data: null,
};
const defaultExpectedGrossMargins = {
  [LGMCommodityType.CATTLE]: {
    [LGMQuoteType.YEARLING_FINISHING]: null as any,
    [LGMQuoteType.CALF_FINISHING]: null as any,
  },
  [LGMCommodityType.SWINE]: {
    [LGMQuoteType.FARROW_TO_FINISH]: null as any,
    [LGMQuoteType.FINISHING]: null as any,
    [LGMQuoteType.SEW_PIG_FINISHING]: null as any,
  },
  [LGMCommodityType.DAIRY_CATTLE]: {
    [LGMQuoteType.NO_TYPE_SPECIFIED]: null as any,
  },
};
const initialState = {
  input: {
    state_name: new StateUSA("Minnesota"),
    cattle_marketing_plan: base_marketing_plan,
    milk_marketing_plan: base_marketing_plan,
    dairy_corn_meal_feed: base_marketing_plan,
    dairy_soybean_meal_feed: base_marketing_plan,
    swine_marketing_plan: base_swine_marketing_plan,
    deductible_selection: 0,
    policy: new LGMCommodityType("CATTLE"),
    type: new LGMQuoteType("YEARLING_FINISHING"),
    beginning_farmer: "Minnesota",
    cattle_corn_target_weight: 0,
    cattle_live_cattle_target_weight: 0,
    cattle_feeder_cattle_target_weight: 0,
  },
  inputs_changed: false,
  target_months: target_months,
  lgmQuoteResults: defaultLGMResults,

  expectedGrossMarginsLGM: {
    expectedGrossMargins: defaultExpectedGrossMargins as any,
    isError: false,
    isLoading: true,
  },
  message: "",
  isError: false,
  sales_effective_date: "" as any,
  indemnityBasePrices: {
    cattle: 0,
    swine: 0,
    milk: 0,
    feeder_cattle: 0,
    corn: 0,
    soybean: 0,
  },
  estimatedIndemnities: [] as any,
  expectedNetToProducerValues: [] as any,
  indexValue: 0,
};

export const LGMSlice = createSlice({
  name: "LGM",
  initialState,
  reducers: {
    setLGMPolicy: (state, action) => {
      state.input.policy = action.payload;
      state.inputs_changed = true;
    },
    setLGMType: (state, action) => {
      state.input.type = action.payload;
      state.inputs_changed = true;
    },
    setLGMCattleMarketingPlan: (state, action) => {
      state.input.cattle_marketing_plan = action.payload;
      state.inputs_changed = true;
    },
    setLGMSwineMarketingPlan: (state, action) => {
      state.input.swine_marketing_plan = action.payload;
      state.inputs_changed = true;
    },
    setLGMDairyMarketingPlan: (state, action) => {
      state.input.milk_marketing_plan = action.payload;
      state.inputs_changed = true;
    },
    setLGMCornMarketingPlan: (state, action) => {
      state.input.dairy_corn_meal_feed = action.payload;
      state.inputs_changed = true;
    },
    setLGMSoybeanMarketingPlan: (state, action) => {
      state.input.dairy_soybean_meal_feed = action.payload;
      state.inputs_changed = true;
    },
    setLGMCattleCornTargetWeight: (state, action) => {
      state.input.cattle_corn_target_weight = action.payload;
      state.inputs_changed = true;
    },
    setLGMCattleliveCattleTargetWeight: (state, action) => {
      state.input.cattle_live_cattle_target_weight = action.payload;
      state.inputs_changed = true;
    },
    setLGMCattleFeederCattleTargetWeight: (state, action) => {
      state.input.cattle_feeder_cattle_target_weight = action.payload;
      state.inputs_changed = true;
    },
    setIndemnityBasePrices: (state, action) => {
      state.indemnityBasePrices = {
        ...action.payload,
      };
    },
    setIndemnityValues: (state, action) => {
      state.estimatedIndemnities = action.payload;
    },
    setExpectedNetToProducerValues: (state, action) => {
      state.expectedNetToProducerValues = action.payload;
    },

    setIndexValue: (state, action) => {
      state.indexValue = action.payload;
    },
    resetLGMQuoteResults: (state) => {
      state.lgmQuoteResults = defaultLGMResults;
    },
  },

  extraReducers: (builder) => {
    // Get Most Recent Prices Secure
    builder
      .addCase(getMostRecentDateSecureLGM.fulfilled, (state, action) => {
        state.isError = false;
        // console.log("action.payload", action.payload);
        state.sales_effective_date = globals.use_domain_objects_lgm
          ? SalesEffectiveDate.from_json(action.payload)
          : // new SalesEffectiveDate("20240523")
            action.payload;
        // state.sales_effective_date =  action.payload
      })
      .addCase(getMostRecentDateSecureLGM.rejected, (state, action) => {
        state.isError = true;
        state.message = action.payload as string;
      });
    builder
      .addCase(getMonthsToShow.fulfilled, (state, action) => {
        let base_marketing_plan = {};
        let base_swine_marketing_plan = {};
        action.payload.dates.slice(0, 10).forEach((date: any) => {
          base_marketing_plan[date] = 0;
        });
        action.payload.dates.slice(0, 5).forEach((date: any) => {
          base_swine_marketing_plan[date] = 0;
        });
        state.isError = false;
        state.target_months = action.payload.dates.slice(0, 10);
        state.input.cattle_marketing_plan = base_marketing_plan;
        state.input.milk_marketing_plan = base_marketing_plan;
        state.input.dairy_corn_meal_feed = base_marketing_plan;
        state.input.dairy_soybean_meal_feed = base_marketing_plan;
        state.input.swine_marketing_plan = base_swine_marketing_plan;
      })
      .addCase(getMonthsToShow.rejected, (state, action) => {
        state.isError = true;
        state.message = action.payload as string;
      });

    // Get LGM Quote
    builder
      .addCase(getLGMQuoteSecure.fulfilled, (state, action) => {
        // console.log(action.payload);
        state.lgmQuoteResults.lgm_quote_results = globals.use_domain_objects_lgm
          ? action.payload.lgm_quote_results.map((lgm_quote_result) =>
              LGMQuoteResult.from_json(lgm_quote_result),
            )
          : action.payload;

        // console.log(state.lgmQuoteResults.lgm_quote_results);

        state.lgmQuoteResults.extra_data = action.payload.extra_data;
        state.lgmQuoteResults.monthly_gross_margins =
          action.payload.monthly_gross_margins;
        state.lgmQuoteResults.loading = false;
        state.lgmQuoteResults.error = false;
        state.lgmQuoteResults.message = "";
        state.inputs_changed = false;
      })
      .addCase(getLGMQuoteSecure.pending, (state) => {
        state.lgmQuoteResults.loading = true;
      })
      .addCase(getLGMQuoteSecure.rejected, (state, action) => {
        state.lgmQuoteResults.lgm_quote_results = null;
        state.lgmQuoteResults.loading = false;
        state.lgmQuoteResults.error = true;
        state.lgmQuoteResults.message = action.payload as any;
      });

    // Get Expected Gross Margins
    builder
      .addCase(getInitalExpectedGrossMargins.fulfilled, (state, action) => {
        //console.info(action.payload);
        state.expectedGrossMarginsLGM.expectedGrossMargins = {
          [LGMCommodityType.CATTLE]: {
            [LGMQuoteType.CALF_FINISHING]:
              action.payload[LGMCommodityType.CATTLE][
                LGMQuoteType.CALF_FINISHING
              ],
            [LGMQuoteType.YEARLING_FINISHING]:
              action.payload[LGMCommodityType.CATTLE][
                LGMQuoteType.YEARLING_FINISHING
              ],
          },
          [LGMCommodityType.SWINE]: {
            [LGMQuoteType.FARROW_TO_FINISH]:
              action.payload[LGMCommodityType.SWINE][
                LGMQuoteType.FARROW_TO_FINISH
              ],
            [LGMQuoteType.FINISHING]:
              action.payload[LGMCommodityType.SWINE][LGMQuoteType.FINISHING],
            [LGMQuoteType.SEW_PIG_FINISHING]:
              action.payload[LGMCommodityType.SWINE][
                LGMQuoteType.SEW_PIG_FINISHING
              ],
          },
        };
        state.expectedGrossMarginsLGM.isError = false;
        state.expectedGrossMarginsLGM.isLoading = false;
      })
      .addCase(getInitalExpectedGrossMargins.pending, (state) => {
        state.expectedGrossMarginsLGM.isLoading = true;
      })
      .addCase(getInitalExpectedGrossMargins.rejected, (state, action) => {
        state.expectedGrossMarginsLGM.expectedGrossMargins =
          defaultExpectedGrossMargins;
        state.expectedGrossMarginsLGM.isError = true;
        state.expectedGrossMarginsLGM.isLoading = false;
      });
    // // Get/Update Historical Prices
    // builder
    //   .addCase(updateHistoricalPrices.fulfilled, (state, action) => {
    //     state.historicalPrices = action.payload;
    //     state.isChartLoading = false;
    //   })
    //   .addCase(updateHistoricalPrices.rejected, (state, action) => {
    //     state.message = action.payload as string;
    //     state.isChartLoading = false;
    //   })
    //   .addCase(updateHistoricalPrices.pending, (state) => {
    //     state.isChartLoading = true;
    //   });

    // // Get/Update most recent date
    // builder.addCase(getMostRecentDateSecure.fulfilled, (state, action) => {
    //   state.salesEffectiveDate = action.payload;
    // });

    // // Get DRP Quote
    // builder
    //   .addCase(getDRPQuoteSecure.fulfilled, (state, action) => {
    //     state.drpQuoteResults.data = action.payload;
    //     state.drpQuoteResults.loading = false;
    //     state.drpQuoteResults.error = false;
    //     state.drpQuoteResults.message = "";
    //     state.drpInputsChanged = false;
    //     state.indemnityType = action.payload["TypeAbbreviation"];
    //   })
    //   .addCase(getDRPQuoteSecure.pending, (state) => {
    //     state.drpQuoteResults.loading = true;
    //   })
    //   .addCase(getDRPQuoteSecure.rejected, (state, action) => {
    //     state.drpQuoteResults.data = null;
    //     state.drpQuoteResults.loading = false;
    //     state.drpQuoteResults.error = true;
    //     state.drpQuoteResults.message = action.payload as string;
    //   });

    // // Get yields data
    // // TODO: Yields error handling
    // builder.addCase(getYields.fulfilled, (state, action) => {
    //   state.yields = action.payload;
    // });

    // //get restricted values
    // builder.addCase(
    //   getComponentPriceRestrictedValues.fulfilled,
    //   (state, action) => {
    //     console.log("action.payload", action.payload);
    //     state.nonfatExtras.componentPriceRestrictedValues = action.payload;
    //   }
    // );
  },
});

export const {
  setLGMPolicy,
  setLGMType,
  setLGMCattleMarketingPlan,
  setLGMSwineMarketingPlan,
  setLGMDairyMarketingPlan,
  setLGMCornMarketingPlan,
  setLGMSoybeanMarketingPlan,
  setIndemnityBasePrices,
  setIndemnityValues,
  setExpectedNetToProducerValues,
  setIndexValue,
  resetLGMQuoteResults,
  setLGMCattleCornTargetWeight,
  setLGMCattleliveCattleTargetWeight,
  setLGMCattleFeederCattleTargetWeight,
} = LGMSlice.actions;

export default LGMSlice.reducer;
