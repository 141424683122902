import { createSlice } from "@reduxjs/toolkit";
import { getPodcasts, downloadPodcast } from "./podcastsActions";

type PodcastDownloadState = {
  isError: boolean;
  message: string;
  isLoading: boolean;
  data: any;
  fileName: string;
  description?: string;
  type?: string;
};
const initialState = {
  // podcasts: {
  podcastList: [] as PodcastDownloadState[],
  isError: false,
  message: "",
  isLoading: false,

  // },
  // downloadPostcast: {
  //   isError: false,
  //   message: "",
  //   isLoading: false,
  //   fileName: "",
  // },
};

export const podcastSlice = createSlice({
  name: "podcasts",
  initialState,

  reducers: {
    //updateDownloadPercentForPodcast: (state, action) => {
    // const targetIndex = state.podcastList.findIndex(
    //    (podcastObj) => podcastObj.fileName === action.payload.name
    //  );
    //  state.podcastList[targetIndex].downloadPercent = action.payload.percent;
    // },
  },
  extraReducers: (builder) => {
    // Get HedgeMAP Listing`
    builder
      .addCase(getPodcasts.fulfilled, (state, action) => {
        state.podcastList = action.payload.map((podcast: any) => {
          return {
            isError: false,
            message: "",
            isLoading: false,
            data: undefined,
            fileName: podcast.name,
            description: podcast?.description,
            type: podcast?.type,
          };
        });

        state.isError = false;
        state.message = "";
        state.isLoading = false;
      })
      .addCase(getPodcasts.pending, (state, action) => {
        state.isError = false;
        state.message = "Loading...";
        state.isLoading = true;
      })
      .addCase(getPodcasts.rejected, (state, action) => {
        state.isError = true;
        state.message = action.payload as string;
        state.isLoading = false;
      });

    // Download Podcast
    builder
      .addCase(downloadPodcast.fulfilled, (state, action) => {
        const playable_audio = window.URL.createObjectURL(
          action.payload.data.data,
        );
        const targetIndex = state.podcastList.findIndex(
          (podcastObj) => podcastObj.fileName === action.payload.name,
        );

        state.podcastList[targetIndex].data = playable_audio;
        state.podcastList[targetIndex].isError = false;
        state.podcastList[targetIndex].message = "";
        state.podcastList[targetIndex].isLoading = false;
      })

      .addCase(downloadPodcast.pending, (state, action) => {
        const targetIndex = state.podcastList.findIndex(
          (podcastObj) => podcastObj.fileName === action.meta.arg,
        );

        state.podcastList[targetIndex].isError = false;
        state.podcastList[targetIndex].message = "Loading podcast...";
        state.podcastList[targetIndex].isLoading = true;
      })

      //   state.downloadPostcast.fileName = action.meta.arg[3];
      // })
      .addCase(downloadPodcast.rejected, (state, action) => {
        const targetIndex = state.podcastList.findIndex(
          (podcastObj) => podcastObj.fileName === action.meta.arg,
        );

        state.podcastList[targetIndex].isError = true;
        state.podcastList[targetIndex].message = action.payload as string;
        state.podcastList[targetIndex].isLoading = false;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = podcastSlice.actions;

export default podcastSlice.reducer;
