import {
  sendDownloadHedgeMAPsBase64Request,
  sendDownloadHedgeMAPsRequest,
  sendViewHedgeMAPsRequest,
} from "../../app/utils/sendRequest";
import * as globals from "../../globals";
const sendGetHedgeMAPListingRequest = async () => {
  const apifunc = `service-view-hedgemaps/get-hedgemap-listing`;
  const args = {};

  return sendViewHedgeMAPsRequest(apifunc, args);
};

const sendDownloadHedgeMAPRequest = async (hedgemapDetails: string[]) => {
  const apifunc = `service-view-hedgemaps/download-hedgemap`;
  const args = {
    hedgemap_details: hedgemapDetails,
    src: globals.src,
  };

  return sendDownloadHedgeMAPsRequest(apifunc, args);
};

const sendDownloadSingleInsuranceTypeHedgeMAPsRequest = async (
  hedgemapDetails: string[],
) => {
  const apifunc = `service-view-hedgemaps/download-single-insurance-type-hedgemaps-for-agency`;
  const args = {
    hedgemap_details: hedgemapDetails,
    src: globals.src,
  };

  return sendDownloadHedgeMAPsBase64Request(apifunc, args);
};

const hedgeMAPsService = {
  sendGetHedgeMAPListingRequest,
  sendDownloadHedgeMAPRequest,
  sendDownloadSingleInsuranceTypeHedgeMAPsRequest,
};

export default hedgeMAPsService;
