export function expected_butter_price(
  class3_price: number,
  protein_price: number,
  other_solids_price: number,
  kwargs,
) {
  return (
    (class3_price - (protein_price * 3.1 + other_solids_price * 5.9) * 0.965) /
    3.5
  );
}

export function expected_protein_price(
  class3_price: number,
  butter_price: number,
  other_solids_price: number,
  kwargs,
) {
  return (
    ((class3_price - butter_price * 3.5) / 0.965 - other_solids_price * 5.9) /
    3.1
  );
}

export function expected_othersolids_price(
  class3_price: number,
  butterfat_price: number,
  protein_price: number,
  kwargs,
) {
  return (
    ((class3_price - butterfat_price * kwargs.BUTTERFAT_PERCENTAGE) /
      kwargs.CLASS3_MULTIPLIER -
      protein_price * kwargs.PROTEIN_PERCENTAGE) /
    kwargs.SOLIDS_MULTIPLIER_CLASS
  );
}

export function expected_nonfatsolids_price(
  class4_price: number,
  butterfat_price: number,
  kwargs,
) {
  return (
    (class4_price - butterfat_price * kwargs.BUTTERFAT_PERCENTAGE) /
    kwargs.CLASS4_MULTIPLIER /
    kwargs.NONFAT_SOLIDS_MULTIPLIER
  );
}

export function expected_butter_price_c4(
  class4_price: number,
  nonfat_solids_price: number,
  kwargs,
) {
  return (
    (class4_price -
      nonfat_solids_price *
        kwargs.NONFAT_SOLIDS_MULTIPLIER *
        kwargs.CLASS4_MULTIPLIER) /
    kwargs.BUTTERFAT_PERCENTAGE
  );
}

export function expected_class4_price(
  nonfat_solids_price: number,
  butterfat_price: number,
  kwargs,
) {
  return (
    nonfat_solids_price *
      kwargs.NONFAT_SOLIDS_MULTIPLIER *
      kwargs.CLASS4_MULTIPLIER +
    butterfat_price * kwargs.BUTTERFAT_PERCENTAGE
  );
}

export function expected_class3_price(
  protein_price: number,
  butter_price: number,
  other_solids_price: number,
  kwargs,
) {
  return (
    (protein_price * 3.1 + other_solids_price * 5.9) * 0.965 +
    butter_price * 3.5
  );
}
