import { createSlice } from "@reduxjs/toolkit";
import {
  downloadFile,
  downloadLGMvsDRPComparison,
  downloadLGMvsLRPCattle,
  downloadLGMvsLRPSwine,
} from "./resourcesActions";

const initialState = {
  downloadResource: {
    isError: false,
    message: "",
    isLoading: false,
    fileName: "",
  },
};

const _handleDownload = (action) => {
  let regexp = /filename=(.*?)$/;
  let saving_file_name =
    action.payload.headers["content-disposition"].match(regexp)[1];

  const fileURL = window.URL.createObjectURL(action.payload.data);

  let alink = document.createElement("a");
  alink.href = fileURL;
  alink.download = saving_file_name;
  alink.click();
  return saving_file_name;
};
export const resourcesSlice = createSlice({
  name: "resources",
  initialState,
  reducers: {
    // resetDownloadHedgeMAP: (state) => {
    //   state.downloadHedgeMAP = initialState.downloadHedgeMAP;
    // },
  },
  extraReducers: (builder) => {
    //downloadLGMvsDRPComparison
    builder
      .addCase(downloadLGMvsDRPComparison.fulfilled, (state, action) => {
        const filename = _handleDownload(action);

        state.downloadResource.isError = false;
        state.downloadResource.message = "Resource downloaded successfully.";
        state.downloadResource.isLoading = false;
        state.downloadResource.fileName = filename;
      })
      .addCase(downloadLGMvsDRPComparison.pending, (state, action) => {
        state.downloadResource.isError = false;
        state.downloadResource.message = "Loading...";
        state.downloadResource.isLoading = true;
        // state.downloadResource.fileName = action.meta.arg[3];
      })
      .addCase(downloadLGMvsDRPComparison.rejected, (state, action) => {
        state.downloadResource.isError = true;
        state.downloadResource.message = action.payload as string;
        state.downloadResource.isLoading = false;
      });

    //downloadLGMvsLRPSwine
    builder
      .addCase(downloadLGMvsLRPSwine.fulfilled, (state, action) => {
        const filename = _handleDownload(action);

        state.downloadResource.isError = false;
        state.downloadResource.message = "Resource downloaded successfully.";
        state.downloadResource.isLoading = false;
        state.downloadResource.fileName = filename;
      })
      .addCase(downloadLGMvsLRPSwine.pending, (state, action) => {
        state.downloadResource.isError = false;
        state.downloadResource.message = "Loading...";
        state.downloadResource.isLoading = true;
        // state.downloadResource.fileName = action.meta.arg[3];
      })
      .addCase(downloadLGMvsLRPSwine.rejected, (state, action) => {
        state.downloadResource.isError = true;
        state.downloadResource.message = action.payload as string;
        state.downloadResource.isLoading = false;
      });

    //downloadLGMvsLRPCattle
    builder
      .addCase(downloadLGMvsLRPCattle.fulfilled, (state, action) => {
        const filename = _handleDownload(action);

        state.downloadResource.isError = false;
        state.downloadResource.message = "Resource downloaded successfully.";
        state.downloadResource.isLoading = false;
        state.downloadResource.fileName = filename;
      })
      .addCase(downloadLGMvsLRPCattle.pending, (state, action) => {
        state.downloadResource.isError = false;
        state.downloadResource.message = "Loading...";
        state.downloadResource.isLoading = true;
        // state.downloadResource.fileName = action.meta.arg[3];
      })
      .addCase(downloadLGMvsLRPCattle.rejected, (state, action) => {
        state.downloadResource.isError = true;
        state.downloadResource.message = action.payload as string;
        state.downloadResource.isLoading = false;
      });

    //downloadFile
    builder
      .addCase(downloadFile.fulfilled, (state, action) => {
        const filename = _handleDownload(action);

        state.downloadResource.isError = false;
        state.downloadResource.message = "Resource downloaded successfully.";
        state.downloadResource.isLoading = false;
        state.downloadResource.fileName = filename;
      })
      .addCase(downloadFile.pending, (state, action) => {
        state.downloadResource.isError = false;
        state.downloadResource.message = "Loading...";
        state.downloadResource.isLoading = true;
        state.downloadResource.fileName = action.meta.arg[3];
      })
      .addCase(downloadFile.rejected, (state, action) => {
        state.downloadResource.isError = true;
        state.downloadResource.message = action.payload as string;
        state.downloadResource.isLoading = false;
      });
  },
});

// export const {} = resourcesSlice.actions;

export default resourcesSlice.reducer;
