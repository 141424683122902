import { useAppSelector } from "../../../app/redux/hooks";

import React from "react";
import EmailVerification from "../../components/Registration/EmailVerification";
import AgentRegistrationAuthorization from "../../components/Registration/AgentRegistrationAuthorization";
import ProducerRegistrationAuthorization from "../../components/Registration/ProducerRegistrationAuthorization";
import AgencyRegistration from "../../components/Registration/AgencyRegistration";
import ViewAgencyProfile from "../../components/ViewAgencyProfile";
import AccountSettings from "../../components/AccountSettings";

function ManageAccount() {
  const {
    userType,
    registrationAuthorization,
    emailVerification,
    agencyProfile,
  } = useAppSelector((state) => state.auth);

  // useEffect(() => {
  //   if (
  //     emailVerification.isEmailVerified &&
  //     registrationAuthorization.isRegistrationAuthorized &&
  //     agencyProfile.isAgencyProfileComplete
  //   ) {
  //     navigate("/lgm-decisiontool");
  //   }
  // }, [registrationAuthorization, emailVerification, agencyProfile]);
  const showAccountSettings =
    userType === "USER"
      ? registrationAuthorization.isRegistrationAuthorized &&
        emailVerification.isEmailVerified // Condition for USER
      : registrationAuthorization.isRegistrationAuthorized &&
        emailVerification.isEmailVerified &&
        agencyProfile.isAgencyProfileComplete; // Additional condition for AGENT

  return (
    <div className="ms-4">
      <table>
        <tbody>
          <tr>
            <td>
              <pre>
                [{" "}
                {registrationAuthorization.isRegistrationAuthorized
                  ? "complete"
                  : registrationAuthorization.message}{" "}
                ]
              </pre>
            </td>
            <td>
              <pre>: Registration Authorization</pre>
            </td>
          </tr>
          <tr>
            <td>
              <pre>
                [{" "}
                {emailVerification.isEmailVerified
                  ? "complete"
                  : emailVerification.message}{" "}
                ]
              </pre>
            </td>
            <td>
              <pre>: Email Confirmation</pre>
            </td>
          </tr>
          {userType === "AGENT" && (
            <tr>
              <td>
                <pre>
                  [{" "}
                  {agencyProfile.isAgencyProfileComplete
                    ? "complete"
                    : agencyProfile.message}{" "}
                  ]
                </pre>
              </td>
              <td>
                <pre>: Agency Profile</pre>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {userType === "AGENT" && (
        <div>
          {!emailVerification.isEmailVerified && <EmailVerification />}
          {!registrationAuthorization.isRegistrationAuthorized && (
            <AgentRegistrationAuthorization />
          )}
          {!agencyProfile.isAgencyProfileComplete ? (
            <AgencyRegistration />
          ) : (
            <ViewAgencyProfile />
          )}
        </div>
      )}

      {userType === "USER" && (
        <div>
          {!emailVerification.isEmailVerified && <EmailVerification />}
          {!registrationAuthorization.isRegistrationAuthorized && (
            <ProducerRegistrationAuthorization />
          )}
        </div>
      )}
      {showAccountSettings && <AccountSettings />}
    </div>
  );
}

export default ManageAccount;
