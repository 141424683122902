import React, { useState, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import {
  authorizeOrDenyProducerAccess,
  getAllUsersForAgent,
} from "../../features/authActions";

import "./AuthorizeProducers.scss";
import company_logo from "../../../app/assets/csc_logo_transparent.png";

type Person = {
  UserEmailAddress: string;
  StatusName: string;
};

function getStatusBadge(statusName) {
  switch (statusName) {
    case "UNAUTHORIZED":
      return "bg-warning";
    case "AUTHORIZED":
      return "bg-success";
    case "RESTRICTED":
      return "bg-danger";
    case "BLACKLISTED":
      return "bg-dark";
    default:
      return "bg-secondary";
  }
}
function getStatusName(statusName) {
  switch (statusName) {
    case "UNAUTHORIZED":
      return "UNAUTHORIZED";
    case "AUTHORIZED":
      return "AUTHORIZED";
    case "RESTRICTED":
      return "RESTRICTED";
    case "BLACKLISTED":
      return "SYSTEM RESTRICTED";
    default:
      return "bg-secondary";
  }
}

function getAction(person, completeAuthorization) {
  switch (person.StatusName) {
    case "UNAUTHORIZED":
      return (
        <>
          <span className="authorize">
            <button
              type="button"
              className="btn btn-success"
              onClick={() =>
                completeAuthorization(true, person.UserEmailAddress)
              }
            >
              Authorize
            </button>
          </span>
          <span className="reject">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() =>
                completeAuthorization(false, person.UserEmailAddress)
              }
            >
              Restrict
            </button>
          </span>
        </>
      );
    case "AUTHORIZED":
      return (
        <span className="reject">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() =>
              completeAuthorization(false, person.UserEmailAddress)
            }
          >
            Restrict
          </button>
        </span>
      );
    case "RESTRICTED":
      return (
        <span className="reject">
          <button
            type="button"
            className="btn btn-success"
            onClick={() => completeAuthorization(true, person.UserEmailAddress)}
          >
            Authorize
          </button>
        </span>
      );
    case "BLACKLISTED":
      return <></>;
    default:
      return <></>;
  }
}

const _sort = (a: any, b: any) => {
  if (a.UserEmailAddress < b.UserEmailAddress) {
    return -1;
  }
  if (a.UserEmailAddress > b.UserEmailAddress) {
    return 1;
  }
  return 0;
};

function AuthorizeProducers() {
  const dispatch = useAppDispatch();
  const { email, authentication, agents_producers } = useAppSelector(
    (state) => state.auth,
  );
  // const [unauthorized_users, setUnauthorizedUsers] = useState<Array<object>>(
  //   [],
  // );
  // const [authorized_users, setAuthorizedUsers] = useState<Array<object>>([]);
  // const [restricted_users, setRestrictedUsers] = useState<Array<object>>([]);
  // const [blacklisted_users, setBlacklistedUsers] = useState<Array<object>>([]);

  const [users, setUsers] = useState([]);
  const [fullyInitialized, setFullyInitialized] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [targetedUser, setTargetedUser] = useState<string>("");
  const completeAuthorization = async (
    is_authorized: boolean,
    user_email: string,
  ) => {
    setTargetedUser(user_email);

    await dispatch(
      authorizeOrDenyProducerAccess({
        is_authorized: is_authorized,
        user_email: user_email,
      }),
    );
    setTargetedUser("");
  };
  useEffect(() => {
    const getAllUsersForAgentAsync = async () => {
      await dispatch(getAllUsersForAgent());
    };
    getAllUsersForAgentAsync();
    setFullyInitialized(true);
  }, [dispatch]);

  useEffect(() => {
    console.log(agents_producers);
    if (agents_producers.length > 0) {
      setUsers([...agents_producers].sort(_sort));
      // setUsers(sortedUsers);
      //   setUnauthorizedUsers(
      //     agents_producers.filter((user) => {
      //       return user["StatusName"] === "UNAUTHORIZED";
      //     }),
      //   );
      //   setAuthorizedUsers(
      //     agents_producers.filter((user) => {
      //       return user["StatusName"] === "AUTHORIZED";
      //     }),
      //   );
      //   setRestrictedUsers(
      //     agents_producers.filter((user) => {
      //       return user["StatusName"] === "RESTRICTED";
      //     }),
      //   );
      //   setBlacklistedUsers(
      //     agents_producers.filter((user) => {
      //       return user["StatusName"] === "BLACKLISTED";
      //     }),
      //   );
    }
    setRefreshing(false);
  }, [agents_producers]);

  const refreshUsers = () => {
    setRefreshing(true);
    dispatch(getAllUsersForAgent());
  };

  return (
    <div
      className="container-fluid ps-4 pe-4 pl-md-1 pr-md-1"
      style={{ maxWidth: 1140 }}
    >
      <div className="card mx-auto" style={{ width: " 80 %" }}>
        <div className="card-body">
          <div className="w-100 text-center mb-2" style={{ height: "120px" }}>
            <img
              className="mt-2"
              src={company_logo}
              alt="AgHedgeDesk"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
          <div className="text-center">
            <h3 className="card-title mb-4">
              Confirm Policy Producer Registration
            </h3>
          </div>
          <div className="text-center">
            {/* {unauthorized_users.length > 0 ||
            authorized_users.length > 0 ||
            restricted_users.length > 0 ||
              blacklisted_users.length > 0 */}
            {users.length > 0 ? (
              <div className="table-responsive">
                <table className="table" style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th>Status:</th>

                      <th>Email:</th>

                      <th>Actions:</th>
                    </tr>
                  </thead>
                  <tbody style={{ width: "100%" }}>
                    {users.map((person: Person, i) => (
                      <tr
                        className="d-print-none"
                        key={person.UserEmailAddress}
                      >
                        <th className="text-center align-middle">
                          <span
                            className={`badge ${getStatusBadge(person.StatusName)}`}
                          >
                            {getStatusName(person.StatusName)}
                          </span>
                        </th>
                        <td>{person.UserEmailAddress}</td>
                        <td>
                          {person.UserEmailAddress === targetedUser ? (
                            <>
                              {!authentication.isLoading ? (
                                <div>{authentication.message}</div>
                              ) : (
                                <div>Loading...</div>
                              )}
                            </>
                          ) : (
                            <>{getAction(person, completeAuthorization)}</>
                          )}
                        </td>
                      </tr>
                    ))}
                    {/* {(unauthorized_users as Array<Person>)
                      .sort()
                      .map((person, i) => (
                        <tr
                          className="d-print-none"
                          key={person.UserEmailAddress}
                        >
                          <th className="text-center align-middle">
                            <span
                              className="badge bg-warning"
                              style={{ color: "black" }}
                            >
                              UNAUTHORIZED
                            </span>
                          </th>

                          <td>{person.UserEmailAddress}</td>

                          {person.UserEmailAddress === targetedUser ? (
                            <>
                              {!authentication.isLoading ? (
                                <div>{authentication.message}</div>
                              ) : (
                                <div>Loading...</div>
                              )}
                            </>
                          ) : (
                            <td>
                              <span className="authorize">
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  onClick={() =>
                                    completeAuthorization(
                                      true,
                                      person.UserEmailAddress,
                                    )
                                  }
                                >
                                  Authorize
                                </button>
                              </span>
                              <span className="reject">
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={() =>
                                    completeAuthorization(
                                      false,
                                      person.UserEmailAddress,
                                    )
                                  }
                                >
                                  Restrict
                                </button>
                              </span>
                            </td>
                          )}
                        </tr>
                      ))}


                    {(authorized_users as Array<Person>)
                      .sort()
                      .map((person, i) => (
                        <tr
                          className="d-print-none"
                          key={person.UserEmailAddress}
                        >
                          <th className="text-center align-middle">
                            <span className="badge bg-success">AUTHORIZED</span>
                          </th>

                          <td>{person.UserEmailAddress}</td>

                          {person.UserEmailAddress === targetedUser ? (
                            <>
                              {!authentication.isLoading ? (
                                <div>{authentication.message}</div>
                              ) : (
                                <div>Loading...</div>
                              )}
                            </>
                          ) : (
                            <td>
                              <span className="reject">
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={() =>
                                    completeAuthorization(
                                      false,
                                      person.UserEmailAddress,
                                    )
                                  }
                                >
                                  Restrict
                                </button>
                              </span>
                            </td>
                          )}
                        </tr>
                      ))}


                    {(restricted_users as Array<Person>)
                      .sort()
                      .map((person, i) => (
                        <tr
                          className="d-print-none"
                          key={person.UserEmailAddress}
                        >
                          <th className="text-center align-middle">
                            <span className="badge bg-danger">RESTRICTED</span>
                          </th>

                          <td>{person.UserEmailAddress}</td>

                          {person.UserEmailAddress === targetedUser ? (
                            <>
                              {!authentication.isLoading ? (
                                <div>{authentication.message}</div>
                              ) : (
                                <div>Loading...</div>
                              )}
                            </>
                          ) : (
                            <td>
                              <span className="reject">
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  onClick={() =>
                                    completeAuthorization(
                                      true,
                                      person.UserEmailAddress,
                                    )
                                  }
                                >
                                  Authorize
                                </button>
                              </span>
                            </td>
                          )}
                        </tr>
                      ))} */}
                    {/* 
                    {(blacklisted_users as Array<Person>)
                      .sort()
                      .map((person, i) => (
                        <tr
                          className="d-print-none"
                          key={person.UserEmailAddress}
                        >
                          <th className="text-center align-middle">
                            <span className="badge bg-dark">
                              SYSTEM RESTRICTED
                            </span>
                          </th>

                          <td>{person.UserEmailAddress}</td>

                          {person.UserEmailAddress === targetedUser ? (
                            <>
                              {!authentication.isLoading ? (
                                <div>{authentication.message}</div>
                              ) : (
                                <div>Loading...</div>
                              )}
                            </>
                          ) : (
                            <td></td>
                          )}
                        </tr>
                      ))} */}
                  </tbody>
                </table>
                <div
                  className="text-left py-4 mx-auto px-4 mt-3"
                  style={{ fontSize: "0.75em" }}
                >
                  <div className="w-100 text-center">
                    {
                      // unauthorized_users.length > 0 ||
                      // authorized_users.length > 0 ||
                      // restricted_users.length > 0 ||
                      // blacklisted_users.length > 0
                      users.length > 0 && <b>Legend:</b>
                    }
                  </div>
                  {users.filter(
                    (user: Person) => user.StatusName === "UNAUTHORIZED",
                  ).length > 0 && (
                    <div className="mt-2">
                      <span
                        className="badge bg-warning"
                        style={{ color: "black" }}
                      >
                        UNAUTHORIZED
                      </span>{" "}
                      - User has not been authorized and cannot access the Ag
                      Hedge Desk platform. If you recognize this user and wish
                      to grant them access click "Authorize"
                      <br />
                    </div>
                  )}
                  {users.filter(
                    (user: Person) => user.StatusName === "AUTHORIZED",
                  ).length > 0 && (
                    <div className="mt-2">
                      <span className="badge bg-success">AUTHORIZED</span> -
                      User has been authorized to access the Ag Hedge Desk
                      platform. If you wish to restrict this user access for any
                      reason, click "Restrict"
                      <br />
                    </div>
                  )}
                  {users.filter(
                    (user: Person) => user.StatusName === "RESTRICTED",
                  ).length > 0 && (
                    <div className="mt-2">
                      <span className="badge bg-danger">RESTRICTED</span> - User
                      has been denied access and cannot access the Ag Hedge Desk
                      platform. If you wish to grant this user access to the
                      portal click "Authorize"
                      <br />
                    </div>
                  )}
                  {users.filter(
                    (user: Person) => user.StatusName === "BLACKLISTED",
                  ).length > 0 && (
                    <div className="mt-2">
                      <span className="badge bg-dark">SYSTEM RESTRICTED</span> -
                      User has been restricted by Ag Hedge Desk systems and
                      cannot access the Ag Hedge Desk platform. Contact your AG
                      Representative to have this user reinstated.
                      <br />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div>
                <div
                  hidden={!fullyInitialized}
                  className="alert alert-secondary"
                  role="alert"
                >
                  No producers have registered for a Producer Dashboard account
                  with your email address. If you believe this to be a mistake,
                  please make sure the producer used the email address
                  associated with your AG Portal account:
                  <br />
                  <br />
                  <span className="font-weight-bold">
                    <pre>
                      <code>{email}</code>
                    </pre>
                  </span>
                </div>
                <div hidden={fullyInitialized}> Loading...</div>
              </div>
            )}
            <button
              disabled={refreshing}
              hidden={!fullyInitialized}
              className="btn btn-secondary w-auto mt-1"
              onClick={() => refreshUsers()}
            >
              Refresh Users
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthorizeProducers;
