import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/redux/hooks";
import {
  authorizeProducerToViewHedgeMAP,
  getSpecificHedgemapInfo,
} from "../../../../ProducerViewHedgeMAPs/features/producerHedgeMAPsActions";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  cloneAllHedgemapTypes,
  policyDisplayMapping,
  typeDisplayMapping,
  dynamicallyDetermineTrueCount,
} from "../../../utils/producerHedgeMAPsUtils";

type SelectPolicyProducerProps = {
  policyProducer: any;
  user: string;
  setChangesMade: (value: boolean) => void;
  showEditPermissionsModal: boolean;
  setShowEditPermissionsModal: (value: boolean) => void;
};
type ModalData = {
  insuranceType: any;
  commodityType: any;
  commoditySubType: any;
  value: any;
};
const SelectHedgeMAPTypes = (props: SelectPolicyProducerProps) => {
  const {
    user,
    policyProducer,
    setChangesMade,
    showEditPermissionsModal,
    setShowEditPermissionsModal,
  } = props;

  const dispatch = useAppDispatch();
  const [modalData, setModalData] = useState<ModalData | null>(null);
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);

  const [initialHedgeMAPTypes, setInitialHedgeMAPTypes] = useState({});

  const [selectedHedgeMAPTypes, setSelectedHedgeMAPTypes] = useState({});

  const { data, hedgemapFilesExist, isError, isLoading, message } =
    useAppSelector((state) => state.producerHedgeMAPs.selectedHedgemapTypes);

  const {
    // isError: isAuthorizationError,
    // message: authorizationMessage,
    isLoading: isAuthorizationLoading,
  } = useAppSelector((state) => state.producerHedgeMAPs.agentAuthorization);

  const changesMade =
    JSON.stringify(initialHedgeMAPTypes) !==
    JSON.stringify(selectedHedgeMAPTypes);

  setChangesMade(changesMade);

  const handleSaveChanges = async () => {
    if (changesMade) {
      await submitAuthorizationLinkage();
      setInitialHedgeMAPTypes(selectedHedgeMAPTypes); // update initial state to new state after changes are made
    }
  };

  //moving logic to compoknent
  useEffect(() => {
    dispatch(
      getSpecificHedgemapInfo({
        policy_producer_aggregate: policyProducer,
        user: user,
      }),
    );
  }, [policyProducer, user]);

  useEffect(() => {
    if (data) {
      setSelectedHedgeMAPTypes(data);
      setInitialHedgeMAPTypes(data); // Set initial state on load
    }
  }, [data]);

  const handleUnlinkClose = () => setShowUnlinkModal(false);

  const handleUnlinkShow = (
    insuranceType,
    commodityType,
    commoditySubType,
    value,
  ) => {
    setModalData({ insuranceType, commodityType, commoditySubType, value });
    setShowUnlinkModal(true);
  };

  const submitAuthorizationLinkage = () => {
    const hedgemap_info = {
      DisplayName: policyProducer.DisplayName,
      PolicyProducerAggregateID: policyProducer.PolicyProducerAggregateID,
      SelectedHedgemapTypes: selectedHedgeMAPTypes,
    };
    dispatch(
      authorizeProducerToViewHedgeMAP({
        authorized_email: user,
        hedgemap_info: hedgemap_info,
      }),
    );
  };
  const toggleHedgeMapType = (
    insuranceType,
    commodityType,
    commoditySubType,
  ) => {
    setSelectedHedgeMAPTypes((prev) => {
      const target_val = prev[insuranceType][commodityType][commoditySubType];

      const trueCount = dynamicallyDetermineTrueCount(prev);

      // If unchecking the last box, show the unlink modal
      if (target_val && trueCount === 1) {
        handleUnlinkShow(
          insuranceType,
          commodityType,
          commoditySubType,
          target_val,
        );
        return prev; // Keep state unchanged for now
      }

      return {
        ...prev,
        [insuranceType]: {
          ...prev[insuranceType],
          [commodityType]: {
            ...prev[insuranceType][commodityType],
            [commoditySubType]: !target_val,
          },
        },
      };
    });
  };

  //If agent only wants to aurorize one or two hedgemaps give them easy access to deselect all instead of unchecking all boxes
  const handleUncheckAll = () => {
    const resetHedgeMAPTypes = JSON.parse(
      JSON.stringify(selectedHedgeMAPTypes),
    );
    for (const insuranceType in resetHedgeMAPTypes) {
      for (const commodityType in resetHedgeMAPTypes[insuranceType]) {
        for (const commoditySubType in resetHedgeMAPTypes[insuranceType][
          commodityType
        ]) {
          resetHedgeMAPTypes[insuranceType][commodityType][commoditySubType] =
            false;
        }
      }
    }

    setSelectedHedgeMAPTypes(resetHedgeMAPTypes);
  };

  //If agent wants to easily check all
  const handleCheckAll = () => {
    const resetHedgeMAPTypes = JSON.parse(
      JSON.stringify(selectedHedgeMAPTypes),
    );
    for (const insuranceType in resetHedgeMAPTypes) {
      for (const commodityType in resetHedgeMAPTypes[insuranceType]) {
        for (const commoditySubType in resetHedgeMAPTypes[insuranceType][
          commodityType
        ]) {
          resetHedgeMAPTypes[insuranceType][commodityType][commoditySubType] =
            true;
        }
      }
    }

    setSelectedHedgeMAPTypes(resetHedgeMAPTypes);
  };
  return (
    <>
      <div className="row mb-4">
        <div className="d-flex justify-content-center">
          <Button
            variant="primary"
            onClick={() => setShowEditPermissionsModal(true)}
          >
            Edit Permissions
          </Button>
        </div>
      </div>
      <Modal
        show={showEditPermissionsModal}
        onHide={() => {
          setShowEditPermissionsModal(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Policy Producer Permissions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!isLoading ? (
            <>
              {!isError ? (
                <>
                  <div className="row mb-4">
                    <div className="col-12">
                      <h5 className="text-center">Editing permissions for:</h5>
                      <h6 className="text-center">
                        {user} - {policyProducer.DisplayName}
                      </h6>
                    </div>
                    <div
                      className="col-6 d-flex justify-content-end"
                      style={{
                        borderRight: "1px solid gainsboro",

                        height: "100%",
                      }}
                    >
                      <Button variant="danger" onClick={handleUncheckAll}>
                        Uncheck all
                      </Button>
                    </div>
                    <div className="col-6 d-flex justify-content-start">
                      <Button variant="primary" onClick={handleCheckAll}>
                        Check all
                      </Button>
                    </div>
                  </div>
                  <div className="text-center mt-3">
                    {!isAuthorizationLoading && changesMade && (
                      <div
                        style={{
                          borderBottom: "1px solid gainsboro",
                          paddingBottom: "15px",
                          marginBottom: "15px",
                        }}
                      >
                        <Button variant="success" onClick={handleSaveChanges}>
                          Save Changes
                        </Button>
                      </div>
                    )}
                    {isAuthorizationLoading && (
                      <div
                        style={{
                          borderBottom: "1px solid gainsboro",
                          paddingBottom: "15px",
                          marginBottom: "15px",
                        }}
                      >
                        <Button variant="primary" disabled>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          Saving...
                        </Button>
                      </div>
                    )}
                  </div>
                  {Object.entries(cloneAllHedgemapTypes()).flatMap(
                    ([insuranceType, commodities]) =>
                      Object.entries(commodities).map(
                        ([commodityType, quotes]) =>
                          Object.entries(quotes).map((quote, index) => {
                            const commoditySubType = quote[0];

                            return (
                              <div
                                key={`${insuranceType}-${index}`}
                                className="form-check"
                              >
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={`${insuranceType}-${commodityType}-${commoditySubType}`}
                                  id={`check-${insuranceType}-${index}`}
                                  checked={
                                    selectedHedgeMAPTypes &&
                                    selectedHedgeMAPTypes[insuranceType] &&
                                    selectedHedgeMAPTypes[insuranceType][
                                      commodityType
                                    ] &&
                                    selectedHedgeMAPTypes[insuranceType][
                                      commodityType
                                    ][commoditySubType]
                                  }
                                  onChange={() =>
                                    toggleHedgeMapType(
                                      insuranceType,
                                      commodityType,
                                      commoditySubType,
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`check-${insuranceType}-${index}`}
                                >
                                  {insuranceType} -{" "}
                                  {policyDisplayMapping[commodityType]} -{" "}
                                  {typeDisplayMapping[commoditySubType]}
                                  <span style={{ color: "rgb(142, 39, 54)" }}>
                                    {hedgemapFilesExist &&
                                      hedgemapFilesExist[insuranceType] &&
                                      hedgemapFilesExist[insuranceType][
                                        commodityType
                                      ] &&
                                      hedgemapFilesExist[insuranceType][
                                        commodityType
                                      ][commoditySubType] &&
                                      " *"}
                                  </span>
                                </label>
                              </div>
                            );
                          }),
                      ),
                  )}
                  <div className="row mt-4 justify-content-center">
                    <p>
                      <strong>Note:</strong> Checked boxes do not necessarily
                      indicate that a HedgeMAP exists. They simply determine the
                      types of HedgeMAPs the user will see when new HedgeMAPs
                      are added to the underlying Policy Producer. Choose
                      selectively for controlled visibility.
                    </p>

                    {/* Only show this if there is a hedgemap to avoid confusion */}
                    {dynamicallyDetermineTrueCount(hedgemapFilesExist) > 0 && (
                      <p>
                        An asterisk (
                        <span style={{ color: "rgb(142, 39, 54)" }}>*</span>){" "}
                        indicates that a generated HedgeMAP is available for
                        viewing.
                      </p>
                    )}
                  </div>
                  <Modal
                    show={showUnlinkModal}
                    onHide={() => {
                      handleUnlinkClose();

                      setModalData(null);
                    }}
                    size="lg"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Confirm Authorization</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                        If you proceed this portal user will not be able to see
                        any HedgeMAPs from this Policy Producer.
                      </p>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          handleUnlinkClose();
                          setModalData(null);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        // disabled={!notEmpty(linkedPolicyProducer)}
                        variant="primary"
                        onClick={() => {
                          if (modalData) {
                            const {
                              insuranceType,
                              commodityType,
                              commoditySubType,
                              value,
                            } = modalData;

                            setSelectedHedgeMAPTypes((prev) => {
                              return {
                                ...prev,
                                [insuranceType]: {
                                  ...prev[insuranceType],
                                  [commodityType]: {
                                    ...prev[insuranceType][commodityType],
                                    [commoditySubType]: !value,
                                  },
                                },
                              };
                            });
                          }
                          setModalData(null);
                          handleUnlinkClose();
                        }}
                      >
                        Confirm
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>
              ) : (
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              )}
            </>
          ) : (
            <div className="alert alert-primary" role="alert">
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              {"  "}
              Loading...
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowEditPermissionsModal(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SelectHedgeMAPTypes;
