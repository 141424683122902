export function calculateNextQuarterLabel(quarterLabel) {
  const quarterMapping = { Q1: 1, Q2: 2, Q3: 3, Q4: 4 };

  try {
    const [yearStr, quarterStr] = quarterLabel.split("_");

    const year = parseInt(yearStr);
    const quarter = quarterMapping[quarterStr.toUpperCase()];

    if (quarter === undefined || isNaN(year)) {
      throw new Error("Invalid year or quarter format");
    }

    if (quarter < 1 || quarter > 4) {
      throw new Error("Quarter should be between 1 and 4");
    }

    const currentMonth = (quarter - 1) * 3;
    const currentDate = new Date(year, currentMonth, 1);
    currentDate.setUTCHours(0, 0, 0, 0);

    const nextDate = new Date(currentDate.getTime() + 93 * 24 * 60 * 60 * 1000); // 93 days to move to the next quarter

    const nextYear = nextDate.getUTCFullYear();
    const nextQuarter = Math.floor(nextDate.getUTCMonth() / 3) + 1;

    return `${String(nextYear)}_Q${nextQuarter}`;
  } catch (error) {
    throw error;
  }
}
