const EMAIL_PATTERN = new RegExp(
  /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
);
const PHONE_PATTERN = new RegExp(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/);
const PASSWORD_MIN_LENGTH = 10;
const PASSWORD_MAX_LENGTH = 64;

export interface validationResponse {
  valid: boolean;
  message: any;
}

// Validators
const passwordValidator = (password1: string, password2: string) => {
  let passwordErrors: string[] = [];

  if (password1 !== password2) {
    passwordErrors.push("Passwords do not match.");
  }
  if (
    password1.length < PASSWORD_MIN_LENGTH ||
    password1.length > PASSWORD_MAX_LENGTH
  ) {
    passwordErrors.push(
      `Password needs to be between ${PASSWORD_MIN_LENGTH} and ${PASSWORD_MAX_LENGTH} characters long.`,
    );
  }
  if (password1.search(/\\*([a-z]+)\\*/) < 0) {
    passwordErrors.push(
      "Password must contain at-least one lower case letter.",
    );
  }
  if (password1.search(/\\*([A-Z]+)\\*/) < 0) {
    passwordErrors.push(
      "Password must contain at-least one upper case letter.",
    );
    // if (password1.search(/\\*([0-9]+)\\*/) < 0) {
    //   passwordErrors.push("Password must contain at-least one number.");
    // }
    // if (password1.search(/\\*([!@#$%^&*]+)\\*/) < 0) {
    //   passwordErrors.push("Password must contain at-least special character.");
    // }
  }
  if (passwordErrors.length === 0) {
    // If no errors then valid
    return {
      valid: true,
      messages: [],
    };
  }

  // Else, invalid
  return {
    valid: false,
    messages: passwordErrors,
  };
};

const emailValidator = (email: string) => {
  if (EMAIL_PATTERN.test(email)) {
    return { valid: true, message: null };
  }
  return { valid: false, message: "Please enter a valid email" };
};

const phoneValidator = (phone_number: string, is_optional: boolean = false) => {
  if (PHONE_PATTERN.test(phone_number)) {
    return { valid: true, message: null };
  }
  if (is_optional && phone_number.length === 0) {
    return { valid: true, message: null };
  }
  return {
    valid: false,
    message: "Please enter a valid phone number (555-555-5555)",
  };
};

const nameValidator = (name: string) => {
  if (name.length > 0) {
    return { valid: true, message: null };
  }
  return { valid: false, message: "Please enter a valid name" };
};
const mobileCarrierValidator = (name: string, phone_number: string) => {
  if (phone_number.length > 0 && name === "Select carrier...") {
    return { valid: false, message: "Please select a mobile carrier" };
  }
  return { valid: true, message: null };
};
// Validator class
const authFormValidator = {
  emailValidator,
  passwordValidator,
  phoneValidator,
  nameValidator,
  mobileCarrierValidator,
};
export default authFormValidator;
