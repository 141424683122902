import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../../app/redux/hooks";

import React from "react";

function ProtectedRoutes() {
  const {
    email,
    userType,
    isLoggedIn,
    registrationAuthorization,
    emailVerification,
    agencyProfile,
  } = useAppSelector((state) => state.auth);

  const _authorized =
    userType === "AGENT"
      ? email &&
        isLoggedIn &&
        registrationAuthorization.isRegistrationAuthorized &&
        emailVerification.isEmailVerified &&
        agencyProfile.isAgencyProfileComplete
      : email &&
        isLoggedIn &&
        registrationAuthorization.isRegistrationAuthorized &&
        emailVerification.isEmailVerified;

  return _authorized ? <Outlet /> : <Navigate to="/manage-account" />;
}

export default ProtectedRoutes;
