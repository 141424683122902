import React, { useEffect } from "react";

type PolicyProducer = {
  DisplayName: string;
};
type SelectUserProps = {
  policyProducerList: any[];
  setSelectedPolicyProducer: (value: any) => void;
  selectedPolicyProducer: PolicyProducer;
};

const SelectPolicyProducer = (props: SelectUserProps) => {
  const {
    policyProducerList,
    setSelectedPolicyProducer,
    selectedPolicyProducer,
  } = props;

  return (
    <div className="col-4">
      {/* Second dropdown */}
      <select
        name="producer-select"
        id="producer-select"
        className="form-control ag-form-focus"
        defaultValue=""
        onChange={(e) => {
          const policyProducerObj = policyProducerList.find(
            (item: any) => item.DisplayName === e.target.value,
          );

          setSelectedPolicyProducer(policyProducerObj);
        }}
      >
        <option value="" disabled hidden>
          Select Policy Producer
        </option>
        {policyProducerList &&
          policyProducerList
            .slice()
            .sort((a, b) => {
              if (a.DisplayName < b.DisplayName) {
                return -1;
              }
              if (a.DisplayName > b.DisplayName) {
                return 1;
              }
              return 0;
            })
            .map((policy_producer: any, index) => {
              return (
                <option
                  key={index}
                  value={policy_producer.DisplayName}
                  selected={
                    policy_producer.DisplayName ===
                    selectedPolicyProducer.DisplayName
                  }
                >
                  {policy_producer.DisplayName}
                </option>
              );
            })}
      </select>
    </div>
  );
};

export default SelectPolicyProducer;
