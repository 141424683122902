import React, { useState, useEffect } from "react";
import { setIndexPrice } from "../../LRPSlice";
import { useAppDispatch, useAppSelector } from "../../../../../app/redux/hooks";
import LRPQuoteResultRow from "./LRPQuoteResultRow/LRPQuoteResultRow";

function LRPStripQuoteTable({ stripQuotes }) {
  const dispatch = useAppDispatch();
  const { selectedWeek } = useAppSelector((state) => state.lrp);

  const [selectedStripQuotes, setSelectedStripQuotes] = useState(null as any);
  const [isPerHead, setIsPerHead] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const togglePerHead = () => {
    setIsPerHead((prevState) => !prevState);
  };

  const toggleShowMore = () => {
    setShowMore((prevState) => !prevState);
  };

  useEffect(() => {
    if (selectedWeek !== "0") {
      setSelectedStripQuotes(stripQuotes[selectedWeek]);
    }
    // stripQuotes selectedWeek dont cause update unless they change week
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWeek]);

  useEffect(() => {
    // When strip quotes change, update the index price
    // for the indemnity calculator.
    if (selectedStripQuotes) {
      const index_price =
        selectedStripQuotes["quote_result_list"][0]["quote_params"][
          "expected_ending_value_amount"
        ];
      dispatch(setIndexPrice(index_price));
    }
  }, [selectedStripQuotes, dispatch]);

  return (
    <div className="table-responsive" style={{ width: "100%" }}>
      {/* Quotes */}
      <table className="table">
        <thead>
          {/* Headings */}
          <tr>
            <th scope="col">Coverage level</th>
            <th scope="col">Coverage Price Per CWT</th>
            <th scope="col">
              Producer Premium
              <br />
              {isPerHead ? "(Per Head)" : "(Per CWT)"}
            </th>
            <th scope="col">
              Indemnity
              <br />
              {isPerHead ? "(Per Head)" : "(Per CWT)"}
            </th>
            <th scope="col">
              Expected Net To Producer
              <br />
              {isPerHead ? "(Per Head)" : "(Per CWT)"}
            </th>
          </tr>
        </thead>
        <tbody style={{ width: "100%" }}>
          {/* Body should consist of LRPQuoteObject instances */}
          {selectedStripQuotes !== null &&
            selectedStripQuotes !== undefined && (
              <>
                {showMore ? (
                  <>
                    {Object.entries(selectedStripQuotes.quote_result_list)
                      .sort(
                        (a: object, b: object) =>
                          // Sort in ascending order of Coverage Level
                          b[1]["coverage_level_percent"] -
                          a[1]["coverage_level_percent"],
                      )
                      .map((quote) => (
                        <LRPQuoteResultRow
                          quote={quote[1]}
                          isPerHead={isPerHead}
                          key={quote[0]}
                        />
                      ))}
                  </>
                ) : (
                  <>
                    {Object.entries(selectedStripQuotes.quote_result_list)
                      .sort(
                        (a: object, b: object) =>
                          // Sort in ascending order of Coverage Level
                          b[1]["coverage_level_percent"] -
                          a[1]["coverage_level_percent"],
                      )
                      .slice(0, 5)
                      .map((quote) => (
                        <LRPQuoteResultRow
                          quote={quote[1]}
                          isPerHead={isPerHead}
                          key={quote[0]}
                        />
                      ))}
                  </>
                )}
              </>
            )}
          {/* <LRPQuoteResultRow /> */}
          {/* Buttons */}
          <tr style={{ borderTop: "2px solid rgb(222, 226, 230)" }}>
            <td>
              <b>Actions:</b>
            </td>
            <td>
              <button
                className="btn btn-primary my-2"
                type="button"
                onClick={toggleShowMore}
              >
                {showMore ? "Show Less Rows" : "Show More Rows"}
              </button>
            </td>
            <td>
              <button
                className="btn btn-primary my-2"
                type="button"
                onClick={togglePerHead}
              >
                {isPerHead ? "Show Per CWT" : "Show Per Head"}
                {isPerHead}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default LRPStripQuoteTable;
