import React, { useEffect } from "react";
import { ListGroup } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import "./Podcasts.scss";
import PodcastListing from "../components/PodcastListing";
import { getPodcasts } from "../features/podcastsActions";
const ViewHedgeMAPs = (e) => {
  const dispatch = useAppDispatch();

  const PODCASTS_TO_SHOW = 5;
  const { podcastList, message, isLoading, isError } = useAppSelector(
    (state) => state.podcasts,
  );
  useEffect(() => {
    dispatch(getPodcasts(""));
  }, [dispatch]);

  // useEffect(() => {}, [podcastList]);

  return (
    <div className="ms-4">
      <h5>Podcasts</h5>
      <div>
        To download a podcast file, click the filename under the desired date.
      </div>
      <hr className="mb-4"></hr>
      <ListGroup>
        {!isLoading ? (
          !isError ? (
            podcastList.length > 0 ? (
              podcastList
                .slice(0, PODCASTS_TO_SHOW)
                .map((listing) => <PodcastListing listing={listing} />)
            ) : (
              <span
                className="display-inlint-block ms-2"
                style={{
                  color: "rgb(142, 39, 54)",
                }}
              >
                No podcasts are currently available. Please check back later.
              </span>
            )
          ) : (
            <span
              className="display-inlint-block ms-2"
              style={{
                color: "rgb(142, 39, 54)",
              }}
            >
              {message}
            </span>
          )
        ) : (
          <div>
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span> {message} </span>
          </div>
        )}
      </ListGroup>
    </div>
  );
};
export default ViewHedgeMAPs;
