import React from "react";

type SelectUserProps = {
  userList: string[];
  selectedUser: string;
  setSelectedUser: (value: string) => void;
};

const SelectUser = (props: SelectUserProps) => {
  const { userList, selectedUser, setSelectedUser } = props;

  return (
    <div className="col-4">
      <select
        name="producer-select"
        id="producer-select"
        className="form-control ag-form-focus"
        defaultValue=""
        onChange={(e) => setSelectedUser(e.target.value)}
        value={selectedUser}
      >
        <option value="" disabled hidden>
          Select User
        </option>
        {userList.map((email, index) => {
          return (
            <option key={index} value={email}>
              {email}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default SelectUser;
