import React, { useEffect } from "react";
import FedCattleNumberHeadSelection from "./FedCattleNumberHeadSelection/FedCattleNumberHeadSelection";
import FedCattleTargetWeightSelection from "./FedCattleTargetWeightSelection/FedCattleTargetWeightSelection";
import { resetLRPParams } from "../../LRPSlice";
import { useAppDispatch } from "../../../../../app/redux/hooks";
import { setType } from "../../LRPSlice";

function LRPFedCattleMarketingPlan() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Reset CWT and Head Count when page loads.
    dispatch(resetLRPParams());
    dispatch(setType("STEERS_AND_HEIFERS"));
  }, [dispatch]);

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col me-1 mt-1 p-0">
            <FedCattleNumberHeadSelection />
          </div>
          <div className="col m-0 mt-1 p-0">
            <FedCattleTargetWeightSelection />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LRPFedCattleMarketingPlan;
