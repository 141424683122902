import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/redux/hooks";
import {
  getLRPQuoteAuth,
  getMostRecentDateSecureLRP,
} from "../../features/LRPActions";
import { resetLRPQuoteResult, setSelectedWeek } from "../LRPSlice";
import {
  LRPQuoteUserInputs,
  LRPCommodityType,
  LRPQuoteType,
} from "@agdeveloper/domain";
import LRPWeekSelection from "./LRPWeekSelection/LRPWeekSelection";
import LRPQuoteDetails from "./LRPQuoteDetails/LRPQuoteDetails";
import LRPStripQuoteTable from "./LRPStripQuoteTable/LRPStripQuoteTable";
import LRPIndemnityCalculator from "./LRPIndemnityCalculator/LRPIndemnityCalculator";

const buttonStyles = {
  minHeight: "50px",
  // borderColor: "rgb(142, 39, 54)",
};

function LRPResultTable() {
  const dispatch = useAppDispatch();
  // const lrpStates = useAppSelector((state) => state.lrp);
  const {
    lrpSalesEffectiveDate,
    lrpQuoteResults,
    headCount,
    CWT,
    type,
    policy,
    inputsChanged,
  } = useAppSelector((state) => state.lrp);

  // const [aggregatedQuotes, setAggregatedQuotes] = useState(null as any);
  // const [stripQuotes, setStripQuotes] = useState({});
  // const [weekList, setWeekList] = useState<string[]>([]);
  const [userInputs, setUserInputs] = useState(null as any);

  const handleSubmit = () => {
    dispatch(getMostRecentDateSecureLRP());

    const commodity_type = new LRPCommodityType(policy);

    const quote_type = new LRPQuoteType(
      // Convert from format "word1 word2" to "WORD1_WORD2"
      type.toUpperCase().split(" ").join("_"),
    );

    let dateArray = lrpSalesEffectiveDate.split("-");
    let year = dateArray[0];
    let month = parseInt(dateArray[1], 10) - 1;
    let date = dateArray[2];

    const lrp_user_inputs = new LRPQuoteUserInputs(
      new Date(year, month, date),
      headCount,
      parseFloat(CWT),
      0,
      commodity_type,
      quote_type,
      "Minnesota",
      1,
      false,
      0.0,
    );
    // console.log(lrp_user_inputs.to_json())
    dispatch(resetLRPQuoteResult);
    dispatch(getLRPQuoteAuth(lrp_user_inputs));
  };

  useEffect(() => {
    if (lrpQuoteResults.data) {
      dispatch(
        setSelectedWeek(
          Object.keys(lrpQuoteResults.data.quote_result_dict).sort()[0],
        ),
      );

      const first_entry: any = Object.entries(
        lrpQuoteResults.data.quote_result_dict,
      )[0][1];

      // Get user inputs from first entry
      setUserInputs(first_entry.quote_result_list[0].quote_params.user_input);
    }
  }, [lrpQuoteResults.data, dispatch]);

  useEffect(() => {
    dispatch(getMostRecentDateSecureLRP());
  }, [dispatch]);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title pb-1">Quote Results</h5>
          {/* class-name for button when inputs changed: .btn-ag-warning (from index.scss) */}
          <div className="d-flex flex-row justify-content-between">
            <div>
              <button
                className={`btn btn-secondary ${
                  inputsChanged && "btn-ag-warning"
                }`}
                type="button"
                style={buttonStyles}
                onClick={handleSubmit}
                disabled={
                  lrpQuoteResults.loading || !inputsChanged ? true : false
                }
              >
                {lrpQuoteResults.loading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span> Loading... </span>
                  </>
                ) : (
                  <>Calculate</>
                )}
              </button>

              {inputsChanged && (
                <span
                  className="display-inline-block ps-3"
                  style={{ fontStyle: "italic", color: "rgb(142, 39, 54)" }}
                >
                  Inputs changed. Click calculate to recalculate results.
                </span>
              )}
              {!inputsChanged && lrpQuoteResults.data && (
                <span
                  className="display-inline-block ps-3"
                  style={{ fontStyle: "italic", color: "rgb(142, 39, 54)" }}
                >
                  Update input parameters to calculate another quote.
                </span>
              )}
            </div>
            <span className="float-right">
              Data valid as of:{" "}
              <span className="fw-bold ms-1">
                {/* {lrpSalesEffectiveDate.value} */}
                {lrpSalesEffectiveDate}
              </span>
            </span>
          </div>

          {/* Error Message */}
          {/* Show if lrpQuoteResults.error === true */}

          {lrpQuoteResults.error && (
            <section
              id="error-message"
              className="my-4 alert alert-danger d-flex align-items-center"
            >
              {/* <use xlinkHref="#exclamation-triangle-fill" /> */}
              <i
                className="bx bxs-error mx-2"
                style={{ color: "#842029", fontSize: "1.5rem" }}
              ></i>
              <span className="fw-bold" style={{ color: "rgb(142, 39, 54)" }}>
                {Array.isArray(lrpQuoteResults.message)
                  ? lrpQuoteResults.message.map((message, index) => (
                      <div key={index}>{`${index + 1}. ${message}`}</div>
                    ))
                  : lrpQuoteResults.message}
              </span>
            </section>
          )}

          {/* Quote Details */}
          {/* Hidden when lrpquote loading = true */}
          {lrpQuoteResults.data !== null && !lrpQuoteResults.loading && (
            // {!lrpQuoteResults.data !== null && !lrpQuoteResults.loading && (
            <div style={{ marginTop: "2em" }}>
              {userInputs !== null && (
                <>
                  <LRPQuoteDetails userInputs={userInputs} />
                  <LRPWeekSelection
                    userInputs={userInputs}
                    weekList={Object.keys(
                      lrpQuoteResults.data.quote_result_dict,
                    ).sort()}
                  />
                  <LRPStripQuoteTable
                    stripQuotes={lrpQuoteResults.data.quote_result_dict}
                  />
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Indemnity Calculator */}
      <br />
      {lrpQuoteResults.data !== null && !lrpQuoteResults.loading && (
        // {!lrpQuoteResults.data !== null && !lrpQuoteResults.loading && (
        <LRPIndemnityCalculator
          stripQuotes={lrpQuoteResults.data.quote_result_dict}
        />
      )}
    </>
  );
}

export default LRPResultTable;
