// import React, { ReactInstance, useRef } from "react";
import React, { useRef } from "react";
import LGMResultTable from "./LGMResultTable/LGMResultTable";
import ResultTableIndemnitiesParent from "./LGMResultTableIndemnityParent";

function ResultTableContainer() {
  const printComponentRef = useRef<any>();

  const handlePrint = (printComponentRef) => {
    const Print = window.open("", "", "width=1000,height=1000");

    Print?.document.write(
      "<html><head><style>.d-print-none {display: none !important;}</style></head>",
    );
    Print?.document.write(
      '<link rel="stylesheet" type="text/css" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css">',
    );
    Print?.document.write(printComponentRef.current.innerHTML);
    // Print.document.write('<p class="pl-4 pr-4" style="font-style: italic; font-weight: 500;">***NOTICE: The premium listed above is an estimate and is provided for reference only. Please contact your insurance agent for an official premium quote.</p>');
    Print?.document.write("</html>");

    Print?.document.close();
    // let user get a preview
    Print?.setTimeout(function () {
      Print?.focus();
      Print?.print();
      Print?.close();
    }, 1500);
  };
  return (
    <>
      <div id="printArea" ref={printComponentRef}>
        <LGMResultTable printResult={() => handlePrint(printComponentRef)} />
      </div>
      <ResultTableIndemnitiesParent />
    </>
  );
}

export default ResultTableContainer;
