import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/redux/hooks";
import {
  getLGMQuoteSecure,
  getMostRecentDateSecureLGM,
  getInitalExpectedGrossMargins,
} from "../../features/LGMActions";
import {
  LGMQuoteUserInputs,
  LGMQuoteType,
  LGMCommodityType,
} from "@agdeveloper/domain";
import { resetLGMQuoteResults } from "../../features/LGMSlice";
const buttonStyles = {
  minHeight: "50px",
  // borderColor: "rgb(142, 39, 54)",
};

const policyDisplayMapping = {
  [LGMCommodityType.CATTLE]: "Cattle",
  [LGMCommodityType.SWINE]: "Swine",
  [LGMCommodityType.DAIRY_CATTLE]: "Dairy Cattle",
};
const typeDisplayMapping = {
  [LGMQuoteType.YEARLING_FINISHING]: "Yearling Finishing",
  [LGMQuoteType.CALF_FINISHING]: "Calf Finishing",
  [LGMQuoteType.FINISHING]: "Finishing",
  [LGMQuoteType.FARROW_TO_FINISH]: "Farrow To Finish",
  [LGMQuoteType.SEW_PIG_FINISHING]: "SEW Pig Finishing",
  [LGMQuoteType.NO_TYPE_SPECIFIED]: "No Type Selected",
};
const factorMapping = {
  CATTLE: {
    YEARLING_FINISHING: 12.5,
    CALF_FINISHING: 11.5,
  },
  SWINE: {
    SEW_PIG_FINISHING: 1.924,
    FARROW_TO_FINISH: 1.924,
    FINISHING: 1.924,
  },
  DAIRY_CATTLE: {
    NO_TYPE_SPECIFIED: 1,
  },
};
const condensedRowsToShow = {
  [LGMCommodityType.CATTLE]: [0, 40, 70],
  [LGMCommodityType.SWINE]: [0, 6, 10],
  [LGMCommodityType.DAIRY_CATTLE]: [0, 0.5, 1],
};

function LGMResultTable({ printResult }) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getMostRecentDateSecureLGM());
  }, [dispatch]);

  const {
    sales_effective_date,
    estimatedIndemnities,
    expectedNetToProducerValues,
    target_months,
  } = useAppSelector((state) => state.lgm);
  const {
    state_name,
    cattle_marketing_plan,
    milk_marketing_plan,
    dairy_corn_meal_feed,
    dairy_soybean_meal_feed,
    swine_marketing_plan,
    deductible_selection,
    policy,
    type,
    cattle_corn_target_weight,
    cattle_feeder_cattle_target_weight,
    cattle_live_cattle_target_weight,
  } = useAppSelector((state) => state.lgm.input);

  useEffect(() => {
    if (sales_effective_date && state_name) {
      dispatch(
        getInitalExpectedGrossMargins({
          sales_effective_date: sales_effective_date,
          state_name: state_name,
        }),
      );
    }
  }, [sales_effective_date, state_name, dispatch]);
  const [isResultRowsCondensed, _setIsResultRowsCondensed] = useState(false);
  const [isPerHead, _setIsPerHead] = useState(true);
  const [totalMarketed, setTotalMarketed] = useState(0);
  const [multiplicationFactor, setMultiplicationFactor] = useState(1);

  const [numberMonthsMarketed, setNumberMonthsMarketed] = useState(0);

  const { inputs_changed } = useAppSelector((state) => state.lgm);

  const { lgm_quote_results, loading, error, message } = useAppSelector(
    (state) => state.lgm.lgmQuoteResults,
  );

  useEffect(() => {
    if (lgm_quote_results) {
      if (
        //TODO: for sales effective date pre 2025 crop year live_cattle_target_weight_quantity is 0 not the default once this is changes we dont have to switch on sales effective date
        sales_effective_date.value >= new Date("2024-07-01") &&
        lgm_quote_results[0]["quote_params"]["user_input"]["commodity_type"]
          .value === LGMCommodityType.CATTLE
      ) {
        setMultiplicationFactor(
          lgm_quote_results[0]["quote_params"]["user_input"][
            "live_cattle_target_weight_quantity"
          ],
        );
      } else {
        setMultiplicationFactor(
          factorMapping[
            lgm_quote_results[0]["quote_params"]["user_input"][
              "commodity_type"
            ]["value"]
          ][
            lgm_quote_results[0]["quote_params"]["user_input"]["quote_type"][
              "value"
            ]
          ],
        );
      }

      //Set toal marketed (we can still iterate over the 7,8,9,10,11 for swine as those will have 0 and exist)
      //map creates a new array with the values of each month marketed
      //reduce adds all the values in the array
      setTotalMarketed(
        [2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
          .map(
            (index) =>
              lgm_quote_results[0]["quote_params"]["user_input"][
                `month_${index}_marketed`
              ],
          )
          .reduce((a, b) => a + b, 0),
      );

      setNumberMonthsMarketed(
        [2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
          .map(
            (index) =>
              lgm_quote_results[0]["quote_params"]["user_input"][
                `month_${index}_marketed`
              ],
          )
          .filter((value) => value > 0).length,
      );
    }
  }, [lgm_quote_results]);

  const getQuote = () => {
    dispatch(resetLGMQuoteResults());

    let _lgm_quote_params = {};
    if (policy.value === LGMCommodityType.CATTLE) {
      //let count = 0;
      console.log(cattle_marketing_plan);
      console.log(target_months);
      _lgm_quote_params = new LGMQuoteUserInputs(
        sales_effective_date,
        state_name,
        policy,
        type,
        deductible_selection,
        Number(cattle_marketing_plan[target_months[0]]),
        Number(cattle_marketing_plan[target_months[1]]),
        Number(cattle_marketing_plan[target_months[2]]),
        Number(cattle_marketing_plan[target_months[3]]),
        Number(cattle_marketing_plan[target_months[4]]),
        Number(cattle_marketing_plan[target_months[5]]),
        Number(cattle_marketing_plan[target_months[6]]),
        Number(cattle_marketing_plan[target_months[7]]),
        Number(cattle_marketing_plan[target_months[8]]),
        Number(cattle_marketing_plan[target_months[9]]),
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        Number(cattle_corn_target_weight),
        Number(cattle_feeder_cattle_target_weight),
        Number(cattle_live_cattle_target_weight),
      );
    } else if (policy.value === LGMCommodityType.SWINE) {
      //let count = 0;
      _lgm_quote_params = new LGMQuoteUserInputs(
        sales_effective_date,
        state_name,
        policy,
        type,
        deductible_selection,
        Number(swine_marketing_plan[target_months[0]]),
        Number(swine_marketing_plan[target_months[1]]),
        Number(swine_marketing_plan[target_months[2]]),
        Number(swine_marketing_plan[target_months[3]]),
        Number(swine_marketing_plan[target_months[4]]),
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
      );
    } else if (policy.value === LGMCommodityType.DAIRY_CATTLE) {
      // _args = {
      //   sales_effective_date: sales_effective_date.replace(/-/g, ""),
      //   state_name: state_name,
      //   milk_marketing_plan: Object.values(milk_marketing_plan),
      //   dairy_corn_meal_feed: Object.values(dairy_corn_meal_feed),
      //   dairy_soybean_meal_feed: Object.values(dairy_soybean_meal_feed),
      //   deductible_selection: deductible_selection,
      //   policy_selection: policyMapping[policy],
      //   quote_selection: typeMapping[type],
      //   beginning_farmer: 0,
      // };
      _lgm_quote_params = new LGMQuoteUserInputs(
        sales_effective_date,
        state_name,
        policy,
        type,
        deductible_selection,
        Number(milk_marketing_plan[target_months[0]]),
        Number(milk_marketing_plan[target_months[1]]),
        Number(milk_marketing_plan[target_months[2]]),
        Number(milk_marketing_plan[target_months[3]]),
        Number(milk_marketing_plan[target_months[4]]),
        Number(milk_marketing_plan[target_months[5]]),
        Number(milk_marketing_plan[target_months[6]]),
        Number(milk_marketing_plan[target_months[7]]),
        Number(milk_marketing_plan[target_months[8]]),
        Number(milk_marketing_plan[target_months[9]]),
        Number(dairy_corn_meal_feed[target_months[0]]),
        Number(dairy_corn_meal_feed[target_months[1]]),
        Number(dairy_corn_meal_feed[target_months[2]]),
        Number(dairy_corn_meal_feed[target_months[3]]),
        Number(dairy_corn_meal_feed[target_months[4]]),
        Number(dairy_corn_meal_feed[target_months[5]]),
        Number(dairy_corn_meal_feed[target_months[6]]),
        Number(dairy_corn_meal_feed[target_months[7]]),
        Number(dairy_corn_meal_feed[target_months[8]]),
        Number(dairy_corn_meal_feed[target_months[9]]),
        Number(dairy_soybean_meal_feed[target_months[0]]),
        Number(dairy_soybean_meal_feed[target_months[1]]),
        Number(dairy_soybean_meal_feed[target_months[2]]),
        Number(dairy_soybean_meal_feed[target_months[3]]),
        Number(dairy_soybean_meal_feed[target_months[4]]),
        Number(dairy_soybean_meal_feed[target_months[5]]),
        Number(dairy_soybean_meal_feed[target_months[6]]),
        Number(dairy_soybean_meal_feed[target_months[7]]),
        Number(dairy_soybean_meal_feed[target_months[8]]),
        Number(dairy_soybean_meal_feed[target_months[9]]),
      );
    }
    // TODO: Add error handling for sending DRP Quote request

    dispatch(
      getLGMQuoteSecure({
        lgm_quote_params: _lgm_quote_params,
        months: Object.keys(cattle_marketing_plan),
      }),
    );
  };

  const _getClassName = (value) => {
    if (value > 0) {
      return `${"positive_val"}`;
    } else if (value < 0) {
      return `${"negative_val"}`;
    } else {
      return `${"neutral_val"}`;
    }
  };
  return (
    <div className="card">
      <div className="card-body">
        <div className="side-by-side">
          <h5 className="card-title pb-1">Quote Results</h5>
          <div
            className={`side-by-side mb-1 ${
              !lgm_quote_results || loading ? "hidden" : ""
            }`}
          >
            <span className="me-1">
              <i className="bx bx-sm bxs-printer"></i>
            </span>
            <span className="fake-a-tag d-print-none" onClick={printResult}>
              Print This Quote
            </span>
          </div>
        </div>
        {/* class-name for button when inputs changed: .btn-ag-warning (from index.scss) */}
        <div className="d-flex flex-row justify-content-between">
          <div>
            <button
              className={`btn btn-secondary ${
                inputs_changed && "btn-ag-warning"
              }`}
              type="button"
              style={buttonStyles}
              onClick={getQuote}
              disabled={loading || !inputs_changed}
            >
              {loading && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {loading ? " Loading..." : "Calculate"}
            </button>
            {/* TODO: Should appear only when any LGM component states change */}
            {/* TODO: Move LGM states to LGM Redux slice. */}
            {inputs_changed && (
              <span
                className="display-inline-block ps-3"
                style={{ fontStyle: "italic", color: "rgb(142, 39, 54)" }}
              >
                Inputs changed. Click calculate to recalculate results.
              </span>
            )}
            {!inputs_changed && lgm_quote_results && (
              <span
                className="display-inline-block ps-3"
                style={{ fontStyle: "italic", color: "rgb(142, 39, 54)" }}
              >
                Update input parameters to calculate another quote.
              </span>
            )}
          </div>
          <span className="float-right">
            Sales Effective Date:{" "}
            <span className="fw-bold ms-1">
              {sales_effective_date &&
                `${sales_effective_date.value
                  .getFullYear()
                  .toLocaleString("en", {
                    minimumIntegerDigits: 4,
                    useGrouping: false,
                  })}-${(
                  sales_effective_date.value.getMonth() + 1
                ).toLocaleString("en", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })}-${sales_effective_date.value
                  .getDate()
                  .toLocaleString("en", {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  })}`}
            </span>
          </span>
        </div>
        <hr />

        {!loading && lgm_quote_results ? (
          <div className="card-body">
            {/* Policy and Type */}
            <div className="table-responsive">
              <table className="table table-sm table-hover">
                <thead></thead>
                <tbody>
                  <tr
                    style={{
                      verticalAlign: "middle",
                    }}
                  >
                    <th style={{ width: "20%" }}>Policy Selection:</th>
                    <td>
                      {
                        policyDisplayMapping[
                          lgm_quote_results[0]["quote_params"]["user_input"][
                            "commodity_type"
                          ]["value"]
                        ]
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "4em", verticalAlign: "middle" }}>
                    <th style={{ width: "20%" }}>Quote Selection:</th>
                    <td>
                      {
                        typeDisplayMapping[
                          lgm_quote_results[0]["quote_params"]["user_input"][
                            "quote_type"
                          ]["value"]
                        ]
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Marketing Plans */}
            {lgm_quote_results[0]["quote_params"]["user_input"][
              "commodity_type"
            ]["value"] === LGMCommodityType.CATTLE && (
              <div className="table-responsive">
                <table className="table table-sm table-hover">
                  <thead>
                    <tr
                      style={{
                        height: "3em",
                        borderBottom: "2px solid #dee2e6",
                      }}
                    >
                      <th>
                        <span className="fw-bold">Marketing Plan:</span>
                      </th>
                      {target_months.map((month, index) => {
                        return (
                          <th key={index}>
                            <span className="fw-bold">{month}</span>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ height: "3em", verticalAlign: "middle" }}>
                      <th>Cattle Marketed:</th>

                      {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => (
                        <td style={{ minWidth: "4em" }} key={month}>
                          {
                            lgm_quote_results[0]["quote_params"]["user_input"][
                              `month_${month}_marketed`
                            ]
                          }
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            )}

            {lgm_quote_results[0]["quote_params"]["user_input"][
              "commodity_type"
            ]["value"] === LGMCommodityType.SWINE && (
              <div className="table-responsive">
                <table className="table table-sm table-hover">
                  <thead>
                    <tr style={{ height: "3em", verticalAlign: "middle" }}>
                      <th>
                        <span className="fw-bold">Marketing Plan:</span>
                      </th>
                      {Object.keys(swine_marketing_plan).map((month, index) => {
                        return (
                          <th key={index}>
                            <span className="fw-bold">{month}</span>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ height: "3em", verticalAlign: "middle" }}>
                      <th>Swine Marketed:</th>

                      {[2, 3, 4, 5, 6].map((month) => (
                        <td style={{ minWidth: "4em" }} key={month}>
                          {
                            lgm_quote_results[0]["quote_params"]["user_input"][
                              `month_${month}_marketed`
                            ]
                          }
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            )}

            {lgm_quote_results[0]["quote_params"]["user_input"][
              "commodity_type"
            ]["value"] === LGMCommodityType.DAIRY_CATTLE && (
              <div className="table-responsive">
                <table className="table table-sm table-hover">
                  <thead>
                    <tr
                      style={{
                        height: "3em",
                        verticalAlign: "middle",
                        borderBottom: "2px solid #dee2e6",
                      }}
                    >
                      <th>
                        <span className="fw-bold">Marketing Plan:</span>
                      </th>
                      {Object.keys(milk_marketing_plan).map((month, index) => {
                        return (
                          <th key={index}>
                            <span className="fw-bold">{month}</span>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ height: "3em", verticalAlign: "middle" }}>
                      <th>Milk Marketed (Hundredweights):</th>
                      {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => (
                        <td style={{ minWidth: "4em" }} key={month}>
                          {
                            lgm_quote_results[0]["quote_params"]["user_input"][
                              `month_${month}_marketed`
                            ]
                          }
                        </td>
                      ))}
                    </tr>
                    <tr style={{ height: "3em", verticalAlign: "middle" }}>
                      <th>Corn Equivalent Fed (Tons):</th>
                      {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => (
                        <td style={{ minWidth: "4em" }} key={month}>
                          {
                            lgm_quote_results[0]["quote_params"]["user_input"][
                              `month_${month}_corn_marketed`
                            ]
                          }
                        </td>
                      ))}
                    </tr>
                    <tr style={{ height: "3em", verticalAlign: "middle" }}>
                      <th>Soybean Meal Equivalent Fed (Tons):</th>
                      {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => (
                        <td style={{ minWidth: "4em" }} key={month}>
                          {
                            lgm_quote_results[0]["quote_params"]["user_input"][
                              `month_${month}_soybean_marketed`
                            ]
                          }
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            {numberMonthsMarketed < 2 && (
              <div className="alert alert-warning">
                Only one month has marketings. To receive a subsidy you must
                market at least two months. If you wish to view subsidized
                results, market another month and recalculate results.
              </div>
            )}

            <div className="table-responsive">
              <table className="table table-fit">
                <thead>
                  <tr>
                    <th>
                      Deductible{" "}
                      {lgm_quote_results[0]["quote_params"]["user_input"][
                        "commodity_type"
                      ]["value"] !== "DAIRY_CATTLE"
                        ? "Per Head"
                        : "Per CWT"}
                      <br />
                      {!isPerHead && "(Per CWT)"}
                    </th>
                    <th>Subsidy</th>
                    <th>
                      Producer Premium
                      <br />
                      {!isPerHead ||
                      lgm_quote_results[0]["quote_params"]["user_input"][
                        "commodity_type"
                      ]["value"] === "DAIRY_CATTLE"
                        ? "(Per CWT)"
                        : "(Per Head)"}
                    </th>
                    <th>
                      Guaranteed Gross Margin
                      <br />
                      {!isPerHead ||
                      lgm_quote_results[0]["quote_params"]["user_input"][
                        "commodity_type"
                      ]["value"] === "DAIRY_CATTLE"
                        ? "(Per CWT)"
                        : "(Per Head)"}{" "}
                    </th>
                    <th>
                      Indemnity
                      <br />
                      {!isPerHead ||
                      lgm_quote_results[0]["quote_params"]["user_input"][
                        "commodity_type"
                      ]["value"] === "DAIRY_CATTLE"
                        ? "(Per CWT)"
                        : "(Per Head)"}{" "}
                    </th>
                    <th>
                      Expected Net To Producer
                      <br />
                      {!isPerHead ||
                      lgm_quote_results[0]["quote_params"]["user_input"][
                        "commodity_type"
                      ]["value"] === "DAIRY_CATTLE"
                        ? "(Per CWT)"
                        : "(Per Head)"}{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(lgm_quote_results as Array<any>)
                    .filter((lgm_quote_result, index) => {
                      return isResultRowsCondensed
                        ? condensedRowsToShow[
                            lgm_quote_result["quote_params"]["user_input"][
                              "commodity_type"
                            ]["value"]
                          ].includes(
                            lgm_quote_result["quote_params"]["user_input"][
                              "deductible"
                            ],
                          )
                        : lgm_quote_result;
                    })
                    .map((lgm_quote_result, index) => {
                      return (
                        <tr
                          style={{ height: "4em", verticalAlign: "middle" }}
                          key={index}
                        >
                          <th>
                            {lgm_quote_results[0]["quote_params"]["user_input"][
                              "commodity_type"
                            ]["value"] !== "DAIRY_CATTLE" ? (
                              <>
                                {`$${lgm_quote_result["quote_params"][
                                  "user_input"
                                ]["deductible"].toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                })}`}
                                <br />
                                {!isPerHead &&
                                  `$${(
                                    lgm_quote_result["quote_params"][
                                      "user_input"
                                    ]["deductible"] / multiplicationFactor
                                  ).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}`}
                              </>
                            ) : (
                              <>
                                {`$${(
                                  lgm_quote_result["quote_params"][
                                    "user_input"
                                  ]["deductible"] / multiplicationFactor
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}`}
                              </>
                            )}
                          </th>
                          <td>{lgm_quote_result["subsidy_amount"]}</td>
                          <td>
                            {`$${lgm_quote_result[
                              "producer_premium_amount"
                            ].toLocaleString()}`}
                            <br />

                            {isPerHead
                              ? `($${(
                                  lgm_quote_result["producer_premium_amount"] /
                                  totalMarketed
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })})`
                              : `($${(
                                  lgm_quote_result["producer_premium_amount"] /
                                  (totalMarketed * multiplicationFactor)
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })})`}
                          </td>
                          <td>
                            {`$${lgm_quote_result[
                              "guaranteed_gross_margin_amount"
                            ].toLocaleString()}`}
                            <br />
                            {isPerHead
                              ? `($${(
                                  lgm_quote_result[
                                    "guaranteed_gross_margin_amount"
                                  ] / totalMarketed
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })})`
                              : `($${(
                                  lgm_quote_result[
                                    "guaranteed_gross_margin_amount"
                                  ] /
                                  (totalMarketed * multiplicationFactor)
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })})`}
                          </td>
                          <td>
                            <label>
                              <span>
                                {`$${Math.abs(
                                  estimatedIndemnities[
                                    lgm_quote_result["quote_params"][
                                      "user_input"
                                    ]["deductible"]
                                  ],
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                })}`}
                              </span>
                              <br />

                              <span>
                                {isPerHead
                                  ? `($${Math.abs(
                                      estimatedIndemnities[
                                        lgm_quote_result["quote_params"][
                                          "user_input"
                                        ]["deductible"]
                                      ] / totalMarketed,
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })})`
                                  : `($${Math.abs(
                                      estimatedIndemnities[
                                        lgm_quote_result["quote_params"][
                                          "user_input"
                                        ]["deductible"]
                                      ] /
                                        (totalMarketed * multiplicationFactor),
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })})`}
                              </span>
                            </label>
                          </td>
                          <td>
                            <label
                              className={_getClassName(
                                expectedNetToProducerValues[
                                  lgm_quote_result["quote_params"][
                                    "user_input"
                                  ]["deductible"]
                                ],
                              )}
                            >
                              <span>
                                {`$${Math.abs(
                                  expectedNetToProducerValues[
                                    lgm_quote_result["quote_params"][
                                      "user_input"
                                    ]["deductible"]
                                  ],
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                })}`}
                              </span>
                              <br />
                              <span>
                                {isPerHead
                                  ? `($${Math.abs(
                                      expectedNetToProducerValues[
                                        lgm_quote_result["quote_params"][
                                          "user_input"
                                        ]["deductible"]
                                      ] / totalMarketed,
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })})`
                                  : `($${Math.abs(
                                      expectedNetToProducerValues[
                                        lgm_quote_result["quote_params"][
                                          "user_input"
                                        ]["deductible"]
                                      ] /
                                        (totalMarketed * multiplicationFactor),
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })})`}
                              </span>
                            </label>
                          </td>
                        </tr>
                      );
                    })}

                  <tr
                    style={{
                      borderTop: "2px solid #dee2e6",
                      height: "4em",
                      verticalAlign: "middle",
                    }}
                  >
                    <td>
                      <b>Actions:</b>
                    </td>
                    <td colSpan={5}>
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          _setIsResultRowsCondensed(!isResultRowsCondensed)
                        }
                        style={{
                          margin: 5,
                          // display: "inline-block",
                          // wordWrap: "break-word",
                        }}
                      >
                        {isResultRowsCondensed
                          ? "Show More Rows"
                          : "Show Fewer Rows"}
                      </button>

                      {(lgm_quote_results[0]["quote_params"]["user_input"][
                        "commodity_type"
                      ]["value"] === LGMCommodityType.CATTLE ||
                        lgm_quote_results[0]["quote_params"]["user_input"][
                          "commodity_type"
                        ]["value"] === LGMCommodityType.SWINE) && (
                        <button
                          className="btn btn-primary"
                          onClick={() => _setIsPerHead(!isPerHead)}
                          style={{
                            margin: 5,
                            // display: "inline-block",
                            // wordWrap: "break-word",
                          }}
                        >
                          {isPerHead ? "Show Per CWT" : "Show Per Head"}
                        </button>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) : loading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : error ? (
          <section
            id="error-message"
            className="my-4 alert alert-danger d-flex align-items-center"
          >
            {/* <use xlinkHref="#exclamation-triangle-fill" /> */}
            <i
              className="bx bxs-error mx-2"
              style={{ color: "#842029", fontSize: "1.5rem" }}
            ></i>
            <span className="fw-bold" style={{ color: "rgb(142, 39, 54)" }}>
              {/* if message is array then iterate over it else  if string just display */}
              {Array.isArray(message)
                ? message.map((msg, index) => (
                    <div key={index} className="mb-0">
                      {msg}
                    </div>
                  ))
                : message}
            </span>
          </section>
        ) : (
          <b>No results to display.</b>
        )}
      </div>
    </div>
  );
}

export default LGMResultTable;
