import React, { useState, useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../app/redux/hooks";
import { setCWT } from "../../../LRPSlice";

const WEIGHT_1_CWT = {
  min: 1,
  max: 5.99,
};

const WEIGHT_2_CWT = {
  min: 6,
  max: 10,
};

function LRPFeederCattleTargetWeightSelection({ w1, w2 }) {
  const dispatch = useAppDispatch();

  const { type } = useAppSelector((state) => state.lrp);

  const [localCWT, setLocalCWT] = useState("0");
  const [CWTLimits, setCWTLimits] = useState(WEIGHT_1_CWT);

  const [warningMessage, setWarningMessage] = useState("");

  const updateCWT = (event) => {
    // const val = Number(event.target.value.replace(/\D/g, ""));
    let val = String(event.target.value);
    if (Number.isNaN(Number(val))) {
      val = "0";
      setWarningMessage("Please enter a valid amount.");
    }
    setLocalCWT(val);
  };

  useEffect(() => {
    if (w1.includes(type)) {
      setCWTLimits(WEIGHT_1_CWT);
    } else if (w2.includes(type)) {
      setCWTLimits(WEIGHT_2_CWT);
    }
    // else {
    //   setWarningMessage("Invalid Weight Limit!");
    // }
  }, [type, w1, w2]);

  useEffect(() => {
    const parsed_CWT = parseFloat(localCWT);
    if (
      parsed_CWT < CWTLimits.min ||
      parsed_CWT > CWTLimits.max ||
      isNaN(parsed_CWT)
    ) {
      setWarningMessage(
        `Target Weight must be between ${CWTLimits.min} and ${CWTLimits.max} CWT.`,
      );
    } else {
      setWarningMessage("");
    }
    dispatch(setCWT(parsed_CWT.toFixed(2)));
  }, [localCWT, CWTLimits, dispatch]);

  useEffect(() => {
    setWarningMessage("");
  }, []);

  return (
    <div className="card w-100">
      <div className="mx-0 px-0" style={{ minWidth: 300 }}>
        <div className="card-body">
          <h5 className="card-title">Target Weight</h5>
          <p className="card-text">
            {w1.includes(type) ? (
              <span>
                Enter a target weight between 1 and 5.99 CWT for weight 1 and
                unborn selections.
              </span>
            ) : (
              <span>
                Enter a target weight between 6 and 10 CWT for weight 2
                selections.
              </span>
            )}
          </p>
          <div className="input-group has-validation">
            <input
              type="text"
              name="cwt"
              id="cwt"
              className={`form-control ag-form-focus ${
                warningMessage !== "" && "is-invalid"
              }`}
              value={localCWT}
              onChange={updateCWT}
            />
            <span className="input-group-text">CWT</span>
            <div className="invalid-feedback">
              <p>{warningMessage}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LRPFeederCattleTargetWeightSelection;
