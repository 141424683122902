import React, { useEffect } from "react";
import { useAppSelector } from "../../../app/redux/hooks";
import LGMCattleMarketingPlan from "./LGMMarketingPlans/LGMCattleMarketingPlan";
import LGMDairyCattleMarketingPlan from "./LGMMarketingPlans/LGMDairyCattleMarketingPlan";
import LGMSwineMarketingPlan from "./LGMMarketingPlans/LGMSwineMarketingPlan";
import { LGMCommodityType } from "@agdeveloper/domain";
import { useAppDispatch } from "../../../app/redux/hooks";
import { getMonthsToShow } from "../features/LGMActions";
const LGMMarketingPlan = () => {
  const { policy } = useAppSelector((state) => state.lgm.input);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getMonthsToShow(""));
  }, [dispatch]);

  return (
    <>
      {policy?.value === LGMCommodityType.CATTLE && <LGMCattleMarketingPlan />}
      {policy?.value === LGMCommodityType.SWINE && <LGMSwineMarketingPlan />}
      {policy.value === LGMCommodityType.DAIRY_CATTLE && (
        <LGMDairyCattleMarketingPlan />
      )}
    </>
  );
};

export default LGMMarketingPlan;
