import axios from "axios";
import { logout } from "../../Authentication/features/authActions";
const UNAUTHORIZED = 401;
const FORBIDDEN = 403;
//When we get a 401 back logout the user

const defaultInstance = axios.create({ withCredentials: true });

export function configureDefaultInstance(store) {
  defaultInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const status = error.status || error.response.status;
      if (status === UNAUTHORIZED || status === FORBIDDEN) {
        //Only dispatch logout if they are logged in else we will get a infinite loop
        if (store.getState().auth.isLoggedIn) {
          store.dispatch(logout());
        }
      }

      return Promise.reject(error);
    },
  );
}
export default defaultInstance;
