import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/redux/hooks";
import "../ResultTableIndemnities.scss";
import IndemnitySliderButton from "../IndemnitySliderButton";
import {
  setIndemnityValueSingle,
  setEndorsementValueSingle,
} from "../../../features/DRPSlice";
function ResultTableIndemnitySingle(props) {
  const { indemnityBasePrices, drpQuoteResults } = useAppSelector(
    (state) => state.drp,
  );
  const dispatch = useAppDispatch();
  // Indemnity States
  const [indexValue, setIndexValue] = useState<number>(0);

  const updateIndemnity = () => {
    const res = drpQuoteResults.data;

    let ActualMilkRevenue;

    let index_value;
    if (res) {
      if (res["quote_params"]["quote_type"]["value"] === "CLPROP") {
        let _class3_revenue =
          Math.round(
            props.projectedPrices.projectedClass3Price *
              res["quote_params"]["declared_class_price_weighting_factor"] *
              10000,
          ) / 10000;
        _class3_revenue = _class3_revenue ? _class3_revenue : 0;

        let _class4_revenue =
          Math.round(
            props.projectedPrices.projectedClass4Price *
              (1 -
                res["quote_params"]["declared_class_price_weighting_factor"]) *
              10000,
          ) / 10000;
        _class4_revenue = _class4_revenue ? _class4_revenue : 0;

        ActualMilkRevenue =
          Math.round(
            Math.round(
              ((Math.round((_class3_revenue + _class4_revenue) * 10000) /
                10000) *
                res["quote_params"]["declared_covered_milk_production"]) /
                100,
            ) * 10000,
          ) / 10000;

        // let _class3_final_revenue =
        //   Math.round(
        //     indemnityBasePrices.class3Price *
        //       res["quote_params"]["declared_class_price_weighting_factor"] *
        //       10000
        //   ) / 10000;

        // _class3_final_revenue = _class3_final_revenue
        //   ? _class3_final_revenue
        //   : 0;

        // let _class4_final_revenue =
        //   Math.round(
        //     indemnityBasePrices.class4Price *
        //       (1 -
        //         res["quote_params"]["declared_class_price_weighting_factor"]) *
        //       10000
        //   ) / 10000;

        // _class4_final_revenue = _class4_final_revenue;
        // ? _class4_final_revenue
        // : 0;

        // FinalMilkRevenue =
        //   Math.round(
        //     Math.round(
        //       ((Math.round(
        //         (_class3_final_revenue + _class4_final_revenue) * 10000
        //       ) /
        //         10000) *
        //         res["quote_params"]["declared_covered_milk_production"]) /
        //         100
        //     ) * 10000
        //   ) / 10000;
      } else if (res["quote_params"]["quote_type"]["value"] === "CMPROP") {
        const ActualMilkRevenue_c3 = Math.round(
          Math.round(
            (((Math.round(
              (Math.round(
                props.projectedPrices.projectedButterPrice *
                  res["quote_params"]["declared_butterfat_test"] *
                  10000,
              ) /
                10000 +
                Math.round(
                  props.projectedPrices.projectedProteinPrice *
                    res["quote_params"]["declared_protein_test"] *
                    10000,
                ) /
                  10000 +
                Math.round(
                  props.projectedPrices.projectedOthersolidsPrice * 5.7 * 10000,
                ) /
                  10000) *
                10000,
            ) /
              10000) *
              res["quote_params"]["declared_covered_milk_production"]) /
              100) *
              10000,
          ) / 10000,
        );

        const ActualMilkRevenue_c4 = Math.round(
          Math.round(
            (((Math.round(
              (Math.round(
                props.projectedPrices.projectedButterPrice *
                  res["quote_params"]["declared_butterfat_test"] *
                  10000,
              ) /
                10000 +
                Math.round(
                  props.projectedPrices.projectedNonfatSolidsPrice *
                    (res["quote_params"]["declared_protein_test"] + 5.7) *
                    10000,
                ) /
                  10000) *
                10000,
            ) /
              10000) *
              res["quote_params"]["declared_covered_milk_production"]) /
              100) *
              10000,
          ) / 10000,
        );

        ActualMilkRevenue =
          ActualMilkRevenue_c3 *
            res["quote_params"]["declared_component_price_weighting_factor"] +
          ActualMilkRevenue_c4 *
            (1 -
              res["quote_params"]["declared_component_price_weighting_factor"]);
      }

      ActualMilkRevenue =
        ActualMilkRevenue * (1 + props.yieldValues.yieldAdjustFactor / 100);
      index_value =
        ActualMilkRevenue /
        (res["quote_params"]["declared_covered_milk_production"] / 100);

      setIndexValue(index_value);

      const indemnityValue = Math.round(
        Math.max(res["expected_revenue_guarantee"] - ActualMilkRevenue, 0) *
          res["quote_params"]["declared_share"] *
          res["quote_params"]["protection_factor"],
      );

      dispatch(setIndemnityValueSingle(indemnityValue));

      const estimatedEndorsementValue =
        indemnityValue - res["producer_premium_amount"];

      dispatch(setEndorsementValueSingle(estimatedEndorsementValue));
    }
  };
  // dont trigger with new quote
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateIndemnity, [
    props.projectedPrices.projectedButterPrice,
    props.projectedPrices.projectedNonfatSolidsPrice,
    props.projectedPrices.projectedOthersolidsPrice,
    props.projectedPrices.projectedProteinPrice,
    props.projectedPrices.projectedClass3Price,
    props.projectedPrices.projectedClass4Price,
    props.yieldValues.yieldAdjustFactor,
    dispatch,
  ]);
  useEffect(() => {
    // Reset sliders and values prices when base prices change
    props.sliderMethods.resetAllSliders();
    updateIndemnity();

    // dont trigger with call of reset
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indemnityBasePrices]);

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title mb-3">Indemnity Calculations:</h5>
        <div className="card-text mb-3">
          Use the sliders below to see how your endorsement will react under
          various market conditions.
        </div>
        <div className="form-group">
          <table>
            <thead></thead>
            <tbody>
              <tr>
                <td>
                  <p>Index Price: </p>
                </td>
                <td>
                  <p
                    className="text-center fw-bold price-clprop"
                    style={{ paddingLeft: "42px" }}
                  >
                    {indexValue.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <table
            style={{ display: "block" }}
            className="table table-sm table-borderless"
          >
            <tbody>
              <tr>
                <th scope="row">Class III:</th>
                <td className="slider-cell">
                  <IndemnitySliderButton
                    name={"Class III - Class"}
                    value={-1}
                    onClick={props.sliderMethods.incrementSlider}
                    onMouseDown={props.sliderMethods.startIncrementSlider}
                    onMouseUp={props.sliderMethods.stopSliderIncrement}
                  />
                  <input
                    type="range"
                    min={props.slider_consts.class3.min}
                    max={props.slider_consts.class3.max}
                    value={props.sliderValues.class3Slider}
                    name="class3"
                    onChange={(e) => {
                      props.sliderMethods.updateFromClass3Slider_c3c4(
                        Number(e.target.value),
                      );
                    }}
                  />
                  <IndemnitySliderButton
                    name={"Class III - Class"}
                    value={1}
                    onClick={props.sliderMethods.incrementSlider}
                    onMouseDown={props.sliderMethods.startIncrementSlider}
                    onMouseUp={props.sliderMethods.stopSliderIncrement}
                  />
                </td>
                <td>
                  <div className="d-inline-block fw-bold">
                    {props.projectedPrices.projectedClass3Price.toFixed(2)}
                  </div>
                </td>
                <td>
                  <div className="fw-normal">
                    ({indemnityBasePrices.class3Price.toFixed(2)})
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">Class IV:</th>
                <td className="slider-cell">
                  <IndemnitySliderButton
                    name={"Class IV - Class"}
                    value={-1}
                    onClick={props.sliderMethods.incrementSlider}
                    onMouseDown={props.sliderMethods.startIncrementSlider}
                    onMouseUp={props.sliderMethods.stopSliderIncrement}
                  />
                  <input
                    type="range"
                    min={props.slider_consts.class4.min}
                    max={props.slider_consts.class4.max}
                    value={props.sliderValues.class4Slider}
                    name="class4"
                    onChange={(e) => {
                      props.sliderMethods.updateFromClass4Slider_c3c4(
                        Number(e.target.value),
                      );
                    }}
                  />
                  <IndemnitySliderButton
                    name={"Class IV - Class"}
                    value={1}
                    onClick={props.sliderMethods.incrementSlider}
                    onMouseDown={props.sliderMethods.startIncrementSlider}
                    onMouseUp={props.sliderMethods.stopSliderIncrement}
                  />
                </td>
                <td>
                  <div className="d-inline-block fw-bold">
                    {props.projectedPrices.projectedClass4Price.toFixed(2)}
                  </div>
                </td>
                <td>
                  <div className="fw-normal">
                    ({indemnityBasePrices.class4Price.toFixed(2)})
                  </div>
                </td>
              </tr>

              <tr>
                <th>&nbsp;</th>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>

              <tr>
                <th scope="row">Yield Adj.:</th>
                <td className="slider-cell">
                  <IndemnitySliderButton
                    name={"Yield Adj - Class"}
                    value={-1}
                    onClick={props.sliderMethods.incrementSlider}
                    onMouseDown={props.sliderMethods.startIncrementSlider}
                    onMouseUp={props.sliderMethods.stopSliderIncrement}
                  />
                  <input
                    type="range"
                    min={props.slider_consts.yield.min}
                    max={props.slider_consts.yield.max}
                    name="yaf"
                    value={props.sliderValues.yafSlider}
                    onChange={(e) =>
                      props.sliderMethods.updateFromYAFSlider_c3c4(
                        Number(e.target.value),
                      )
                    }
                  />

                  <IndemnitySliderButton
                    name={"Yield Adj - Class"}
                    value={1}
                    onClick={props.sliderMethods.incrementSlider}
                    onMouseDown={props.sliderMethods.startIncrementSlider}
                    onMouseUp={props.sliderMethods.stopSliderIncrement}
                  />

                  <br />
                  <div className="rounded-yield-section">
                    <span className="fw-bold">
                      {props.yieldValues.currentYield.toLocaleString()} &#10230;
                      {props.yieldValues.roundedYieldAdjustment.toLocaleString()}
                    </span>
                    <br />
                    <span className="small">
                      {(
                        props.yieldValues.currentYield / props.daysInQtr
                      ).toFixed(1)}
                      <span>&#10230;</span>
                      {(
                        props.yieldValues.roundedYieldAdjustment /
                        props.daysInQtr
                      ).toFixed(1)}
                    </span>
                  </div>
                </td>
                <td>
                  <div className="d-inline-block fw-bold">
                    {props.yieldValues.yieldAdjustFactor}%
                  </div>
                </td>
                <td>
                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={() => props.sliderMethods.resetYafSlider()}
                  >
                    Reset
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <button
            className="btn btn-secondary btn-sm"
            style={{ marginLeft: "3px" }}
            onClick={props.sliderMethods.resetAllSliders}
          >
            Reset All
          </button>
          <div className="form-check d-inline-block ms-4">
            <input
              type="checkbox"
              id="lockClass3Class4"
              className="form-check-input"
              checked={props.c3c4Lock}
              onChange={() => props.handleLock("class")}
            />
            <label
              htmlFor="lockClass3Class4"
              className="form-check-label"
              style={{ fontSize: "10pt" }}
            >
              Lock ClassIII and ClassIV together
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResultTableIndemnitySingle;
