import React, { useRef } from "react";
import ResultTableSingle from "./ResultTableSingle/ResultTableSingle";
import ResultTableIndemnitiesParent from "../ResultTableIndemnities/ResultTableIndemnitiesParent";
import ResultTableClassVsComponent from "./ResultTableClassVsComponent/ResultTableClassVsComponent";
import ResultTableStrip from "./ResultTableStrip/ResultTableStrip";

const handlePrint = (printComponentRef) => {
  const Print = window.open("", "", "width=1000,height=1000");

  Print?.document.write(
    "<html><head><style>.d-print-none {display: none !important;}</style></head>",
  );
  Print?.document.write(
    '<link rel="stylesheet" type="text/css" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css">',
  );
  Print?.document.write(printComponentRef.current.innerHTML);
  // Print.document.write('<p class="pl-4 pr-4" style="font-style: italic; font-weight: 500;">***NOTICE: The premium listed above is an estimate and is provided for reference only. Please contact your insurance agent for an official premium quote.</p>');
  Print?.document.write("</html>");

  Print?.document.close();
  // let user get a preview
  Print?.setTimeout(function () {
    Print?.focus();
    Print?.print();
    Print?.close();
  }, 1500);
};

function ResultTablesContainer({ flag }) {
  const printComponentRef = useRef<any>();
  return (
    <>
      <div>
        {flag === "single" && (
          <div className="card" id="printArea" ref={printComponentRef}>
            <ResultTableSingle
              flag={flag}
              printResult={() => handlePrint(printComponentRef)}
            />
          </div>
        )}
        {flag === "class-vs-component" && (
          <div className="card" id="printArea" ref={printComponentRef}>
            <ResultTableClassVsComponent
              flag={flag}
              printResult={() => handlePrint(printComponentRef)}
            />
          </div>
        )}
        {flag === "strip" && (
          <div className="card" id="printArea" ref={printComponentRef}>
            <ResultTableStrip
              flag={flag}
              printResult={() => handlePrint(printComponentRef)}
            />
          </div>
        )}
      </div>
      <div>
        {(flag === "class-vs-component" || flag === "single") && (
          <ResultTableIndemnitiesParent flag={flag} />
        )}
      </div>
    </>
  );
}

export default ResultTablesContainer;
