import React from "react";
import { useAppSelector } from "../../../../app/redux/hooks";
// import FedCattleNumberHeadSelection from './FedCattleNumberHeadSelection/FedCattleNumberHeadSelection'
// import FedCattleNumberHeadSelection from './LRPFedCattleMarketingPlan/FedCattleNumberHeadSelection/FedCattleNumberHeadSelection'
// import FedCattleTargetWeightSelection from './LRPFedCattleMarketingPlan/FedCattleTargetWeightSelection/FedCattleTargetWeightSelection'
import LRPFedCattleMarketingPlan from "./LRPFedCattleMarketingPlan/LRPFedCattleMarketingPlan";
import LRPFeederCattleMarketingPlan from "./LRPFeederCattleMarketingPlan/LRPFeederCattleMarketingPlan";
import LRPSwineMarketingPlan from "./LRPSwineMarketingPlan/LRPSwineMarketingPlan";

function LRPMarketingPlan() {
  const { policy } = useAppSelector((state) => state.lrp);
  return (
    <div>
      <div className="row my-2 no">
        {/* <FedCattleNumberHeadSelection />
                <FedCattleTargetWeightSelection /> */}
        {/* TODO: Component should be switched basde on selected policy */}
        {policy === "FED_CATTLE" ? (
          <LRPFedCattleMarketingPlan />
        ) : policy === "FEEDER_CATTLE" ? (
          <LRPFeederCattleMarketingPlan />
        ) : policy === "SWINE" ? (
          <LRPSwineMarketingPlan />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default LRPMarketingPlan;
