import hedgeMAPService from "./hedgeMAPsService";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { saveAs } from "file-saver";

export const getHedgeMAPListing = createAsyncThunk(
  "drp/sendGetHedgeMAPListingRequest",
  async (_: void, thunkAPI) => {
    try {
      const response = await hedgeMAPService.sendGetHedgeMAPListingRequest();
      const payload = await response.data;

      if (response.status === 200) {
        console.log(payload);
        return payload;
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to fetch hedgeMAPs. Please try again later.",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to fetch hedgeMAPs. Please try again later.",
      );
    }
  },
);

export const downloadHedgeMAP = createAsyncThunk(
  "drp/downloadHedgeMAP",
  async (data: any, thunkAPI) => {
    try {
      const response = await hedgeMAPService.sendDownloadHedgeMAPRequest(data);
      const payload = await response;

      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to download hedgeMAP. Please try again later.",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to download hedgeMAP. Please try again later.",
      );
    }
  },
);

export const downloadSingleInsuranceTypeHedgeMAPs = createAsyncThunk(
  "drp/downloadSingleInsuranceTypeHedgeMAPs",
  async (data: any, thunkAPI) => {
    try {
      const response =
        await hedgeMAPService.sendDownloadSingleInsuranceTypeHedgeMAPsRequest(
          data,
        );
      const payload = await response;
      const byteArray = Uint8Array.from(atob(payload.data.file), (c) =>
        c.charCodeAt(0),
      );
      const blob = new Blob([byteArray], { type: "application/zip" });
      saveAs(blob, payload.data.filename);

      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to download hedgeMAP. Please try again later.",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to download hedgeMAP. Please try again later.",
      );
    }
  },
);
