import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { setLGMType } from "../features/LGMSlice";
import { LGMQuoteType, LGMCommodityType } from "@agdeveloper/domain";
import LGMCattleCornTargetWeight from "./LGMCattleCornTargetWeight";
import LGMCattleFeederCattleTargetWeight from "./LGMCattleFeederCattleTargetWeight";
import LGMCattleLiveCattleTargetWeight from "./LGMCattleLiveCattleTargetWeight";

const LGMCattleInputs = () => {
  const { policy } = useAppSelector((state) => state.lgm.input);

  return (
    <>
      {policy.value === LGMCommodityType.CATTLE && (
        <div className="container-fluid">
          <div className="row">
            <div className="col me-1 mt-1 p-0">
              <LGMCattleCornTargetWeight />
            </div>
            <div className="col me-1 mt-1 p-0">
              <LGMCattleFeederCattleTargetWeight />
            </div>
            <div className="col me-1 mt-1 p-0">
              <LGMCattleLiveCattleTargetWeight />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LGMCattleInputs;
