import React from "react";
import "./DecisionToolStyles.scss";

import logo from "../app/assets/csc_logo_transparent.png";
type DecisionToolContainerProps = {
  title: string;
  children: React.ReactNode;
};
function DecisionToolContainer({
  title,
  children,
}: DecisionToolContainerProps) {
  const pageTitle = title;

  return (
    <div
      className="container-fluid ps-4 pe-4 pl-md-1 pr-md-1"
      style={{ maxWidth: 1140 }}
    >
      <div className="jumbotron d-flex flex-row flex-wrap">
        <div>
          <img
            className="rounded float-left d-inline-block"
            height="150"
            style={{ marginTop: "-20px" }}
            src={String(logo)}
            alt="CSC Logo"
          />
        </div>
        <div>
          <h1>{pageTitle}</h1>
          <h5>Powered by AgHedgeDesk.com</h5>
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
}

export default DecisionToolContainer;
