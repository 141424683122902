import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { reset } from "../../features/authSlice";
import { resetPassword } from "../../features/authActions";
import authFormValidator from "../../utils/authFormValidator";
import useMounted from "../../../app/utils/hooks/useMounted";
import "../../styles/authStyles.scss";

import company_logo from "../../../app/assets/csc_logo_transparent.png";

function PasswordResetConfirm() {
  const [searchParams] = useSearchParams();
  const [recoveryCode, setRecoveryCode] = useState("");
  const [recoveryEmail, setRecoveryEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [disabled, setDisabled] = useState(true);
  const { isSuccess, isError, message, isPasswordReset, isLoading } =
    useAppSelector((state) => state.auth.authentication);

  const [formData, setFormData] = useState({
    password1: "",
    password2: "",
  });

  const [formErrors, setFormErrors] = useState<any>({
    password: [],
  });

  const [formValid, setFormValid] = useState<any>(false);
  const [formTouched, setFormTouched] = useState<any>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { password1, password2 } = formData;

  const isMounted = useMounted();

  // Reset on navigate
  useEffect(() => {
    dispatch(reset());
    setFormErrors({
      password: [],
    });
    setErrorMessage("");
    setDisabled(true);
  }, [navigate, dispatch]);

  // Get r_code and email from url params, if not found then throw error
  useEffect(() => {
    dispatch(reset());
    const r_code = searchParams.get("rcode");
    const email = searchParams.get("email");
    if (r_code && email) {
      setRecoveryCode(r_code);
      setRecoveryEmail(email);
    } else {
      setErrorMessage(
        "Recovery link is missing recovery code and/or email. You will be unable to reset your password without them.",
      );
    }
  }, [dispatch, searchParams]);

  // Validate passwords on input
  useEffect(() => {
    if (isMounted) {
      const password_validation = authFormValidator.passwordValidator(
        password1,
        password2,
      );

      if (password_validation.valid) {
        setFormValid(true);
      } else {
        setFormValid(false);
      }
      setFormErrors({
        password: password_validation.messages,
      });
    }
  }, [password1, password2, isMounted]);

  // Check form valid
  useEffect(() => {
    if (formValid) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [formValid]);

  // After reset request is sent
  useEffect(() => {
    if (isSuccess && isPasswordReset) {
      navigate("/login");
    }
    if (isError) {
      setErrorMessage(message);
    } else {
      setErrorMessage("");
    }
  }, [isSuccess, isPasswordReset, isError, message, navigate]);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const password_validation = authFormValidator.passwordValidator(
      password1,
      password2,
    );
    if (password_validation.valid) {
      // TODO: Set error message if dispatch fails
      dispatch(
        resetPassword({
          rcode: recoveryCode,
          email: recoveryEmail,
          new_password: password1,
        }),
      );
    } else {
      setFormErrors(password_validation.messages);
    }
  };

  return (
    <div className="card mx-auto auth-card">
      <div className="card-body">
        <div className="w-100 text-center mb-2">
          <img
            src={company_logo}
            alt="CSC AG Logo"
            className="mt-2 mb-4 mx-auto company-logo"
          />
        </div>
        <div className="text-center">
          <h5 className="card-title mb-4 px-3">Reset Your Password</h5>
        </div>
        <div className="mt-2">
          <form onSubmit={onSubmit}>
            <div className="form-group text-left small">
              <div>
                <p className="info-text">Minimum Password Requirements:</p>
                <ul className="info-list">
                  <li>At least 10 characters</li>
                  <li>Contains both UPPERCASE and lowercase letters</li>
                </ul>
              </div>
              <label htmlFor="password1" className="form-label">
                New Password
              </label>
              <input
                className="form-control"
                type="password"
                value={password1}
                name="password1"
                id="password1"
                placeholder="password"
                onChange={onChange}
                autoComplete="off"
                onBlur={() => setFormTouched(true)}
              />
            </div>
            <div className="form-group text-left small mt-2">
              <label htmlFor="password2" className="form-label">
                Confirm Password
              </label>
              <input
                className="form-control"
                type="password"
                value={password2}
                name="password2"
                id="password2"
                placeholder="password"
                onChange={onChange}
                autoComplete="off"
              />
            </div>
            <ul className="error-list">
              {formTouched &&
                formErrors.password.map((error, index) => (
                  <li key={index} className="text-danger">
                    {error}
                  </li>
                ))}
            </ul>
            <div className="text-center">
              <button
                disabled={disabled || isLoading}
                type="submit"
                className="btn btn-secondary w-75"
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span> Loading... </span>
                  </>
                ) : (
                  <>Reset Password</>
                )}
              </button>
            </div>
          </form>
        </div>
        <div className="text-center mt-4">
          <p className="text-danger">{errorMessage}</p>
        </div>
        <div className="mt-4 w-100 small text-center">
          <Link style={{ textDecoration: "none" }} to="/login">
            <small>{"<< Back to Login"}</small>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PasswordResetConfirm;
