import resourcesService from "./resourcesService";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const downloadLGMvsDRPComparison = createAsyncThunk(
  "resources/downloadLGMvsDRPComparison",
  async (_: void, thunkAPI) => {
    try {
      const response = await resourcesService.downloadLGMvsDRPComparison();
      const payload = await response;

      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to fetch LGM vs DRP Comparison. Please try again later.",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to fetch LGM vs DRP Comparison. Please try again later.",
      );
    }
  },
);

export const downloadLGMvsLRPCattle = createAsyncThunk(
  "resources/downloadLGMvsLRPCattle",
  async (_: void, thunkAPI) => {
    try {
      const response = await resourcesService.downloadLGMvsLRPCattle();
      const payload = await response;

      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to fetch LGM vs LRP Comparison. Please try again later.",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to fetch LGM vs LRP Comparison. Please try again later.",
      );
    }
  },
);

export const downloadLGMvsLRPSwine = createAsyncThunk(
  "resources/downloadLGMvsLRPSwine",
  async (_: void, thunkAPI) => {
    try {
      const response = await resourcesService.downloadLGMvsLRPSwine();
      const payload = await response;
      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to fetch LGM vs LRP Comparison. Please try again later.",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to fetch LGM vs LRP Comparison. Please try again later.",
      );
    }
  },
);

export const downloadFile = createAsyncThunk(
  "resources/downloadFile",
  async (target: string, thunkAPI) => {
    try {
      const response = await resourcesService.downloadFile(target);
      const payload = await response;

      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to download file. Please try again later.",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to download file. Please try again later.",
      );
    }
  },
);
