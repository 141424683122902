import {
  sendDownloadHedgeMAPsRequest,
  sendViewHedgeMAPsRequest,
} from "../../app/utils/sendRequest";

import * as globals from "../../globals";
// const sendGetAgentsAssociatedHedgeMAPsRequest = async () => {
//   const apifunc = `service-producer-hedgemaps/get-agents-associated-hedgemaps`;
//   const args = {};

//   return sendViewHedgeMAPsRequest(apifunc, args);
// };
const sendAuthorizeProducerToViewHedgeMAPRequest = async (data: any) => {
  const apifunc = `service-producer-hedgemaps/authorize-producer-view-hedgemap`;

  const args = {
    authorized_email: data.authorized_email,
    hedgemap_info: data.hedgemap_info,
  };

  return sendViewHedgeMAPsRequest(apifunc, args);
};
// const sendAuthorizeProducerToViewHedgeMAPByProducerRequest = async (data: any) => {
//   const apifunc = `service-producer-hedgemaps/authorize-producer-view-hedgemap`;

//   const args = {
//     authorized_email: data.authorized_email,
//     hedgemap_info: data.hedgemap_info,
//   };

//   return sendViewHedgeMAPsRequest(apifunc, args);
// };
const sendGetProducersHedgeMAPsRequest = async () => {
  const apifunc = `service-producer-hedgemaps/get-producers-hedgemaps`;
  const args = {};

  return sendViewHedgeMAPsRequest(apifunc, args);
};

const sendDownloadHedgeMAPRequest = async (hedgemapDetails: string[]) => {
  const apifunc = `service-producer-hedgemaps/download-hedgemap`;

  const args = {
    hedgemap_details: {
      insurance_type: hedgemapDetails["insurance_type"],
      commodity_sub_type: hedgemapDetails["commodity_sub_type"],
      commodity_type: hedgemapDetails["commodity_type"],
      date: hedgemapDetails["date"],
      file_name: hedgemapDetails["file_name"],
      policy_producer: hedgemapDetails["policy_producer"],
    },
    src: globals.src,
  };

  return sendDownloadHedgeMAPsRequest(apifunc, args);
};
const sendGetUsersAssociatedPolicyProducerAggregatesRequest = async (
  selected_user: string,
) => {
  const apifunc = `service-producer-hedgemaps/get-users-associated-policy-producer-aggregates`;

  const args = {
    user: selected_user,
  };

  return sendViewHedgeMAPsRequest(apifunc, args);
};

// const sendGetAllAgentsUsersAssociatedPolicyProducerAggregatesRequest =
//   async () => {
//     const apifunc = `service-producer-hedgemaps/get-all-agents-associated-user-policy-producer-aggregates`;

//     const args = {};

//     return sendViewHedgeMAPsRequest(apifunc, args);
//   };

const sendLinkProducerAggregatedToUserRequest = async (data) => {
  const apifunc = `service-producer-hedgemaps/link-user-to-policy-producer-aggregate`;
  const args = {
    user: data.user,
    policy_producer: data.policy_producer,
  };

  return sendViewHedgeMAPsRequest(apifunc, args);
};

const sendUnlinkProducerAggregatedToUserRequest = async (data) => {
  const apifunc = `service-producer-hedgemaps/unlink-user-to-policy-producer-aggregate`;
  const args = {
    user: data.user,
    policy_producer: data.policy_producer,
  };

  return sendViewHedgeMAPsRequest(apifunc, args);
};

//this endpoint is the same as get-all-agents-associated-user-policy-producer-aggregates but also returns hedgemap info

const sendGetAllAgentsProducerHedgemapInfoRequest = async () => {
  const apifunc = `service-producer-hedgemaps/get-all-agents-producer-hedgemap-info`;
  const args = {};

  return sendViewHedgeMAPsRequest(apifunc, args);
};

const sendGetPolicyProducersAssociatedUsersRequest = async (
  policy_producer: string,
) => {
  const apifunc = `service-producer-hedgemaps/get-policy-producer-aggregate-associated-users`;

  const args = {
    policy_producer_aggregate: policy_producer,
  };

  return sendViewHedgeMAPsRequest(apifunc, args);
};

const sendGetSpecificHedgemapInfoRequest = async (data: any) => {
  const apifunc = "service-producer-hedgemaps/get-specific-hedgemap-info";

  const args = {
    policy_producer_aggregate: data["policy_producer_aggregate"],
    user: data["user"],
  };

  return sendViewHedgeMAPsRequest(apifunc, args);
};

const sendGetAllHedgemapInfoForAgentRequest = async (data: any) => {
  const apifunc = "service-producer-hedgemaps/get-all-hedgemap-info-for-agent";

  const args = {
    by_user: data["by_user"],
  };

  return sendViewHedgeMAPsRequest(apifunc, args);
};

const hedgeMAPsService = {
  // sendGetAgentsAssociatedHedgeMAPsRequest,
  sendAuthorizeProducerToViewHedgeMAPRequest,
  sendGetProducersHedgeMAPsRequest,
  sendGetSpecificHedgemapInfoRequest,
  sendDownloadHedgeMAPRequest,
  sendGetUsersAssociatedPolicyProducerAggregatesRequest,
  // sendGetAllAgentsUsersAssociatedPolicyProducerAggregatesRequest,
  sendLinkProducerAggregatedToUserRequest,
  sendGetAllAgentsProducerHedgemapInfoRequest,
  sendUnlinkProducerAggregatedToUserRequest,
  sendGetPolicyProducersAssociatedUsersRequest,
  sendGetAllHedgemapInfoForAgentRequest,
};

export default hedgeMAPsService;
