if (!import.meta.env.VITE_REACT_APP_DEPLOY_FOR) {
  throw new Error("VITE_REACT_APP_DEPLOY_FOR is not set");
}
if (!import.meta.env.VITE_REACT_APP_USING_MIDDLEWARE) {
  throw new Error("VITE_REACT_APP_USING_MIDDLEWARE is not set");
}
let production = false; // Default value

// if (typeof import.meta.env.VITE_REACT_APP_DEPLOY_FOR == "boolean") {
// production = import.meta.env.VITE_REACT_APP_DEPLOY_FOR;
// }
production = import.meta.env.PROD;

if (!production) {
  console.log(`import.meta.env.PROD: ${import.meta.env.PROD}`);
  console.log(
    `import.meta.env.VITE_REACT_APP_DEPLOY_FOR: ${import.meta.env.VITE_REACT_APP_DEPLOY_FOR}`,
  );
  console.log(
    `import.meta.env.VITE_REACT_APP_USING_MIDDLEWARE: ${import.meta.env.VITE_REACT_APP_USING_MIDDLEWARE}`,
  );
}
export const environment = {
  production: production,
};
