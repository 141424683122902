import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { environment } from "./env";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
// Redux
import { Provider } from "react-redux";
import { store } from "./app/redux/store";
import { configureDefaultInstance } from "./app/utils/axiosConfig";
// Disable logging to console in production
if (environment.production) {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
  console.info = () => {};
}

console.log("App running in development mode.");
console.error(
  "Log test. THIS SHOULD NOT BE DISPLAYED IF RUNNING IN PRODUCTION!",
);

configureDefaultInstance(store);
// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>
// );
// ReactDOM.createRoot(document.getElementById("root")!).render(
ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
