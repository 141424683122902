import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import {
  getLastEnteredAgentEmail,
  sendAgentAuthorizationEmail,
} from "../../features/authActions";
import authFormValidator from "../../utils/authFormValidator";
import useMounted from "../../../app/utils/hooks/useMounted";
import FormInput from "../utility/FormInput";

function RegistrationAuthorization() {
  const dispatch = useAppDispatch();
  const isMounted = useMounted();

  const { email, registrationAuthorization } = useAppSelector(
    (state) => state.auth,
  );

  const [formData, setFormData] = useState({
    old_agent_email: "",
    new_agent_email: "",
  });

  useEffect(() => {
    if (isMounted && registrationAuthorization.lastEnteredAgentEmail) {
      setFormData((prevState) => ({
        ...prevState,
        old_agent_email: registrationAuthorization.lastEnteredAgentEmail,
      }));
    }
  }, [registrationAuthorization, isMounted]);

  const [formValidity, setFormValidity] = useState<any>({
    new_agent_email: {
      valid: false,
      message: null,
      touched: false,
      required: true,
    },
  });

  // Validate email
  useEffect(() => {
    if (isMounted) {
      setFormValidity((prevState) => ({
        ...prevState,
        new_agent_email: {
          ...prevState.new_agent_email,
          ...authFormValidator.emailValidator(formData.new_agent_email),
        },
      }));
    }
  }, [formData, isMounted]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    setFormValidity((prevState) => ({
      ...prevState,
      new_agent_email: {
        ...prevState.new_agent_email,
        touched: true,
      },
    }));
  };

  useEffect(() => {
    dispatch(getLastEnteredAgentEmail());
  }, [dispatch]);

  const changeAgentSubmit = (e) => {
    e.preventDefault();
    if (registrationAuthorization.isAtMaxChangeAgentEmailAttempts) {
      return;
    }

    const info = {
      producerEmailAddress: email,
      agentEmailAddress: formData.new_agent_email,
      isAfterRegistration: false,
    };

    dispatch(sendAgentAuthorizationEmail(info));
  };
  return (
    <div className="alert alert-dark m-2 p-4">
      <h4 className="alert-heading">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            className="bi bi-exclamation-triangle-fill mx-1"
            viewBox="0 0 16 16"
          >
            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
          </svg>
          Registration Not Authorized
        </span>
      </h4>
      <p>
        Your registration has not yet been authorized. If the Agent Email you
        entered exists in our system, then your agent has been notified of your
        authorization request. Please contact your agent directly if you require
        immediate authorization. If the Agent Email listed below is incorrect,
        please update your agent's email address, but be advised that if you
        make too many updates, your account may be locked by our system.
      </p>
      {registrationAuthorization.isAtMaxChangeAgentEmailAttempts && (
        <div className="alert alert-danger ">
          Your account has been locked because you have submitted too many Agent
          Email Update requests. Please contact your agent to request your
          account be unlocked.
        </div>
      )}

      <div style={{ maxWidth: "400px" }}>
        <form onSubmit={changeAgentSubmit}>
          <div className="card mt-3">
            <div className="card-body">
              <h5 className="card-title">Agent Information</h5>

              <div className="form-group">
                <label>Agent Email:</label>

                <input
                  type="text"
                  disabled={true}
                  className="form-control"
                  id="inputEmailAddress"
                  value={formData.old_agent_email}
                />
              </div>

              <FormInput
                label={"Update Agent Email:"}
                type={"text"}
                onChange={onChange}
                name={"new_agent_email"}
                value={formData.new_agent_email}
                touched={formValidity.new_agent_email.touched}
                message={formValidity.new_agent_email.message}
                required={false}
                id={"new_agent_email"}
                disabled={
                  registrationAuthorization.isAtMaxChangeAgentEmailAttempts
                }
              />
            </div>
          </div>

          <button
            disabled={
              !formValidity.new_agent_email.valid ||
              registrationAuthorization.isAtMaxChangeAgentEmailAttempts
            }
            className={
              registrationAuthorization.isAtMaxChangeAgentEmailAttempts
                ? "btn btn-dark w-auto float-right mt-2 mb-2"
                : "btn btn-primary w-auto float-right mt-2 mb-2"
            }
            type="submit"
          >
            Update Agent Email
          </button>
        </form>
      </div>
    </div>
  );
}

export default RegistrationAuthorization;
