// import React, { useEffect, useState } from "react";
// import Collapse from "react-bootstrap/esm/Collapse";
// import { useAppDispatch, useAppSelector } from "../../app/hooks";
// import { resetDownloadHedgeMAP } from "../../features/hedgeMAPs/hedgeMAPsSlice";
// import "./PodcastListing.scss";
// import { downloadPodcast } from "../../features/Podcasts/podcastsActions";
// import podcastService from "../../features/Podcasts/podcastsService";
// import * as globals from "../../globals";
// const PodcastListing = ({ listing }) => {
//   const dispatch = useAppDispatch();

//   const [podcastExpanded, setPodcastExpanded] = useState(false);
//   const [audioSrc, setAudioSrc] = useState("null");

//   useEffect(() => {
//     if (podcastExpanded) {
//       console.info("download podcast: " + listing.fileName);
//       dispatch(downloadPodcast(listing.fileName));
//     }
//   }, [podcastExpanded]);

//   useEffect(() => {
//     console.info(listing);
//   }, [listing]);

//   // LEAVE THIS HERE FOR NOW - GET REQUEST CODE
//   useEffect(() => {
//     const fetchAudioToken = async () => {
//       try {
//         console.info("fetching audio URL: " + listing.fileName);
//         const response = await podcastService.sendGetStreamTokenRequest(
//           listing.fileName
//         );
//         console.info(response.data);
//         if (response.data.token) {
//           console.info("temp token: " + response.data.token);
//           setAudioSrc(
//             globals.buildurl_get(
//               `service-get-podcasts/stream_podcast`,
//               response.data.token
//             )
//           );
//         }
//       } catch (error) {
//         console.error("Error fetching audio URL:", error);
//       }
//     };

//     fetchAudioToken();
//   }, []);

//   return (
//     <div className="listing-component ">
//       {/* Agency */}
//       <span
//         onClick={() => {
//           setPodcastExpanded(!podcastExpanded);
//         }}
//         className="maroon-border-left list-group-item rounded-0 list-group-link"
//       >
//         <span id="chevron-arrow-right"></span>
//         {listing?.description ? listing.description : listing.name}
//       </span>
//       <Collapse in={podcastExpanded}>
//         <div className="list-group">
//           {/* Date Folder  */}
//           <div className="indent">
//             <span
//               className="list-group-item maroon-border-left rounded-0 list-group-link"
//               style={{
//                 alignItems: "center",
//                 display: "flex",
//               }}
//             >
//               {/* <i className="bx bx-play-circle bx-lg"></i> */}
//               {listing?.description && listing.name}
//               <span className="ml-6" style={{ marginLeft: 30 }}>
//                 {/* {audioSrc ? ( */}
//                 {audioSrc && (
//                   <audio
//                     controls
//                     // src={listing.data ? listing.data : ""}
//                     src={audioSrc}
//                     title={listing.name}
//                     style={{ width: 500 }}
//                   ></audio>
//                 )}
//                 {/* // ) : (
//                 //   <div
//                 //     className="d-flex justify-content-around"
//                 //     style={{
//                 //       alignItems: "center",
//                 //       display: "flex",
//                 //     }}
//                 //   >
//                 //     <span>loading...</span>
//                 //     <span
//                 //       className="spinner-border spinner-border-sm"
//                 //       role="status"
//                 //       aria-hidden="true"
//                 //       style={{ marginLeft: 5 }}
//                 //     ></span>{" "}
//                 //   </div>
//                 // )} */}
//               </span>
//             </span>
//           </div>

//           {/* {listing.contents.map((date_folder) => (
//             <div className="indent">
//               <span
//                 onClick={() => {
//                   //   let newObj = { ...dateExpanded };
//                   //   newObj[date_folder.date_folder_display_name] =
//                   //     !dateExpanded[date_folder.date_folder_display_name];
//                   //   setDateExpanded(newObj);
//                 }}
//                 className="list-group-item maroon-border-left rounded-0 list-group-link"
//               >
//                 <span id="chevron-arrow-right"></span>
//                 {date_folder.date_folder_display_name}
//               </span>
//             </div>
//           ))} */}
//         </div>
//       </Collapse>
//     </div>
//   );
// };
// export default PodcastListing;

import React, { useEffect, useRef, useState } from "react";
import Collapse from "react-bootstrap/esm/Collapse";
import { useAppDispatch } from "../../app/redux/hooks";
import "./PodcastListing.scss";
import { downloadPodcast } from "../features/podcastsActions";

const PodcastListing = ({ listing }) => {
  const dispatch = useAppDispatch();

  const [podcastExpanded, setPodcastExpanded] = useState(false);
  const downloadAttempted = useRef(false);

  useEffect(() => {
    console.info(listing);
    if (
      podcastExpanded &&
      !listing.data &&
      !listing.loading &&
      !downloadAttempted.current
    ) {
      downloadAttempted.current = true;
      dispatch(downloadPodcast(listing.fileName));
    }
  }, [podcastExpanded, dispatch, listing]);

  // const audioRef = useRef();

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = listing.data;
    link.download = listing.fileName;
    link.click();
  };

  // const handleSpeedChange = (event) => {
  //   audioRef.current.playbackRate = event.target.value;
  // };

  return (
    <div
      className="listing-component"
      style={{ cursor: "pointer", minWidth: 500 }}
    >
      {/* Agency */}
      <span
        onClick={() => {
          setPodcastExpanded(!podcastExpanded);
        }}
        className="maroon-border-left list-group-item rounded-0 list-group-link"
      >
        <span id="chevron-arrow-right"></span>
        {listing?.description ? listing.description : listing.fileName}
      </span>
      <Collapse in={podcastExpanded}>
        <div className="list-group">
          {/* Date Folder  */}
          <div className="indent">
            <span
              className="list-group-item maroon-border-left rounded-0 list-group-link"
              style={{
                alignItems: "center",
                display: "flex",
              }}
            >
              {/* <i className="bx bx-play-circle bx-lg"></i> */}
              <div onClick={handleDownload}>
                {listing?.description && listing.fileName}
              </div>
              <span className="ml-6" style={{ marginLeft: 30 }}>
                {listing.data ? (
                  // <div>
                  //   <audio
                  //     controls
                  //     // src={listing.data ? listing.data : ""}
                  //     src={listing.data}
                  //     title={listing.fileName}
                  //     // style={{ width: 500 }}
                  //   ></audio>
                  //   <a href="hi" download={listing.data}>
                  //     DownloadButton
                  //   </a>
                  // </div>
                  <div>
                    <audio controls src={listing.data} />
                    {/* <button onClick={handleDownload}>Download</button> */}
                    {/* <label>
                      Playback Speed:
                      <select onChange={handleSpeedChange}>
                        <option value="0.5">0.5x</option>
                        <option value="1">1x</option>
                        <option value="1.5">1.5x</option>
                        <option value="2">2x</option>
                      </select>
                    </label> */}
                  </div>
                ) : (
                  <div
                    className="d-flex justify-content-around"
                    style={{
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <span>{listing.message}</span>
                    {listing.isLoading && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginLeft: 5 }}
                      ></span>
                    )}
                    {/* {listing.downloadPercent} */}
                  </div>
                )}
              </span>
            </span>
          </div>
        </div>
      </Collapse>
    </div>
  );
};
export default PodcastListing;
