import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { Link, useNavigate } from "react-router-dom";
// import {
//   resetAccountDeletionStatus,
//   setUserType,
// } from "../../features/authSlice";
import "../../styles/authStyles.scss";

import company_logo from "../../../app/assets/csc_logo_transparent.png";
import {
  //   alertAccountDeletionRequest,
  alertAccountDeletionRequestNonAuthenticated,
  //   loginAgent,
} from "../../features/authActions";
// import { Button, Modal } from "react-bootstrap";
import authFormValidator from "../../utils/authFormValidator";
import useMounted from "../../../app/utils/hooks/useMounted";
import FormInput from "../../components/utility/FormInput";
const PASSWORD_MIN_LENGTH = 1;
// const PASSWORD_MAX_LENGTH = 64;
const initalFormValidityState = {
  email: {
    valid: true,
    message: null,
    touched: false,
    required: true,
  },
  password: {
    valid: false,
    messages: null,
    touched: false,
    required: true,
  },
};

const passwordValidatorSingle = (password1: string) => {
  let passwordErrors: string[] = [];

  if (
    password1.length < PASSWORD_MIN_LENGTH
    //   ||
    // password1.length > PASSWORD_MAX_LENGTH
  ) {
    passwordErrors.push(`Please enter a password.`);
  }

  if (passwordErrors.length === 0) {
    // If no errors then valid
    return {
      valid: true,
      messages: [],
    };
  }

  // Else, invalid
  return {
    valid: false,
    messages: passwordErrors,
  };
};

function DeleteAccount() {
  const dispatch = useAppDispatch();
  const isMounted = useMounted();
  const navigate = useNavigate();

  const [formValidity, setFormValidity] = useState<any>(
    initalFormValidityState,
  );
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setFormValidity(initalFormValidityState);
  }, [navigate, dispatch]);

  useEffect(() => {
    setDisabled(
      !Object.values({
        ...formValidity,
      }).every((entry: any) => entry.valid),
    );
  }, [formValidity]);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (isMounted) {
      setFormValidity((prevState) => ({
        ...prevState,

        ...prevState,
        email: {
          ...prevState.email,
          ...authFormValidator.emailValidator(formData.email),
        },
        password: {
          ...prevState.password,
          ...passwordValidatorSingle(formData.password),
        },
      }));
    }
  }, [formData, isMounted]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setFormValidity((prevState) => ({
      ...prevState,
      [e.target.name]: {
        ...prevState[e.target.name],
        touched: true,
      },
    }));
  };

  const {
    isError: isAcountDeletionError,
    message: accountDeletionMessage,
    isLoading: accountDeletionIsLoading,
    isSuccess: isAccountDeletionSuccess,
  } = useAppSelector((state) => state.auth.accountDeletion);

  //   const [showModal, setShowModal] = useState(false);

  //   const handleCloseModal = () => setShowModal(false);
  //   const handleShowModal = () => setShowModal(true);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (
      Object.values({
        ...formValidity,
      }).every((entry: any) => entry.valid)
    ) {
      const form = {
        email: formData.email,
        password: formData.password,
      };
      await dispatch(alertAccountDeletionRequestNonAuthenticated(form));
    }
  };

  return (
    <div className="card mx-auto auth-card">
      <div className="card-body">
        <div className="w-100 text-center mb-2">
          <img
            src={company_logo}
            alt="CSC AG Logo"
            className="mt-2 mb-4 mx-auto company-logo"
          />
        </div>
        <div className="text-center">
          <h5 className="card-title mb-4 px-3">Delete Account</h5>
        </div>

        <div className="mt-2">
          <form onSubmit={onSubmit}>
            <div className="form-group text-left small">
              <p className="text-center">
                <small>
                  You are initiating a request to delete your Ag Hedge Desk
                  Account. Proceeding with this request will delete your account
                  and all associated data within 5 business days. You will no
                  longer be able to access the Ag Hedge Desk platform after your
                  account deletion request has been procesed. If you wish to
                  continue, enter your Ag Hedge Desk login credentials and click
                  "Delete Account".
                </small>
              </p>

              <FormInput
                label={"Email Address"}
                type={"email"}
                onChange={onChange}
                name={"email"}
                value={formData.email}
                touched={formValidity.email.touched}
                message={formValidity.email.message}
                required={false}
                placeholder={"someone@example.com"}
                id={"email"}
                //   onBlur={() =>
                //     setTouched((prevState) => ({ ...prevState, email: true }))
                //   }
              />

              <FormInput
                label={"Password"}
                type={"password"}
                onChange={onChange}
                name={"password"}
                value={formData.password}
                touched={formValidity.password.touched}
                message={
                  formValidity?.password?.messages &&
                  formValidity?.password?.messages[0]
                }
                required={false}
                id={"password"}
                placeholder={"password"}
                autoComplete="off"
                //   onBlur={() =>
                //     setTouched((prevState) => ({ ...prevState, password: true }))
                //   }
              />
            </div>
            <div className="text-center mt-2">
              <button
                type="submit"
                disabled={disabled || accountDeletionIsLoading}
                className="btn btn-secondary w-75"
              >
                {accountDeletionIsLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span> Loading... </span>
                  </>
                ) : (
                  <>Continue</>
                )}
              </button>
            </div>
          </form>

          {(isAcountDeletionError || isAccountDeletionSuccess) && (
            <div className="text-center mt-4">
              {isAcountDeletionError ? (
                <p className="text-danger">{accountDeletionMessage}</p>
              ) : (
                <p className="text-success">{accountDeletionMessage}</p>
              )}
            </div>
          )}
        </div>

        <div className="mt-4 w-100 small text-center">
          <Link style={{ textDecoration: "none" }} to="/login">
            <small>{"<< Back to Login"}</small>
          </Link>
        </div>
      </div>
    </div>

    // <div>
    //   <h4 className="mt-3">Account Settings:</h4>

    //   <div className="mt-3">
    //     <table>
    //       <thead></thead>
    //       <tbody>
    //         <tr className="d-flex align-items-center">
    //           <td
    //             style={{
    //               fontWeight: "bold",
    //               textAlign: "right",
    //               verticalAlign: "top",
    //             }}
    //           >
    //             Delete Account:
    //           </td>
    //           <td className="ps-4 ">
    //             <Button
    //               variant="danger"
    //               onClick={() => {
    //                 // setShowDeleteAccountConfirmationModal(
    //                 //   (prevState) => !prevState,
    //                 // )
    //                 dispatch(resetAccountDeletionStatus());
    //                 handleShowModal();
    //               }}
    //             >
    //               Delete Account
    //             </Button>
    //           </td>
    //         </tr>
    //       </tbody>
    //     </table>
    //   </div>
    //   <br></br>
    //   <Modal show={showModal} onHide={handleCloseModal} size="lg">
    //     <Modal.Header closeButton>
    //       <Modal.Title>Delete Account</Modal.Title>
    //     </Modal.Header>
    //     <Modal.Body>
    //       {isAcountDeletionError || isAccountDeletionSuccess ? (
    //         accountDeletionMessage
    //       ) : (
    //         <>
    //           <p>
    //             You are initiating a request to delete your Ag Hedge Desk{" "}
    //             {userType === "AGENT" ? "Portal" : "Dashboard"} Account.
    //             Proceeding with this request will delete your account and all
    //             associated data within 5 business days. You will no longer be
    //             able to access the Ag Hedge Desk{" "}
    //             {userType === "AGENT" ? "Portal" : "Dashboard"} after your
    //             account deletion request has been procesed. Do you wish to
    //             continue?
    //           </p>
    //           <div className="d-flex justify-content-center">
    //             {accountDeletionIsLoading && (
    //               <div>
    //                 <span
    //                   className="spinner-border spinner-border-sm"
    //                   role="status"
    //                   aria-hidden="true"
    //                 ></span>
    //                 <span> Loading... </span>
    //               </div>
    //             )}
    //           </div>
    //         </>
    //       )}
    //     </Modal.Body>
    //     <Modal.Footer>
    //       <Button variant="secondary" onClick={handleCloseModal}>
    //         {isAccountDeletionSuccess ? "Close" : "Cancel"}
    //       </Button>
    //       {!isAccountDeletionSuccess && (
    //         <Button
    //           disabled={accountDeletionIsLoading}
    //           variant="danger"
    //           onClick={() => {
    //             submitDeleteAccount();
    //           }}
    //         >
    //           Delete Account
    //         </Button>
    //       )}
    //     </Modal.Footer>
    //   </Modal>
    // </div>
  );
}

export default DeleteAccount;
