// Hook to check if component is mounted for the first time.
// Mostly useful for skipping useEffect calls on inital renders.
import { useEffect, useState } from "react";

export default function useMounted() {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted;
}
