import { sendAdminRequest } from "../../app/utils/sendRequest";

const getAuthorizedAgentsList = async () => {
  const apifunc = "admin/list-authorized-agents";
  const args = {};

  return sendAdminRequest(apifunc, args);
};

const authorizeAgent = async (email: string) => {
  const apifunc = "admin/authorize-agent-email";
  const args = {
    email_to_authorize: email,
  };

  return sendAdminRequest(apifunc, args);
};

const sendGetSupportedEmailQuoteProjectsRequest = async () => {
  const apifunc = "admin/get-supported-email-quotes";
  const args = {};

  return sendAdminRequest(apifunc, args);
};

const sendGetSubscriptionsForProject = async (project: string) => {
  const apifunc = "admin/get-project-subscription-data";
  const args = {
    project: project,
  };

  return sendAdminRequest(apifunc, args);
};

const sendSubmitSubscriptionUpdateRequest = async (data: Object) => {
  const apifunc = "admin/update-project-subscription-data";

  console.log(data)
  const args = {
    project: data['project'],
    subscription_data: data['subscription_data']
  };
  

  return sendAdminRequest(apifunc, args);
};


const hedgeMAPsService = {
  getAuthorizedAgentsList,
  authorizeAgent,
  sendGetSupportedEmailQuoteProjectsRequest,
  sendGetSubscriptionsForProject,
  sendSubmitSubscriptionUpdateRequest
};

export default hedgeMAPsService;
