// Hook to check if route changes and if so get permissions again.
// Mostly useful for skipping useEffect calls on inital renders.
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../../redux/hooks";
import { getPermissions } from "../../../Authentication/features/authActions";

export default function useCheckPermsOnLocationChange() {
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // When a new routed is routed check their perms again
    dispatch(getPermissions());
  }, [location, dispatch]);
}
