import { useEffect, useRef } from "react";

//A solutuion to the problem of state not updating in a setInterval callback function correctly.
export default function useInterval(callback, dependencies) {
  const lastCallback = useRef<any>();

  // Must save the callback to a ref so that it can be accessed between the useInterval calls.
  useEffect(() => {
    lastCallback.current = callback;
  }, [callback]);

  // When we pass a new set of dependencies, we want to clear the old interval and start a new one.
  useEffect(() => {
    function increment() {
      lastCallback.current();
    }

    let interval = setInterval(increment, 150);

    //clear it when component unmounts
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies]);
}
