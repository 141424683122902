const makeTitle = (word: string) => {
  const word_array = word.toLowerCase().split("_");
  for (let i = 0; i < word_array.length; i += 1) {
    word_array[i] =
      word_array[i].charAt(0).toUpperCase() + word_array[i].slice(1);
  }

  if (word_array.length > 1) {
    return word_array.join(" ");
  }
  return word_array[0];
};

export { makeTitle };
