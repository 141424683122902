export const branding_src = "localhost";
// export const branding_src = 'aghedgedesk.com';
export const branding_default_state = "Minnesota";

export const branding_logo_path = "app/assets/csc_logo_transparent.png";
export const branding_logo_width = "150";
export const logo_margin_left = "0.25in";
export const logo_margin_top = "0.25in";

export const display_quarter_months = false; // display quarter month labels in DecisionTool

export const branding_states_hash_reversed = {
  Alabama: "AL",
  Alaska: "AK",
  // 'American Samoa': 'AS',
  Arizona: "AZ",
  Arkansas: "AR",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  Delaware: "DE",
  // 'District Of Columbia': 'DC',
  // 'Federated States Of Micronesia': 'FM',
  Florida: "FL",
  Georgia: "GA",
  // 'Guam': 'GU',
  Hawaii: "HI",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Iowa: "IA",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Maine: "ME",
  // 'Marshall Islands': 'MH',
  Maryland: "MD",
  Massachusetts: "MA",
  Michigan: "MI",
  Minnesota: "MN",
  Mississippi: "MS",
  Missouri: "MO",
  Montana: "MT",
  Nebraska: "NE",
  Nevada: "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  // 'Northern Mariana Islands': 'MP',
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  // 'Palau': 'PW',
  Pennsylvania: "PA",
  // 'Puerto Rico': 'PR',
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Vermont: "VT",
  // 'Virgin Islands': 'VI',
  Virginia: "VA",
  Washington: "WA",
  "West Virginia": "WV",
  Wisconsin: "WI",
  Wyoming: "WY",
};

export const branding_states_hash = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  // 'AS': 'American Samoa',
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  // 'DC': 'District Of Columbia',
  DE: "Delaware",
  FL: "Florida",
  // 'FM': 'Federated States Of Micronesia',
  GA: "Georgia",
  // 'GU': 'Guam',
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  // 'MH': 'Marshall Islands',
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  // 'MP': 'Northern Mariana Islands',
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  // 'PR': 'Puerto Rico',
  // 'PW': 'Palau',
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  // 'VI': 'Virgin Islands',
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};
