import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/redux/hooks";
import {
  getMostRecentDateSecure,
  getDRPQuoteSecure,
  downloadQuoteResultAsPDF,
} from "../../../features/DRPActions";
import { setCoverageLevel } from "../../../features/DRPSlice";
import "./ResultTableSingle.scss";
import {
  DRPQuoteParams,
  StateUSA,
  SalesEffectiveDate,
} from "@agdeveloper/domain";
import CoverageSelectionDropdown from "../CoverageSelectionDropdown/CoverageSelectionDropdown";
import { _getValidPriceValue } from "../utils/ResultTableUtilityFunctions";

const coverage_levels = [
  0.95, 0.9, 0.85, 0.8,
  // 0.75,
  // 0.70,
];

// const deployfor = globals.deployfor;
// let src = globals.src;
// let serverip = globals.serverip;

// switch (deployfor) {
//   case "localrelay":
//     serverip = "127.0.0.1";
//     // src = 'debug';
//     break;
//   case "localweb":
//     serverip = "localhost";
//     src = "debug";
//     break;
//   case "local":
//     serverip = "127.0.0.1";
//     src = "debug";
//     break;
//   case "azuma":
//     serverip = "10.1.1.44";
//     src = "local";
//     break;
//   case "web":
//     serverip = "aghedgedesk.com";
//     break;
//   case "webrelay":
//     serverip = "aghedgedesk.com";
//     break;
//   case "webrelay-52":
//     serverip = "aghedgedesk.com";
//     break;
//   case "webrelay-51":
//     serverip = "aghedgedesk.com";
//     break;

//   default:
//     serverip = "aghedgedesk.com";
//     break;
// }

const toUSFormat = (val: Number) => {
  return val.toLocaleString("en-US");
};

const toUSCurrency = (val: Number) => {
  return val.toLocaleString("en-US", { style: "currency", currency: "USD" });
};

function ResultTableSingle({ flag, printResult }) {
  const dispatch = useAppDispatch();
  const {
    salesEffectiveDate, // sales_effective_date
    coverageType, // pricing_type
    currentState, // state_name
    selectedQuarter, // quarter
    quarterProduction, // declared_milk_production
    classWeighting, // class_based_weighting_factor
    componentWeighting, // declared_protein_test, declared_butterfat_test, component_price_weighting_factor
    protectionFactor, // protection_factor
    drpQuoteResults,
    drpInputsChanged,

    quoteDownload,
  } = useAppSelector((state) => state.drp);

  const { estimatedIndemnity, estimatedEndorsementValue } = useAppSelector(
    (state) => state.drp.single,
  );

  const [selectedCoverage, setSelectedCoverage] =
    useState<string>("95% coverage");

  const handleSubmit = (level: number) => {
    setSelectedCoverage(`${level * 100}% coverage`);
    dispatch(setCoverageLevel(level));

    // const _data_to_send = {
    //   sales_effective_date: salesEffectiveDate.replace(/\D/g, ""),
    //   coverage_level_percent: level,
    //   pricing_type: coverageType,
    //   state_name: currentState,
    //   quarter: selectedQuarter,
    //   declared_milk_production: parseInt(quarterProduction.replace(/\D/g, "")),
    //   class_price_weighting_factor: classWeighting.values.class3 / 100,
    //   declared_protein_test: Number(componentWeighting.values.protein),
    //   declared_butterfat_test: Number(componentWeighting.values.butterfat),
    //   declared_share: 1.0,
    //   component_price_weighting_factor: Number(
    //     componentWeighting.values.cmp_price_wf
    //   ),
    //   protection_factor: Number(protectionFactor.value),
    //   beginning_farmer: 0,
    //   srcip: serverip,
    //   src: src,
    // };
    const drp_quote_params = new DRPQuoteParams(
      selectedQuarter, //qtr
      new StateUSA(currentState), //StateUSA
      coverageType, //quote_type we dont care fo class vs component
      parseInt(quarterProduction.replace(/\D/g, "")), //production
      classWeighting.values.class3 / 100, //class price weighting factor
      Number(componentWeighting.values.cmp_price_wf), //component price weighting factor
      Number(componentWeighting.values.butterfat), //butterfat
      Number(componentWeighting.values.protein), //protein
      Number(protectionFactor.value), //protection factor
      level, //coverage level
      1.0, //share
      0, //beginning farmer
      new SalesEffectiveDate(salesEffectiveDate.replace(/\D/g, "")), //sales effective date
    );
    // TODO: Add error handling for sending DRP Quote request
    dispatch(getDRPQuoteSecure(drp_quote_params));
  };

  const getMarketConditions = () => {
    const market_conditions = {};
    market_conditions["ProjectedClass3Price"] = _getValidPriceValue(
      quoteDownload.projectedPrices.projectedClass3Price,
    );
    market_conditions["ProjectedClass4Price"] = _getValidPriceValue(
      quoteDownload.projectedPrices.projectedClass4Price,
    );
    market_conditions["ProjectedButterPrice"] = _getValidPriceValue(
      quoteDownload.projectedPrices.projectedButterPrice,
    );
    market_conditions["ProjectedProteinPrice"] = _getValidPriceValue(
      quoteDownload.projectedPrices.projectedProteinPrice,
    );
    market_conditions["ProjectedNonfatSolidsPrice"] = _getValidPriceValue(
      quoteDownload.projectedPrices.projectedNonfatSolidsPrice,
    );
    market_conditions["ProjectedOtherSolidsPrice"] = _getValidPriceValue(
      quoteDownload.projectedPrices.projectedOthersolidsPrice,
    );
    market_conditions["YieldAdjust"] = _getValidPriceValue(
      quoteDownload.projectedPrices.yieldAdjustFactor,
    );
    return market_conditions;
  };

  const downloadQuote = async () => {
    const quoteres = {
      DeclaredCoveredMilkProduction:
        drpQuoteResults.data.quote_params.declared_covered_milk_production,
      CoverageLevelPercent:
        drpQuoteResults.data.quote_params.coverage_level_percent.value,
      ProtectionFactor: drpQuoteResults.data.quote_params.protection_factor,
      ProducerPremiumAmount: drpQuoteResults.data.producer_premium_amount,
      Indemnity: estimatedIndemnity,
      EndorsementValue: estimatedEndorsementValue,
      ExpectedRevenueAmount: drpQuoteResults.data.expected_revenue_amount,
      CoverageType:
        drpQuoteResults.data.quote_params.quote_type.value === "CLPROP"
          ? "CLASS"
          : "COMPONENT",
      ClassPriceWeightingFactor:
        drpQuoteResults.data.quote_params.declared_class_price_weighting_factor,
      ComponentPriceWeightingFactor:
        drpQuoteResults.data.quote_params
          .declared_component_price_weighting_factor,
      DeclaredButterfatTest:
        drpQuoteResults.data.quote_params.declared_butterfat_test,
      DeclaredProteinTest:
        drpQuoteResults.data.quote_params.declared_protein_test,
      Quarter: drpQuoteResults.data.quote_params.quarter,
      StateName: drpQuoteResults.data.quote_params.state_name.name,
      SalesEffectiveDate:
        drpQuoteResults.data.quote_params.sales_effective_date.value.toUTCString(),
    };
    const market_conditions = getMarketConditions();
    const quote_type =
      drpQuoteResults.data["quote_params"]["quote_type"]["value"];
    const download_data = {
      quoteres: { ...quoteres },
      market_conditions: { ...market_conditions },
      coverage_type: quote_type,
      tool_in_use: "single",
    };
    dispatch(downloadQuoteResultAsPDF(download_data));
  };
  useEffect(() => {
    dispatch(getMostRecentDateSecure(""));
  }, [dispatch]);

  return (
    <div className="card-body">
      <div className="side-by-side">
        <p className="card-text"></p>
        <div className="hidden">
          <span>
            <i className="bx bx-printer"></i>
          </span>
          <span
            className="fake-a-tag d-print-none"
            style={{ verticalAlign: "top" }}
          >
            Print This Quote
          </span>
        </div>
      </div>
      <div className="side-by-side">
        <p className="card-title h5">Quote Results</p>
        <div
          className={`side-by-side mb-1 ${
            !drpQuoteResults.data || drpQuoteResults.loading ? "hidden" : ""
          }`}
        >
          <span className="me-1">
            <i className="bx bx-sm bxs-printer"></i>
          </span>
          <span className="fake-a-tag d-print-none" onClick={printResult}>
            Print This Quote
          </span>
        </div>
      </div>
      <div className="side-by-side">
        <p></p>
        <div
          className={`side-by-side mb-1 ${
            !drpQuoteResults.data || drpQuoteResults.loading ? "hidden" : ""
          }`}
        >
          <span className="me-1">
            {quoteDownload.loading && <i className="bx bx-sm bx-refresh"></i>}
            {!quoteDownload.loading && quoteDownload.error && (
              <i className="bx bx-sm  bxs-error-alt"></i>
            )}

            {!quoteDownload.loading && !quoteDownload.error && (
              <i className="bx bx-sm bxs-cloud-download"></i>
            )}
          </span>
          <span className="fake-a-tag d-print-none" onClick={downloadQuote}>
            Download Quote as PDF
          </span>
        </div>
      </div>
      <div
        className="pb-1 d-flex flex-row justify-content-between"
        style={{ minHeight: "50px" }}
      >
        <CoverageSelectionDropdown
          drpInputsChanged={drpInputsChanged}
          drpQuoteResults={drpQuoteResults}
          salesEffectiveDate={salesEffectiveDate}
          selectedCoverage={selectedCoverage}
          coverage_levels={coverage_levels}
          handleSubmit={handleSubmit}
        />
        <div>
          {/* TODO: format date */}
          <span>
            Sales effective date:{" "}
            <span style={{ fontWeight: "bold" }}>{salesEffectiveDate}</span>
          </span>
        </div>
      </div>
      <div>
        <hr />
        {drpQuoteResults.data && !drpQuoteResults.loading ? (
          <div className="table-responsive">
            <table className="table table-striped mb-4">
              <thead>
                <tr>
                  <th scope="col"></th>
                  {drpQuoteResults.data["quote_params"]["quote_type"][
                    "value"
                  ] === "CLPROP" ? (
                    <th scope="col">CLASS</th>
                  ) : (
                    <th scope="col">COMPONENT</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {/* TODO: Format values */}
                <tr>
                  <th scope="row" style={{ width: "50%" }}>
                    Quarter:
                  </th>
                  <td>
                    <label>
                      {drpQuoteResults.data["quote_params"]["quarter"]}
                    </label>
                  </td>
                </tr>
                <tr>
                  <th scope="row" style={{ width: "50%" }}>
                    State:
                  </th>
                  <td>
                    <label>
                      {
                        drpQuoteResults.data["quote_params"]["state_name"][
                          "name"
                        ]
                      }
                    </label>
                  </td>
                </tr>
                <tr>
                  <th scope="row" style={{ width: "50%" }}>
                    Declared Milk Production:
                  </th>
                  <td>
                    <label>
                      {toUSFormat(
                        drpQuoteResults.data["quote_params"][
                          "declared_covered_milk_production"
                        ],
                      )}{" "}
                      lbs./qtr
                    </label>
                  </td>
                </tr>
                <tr>
                  <th scope="row" style={{ width: "50%" }}>
                    Coverage Type:
                  </th>
                  <td>
                    <label>
                      {drpQuoteResults.data["quote_params"][
                        "coverage_level_percent"
                      ]["value"] * 100}
                      % <span> / </span>
                      {drpQuoteResults.data["quote_params"]["quote_type"][
                        "value"
                      ] === "CLPROP"
                        ? "CLASS"
                        : "COMPONENT"}
                    </label>
                  </td>
                </tr>
                <tr>
                  <th scope="row" style={{ width: "50%" }}>
                    Protection Factor:
                  </th>
                  <td>
                    <label>
                      {drpQuoteResults.data["quote_params"][
                        "protection_factor"
                      ].toFixed(2)}
                      x
                    </label>
                  </td>
                </tr>
                {drpQuoteResults.data["quote_params"]["quote_type"]["value"] ===
                "CLPROP" ? (
                  <tr>
                    <th scope="row" style={{ width: "50%" }}>
                      Class III Weighting:
                    </th>
                    <td>
                      <label>
                        {
                          <>
                            <div className="mb-1">
                              <span>
                                <span style={{ fontWeight: "bold" }}>
                                  {(
                                    drpQuoteResults.data["quote_params"][
                                      "declared_class_price_weighting_factor"
                                    ] * 100
                                  ).toFixed()}
                                  %
                                </span>{" "}
                                Class III
                              </span>
                              <span> / </span>
                              <span>
                                <span style={{ fontWeight: "bold" }}>
                                  {Math.ceil(
                                    (1 -
                                      drpQuoteResults.data["quote_params"][
                                        "declared_class_price_weighting_factor"
                                      ]) *
                                      100,
                                  ).toFixed()}
                                  %
                                </span>{" "}
                                Class IV
                              </span>
                            </div>
                            <div>
                              <small>
                                (Class Price Weighting Factor:
                                <span style={{ fontWeight: "bold" }}>
                                  {" "}
                                  {drpQuoteResults.data["quote_params"][
                                    "declared_class_price_weighting_factor"
                                  ].toFixed(2)}
                                </span>
                                )
                              </small>
                            </div>
                          </>
                        }
                      </label>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <th scope="row" style={{ width: "50%" }}>
                      Component Weighting:
                    </th>
                    <td>
                      <label>
                        {
                          <>
                            <div className="mb-1">
                              <span>
                                <span style={{ fontWeight: "bold" }}>
                                  {(
                                    drpQuoteResults.data["quote_params"][
                                      "declared_component_price_weighting_factor"
                                    ] * 100
                                  ).toFixed()}
                                  %
                                </span>{" "}
                                Class III
                              </span>
                              <span> / </span>
                              <span>
                                <span style={{ fontWeight: "bold" }}>
                                  {Math.ceil(
                                    (1 -
                                      drpQuoteResults.data["quote_params"][
                                        "declared_component_price_weighting_factor"
                                      ]) *
                                      100,
                                  ).toFixed()}
                                  %
                                </span>{" "}
                                Class IV
                              </span>
                            </div>
                            <div>
                              <small>
                                (Component Price Weighting Factor:
                                <span style={{ fontWeight: "bold" }}>
                                  {" "}
                                  {Number.parseFloat(
                                    drpQuoteResults.data["quote_params"][
                                      "declared_component_price_weighting_factor"
                                    ],
                                  ).toFixed(2)}
                                </span>
                                )
                              </small>
                            </div>
                          </>
                        }
                      </label>
                    </td>
                  </tr>
                )}
                <tr>
                  <th scope="row" style={{ width: "50%" }}>
                    Index Trigger Price:
                  </th>
                  <td>
                    <label
                      style={
                        drpQuoteResults.data["quote_params"]["quote_type"][
                          "value"
                        ] === "CLPROP"
                          ? { color: "#1f67bd", fontWeight: "bold" }
                          : { color: "#9a0015", fontWeight: "bold" }
                      }
                    >
                      $
                      {(
                        (drpQuoteResults.data["expected_revenue_amount"] *
                          parseFloat(
                            drpQuoteResults.data["quote_params"][
                              "coverage_level_percent"
                            ]["value"],
                          )) /
                        (drpQuoteResults.data["quote_params"][
                          "declared_covered_milk_production"
                        ] /
                          100.0)
                      ).toFixed(2)}
                    </label>
                  </td>
                </tr>
                <tr>
                  <th scope="row" style={{ width: "50%" }}>
                    Producer Premium:
                  </th>
                  <td>
                    <label>
                      <span>
                        {toUSCurrency(
                          drpQuoteResults.data["producer_premium_amount"],
                        )}
                      </span>
                      <span className="ms-2">
                        ($
                        {(
                          drpQuoteResults.data["producer_premium_amount"] /
                          (drpQuoteResults.data["quote_params"][
                            "declared_covered_milk_production"
                          ] /
                            100.0)
                        ).toFixed(2)}
                        /cwt.)
                      </span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <th scope="row" style={{ width: "50%" }}>
                    Estimated Indemnity:
                  </th>
                  <td>
                    <label>
                      <span>{`$${estimatedIndemnity.toLocaleString()}.00`}</span>
                      <span className="ms-2">{`($${(
                        estimatedIndemnity /
                        (drpQuoteResults.data["quote_params"][
                          "declared_covered_milk_production"
                        ] /
                          100.0)
                      ).toFixed(2)}/cwt.) `}</span>
                    </label>
                  </td>
                </tr>

                <tr>
                  <th scope="row" style={{ width: "50%" }}>
                    Estimated Endorsement Value:
                    <br />
                    <span className="small fw-normal">
                      (Indemnity - Producer Premium)
                    </span>
                  </th>
                  <td>
                    <label
                      className={`${
                        // Set color based on if val is +ve/-ve
                        estimatedEndorsementValue > 0
                          ? "positive_val"
                          : "negative_val"
                      }`}
                    >
                      {estimatedEndorsementValue > 0 ? (
                        <span>
                          {`$${Math.abs(
                            estimatedEndorsementValue,
                          ).toLocaleString()}.00`}
                        </span>
                      ) : (
                        // Add paranthesis if val is -ve
                        <span>
                          {`$(${Math.abs(
                            estimatedEndorsementValue,
                          ).toLocaleString()}.00)`}
                        </span>
                      )}
                      <span className="ms-2">{`($${Math.abs(
                        estimatedEndorsementValue /
                          (drpQuoteResults.data["quote_params"][
                            "declared_covered_milk_production"
                          ] /
                            100.0),
                      ).toFixed(2)}/cwt.)`}</span>
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>

            {/* Disclaimer */}
            <div>
              <p
                style={{
                  fontStyle: "italic",
                  fontWeight: "normal",
                  fontSize: "0.93em",
                }}
              >
                ***NOTICE: The premium listed above is an estimate and is
                provided for reference only. Please contact your insurance agent
                for an official premium quote.
              </p>
            </div>
          </div>
        ) : drpQuoteResults.loading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : drpQuoteResults.error ? (
          <section
            id="error-message"
            className="my-4 alert alert-danger d-flex align-items-center"
          >
            {/* <use xlinkHref="#exclamation-triangle-fill" /> */}
            <i
              className="bx bxs-error mx-2"
              style={{ color: "#842029", fontSize: "1.5rem" }}
            ></i>
            <span className="fw-bold" style={{ color: "rgb(142, 39, 54)" }}>
              {drpQuoteResults.message}
            </span>
          </section>
        ) : (
          <b>No results to display.</b>
        )}
      </div>
    </div>
  );
}

export default ResultTableSingle;
