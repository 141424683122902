import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/redux/hooks";
import {
  getMostRecentDateSecure,
  getStripDRPQuoteSecure,
  downloadQuoteResultAsPDF,
} from "../../../features/DRPActions";
import { setCoverageLevel } from "../../../features/DRPSlice";
import "./ResultTableStrip.scss";
import {
  DRPQuoteParams,
  StateUSA,
  SalesEffectiveDate,
  DRPStripQuoteParams,
} from "@agdeveloper/domain";
import CoverageSelectionDropdown from "../CoverageSelectionDropdown/CoverageSelectionDropdown";
const coverage_levels = [
  0.95, 0.9, 0.85, 0.8,
  // 0.75,
  // 0.70,
];

const toUSFormat = (val: Number) => {
  return val.toLocaleString("en-US");
};

const toUSCurrency = (val: Number) => {
  return val.toLocaleString("en-US", { style: "currency", currency: "USD" });
};

function ResultTableStrip({ flag, printResult }) {
  const dispatch = useAppDispatch();
  const {
    salesEffectiveDate, // sales_effective_date
    coverageType, // pricing_type
    currentState, // state_name
    quarterProduction, // declared_milk_production
    classWeighting, // class_based_weighting_factor
    componentWeighting, // declared_protein_test, declared_butterfat_test, component_price_weighting_factor
    protectionFactor, // protection_factor
    drpQuoteResults,
    drpInputsChanged,
    quarterLabels,
    quoteDownload,
  } = useAppSelector((state) => state.drp);
  const { estimatedIndemnity, estimatedEndorsementValue } = useAppSelector(
    (state) => state.drp.single,
  );

  const [selectedCoverage, setSelectedCoverage] =
    useState<string>("95% coverage");

  const handleSubmit = (level: number) => {
    setSelectedCoverage(`${level * 100}% coverage`);
    dispatch(setCoverageLevel(level));

    const drp_quote_params_array = quarterLabels.map((label, index) => {
      return new DRPQuoteParams(
        label, //qtr
        new StateUSA(currentState), //StateUSA
        coverageType, //quote_type we dont care fo class vs component
        parseInt(quarterProduction.replace(/\D/g, "")), //production
        classWeighting.values.class3 / 100, //class price weighting factor
        Number(componentWeighting.values.cmp_price_wf), //component price weighting factor
        Number(componentWeighting.values.butterfat), //butterfat
        Number(componentWeighting.values.protein), //protein
        Number(protectionFactor.value), //protection factor
        level, //coverage level
        1.0, //share
        0, //beginning farmer
        new SalesEffectiveDate(salesEffectiveDate.replace(/\D/g, "")), //sales effective date
      );
    });
    let strip_quote_params = new DRPStripQuoteParams(drp_quote_params_array);

    // TODO: Add error handling for sending DRP Quote request
    dispatch(getStripDRPQuoteSecure(strip_quote_params));
  };

  const getMarketConditions = () => {
    const market_conditions = {};
    market_conditions["ProjectedClass3Price"] =
      quoteDownload.projectedPrices.projectedClass3Price.toFixed(2);
    market_conditions["ProjectedClass4Price"] =
      quoteDownload.projectedPrices.projectedClass4Price.toFixed(2);
    market_conditions["ProjectedButterPrice"] =
      quoteDownload.projectedPrices.projectedButterPrice.toFixed(2);
    market_conditions["ProjectedProteinPrice"] =
      quoteDownload.projectedPrices.projectedProteinPrice.toFixed(2);
    market_conditions["ProjectedNonfatSolidsPrice"] =
      quoteDownload.projectedPrices.projectedNonfatSolidsPrice.toFixed(2);
    market_conditions["ProjectedOtherSolidsPrice"] =
      quoteDownload.projectedPrices.projectedOthersolidsPrice.toFixed(2);
    market_conditions["YieldAdjust"] =
      quoteDownload.projectedPrices.yieldAdjustFactor.toFixed(1);
    return market_conditions;
  };

  const downloadQuote = async () => {
    let quoteres = {};
    for (const [index, qtr] of Object.entries(
      Object.keys(
        drpQuoteResults.data.drp_strip_quote_result.quote_result_dict,
      ),
    )) {
      if (drpQuoteResults.data.drp_strip_quote_result.quote_result_dict[qtr]) {
        quoteres[qtr] = {
          DeclaredCoveredMilkProduction:
            drpQuoteResults.data.drp_strip_quote_result.quote_result_dict[qtr]
              .quote_params.declared_covered_milk_production,
          CoverageLevelPercent:
            drpQuoteResults.data.drp_strip_quote_result.quote_result_dict[qtr]
              .quote_params.coverage_level_percent.value,
          ProtectionFactor:
            drpQuoteResults.data.drp_strip_quote_result.quote_result_dict[qtr]
              .quote_params.protection_factor,
          ProducerPremiumAmount:
            drpQuoteResults.data.drp_strip_quote_result.quote_result_dict[qtr]
              .producer_premium_amount,
          Indemnity: estimatedIndemnity,
          EndorsementValue: estimatedEndorsementValue,
          ExpectedRevenueAmount:
            drpQuoteResults.data.drp_strip_quote_result.quote_result_dict[qtr]
              .expected_revenue_amount,
          CoverageType:
            drpQuoteResults.data.drp_strip_quote_result.quote_result_dict[qtr]
              .quote_params.quote_type.value === "CLPROP"
              ? "CLASS"
              : "COMPONENT",
          ClassPriceWeightingFactor:
            drpQuoteResults.data.drp_strip_quote_result.quote_result_dict[qtr]
              .quote_params.declared_class_price_weighting_factor,
          ComponentPriceWeightingFactor:
            drpQuoteResults.data.drp_strip_quote_result.quote_result_dict[qtr]
              .quote_params.declared_component_price_weighting_factor,
          DeclaredButterfatTest:
            drpQuoteResults.data.drp_strip_quote_result.quote_result_dict[qtr]
              .quote_params.declared_butterfat_test,
          DeclaredProteinTest:
            drpQuoteResults.data.drp_strip_quote_result.quote_result_dict[qtr]
              .quote_params.declared_protein_test,
          Quarter:
            drpQuoteResults.data.drp_strip_quote_result.quote_result_dict[qtr]
              .quote_params.quarter,
          StateName:
            drpQuoteResults.data.drp_strip_quote_result.quote_result_dict[qtr]
              .quote_params.state_name.name,
          SalesEffectiveDate:
            drpQuoteResults.data.drp_strip_quote_result.quote_result_dict[
              qtr
            ].quote_params.sales_effective_date.value.toUTCString(),
          TypeAbbreviation:
            drpQuoteResults.data.drp_strip_quote_result.quote_result_dict[qtr]
              .quote_params.quote_type.value,
        };
      } else {
        quoteres[qtr] = {
          DeclaredCoveredMilkProduction: 0,
          CoverageLevelPercent: 0,
          ProtectionFactor: 0,
          ProducerPremiumAmount: 0,
          Indemnity: "-",
          EndorsementValue: "-",
          ExpectedRevenueAmount: 0,
          CoverageType: "-",
          ClassPriceWeightingFactor: 0,
          ComponentPriceWeightingFactor: "-",
          DeclaredButterfatTest: 0,
          DeclaredProteinTest: 0,
          Quarter: "-",
          StateName: "",
          SalesEffectiveDate:
            drpQuoteResults.data.drp_strip_quote_params.param_list[
              index
            ].sales_effective_date.value.toUTCString(),
          TypeAbbreviation: "",
        };
      }
    }
    const market_conditions = getMarketConditions();
    const quote_type =
      drpQuoteResults.data.drp_strip_quote_params.param_list[0]["quote_type"][
        "value"
      ] === "CLPROP"
        ? "CLASS"
        : "COMPONENT";
    const download_data = {
      quoteres: { ...quoteres },
      market_conditions: { ...market_conditions },
      coverage_type: quote_type,
      tool_in_use: "strip",
    };
    dispatch(downloadQuoteResultAsPDF(download_data));
  };

  useEffect(() => {
    dispatch(getMostRecentDateSecure(""));
  }, [dispatch]);

  //sort by qtr in values
  const _sort = (a: any, b: any) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  };
  return (
    <div className="card-body">
      <div className="side-by-side">
        <p className="card-text"></p>
        <div className="hidden">
          <span>
            <i className="bx bx-printer"></i>
          </span>
          <span
            className="fake-a-tag d-print-none"
            style={{ verticalAlign: "top" }}
          >
            Print This Quote
          </span>
        </div>
      </div>
      <div className="side-by-side">
        <p className="card-title h5">Quote Results</p>
        <div
          className={`side-by-side mb-1 ${
            !drpQuoteResults.data || drpQuoteResults.loading ? "hidden" : ""
          }`}
        >
          <span className="me-1">
            <i className="bx bx-sm bxs-printer"></i>
          </span>
          <span className="fake-a-tag d-print-none" onClick={printResult}>
            Print This Quote
          </span>
        </div>
      </div>
      <div className="side-by-side">
        <p></p>
        <div
          className={`side-by-side mb-1 ${
            !drpQuoteResults.data || drpQuoteResults.loading ? "hidden" : ""
          }`}
        >
          <span className="me-1">
            {quoteDownload.loading && <i className="bx bx-sm bx-refresh"></i>}
            {!quoteDownload.loading && quoteDownload.error && (
              <i className="bx bx-sm  bxs-error-alt"></i>
            )}

            {!quoteDownload.loading && !quoteDownload.error && (
              <i className="bx bx-sm bxs-cloud-download"></i>
            )}
          </span>
          <span className="fake-a-tag d-print-none" onClick={downloadQuote}>
            Download Quote as PDF
          </span>
        </div>
      </div>
      <div
        className="pb-1 d-flex flex-row justify-content-between"
        style={{ minHeight: "50px" }}
      >
        <CoverageSelectionDropdown
          drpInputsChanged={drpInputsChanged}
          drpQuoteResults={drpQuoteResults}
          salesEffectiveDate={salesEffectiveDate}
          selectedCoverage={selectedCoverage}
          coverage_levels={coverage_levels}
          handleSubmit={handleSubmit}
        />
        <div>
          {/* TODO: format date */}
          <span>
            Sales effective date:{" "}
            <span style={{ fontWeight: "bold" }}>{salesEffectiveDate}</span>
          </span>
        </div>
      </div>
      <div>
        <hr />
        {drpQuoteResults.data && !drpQuoteResults.loading ? (
          <div className="table-responsive">
            <table className="table table-striped mb-4">
              <thead>
                <tr>
                  <th scope="col"></th>
                  {Object.keys(
                    drpQuoteResults.data["drp_strip_quote_result"][
                      "quote_result_dict"
                    ],
                  )
                    .sort(_sort)
                    .map((qtr, index) => {
                      return <th scope="col">{qtr}</th>;
                    })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row" style={{ width: "25%" }}>
                    State:
                  </th>
                  {Object.entries(
                    drpQuoteResults.data["drp_strip_quote_result"][
                      "quote_result_dict"
                    ],
                  )
                    .sort(_sort)
                    .map(([quarter, drp_quote_result]) => {
                      return (
                        <td>
                          {drp_quote_result
                            ? drp_quote_result["quote_params"]["state_name"][
                                "name"
                              ]
                            : "-"}
                        </td>
                      );
                    })}
                </tr>
                <tr>
                  <th scope="row" style={{ width: "25%" }}>
                    Declared Milk Production:
                  </th>
                  {Object.entries(
                    drpQuoteResults.data["drp_strip_quote_result"][
                      "quote_result_dict"
                    ],
                  )
                    .sort(_sort)
                    .map(([quarter, drp_quote_result]) => {
                      return (
                        <td>
                          <label>
                            {drp_quote_result
                              ? `${toUSFormat(
                                  drp_quote_result["quote_params"][
                                    "declared_covered_milk_production"
                                  ],
                                )} lbs./qtr`
                              : "-"}
                          </label>
                        </td>
                      );
                    })}
                </tr>
                <tr>
                  <th scope="row" style={{ width: "25%" }}>
                    Coverage Type:
                  </th>

                  {Object.entries(
                    drpQuoteResults.data["drp_strip_quote_result"][
                      "quote_result_dict"
                    ],
                  )
                    .sort(_sort)
                    .map(([quarter, drp_quote_result]) => {
                      return (
                        <td>
                          {drp_quote_result ? (
                            <label>
                              {drp_quote_result["quote_params"][
                                "coverage_level_percent"
                              ]["value"] * 100}
                              % <span> / </span>
                              {drp_quote_result["quote_params"]["quote_type"][
                                "value"
                              ] === "CLPROP"
                                ? "CLASS"
                                : "COMPONENT"}
                            </label>
                          ) : (
                            <label>-</label>
                          )}
                        </td>
                      );
                    })}
                </tr>
                <tr>
                  <th scope="row" style={{ width: "25%" }}>
                    Protection Factor:
                  </th>
                  {Object.entries(
                    drpQuoteResults.data["drp_strip_quote_result"][
                      "quote_result_dict"
                    ],
                  )
                    .sort(_sort)
                    .map(([quarter, drp_quote_result]) => {
                      return (
                        <td>
                          {drp_quote_result ? (
                            <label>
                              {drp_quote_result["quote_params"][
                                "protection_factor"
                              ].toFixed(2)}
                              x
                            </label>
                          ) : (
                            <label>-</label>
                          )}
                        </td>
                      );
                    })}
                </tr>

                {(
                  Object.values(
                    drpQuoteResults.data["drp_strip_quote_params"][
                      "param_list"
                    ],
                  )[0] as DRPQuoteParams
                )["quote_type"]["value"] === "CLPROP" ? (
                  <tr>
                    <th scope="row" style={{ width: "25%" }}>
                      Class III Weighting:
                    </th>

                    {Object.entries(
                      drpQuoteResults.data["drp_strip_quote_result"][
                        "quote_result_dict"
                      ],
                    )
                      .sort(_sort)
                      .map(([quarter, drp_quote_result]) => {
                        return (
                          <td>
                            <label>
                              {drp_quote_result ? (
                                <>
                                  <div className="mb-1">
                                    <span>
                                      <span style={{ fontWeight: "bold" }}>
                                        {drp_quote_result["quote_params"][
                                          "declared_class_price_weighting_factor"
                                        ] * 100}
                                        %
                                      </span>{" "}
                                      Class III
                                    </span>
                                    <span> / </span>
                                    <span>
                                      <span style={{ fontWeight: "bold" }}>
                                        {Math.floor(
                                          (1 -
                                            drp_quote_result["quote_params"][
                                              "declared_class_price_weighting_factor"
                                            ]) *
                                            100,
                                        )}
                                        %
                                      </span>{" "}
                                      Class IV
                                    </span>
                                  </div>
                                  <div>
                                    <small>
                                      (Class Price Weighting Factor:
                                      <span style={{ fontWeight: "bold" }}>
                                        {" "}
                                        {drp_quote_result["quote_params"][
                                          "declared_class_price_weighting_factor"
                                        ].toFixed(2)}
                                      </span>
                                      )
                                    </small>
                                  </div>
                                </>
                              ) : (
                                "-"
                              )}
                            </label>
                          </td>
                        );
                      })}
                  </tr>
                ) : (
                  <tr>
                    <th scope="row" style={{ width: "25%" }}>
                      Component Weighting:
                    </th>

                    {Object.entries(
                      drpQuoteResults.data["drp_strip_quote_result"][
                        "quote_result_dict"
                      ],
                    )
                      .sort(_sort)
                      .map(([quarter, drp_quote_result]) => {
                        return (
                          <td>
                            <label>
                              {drp_quote_result ? (
                                <>
                                  <div className="mb-1">
                                    <span>
                                      <span style={{ fontWeight: "bold" }}>
                                        {drp_quote_result["quote_params"][
                                          "declared_component_price_weighting_factor"
                                        ] * 100}
                                        %
                                      </span>{" "}
                                      Class III
                                    </span>
                                    <span> / </span>
                                    <span>
                                      <span style={{ fontWeight: "bold" }}>
                                        {Math.floor(
                                          (1 -
                                            drp_quote_result["quote_params"][
                                              "declared_component_price_weighting_factor"
                                            ]) *
                                            100,
                                        )}
                                        %
                                      </span>{" "}
                                      Class IV
                                    </span>
                                  </div>
                                  <div>
                                    <small>
                                      (Component Price Weighting Factor:
                                      <span style={{ fontWeight: "bold" }}>
                                        {" "}
                                        {Number.parseFloat(
                                          drp_quote_result["quote_params"][
                                            "declared_component_price_weighting_factor"
                                          ],
                                        ).toFixed(2)}
                                      </span>
                                      )
                                    </small>
                                  </div>
                                </>
                              ) : (
                                "-"
                              )}
                            </label>
                          </td>
                        );
                      })}
                  </tr>
                )}
                <tr>
                  <th scope="row" style={{ width: "25%" }}>
                    Index Trigger Price:
                  </th>

                  {Object.entries(
                    drpQuoteResults.data["drp_strip_quote_result"][
                      "quote_result_dict"
                    ],
                  )
                    .sort(_sort)
                    .map(([quarter, drp_quote_result]) => {
                      return (
                        <td>
                          {drp_quote_result ? (
                            <label
                              style={
                                drp_quote_result["quote_params"]["quote_type"][
                                  "value"
                                ] === "CLPROP"
                                  ? { color: "#1f67bd", fontWeight: "bold" }
                                  : { color: "#9a0015", fontWeight: "bold" }
                              }
                            >
                              $
                              {(
                                (drp_quote_result["expected_revenue_amount"] *
                                  parseFloat(
                                    drp_quote_result["quote_params"][
                                      "coverage_level_percent"
                                    ]["value"],
                                  )) /
                                (drp_quote_result["quote_params"][
                                  "declared_covered_milk_production"
                                ] /
                                  100.0)
                              ).toFixed(2)}
                            </label>
                          ) : (
                            <label>-</label>
                          )}
                        </td>
                      );
                    })}
                </tr>
                <tr>
                  <th scope="row" style={{ width: "25%" }}>
                    Producer Premium:
                  </th>

                  {Object.entries(
                    drpQuoteResults.data["drp_strip_quote_result"][
                      "quote_result_dict"
                    ],
                  )
                    .sort(_sort)
                    .map(([quarter, drp_quote_result]) => {
                      return (
                        <td>
                          {drp_quote_result ? (
                            <label>
                              <span>
                                {toUSCurrency(
                                  drp_quote_result["producer_premium_amount"],
                                )}
                              </span>
                              <span className="ms-2">
                                ($
                                {(
                                  drp_quote_result["producer_premium_amount"] /
                                  (drp_quote_result["quote_params"][
                                    "declared_covered_milk_production"
                                  ] /
                                    100.0)
                                ).toFixed(2)}
                                /cwt.)
                              </span>
                            </label>
                          ) : (
                            <label>-</label>
                          )}
                        </td>
                      );
                    })}
                </tr>
              </tbody>
            </table>

            {/* Disclaimer */}
            <div>
              <p
                style={{
                  fontStyle: "italic",
                  fontWeight: "normal",
                  fontSize: "0.93em",
                }}
              >
                ***NOTICE: The premium listed above is an estimate and is
                provided for reference only. Please contact your insurance agent
                for an official premium quote.
              </p>
            </div>
          </div>
        ) : drpQuoteResults.loading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : drpQuoteResults.error ? (
          <section
            id="error-message"
            className="my-4 alert alert-danger d-flex align-items-center"
          >
            {/* <use xlinkHref="#exclamation-triangle-fill" /> */}
            <i
              className="bx bxs-error mx-2"
              style={{ color: "#842029", fontSize: "1.5rem" }}
            ></i>
            <span className="fw-bold" style={{ color: "rgb(142, 39, 54)" }}>
              {drpQuoteResults.message}
            </span>
          </section>
        ) : (
          <b>No results to display.</b>
        )}
      </div>
    </div>
  );
}

export default ResultTableStrip;
