import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/redux/hooks";
import { setLGMCattleMarketingPlan } from "../../features/LGMSlice";
import { LGMCommodityType, LGMQuoteType } from "@agdeveloper/domain";

const DEFAULT_CATTLE_CORN_MAPPING = {
  [LGMQuoteType.YEARLING_FINISHING]: 50,
  [LGMQuoteType.CALF_FINISHING]: 52,
};

const DEFAULT_CATTLE_FEEDER_CATTLE_MAPPING = {
  [LGMQuoteType.YEARLING_FINISHING]: 7.5,
  [LGMQuoteType.CALF_FINISHING]: 5.5,
};

const DEFAULT_CATTLE_LIVE_CATTLE_MAPPING = {
  [LGMQuoteType.YEARLING_FINISHING]: 12.5,
  [LGMQuoteType.CALF_FINISHING]: 11.5,
};

const LGMCattleMarketingPlan = () => {
  const dispatch = useAppDispatch();

  const { type, cattle_marketing_plan } = useAppSelector(
    (state) => state.lgm.input,
  );
  const { target_months, sales_effective_date } = useAppSelector(
    (state) => state.lgm,
  );

  const { isError, isLoading, expectedGrossMargins } = useAppSelector(
    (state) => state.lgm.expectedGrossMarginsLGM,
  );

  const {
    cattle_corn_target_weight,
    cattle_feeder_cattle_target_weight,
    cattle_live_cattle_target_weight,
  } = useAppSelector((state) => state.lgm.input);

  const [marketingPlan, setMarketingPlan] = useState(cattle_marketing_plan);

  useEffect(() => {
    setMarketingPlan(cattle_marketing_plan);
  }, [cattle_marketing_plan]);

  const updateMarketingPlan = (month, marketed) => {
    setMarketingPlan({
      ...marketingPlan,
      [month]: marketed,
    });
  };

  const calculatePerHeadGrossMargin = (monthIdx) => {
    const liveCattlePrice =
      expectedGrossMargins?.[LGMCommodityType.CATTLE]?.[type?.value]
        ?.live_cattle_prices?.[monthIdx];
    const feederCattlePrice =
      expectedGrossMargins?.[LGMCommodityType.CATTLE]?.[type?.value]
        ?.feeder_cattle_prices?.[monthIdx];
    const cornPrice =
      expectedGrossMargins?.[LGMCommodityType.CATTLE]?.[type?.value]
        ?.corn_prices?.[monthIdx];

    const liveCattleTargetWeight =
      cattle_live_cattle_target_weight ||
      DEFAULT_CATTLE_LIVE_CATTLE_MAPPING[type?.value];
    const feederCattleTargetWeight =
      cattle_feeder_cattle_target_weight ||
      DEFAULT_CATTLE_FEEDER_CATTLE_MAPPING[type?.value];
    const cornTargetWeight =
      cattle_corn_target_weight || DEFAULT_CATTLE_CORN_MAPPING[type?.value];

    const perHeadGrossMargin =
      liveCattleTargetWeight * liveCattlePrice -
      feederCattleTargetWeight * feederCattlePrice -
      cornTargetWeight * cornPrice;

    return perHeadGrossMargin;
  };

  const calculateOldPerHeadGrossMargin = (monthIdx) => {
    const expectedGrossMargin =
      expectedGrossMargins?.[LGMCommodityType.CATTLE]?.[type?.value]?.[
        monthIdx
      ];
    return expectedGrossMargin;
  };

  const isNewLogic =
    new Date(sales_effective_date.value) >= new Date("2024-07-01");

  useEffect(() => {
    dispatch(setLGMCattleMarketingPlan(marketingPlan));
  }, [marketingPlan, dispatch]);

  return (
    <div className="card w-100">
      <div className="card-body">
        <h5 className="card-title">Insured Cattle</h5>
        <p className="card-text">
          Enter the number of cattle insured each month
        </p>
        <div className="table-responsive">
          <table className="table table-fit">
            <thead>
              <tr>
                <th></th>
                {target_months.map((month) => (
                  <th scope="col" key={month}>
                    {month}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "6em" }}>
                  Cattle Marketed:
                </th>
                {target_months.map((month, idx) => (
                  <td key={idx}>
                    <input
                      value={marketingPlan[month]}
                      onChange={(e) =>
                        updateMarketingPlan(month, e.target.value)
                      }
                      type="number"
                      style={{ width: "6em" }}
                    />
                  </td>
                ))}
              </tr>
              {expectedGrossMargins?.[LGMCommodityType.CATTLE]?.[type?.value] &&
                !isError && (
                  <tr>
                    <th>
                      Expected Gross Margins: <br />
                      (Per Head)
                    </th>
                    {isLoading ? (
                      <td style={{ textAlign: "center" }}>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <br />
                        <span>Loading...</span>
                      </td>
                    ) : (
                      target_months.map((month, index) => {
                        const perHeadGrossMargin = isNewLogic
                          ? calculatePerHeadGrossMargin(index)
                          : calculateOldPerHeadGrossMargin(index);
                        const totalGrossMargin =
                          perHeadGrossMargin * marketingPlan[month];
                        return (
                          <td key={index}>
                            <div style={{ width: "6em", textAlign: "left" }}>
                              {marketingPlan[month] > 0
                                ? `$${totalGrossMargin.toFixed(2)}`
                                : "-"}
                            </div>
                            <div style={{ width: "6em", textAlign: "left" }}>
                              {`($${perHeadGrossMargin.toFixed(2)})`}
                            </div>
                          </td>
                        );
                      })
                    )}
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default LGMCattleMarketingPlan;
