import React, { useEffect, useReducer } from "react";
import QuarterlyProductionAndState from "../components/QuarterlyProductionAndState/QuarterlyProductionAndState";
import QuarterPriceTable from "../components/QuarterPriceTable/QuarterPriceTable";
import { useAppDispatch } from "../../../app/redux/hooks";
import { setCoverageType } from "../features/DRPSlice";

import InsuranceMix from "../components/InsuranceMix/InsuranceMix";
import Class3Weighting from "../components/Class3Weighting/Class3Weighting";
import ComponentWeighting from "../components/ComponentWeighting/ComponentWeighting";
import ProtectionFactor from "../components/ProtectionFactor/ProtectionFactor";
import ResultTablesContainer from "../components/ResultTables/ResultTablesContainer";

const CLPROP = "CLPROP";
const CMPROP = "CMPROP";

const coverageSwitchReducer = (state: string, action) => {
  switch (action) {
    case CLPROP:
      return "CLPROP";
    case CMPROP:
      return "CMPROP";
    default:
      throw new Error("Invalid coverage type.");
  }
};

const getFlag = (activeTab) => {
  if (activeTab.single) {
    return "single";
  }
  if (activeTab.class_vs_component) {
    return "class-vs-component";
  }
  if (activeTab.strip) {
    return "strip";
  }
};

function DynamicDRPTool({ activeTab }) {
  const dispatch = useAppDispatch();
  const [selectedCoverageType, changeCoverage] = useReducer(
    coverageSwitchReducer,
    "CLPROP",
  );

  useEffect(() => {
    dispatch(setCoverageType(selectedCoverageType));
  }, [selectedCoverageType]);

  return (
    <div>
      <QuarterlyProductionAndState />
      <QuarterPriceTable flag={getFlag(activeTab)} />
      <div className="mb-2 pb-2 w-100">
        <div className="row my-1">
          {(activeTab.single || activeTab.strip) && (
            <div className="col my-1" style={{ minWidth: 300 }}>
              <InsuranceMix changeCoverage={changeCoverage} />
            </div>
          )}
          {(activeTab.single || activeTab.strip) &&
            selectedCoverageType === "CLPROP" && (
              <div className="col my-1" style={{ minWidth: 300 }}>
                <Class3Weighting />
              </div>
            )}
          {(activeTab.single || activeTab.strip) &&
            selectedCoverageType === "CMPROP" && (
              <div className="col my-1" style={{ minWidth: 300 }}>
                <ComponentWeighting />
              </div>
            )}
          {activeTab.class_vs_component && (
            <div className="col my-1" style={{ minWidth: 300 }}>
              <ComponentWeighting />
            </div>
          )}
          <div className="col my-1" style={{ minWidth: 300 }}>
            <ProtectionFactor flag={getFlag(activeTab)} />
          </div>
        </div>
        <ResultTablesContainer flag={getFlag(activeTab)} />
      </div>
    </div>
  );
}

export default DynamicDRPTool;
