import React, { useEffect } from "react";
import LRPFeederCattlePracticeAndTypeSelection from "./LRPFeederCattlePracticeAndTypeSelection/LRPFeederCattlePracticeAndTypeSelection";
import LRPFeederCattleNumberHeadSelection from "./LRPFeederCattleNumberHeadSelection/LRPFeederCattleNumberHeadSelection";
import LRPFeederCattleTargetWeightSelection from "./LRPFeederCattleTargetWeightSelection/LRPFeederCattleTargetWeightSelection";

// import { LRPQuoteType } from "@agdeveloper/domain";
import { makeTitle } from "../../lrp_utils/utils";
import { resetLRPParams } from "../../LRPSlice";

import { useAppDispatch } from "../../../../../app/redux/hooks";

// const TYPES = LRPQuoteType.allowed_values;
const TYPES = [
  "BRAHMAN_WEIGHT_1",
  "BRAHMAN_WEIGHT_2",
  "DAIRY_WEIGHT_1",
  "DAIRY_WEIGHT_2",
  "HEIFERS_WEIGHT_1",
  "HEIFERS_WEIGHT_2",
  "STEERS_WEIGHT_1",
  "STEERS_WEIGHT_2",
  "UNBORN_BRAHMAN",
  "UNBORN_DAIRY",
  "UNBORN_STEERS_AND_HEIFERS",
];

let WEIGHT_1: Array<string> = [];
let WEIGHT_2: Array<string> = [];

// Put Weight 2 into separate list
TYPES.forEach((value) => {
  let weight_name = makeTitle(value);
  if (weight_name.endsWith("2")) {
    WEIGHT_2.push(weight_name);
  } else {
    WEIGHT_1.push(weight_name);
  }
});

function LRPFeederCattleMarketingPlan() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Reset CWT and Head Count when page loads
    dispatch(resetLRPParams());
  }, [dispatch]);

  return (
    <div>
      <div className="container-fluid">
        <div className="row my-2">
          <div className="col m-0 p-0">
            <LRPFeederCattlePracticeAndTypeSelection types={TYPES} />
          </div>
        </div>
        <div className="row my-2">
          <div className="col me-1 mt-1 p-0">
            <LRPFeederCattleNumberHeadSelection />
          </div>
          <div className="col m-0 mt-1 p-0">
            <LRPFeederCattleTargetWeightSelection w1={WEIGHT_1} w2={WEIGHT_2} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LRPFeederCattleMarketingPlan;
