import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/redux/hooks";
import { useNavigate, Link } from "react-router-dom";

import { reset } from "../../features/authSlice";
import { requestPasswordReset } from "../../features/authActions";
import authFormValidator from "../../utils/authFormValidator";
import useMounted from "../../../app/utils/hooks/useMounted";
import "../../styles/authStyles.scss";

import company_logo from "../../../app/assets/csc_logo_transparent.png";
import company_logo_blue from "../../../app/assets/csc_logo_transparent_blue.png";

function PasswordResetRequest() {
  const dispatch = useAppDispatch();
  const { isPasswordResetSent, isError, message, isLoading } = useAppSelector(
    (state) => state.auth.authentication,
  );
  const { userType } = useAppSelector((state) => state.auth);
  const [resetEmailAddress, setResetEmailAddress] = useState("");
  // const [resetEmailSent, setResetEmailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [disabled, setDisabled] = useState(true);
  const [formErrors, setFormErrors] = useState<any>({
    email: "",
  });
  const [formValid, setFormValid] = useState<any>(false);
  const [formTouched, setFormTouched] = useState<any>(false);

  const navigate = useNavigate();
  const isMounted = useMounted();

  // On load, reset state status
  useEffect(() => {
    dispatch(reset());
    setFormErrors({
      email: "",
    });
    setDisabled(true);
    setErrorMessage("");
  }, [navigate, dispatch]);

  // Validate email on inupt
  useEffect(() => {
    if (isMounted) {
      const email_validation =
        authFormValidator.emailValidator(resetEmailAddress);

      if (email_validation.valid) {
        setFormValid(true);
      } else {
        setFormValid(false);
      }
      setFormErrors({
        email: email_validation.message,
      });
    }
  }, [resetEmailAddress, isMounted]);

  useEffect(() => {
    if (formValid) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [formValid]);

  // State dependant
  useEffect(() => {
    // if (isPasswordResetSent) {
    //   setResetEmailSent(true);
    // }
    if (isError && message) {
      setErrorMessage(message);
    } else {
      setErrorMessage("");
    }
  }, [isPasswordResetSent, isError, message]);

  const onChange = (e) => {
    setResetEmailAddress(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(requestPasswordReset(resetEmailAddress));
  };

  return (
    <div className="card mx-auto auth-card">
      <div className="card-body">
        <div className="w-100 text-center mb-2">
          <img
            src={userType === "USER" ? company_logo_blue : company_logo}
            alt="CSC AG Logo"
            className="mt-2 mb-4 mx-auto company-logo"
          />
        </div>
        <div className="text-center">
          <h5 className="card-title mb-4 px-3">Reset Your Password</h5>
        </div>
        {isPasswordResetSent !== true ? (
          <div className="mt-2">
            <form onSubmit={onSubmit}>
              <div className="form-group text-left small">
                <p className="text-center">
                  <small>
                    Enter your registered E-Mail address and we will send you
                    instructions on how to recover your account.
                  </small>
                </p>

                <label htmlFor="email" className="form-label">
                  Email Address
                </label>
                <input
                  className="form-control"
                  onChange={onChange}
                  value={resetEmailAddress}
                  type="email"
                  name="reset_email"
                  placeholder="someone@example.com"
                  id="reset_email"
                  onBlurCapture={() => setFormTouched(true)}
                />
                <p className="text-danger validation-error">
                  {formTouched && formErrors.email}
                </p>
              </div>
              <div className="text-center mt-2">
                <button
                  type="submit"
                  disabled={disabled || isLoading}
                  className="btn btn-secondary w-75"
                >
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span> Loading... </span>
                    </>
                  ) : (
                    <>Continue</>
                  )}
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="text-center">
            <p>
              <small>
                Your request has been submitted. If an account exists under the
                email you have provided, a password reset email has been sent.
              </small>
            </p>
          </div>
        )}
        <div className="text-center mt-4">
          <p className="text-danger">{errorMessage}</p>
        </div>
        <div className="mt-4 w-100 small text-center">
          <Link style={{ textDecoration: "none" }} to="/login">
            <small>{"<< Back to Login"}</small>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PasswordResetRequest;
