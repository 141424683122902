import React from "react";
import { useNavigate } from "react-router-dom";

import "../../Authentication/styles/authStyles.scss";

function Admin() {
  const navigate = useNavigate();
  return (
    <div className="ms-4">
      <h5>Admin Actions</h5>
      <hr className="mb-4" />
      <ul className="container list-group list-group-flush">
        <li
          className="list-group-item list-group-item-action rounded-0 list-group-link"
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/admin/authorize-agent-email")}
        >
          Authorize Agent Email
        </li>
      </ul>
    </div>
  );
}

export default Admin;
