import React, { useState, useEffect } from "react";
import { setClassBasedWeighting } from "../../features/DRPSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/redux/hooks";

function Class3Weighting() {
  const dispatch = useAppDispatch();

  const restricted_message =
    "Class weighting has been restricted to the indicated value for the selected Quarter / Sales Effective Date";
  const [isClassRestricted, setIsClassRestricted] = useState(false);

  const { selectedQuarter, classWeighting, classWeightingExtras } =
    useAppSelector((state) => state.drp);

  const [slider, setSlider] = useState(classWeighting.slider);

  //on change of quarter we need to check if that quarters nonfat is restricted or component restricted value is set
  useEffect(() => {
    setIsClassRestricted(
      !isNaN(
        parseFloat(
          classWeightingExtras.classPriceWeightingFactorRestrictedValue,
        ),
      ) || classWeightingExtras.isClassLocked,
    );
  }, [
    selectedQuarter,
    classWeightingExtras.classPriceWeightingFactorRestrictedValue,
    classWeightingExtras.isClassLocked,
  ]);

  useEffect(() => {
    if (isClassRestricted) {
      setSlider(
        100 *
          (1 -
            parseFloat(
              classWeightingExtras.classPriceWeightingFactorRestrictedValue,
            )),
      );
    }
    // only trigger on isClassRestricted change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClassRestricted]);
  useEffect(() => {
    // Input value validation
    if (slider > 20) {
      setSlider(20);
    }
    if (slider < 0) {
      setSlider(0);
    }
    // Update class values
    dispatch(
      setClassBasedWeighting({
        values: {
          class3: 100 - slider * 5,
          class4: 0 + slider * 5,
        },
        slider: slider,
      }),
    );
  }, [slider, dispatch]);

  const updateSlider = (e) => {
    setSlider(e.target.value);
  };

  return (
    <div className="card w-100">
      <div className="card-body">
        <h5 className="card-title">Class-Based Weighting</h5>
        <p className="card-text">Select your class weighting.</p>
        <div className="form-group">
          <div className="d-inline-block">
            Class III
            <br />
            {/* <input
              type="text"
              name="class3"
              id="class3"
              value={values.class3}
              style={{ maxWidth: "3em" }}
            /> */}
            <span className="mx-1">
              <b>{classWeighting.values.class3} </b>%
            </span>
          </div>
          <div className="d-inline-block">
            <input
              onChange={updateSlider}
              type="range"
              name="range"
              id="range"
              min="0"
              max="20"
              value={slider}
              disabled={isClassRestricted}
            />
          </div>
          <div className="d-inline-block">
            Class IV
            <br />
            {/* <input
              type="text"
              name="class4"
              id="class4"
              value={values.class4}
              style={{ maxWidth: "3em" }}
            /> */}
            <span className="mx-1">
              <b>{classWeighting.values.class4} </b>%
            </span>
          </div>
        </div>
        {isClassRestricted && (
          <div
            className="display-inline-block text-align-center"
            style={{
              fontStyle: "italic",
              color: "#8e2736",
              maxWidth: "250px",
            }}
          >
            <small>{restricted_message}</small>
          </div>
        )}
      </div>
    </div>
  );
}

export default Class3Weighting;
