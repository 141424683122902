import React from "react";

function IndemnitySliderButton({
  name,
  value,
  onClick,
  onMouseDown,
  onMouseUp,
}) {
  const handleArrowKeys = (e, bool) => {
    if (e.key === "ArrowRight") {
      onMouseDown(e.currentTarget.name, 1, bool);
    } else if (e.key === "ArrowLeft") {
      onMouseDown(e.currentTarget.name, -1, bool);
    }
  };

  return (
    <button
      className="btn-indemnity"
      name={name}
      onClick={(e) => {
        onClick(e.currentTarget.name, value);
      }}
      onMouseDown={(e) => onMouseDown(e.currentTarget.name, value, true)}
      onMouseUp={() => onMouseUp()}
      onMouseLeave={() => onMouseUp()}
      onKeyDown={(e) => handleArrowKeys(e, true)}
      onKeyUp={() => onMouseUp()}
    >
      {value > 0 ? (
        <i className="bx bx-plus-circle slider-icon"></i>
      ) : (
        <i className="bx bx-minus-circle slider-icon"></i>
      )}
    </button>
  );
}

export default IndemnitySliderButton;
