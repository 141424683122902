import React, { useState, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import { useNavigate, Link } from "react-router-dom";
import { loginProducer, loginAgent, checkLogin } from "../features/authActions";
import { reset } from "../features/authSlice";
import authFormValidator from "../utils/authFormValidator";
import useMounted from "../../app/utils/hooks/useMounted";
import "../styles/authStyles.scss";
import FormInput from "./utility/FormInput";

import company_logo from "../../app/assets/csc_logo_transparent.png";
import company_logo_blue from "../../app/assets/csc_logo_transparent_blue.png";

function GeneralLogin() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { email, userType, isLoggedIn, authentication } = useAppSelector(
    (state) => state.auth,
  );

  const [userCredentials, setUserCredentials] = useState({
    email: "",
    password: "",
  });
  // const [errorMessage, setErrorMessage] = useState<string>("");
  const [formErrors, setFormErrors] = useState<any>({
    email: null,
    password: null,
  });
  const [formValid, setFormValid] = useState<any>({
    email: false,
    password: false,
  });
  const [touched, setTouched] = useState<any>({
    email: false,
    password: false,
  });

  const [disabled, setDisabled] = useState(true);
  const isMounted = useMounted();

  // Reset on navigate
  useEffect(() => {
    dispatch(reset());
    // Clear error messages
    // setErrorMessage("");
    setDisabled(true);
    setFormErrors({
      email: null,
      password: null,
    });
  }, [navigate, dispatch]);

  // on navigate, try to login if we have saved credentials in a cookie
  useEffect(() => {
    // attempt to log in automatically in case user is already logged in with cookie
    console.debug("Dispatching autologin from GeneralLogin...");
    dispatch(checkLogin());
  }, [navigate]);

  useEffect(() => {
    setDisabled(!(formValid.email && formValid.password));
  }, [formValid]);

  useEffect(() => {
    if (isLoggedIn && email) {
      // If success or email in state then redirect to loading page
      navigate("/loading-screen");
    }

    // if (authentication.isError && authentication.message) {
    //   setErrorMessage(authentication.message);
    // } else {
    //   setErrorMessage("");
    // }
  }, [
    email,
    authentication.isLoading,
    isLoggedIn,
    authentication.isSuccess,
    authentication.isError,
    authentication.message,
    navigate,
  ]);

  const onChange = (e) => {
    // Update credentials in state
    setUserCredentials((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  // Validate email
  useEffect(() => {
    if (isMounted) {
      const email_validation = authFormValidator.emailValidator(
        userCredentials.email,
      );
      if (email_validation.valid) {
        setFormValid((prevState) => ({ ...prevState, email: true }));
      } else {
        setFormValid((prevState) => ({ ...prevState, email: false }));
      }
      setFormErrors((prevState) => ({
        ...prevState,
        email: email_validation.message,
      }));
    }
    // we are not adding isMounted to the dependency array as it would result in error message being shown early
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCredentials.email]);

  // Validate password
  useEffect(() => {
    if (isMounted) {
      if (userCredentials.password.length === 0) {
        setFormErrors((prevState) => ({
          ...prevState,
          password: "Please enter a password",
        }));
        setFormValid((prevState) => ({ ...prevState, password: false }));
      } else {
        setFormValid((prevState) => ({ ...prevState, password: true }));
        setFormErrors((prevState) => ({
          ...prevState,
          password: null,
        }));
      }
    }
    // we are not adding isMounted to the dependency array as it would result in error message being shown early
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCredentials.password]);

  const onSubmit = async (e) => {
    e.preventDefault();
    const email_validation = authFormValidator.emailValidator(
      userCredentials.email,
    );

    if (email_validation.valid) {
      userType === "AGENT"
        ? dispatch(loginAgent(userCredentials))
        : dispatch(loginProducer(userCredentials));
    } else {
      // setErrorMessages("Please check your credentials!")
      setFormErrors((prevState) => ({
        ...prevState,
        email: authentication.message,
      }));
    }
  };

  return (
    <div className="card mx-auto auth-card">
      <div className="card-body">
        <div className="w-100 text-center mb-2">
          <img
            src={userType === "AGENT" ? company_logo : company_logo_blue}
            alt="CSC AG Logo"
            className="mt-2 mb-4 mx-auto company-logo"
          />
        </div>
        <div className="text-center">
          <h5 className="card-title mb-4 px-3">
            Welcome to the
            <br />
            {userType === "AGENT" ? "Agent Portal" : "Producer Dashboard"}
          </h5>
        </div>
        <div className="mt-2">
          <form onSubmit={onSubmit}>
            <FormInput
              label={"Email Address"}
              type={"email"}
              onChange={onChange}
              name={"email"}
              value={userCredentials.email}
              touched={touched.email}
              message={formErrors.email}
              required={false}
              placeholder={"someone@example.com"}
              id={"email"}
              onBlur={() =>
                setTouched((prevState) => ({ ...prevState, email: true }))
              }
            />

            <FormInput
              label={"Password"}
              type={"password"}
              onChange={onChange}
              name={"password"}
              value={userCredentials.password}
              touched={touched.password}
              message={formErrors.password}
              required={false}
              id={"password"}
              placeholder={"password"}
              autoComplete="off"
              onBlur={() =>
                setTouched((prevState) => ({ ...prevState, password: true }))
              }
            />

            <div className="text-center">
              <button
                className="btn btn-secondary w-75"
                disabled={disabled || authentication.isLoading}
                type="submit"
              >
                {authentication.isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span> Loading... </span>
                  </>
                ) : (
                  <>Login</>
                )}
              </button>
            </div>
          </form>
        </div>
        <div className="mt-4 w-100  text-center">
          <small>
            New user? Click{" "}
            <Link
              style={{ textDecoration: "none" }}
              to={
                userType === "AGENT" ? "/register-agent" : "/register-producer"
              }
            >
              here
            </Link>{" "}
            to register.
          </small>
        </div>
        <div className="mt-4 w-100 text-center">
          <small>
            Forgot your password? Click{" "}
            <Link style={{ textDecoration: "none" }} to="/reset-password">
              here
            </Link>{" "}
            to reset.
          </small>
        </div>

        <div className="text-center mt-4">
          {/* Error message */}
          <p className="text-danger">{authentication.message}</p>
        </div>
        <div
          className="mt-4 w-100  text-center"
          style={{ fontSize: "80%", fontWeight: 400 }}
        >
          <Link style={{ textDecoration: "none" }} to="/login">
            {"<< Back to Login"}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default GeneralLogin;
