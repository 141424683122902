import React, { useEffect } from "react";

function InsuranceMix({ changeCoverage }) {
  const onChangeValue = (e) => {
    changeCoverage(e.target.value);
  };

  useEffect(() => {
    changeCoverage("CLPROP");
  }, []);

  return (
    <div className="card w-100">
      <div className="card-body">
        <div>
          <h5 className="card-title">Coverage Type</h5>
        </div>
        <div className="card-text">Select your insurance coverage type.</div>
        <form>
          <div className="form-group">
            <div>
              <input
                type="radio"
                name="coverageType"
                id="classPricing"
                value="CLPROP"
                style={{ marginRight: 10 }}
                defaultChecked={true}
                onChange={onChangeValue}
              />
              <label htmlFor="classPricing" className="form-check-label">
                Class Pricing
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="coverageType"
                id="componentPricing"
                value="CMPROP"
                style={{ marginRight: 10 }}
                onChange={onChangeValue}
              />
              <label htmlFor="componentPricing" className="form-check-label">
                Component Pricing
              </label>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default InsuranceMix;
