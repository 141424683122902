import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { useNavigate } from "react-router-dom";
import { setUserType } from "../../features/authSlice";
import { checkLogin } from "../../features/authActions";
import "../../styles/authStyles.scss";

import company_logo from "../../../app/assets/csc_logo_transparent.png";

function Login() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isLoggedIn, authentication } = useAppSelector((state) => state.auth);

  const [passwordWasReset, setPasswordWasReset] = useState(false);

  useEffect(() => {
    if (authentication.isPasswordReset) {
      setPasswordWasReset(true);
    }
  }, [authentication.isPasswordReset]);

  // try to auto login on navigation
  useEffect(() => {
    // attempt to log in automatically in case user is already logged in with cookie
    console.debug("Dispatching autologin from Login...");
    dispatch(checkLogin());
  }, [navigate]);

  // auto-forward to loading screen if auto logged in
  useEffect(() => {
    if (isLoggedIn) {
      // If we have successfully logged in automatically then redirect to loading page
      navigate("/loading-screen");
    }
  }, [
    authentication.isLoading,
    isLoggedIn,
    authentication.isSuccess,
    authentication.isError,
    authentication.message,
    navigate,
  ]);

  useEffect(() => {
    dispatch(setUserType(""));
  }, [dispatch]);

  // console.debug("returning view...");
  // only show the login screen if we're not going to auto-login
  return (
    authentication.isLoading === false &&
    isLoggedIn === false && (
      <div className="card mx-auto auth-card">
        <div className="card-body">
          <div className="w-100 text-center mb-2">
            <img
              src={company_logo}
              alt="CSC AG Logo"
              className="mt-2 mb-4 mx-auto company-logo"
            />
          </div>
          <div className="text-center">
            <h5 className="card-title mb-4 px-3">
              Welcome to
              <br />
              Ag Hedge Desk
            </h5>
          </div>

          <div className="text-center mt-4 mb-4">
            <div className="mt-4 w-100">
              <button
                className="btn btn-agent w-100 mt-4"
                onClick={() => {
                  dispatch(setUserType("AGENT"));
                  navigate("/login-general");
                }}
                style={{
                  backgroundColor: "#8e2736",
                  color: "#fff",
                  fontWeight: 500,
                }}
              >
                Agent Portal Login
              </button>

              <button
                className="btn btn-producer w-100 mt-4"
                onClick={() => {
                  dispatch(setUserType("USER"));
                  navigate("/login-general");
                }}
                style={{
                  backgroundColor: "#1f497d",
                  color: "#fff",
                  fontWeight: 500,
                }}
              >
                Producer Dashboard Login
              </button>
            </div>
          </div>

          {/* If redirected to login page after resetting password. */}
          {passwordWasReset && (
            <div className="text-center mt-4 alert alert-success" role="alert">
              Your password has been successfully reset, you may now log in
              using your new password.
            </div>
          )}

          <div className="copyright text-center py-3 mx-auto px-4">
            &copy;2022 Ag Hedge Desk
            <br />
            All rights reserved
          </div>
        </div>
      </div>
    )
  );
}

export default Login;
