import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/redux/hooks";
import {
  setQuarterLabels,
  setSelectedQuarter,
  setNonfatExtras,
  setClassWeightingExtras,
} from "../../features/DRPSlice";
import {
  getMostRecentPricesSecure,
  updateHistoricalPrices,
  getYields,
} from "../../features/DRPActions";

import { states_hash_reversed } from "../../../../globals";

import { Quarter } from "./Quarter";
import QuarterSelect from "./QuarterSelect/QuarterSelect";
import { MemoizedPriceTable } from "./PriceTable/PriceTable";
import HistoricalPriceChartContainer from "../HistoricalPriceChart/HistoricalPriceChartContainer";
import { calculateNextQuarterLabel } from "./utils/PriceTableUtilityFunctions";

const products = [
  "Class III",
  "Class IV",
  "Butterfat",
  "Protein",
  "Other Solids",
  "Nonfat Solids",
];

function QuarterPriceTable({ flag }) {
  const dispatch = useAppDispatch();
  const {
    message,
    isError,
    currentState,
    quarterPriceList,
    quarterLabels,
    selectedQuarter,
    salesEffectiveDate,
  } = useAppSelector((state) => state.drp);

  const [quarters, setQuarters] = useState([
    new Quarter("-", "", "", "", "", "", "", "", "", ""),
  ]);

  // Effects
  useEffect(() => {
    dispatch(getMostRecentPricesSecure(currentState));
    // we are not adding currentState to the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    // FIXME: Effect triggers 4 times on each page load
    // Update Historical Prices
    if (quarterLabels.length > 0) {
      dispatch(
        updateHistoricalPrices({ products: products, quarters: quarterLabels }),
      );
    }
  }, [quarterLabels, dispatch]);

  useEffect(() => {
    // Update quarters whenever quarterPriceList is updated
    let _quarters: any = [];
    let _quarter_labels: any = [];

    // const entries = Object.entries(quarterPriceList);
    for (let k = 0; k < 5; k++) {
      // Get values from quarterPriceList and create an instance of Quarter for each.
      try {
        _quarters[k] = new Quarter(
          quarterPriceList[k]["qtr"],
          quarterPriceList[k]["ExpectedClassIIIPrice"],
          quarterPriceList[k]["ExpectedClassIVPrice"],
          quarterPriceList[k]["ExpectedButterfatPrice"],
          quarterPriceList[k]["ExpectedProteinPrice"],
          quarterPriceList[k]["ExpectedOtherSolidsPrice"],
          quarterPriceList[k]["ExpectedNonfatSolidsPrice"],
          quarterPriceList[k]["ExpectedYield"],
          quarterPriceList[k]["ExpectedYieldStandardDeviation"],
          quarterPriceList[k]["days_in_qtr"],
        );

        _quarters[k].setYieldPerDay(
          quarterPriceList[k]["ExpectedYield"],
          quarterPriceList[k]["days_in_qtr"],
        );
      } catch {
        // If the quarter is missing (usually only happens with the last (5th) quarter)
        // then create a new empty quarter instance with the quarter label calculated from the
        // last quarter and the rest of the values set to "-".

        _quarters[k] = new Quarter(
          quarterPriceList[k - 1]
            ? calculateNextQuarterLabel(quarterPriceList[k - 1]["qtr"])
            : "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
        );

        _quarters[k].setYieldPerDay("-", "-");
      }
    }
    // console.log(_quarters);
    for (let i = 0; i < 5; i++) {
      try {
        _quarter_labels[i] = _quarters[i]["textstring"];
      } catch {
        _quarter_labels[i] = "-";
      }
    }

    setQuarters(_quarters); // Set Quarter objects to local state

    if (_quarter_labels.length > 0) {
      dispatch(setQuarterLabels(_quarter_labels));
      dispatch(setSelectedQuarter(_quarter_labels[1]));
      changeSelectedQuarter({ target: { innerHTML: _quarter_labels[1] } }); // Manually call changeSelectedQuarter
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quarterPriceList, dispatch]);

  useEffect(() => {
    // Update prices when currentState is updated
    dispatch(getMostRecentPricesSecure(currentState));
  }, [currentState, dispatch]);

  useEffect(() => {
    // Update yields prices
    if (salesEffectiveDate !== "") {
      const yields_request_data = {
        state_abbr: states_hash_reversed[currentState],
        sales_effective_date: salesEffectiveDate.replace(/-/g, ""),
      };
      dispatch(getYields(yields_request_data));
    }
  }, [currentState, salesEffectiveDate, dispatch]);

  const setDispatchNonfatExtras = (qtr) => {
    const entries = Object.entries(quarterPriceList);

    for (const [k] of entries) {
      if (quarterPriceList[k]["qtr"] === qtr) {
        const component_price_weighting_factor =
          typeof quarterPriceList[k][
            "ComponentPriceWeightingFactorRestrictedValue"
          ] === "number"
            ? quarterPriceList[k][
                "ComponentPriceWeightingFactorRestrictedValue"
              ].toFixed(2)
            : "-";

        const is_nonfat_locked =
          typeof quarterPriceList[k]["ExpectedNonfatSolidsPrice"] === "number"
            ? false
            : true;

        dispatch(
          setNonfatExtras({
            componentPriceWeightingFactorRestrictedValue:
              component_price_weighting_factor,
            isNonfatLocked: is_nonfat_locked,
          }),
        );
        break;
      }
    }
  };
  const setDispatchClassExtras = (qtr) => {
    const entries = Object.entries(quarterPriceList);

    for (const [k] of entries) {
      if (quarterPriceList[k]["qtr"] === qtr) {
        const class_price_weighting_factor =
          typeof quarterPriceList[k][
            "ClassPriceWeightingFactorRestrictedValue"
          ] === "number"
            ? quarterPriceList[k][
                "ClassPriceWeightingFactorRestrictedValue"
              ].toFixed(2)
            : "-";

        const is_class_locked =
          typeof quarterPriceList[k][
            "ClassPriceWeightingFactorRestrictedValue"
          ] === "number"
            ? true
            : false;

        dispatch(
          setClassWeightingExtras({
            classPriceWeightingFactorRestrictedValue:
              class_price_weighting_factor,
            isClassLocked: is_class_locked,
          }),
        );
        break;
      }
    }
  };
  const changeSelectedQuarter = (e) => {
    dispatch(setSelectedQuarter(e.target.innerHTML));
    setDispatchNonfatExtras(e.target.innerHTML);
    setDispatchClassExtras(e.target.innerHTML);

    //fix for nonfat were not updating when quarter changed
  };

  return (
    <div className="card p-2 my-1">
      <div className="card-body">
        {isError && (
          <div>
            <p className="text-danger">Error! {message}</p>
          </div>
        )}
        <div>
          {/* Quarter Select Dropdown */}
          <QuarterSelect
            selectedQuarter={selectedQuarter}
            quarterLabels={quarterLabels}
            changeSelectedQuarter={changeSelectedQuarter}
            flag={flag}
          />
        </div>
        <div>
          <MemoizedPriceTable
            quarterLabels={quarterLabels}
            quarters={quarters}
          />
        </div>
      </div>
      {/* Chart */}
      {/* FIXME: Price chart is rendered whenever 
      another component state changes. */}
      <HistoricalPriceChartContainer />
    </div>
  );
}

export default QuarterPriceTable;
