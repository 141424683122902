import React from "react";

function QuarterSelect({
  selectedQuarter,
  quarterLabels,
  changeSelectedQuarter,
  flag,
}) {
  return (
    <div>
      <h5 className="card-title">Quarter</h5>
      <p className="card-text">
        {flag === "strip"
          ? "Quarter selection is not applicable for strip quotes."
          : "Choose the quarter you would like to insure."}
      </p>
      {!(flag === "strip") && (
        <div className={`dropdown pb-1`}>
          <button
            id="dropdownMenuButton"
            type="button"
            className="btn btn-secondary dropdown-toggle ag-form-focus"
            data-bs-toggle="dropdown"
            // aria-expanded={showDropdown ? "true" : "false"}
            aria-expanded="false"
            // aria-haspopup="true"
            // onClick={toggleDropdown}
          >
            {selectedQuarter}
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            {quarterLabels.map((quarterName, index) => {
              return (
                <li key={index}>
                  {quarterName !== "-" && (
                    <div
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={changeSelectedQuarter}
                    >
                      {quarterName}
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

export default QuarterSelect;
