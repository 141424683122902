import { createSlice } from "@reduxjs/toolkit";
import {
  loginProducer,
  loginAgent,
  checkLogin,
  login,
  logout,
  registerAgent,
  registerProducer,
  requestPasswordReset,
  resetPassword,
  checkEmailVerification,
  requestVerificationEmail,
  getAllUsersForAgent,
  checkAgentAuthorizedProducer,
  createAgencyProfile,
  authorizeOrDenyProducerAccess,
  checkAgentRegistrationAuthorization,
  checkAgencyRegistration,
  getLastEnteredAgentEmail,
  getAgencyRegistration,
  sendAgentAuthorizationEmail,
  getPermissions,
  alertAccountDeletionRequest,
  alertAccountDeletionRequestNonAuthenticated,
} from "./authActions";

// interface authState {
//   email: any;
//   userType: string;
//   isLoggedIn: boolean;
//   isLoading: boolean;
//   isVerifyingEmail: boolean;
//   isSuccess: boolean;
//   isError: boolean;
//   isRegistrationComplete: boolean;
//   isPasswordResetSent: boolean;
//   isPasswordReset: boolean;
//   isEmailVerified: boolean;
//   isRegistrationAuthorized: boolean;
//   isVerificationSent: boolean;
//   message: string;
//   agents_producers: Array<object>;
// }

const initialState = {
  email: null,
  userType: "",
  agents_producers: [],
  isLoggedIn: false,
  scopes: [] as string[],
  authentication: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    isRegistrationComplete: false,
    isPasswordResetSent: false,
    isPasswordReset: false,
    message: "",
  },
  //serves both agent and producer
  registrationAuthorization: {
    isRegistrationAuthorized: false,
    message: "Checking...",
    isLoading: false,
    isError: false,
    lastEnteredAgentEmail: "",
    isAtMaxChangeAgentEmailAttempts: false,
    hasCheckedRegistrationAuthorization: false,
  },
  emailVerification: {
    isEmailVerified: false,
    isVerifyingEmail: false,
    isVerificationSent: false,
    message: "Checking...",
    isLoading: false,
    isError: false,
    hasCheckedEmailVerification: false,
  },
  agencyProfile: {
    isAgencyProfileComplete: false,
    agent_profile: null,
    agency_profile: null,
    message: "Checking...",
    isLoading: false,
    isError: false,
    hasCheckedAgencyProfile: false,
  },
  accountDeletion: {
    isError: false,
    message: "",
    isLoading: false,
    isSuccess: false,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.authentication.isLoading = false;
      state.authentication.isSuccess = false;
      state.authentication.isError = false;
      state.authentication.isPasswordResetSent = false;
      state.authentication.isPasswordReset = false;
      state.emailVerification.isVerificationSent = false;
      state.authentication.message = "";
    },
    // setMessage: (state, action) => {
    //   state.message = action.payload;
    // },
    setUserType: (state, action) => {
      state.userType = action.payload === "USER" ? "USER" : "AGENT";
    },

    setAgentAuthorizedProducer: (state, action) => {
      state.registrationAuthorization.isRegistrationAuthorized = action.payload;
      state.registrationAuthorization.hasCheckedRegistrationAuthorization =
        true;
    },

    resetAccountDeletionStatus: (state) => {
      state.accountDeletion.isError = initialState.accountDeletion.isError;
      state.accountDeletion.message = initialState.accountDeletion.message;
      state.accountDeletion.isLoading = initialState.accountDeletion.isLoading;
      state.accountDeletion.isSuccess = initialState.accountDeletion.isSuccess;
    },
  },
  extraReducers: (builder) => {
    // Login Producer
    builder
      .addCase(loginProducer.pending, (state) => {
        state.authentication.isLoading = true;
      })
      .addCase(loginProducer.fulfilled, (state, action) => {
        state.authentication.isLoading = false;
        state.authentication.isSuccess = true;
        state.isLoggedIn = true;
        state.email = action.payload.email;
        state.userType = action.payload.user_type;
      })
      .addCase(loginProducer.rejected, (state, action) => {
        state.authentication.isLoading = false;
        state.authentication.isSuccess = false;
        state.authentication.isError = true;
        state.email = null;
        state.authentication.message = action.payload as string;
      });

    // Login Agent
    builder
      .addCase(loginAgent.pending, (state) => {
        state.authentication.isLoading = true;
      })
      .addCase(loginAgent.fulfilled, (state, action) => {
        state.authentication.isLoading = false;
        state.authentication.isSuccess = true;
        state.isLoggedIn = true;
        state.email = action.payload.email;
        state.userType = action.payload.user_type;
      })
      .addCase(loginAgent.rejected, (state, action) => {
        state.authentication.isLoading = false;
        state.authentication.isSuccess = false;
        state.authentication.isError = true;
        state.email = null;
        state.authentication.message = action.payload as string;
      });

    // checkLogin
    builder
      .addCase(checkLogin.pending, (state) => {
        state.authentication.isLoading = true;
      })
      .addCase(checkLogin.fulfilled, (state, action) => {
        state.authentication.isLoading = false;
        state.authentication.isSuccess = true;
        state.isLoggedIn = true;
        state.email = action.payload.email;
        state.userType = action.payload.user_type;
      })
      .addCase(checkLogin.rejected, (state, action) => {
        state.authentication.isLoading = false;
        state.authentication.isSuccess = false;
        state.authentication.isError = true;
        state.email = null;
        state.authentication.message = action.payload as string;
      });

    // builder
    //   .addCase(login.pending, (state) => {
    //     state.authentication.isLoading = true;
    //   })
    //   .addCase(login.fulfilled, (state, action) => {
    //     state.authentication.isLoading = false;
    //     state.authentication.isSuccess = true;
    //     state.isLoggedIn = true;
    //     state.email = action.payload.email;
    //     // state.userType = action.payload.user_type;
    //   })
    //   .addCase(login.rejected, (state, action) => {
    //     state.authentication.isLoading = false;
    //     state.authentication.isSuccess = false;
    //     state.authentication.isError = true;
    //     state.email = null;
    //     state.authentication.message = action.payload as string;
    //   });

    // Logout
    builder
      .addCase(logout.fulfilled, (state) => {
        state.email = null;
        state.isLoggedIn = false;
      })
      .addCase(logout.rejected, (state, action) => {
        state.authentication.message = action.payload as string;

        // Log out user anyway
        state.email = null;
        state.isLoggedIn = false;
      });

    // Register Producer
    builder
      .addCase(registerProducer.pending, (state) => {
        state.authentication.isLoading = true;
        state.authentication.isSuccess = false;
      })
      .addCase(registerProducer.fulfilled, (state) => {
        state.authentication.isSuccess = true;
        state.authentication.isLoading = false;
        state.authentication.isRegistrationComplete = true;
      })
      .addCase(registerProducer.rejected, (state, action) => {
        state.authentication.isError = true;
        state.authentication.isLoading = false;
        state.authentication.isSuccess = false;
        state.authentication.isRegistrationComplete = false;
        state.authentication.message = action.payload as string;
      });

    // Register Agent
    builder
      .addCase(registerAgent.pending, (state) => {
        state.authentication.isLoading = true;
        state.authentication.isSuccess = false;
      })
      .addCase(registerAgent.fulfilled, (state) => {
        state.authentication.isSuccess = true;
        state.authentication.isLoading = false;
        state.authentication.isRegistrationComplete = true;
      })
      .addCase(registerAgent.rejected, (state, action) => {
        state.authentication.isError = true;
        state.authentication.isLoading = false;
        state.authentication.isSuccess = false;
        state.authentication.isRegistrationComplete = false;
        state.authentication.message = action.payload as string;
      });
    // Request Password Reset
    builder
      .addCase(requestPasswordReset.pending, (state) => {
        state.authentication.isLoading = true;
        state.authentication.isSuccess = false;
      })
      .addCase(requestPasswordReset.fulfilled, (state) => {
        state.authentication.isSuccess = true;
        state.authentication.isLoading = false;
        state.authentication.isPasswordResetSent = true;
        state.authentication.message = "";
      })
      .addCase(requestPasswordReset.rejected, (state, action) => {
        state.authentication.isSuccess = false;
        state.authentication.isLoading = false;
        state.authentication.isError = true;
        state.authentication.isPasswordResetSent = false;
        state.authentication.message = action.payload as string;
      });

    // Password Reset Finalize
    builder
      .addCase(resetPassword.pending, (state) => {
        state.authentication.isLoading = true;
        state.authentication.isSuccess = false;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.authentication.isSuccess = true;
        state.authentication.isLoading = false;
        state.authentication.isPasswordReset = true;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.authentication.isSuccess = false;
        state.authentication.isLoading = false;
        state.authentication.isError = true;
        state.authentication.isPasswordReset = false;
        state.authentication.message = action.payload as string;
      });

    // Request Verification Email
    builder
      .addCase(requestVerificationEmail.pending, (state) => {
        state.emailVerification.isLoading = true;
        // state.emailVerification.isSuccess = false;
      })
      .addCase(requestVerificationEmail.fulfilled, (state) => {
        state.emailVerification.isVerificationSent = true;
        state.emailVerification.isLoading = false;
        state.emailVerification.isError = false;
        // state.emailVerification.isSuccess = true;
      })
      .addCase(requestVerificationEmail.rejected, (state, action) => {
        state.emailVerification.isVerificationSent = false;
        state.emailVerification.isLoading = false;
        state.emailVerification.isError = true;
        // state.emailVerification.isSuccess = false;
        state.emailVerification.message = action.payload as string;
      });

    // Check Email Verification
    builder
      .addCase(checkEmailVerification.pending, (state, action) => {
        state.emailVerification.isVerifyingEmail = true;
        state.emailVerification.message = "Checking...";
        state.emailVerification.isError = false;
      })
      .addCase(checkEmailVerification.fulfilled, (state, action) => {
        //Only fufilled if email is verified else rejected
        state.emailVerification.isEmailVerified = true;
        state.emailVerification.isVerifyingEmail = false;
        state.emailVerification.isError = false;
        state.emailVerification.message = "completed";
        state.emailVerification.hasCheckedEmailVerification = true;
      })
      .addCase(checkEmailVerification.rejected, (state, action: any) => {
        // TODO: Add error message

        state.emailVerification.isEmailVerified = false;
        state.emailVerification.isVerifyingEmail = false;
        state.emailVerification.isError =
          action.payload?.status && action.payload.status === 200
            ? false
            : true;
        state.emailVerification.message = "incomplete";
        state.emailVerification.hasCheckedEmailVerification = true;
      });

    // CgetAllUsersForAgent
    builder
      .addCase(getAllUsersForAgent.pending, (state) => {
        state.authentication.isSuccess = false;
        state.authentication.isError = false;
      })
      .addCase(getAllUsersForAgent.fulfilled, (state, action) => {
        state.authentication.isSuccess = true;
        state.authentication.isError = false;
        state.agents_producers = action.payload.users;
      })
      .addCase(getAllUsersForAgent.rejected, (state, action) => {
        // TODO: Add error message
        state.authentication.isSuccess = false;
        state.authentication.isError = true;
      });

    // checkAgentAuthorizedProducer
    builder
      .addCase(checkAgentAuthorizedProducer.pending, (state) => {
        state.registrationAuthorization.message = "Checking...";
        state.registrationAuthorization.isLoading = true;
        state.registrationAuthorization.isError = false;
      })
      .addCase(checkAgentAuthorizedProducer.fulfilled, (state, action) => {
        state.registrationAuthorization.message = action.payload.description;
        state.registrationAuthorization.isLoading = false;
        state.registrationAuthorization.isError = false;

        state.registrationAuthorization.isRegistrationAuthorized =
          action.payload.is_authorized;
        state.registrationAuthorization.hasCheckedRegistrationAuthorization =
          true;
      })
      .addCase(checkAgentAuthorizedProducer.rejected, (state, action) => {
        state.registrationAuthorization.message = "unknown error";
        state.registrationAuthorization.isLoading = false;
        state.registrationAuthorization.isError = true;
        state.registrationAuthorization.isRegistrationAuthorized = false;
        state.registrationAuthorization.hasCheckedRegistrationAuthorization =
          true;
      });

    // Authorize User
    builder
      .addCase(authorizeOrDenyProducerAccess.pending, (state) => {
        state.authentication.isSuccess = false;
        state.authentication.isError = false;
        state.authentication.isLoading = true;
      })
      .addCase(authorizeOrDenyProducerAccess.fulfilled, (state, action) => {
        state.authentication.isSuccess = true;
        state.authentication.message = action.payload.message;
        state.authentication.isError = false;
        state.authentication.isLoading = false;

        state.agents_producers = action.payload.users;
      })
      .addCase(authorizeOrDenyProducerAccess.rejected, (state, action) => {
        // TODO: Add error message
        state.authentication.message = action.payload as string;
        state.authentication.isSuccess = false;
        state.authentication.isError = true;
        state.authentication.isLoading = false;
      });

    // checkAgentRegistrationAuthorization
    builder
      .addCase(checkAgentRegistrationAuthorization.pending, (state) => {
        state.registrationAuthorization.isError = false;
        state.registrationAuthorization.isLoading = true;
      })
      .addCase(
        checkAgentRegistrationAuthorization.fulfilled,
        (state, action) => {
          //check error
          if ("description" in action.payload) {
            state.registrationAuthorization.message =
              action.payload.description;
            state.registrationAuthorization.isError = false;
            state.registrationAuthorization.isRegistrationAuthorized = true;
          } else {
            if ("error" in action.payload) {
              if ("description" in action.payload.error) {
                state.registrationAuthorization.message =
                  action.payload.error.description;
                state.registrationAuthorization.isError = true;
              } else {
                state.registrationAuthorization.message = "unknown error";
                state.registrationAuthorization.isError = true;
              }
            } else if ("payload" in action.payload) {
              if ("description" in action.payload.payload) {
                state.registrationAuthorization.message =
                  action.payload.payload.description;
                if (
                  action.payload.payload.description ===
                  "registration confirmed"
                ) {
                  state.registrationAuthorization.isRegistrationAuthorized =
                    true;
                  state.registrationAuthorization.isError = false;
                } else {
                  state.registrationAuthorization.isRegistrationAuthorized =
                    false;
                  state.registrationAuthorization.isError = true;
                }
              } else {
                state.registrationAuthorization.isError = true;
                state.registrationAuthorization.message = "unknown error";
              }
            } else {
              state.registrationAuthorization.isError = true;
              state.registrationAuthorization.message = "unknown error";
            }
          }

          state.registrationAuthorization.isLoading = false;
          state.registrationAuthorization.hasCheckedRegistrationAuthorization =
            true;
        },
      )
      .addCase(
        checkAgentRegistrationAuthorization.rejected,
        (state, action) => {
          state.registrationAuthorization.message = action.payload as string;
          state.registrationAuthorization.isLoading = false;
          state.registrationAuthorization.isError = true;
          state.registrationAuthorization.hasCheckedRegistrationAuthorization =
            true;
        },
      );

    // createAgencyProfile
    builder
      .addCase(createAgencyProfile.pending, (state) => {
        state.agencyProfile.isError = false;
        state.agencyProfile.isLoading = true;
      })
      .addCase(createAgencyProfile.fulfilled, (state, action) => {
        state.agencyProfile.message = "complete";
        state.agencyProfile.isLoading = false;
        state.agencyProfile.isError = false;
        state.agencyProfile.isAgencyProfileComplete = true;
      })
      .addCase(createAgencyProfile.rejected, (state, action) => {
        state.agencyProfile.message = "incomplete";
        state.agencyProfile.isLoading = false;
        state.agencyProfile.isError = true;
        state.agencyProfile.isAgencyProfileComplete = false;
      });

    // checkAgencyRegistration
    builder
      .addCase(checkAgencyRegistration.pending, (state) => {
        state.agencyProfile.isError = false;
        state.agencyProfile.isLoading = true;
      })
      .addCase(checkAgencyRegistration.fulfilled, (state, action) => {
        if ("description" in action.payload) {
          // this.hasRegisteredCRSText = res.description;
        } else {
          if ("error" in action.payload) {
            if ("description" in action.payload.error) {
              state.agencyProfile.isAgencyProfileComplete = true;
              state.agencyProfile.message = action.payload.error.description;
            } else {
              state.agencyProfile.isAgencyProfileComplete = false;
              state.agencyProfile.message = "unknown error";
            }
          } else if ("payload" in action.payload) {
            if ("description" in action.payload.payload) {
              if (
                action.payload.payload.description === "registration confirmed"
              ) {
                state.agencyProfile.isAgencyProfileComplete = true;
                state.agencyProfile.message =
                  action.payload.payload.description;
              } else {
                state.agencyProfile.isAgencyProfileComplete = false;
                state.agencyProfile.message =
                  action.payload.payload.description;
              }
            } else {
              state.agencyProfile.isAgencyProfileComplete = false;
              state.agencyProfile.message = "unknown error";
            }
          } else {
            state.agencyProfile.isAgencyProfileComplete = false;
            state.agencyProfile.message = "unknown error";
          }
        }
        //check error

        state.agencyProfile.isLoading = false;
        state.agencyProfile.isError = false;
        state.agencyProfile.hasCheckedAgencyProfile = true;
      })
      .addCase(checkAgencyRegistration.rejected, (state, action) => {
        state.agencyProfile.message = action.payload as string;
        state.agencyProfile.isLoading = false;
        state.agencyProfile.isError = true;
        state.agencyProfile.isAgencyProfileComplete = false;
        state.agencyProfile.hasCheckedAgencyProfile = true;
      });

    // getLastEnteredAgentEmail
    builder

      .addCase(getLastEnteredAgentEmail.fulfilled, (state, action) => {
        if ("status" in action.payload) {
          if (action.payload["status"] === "success") {
            state.registrationAuthorization.lastEnteredAgentEmail =
              action.payload.agent_email;
          } else {
            if ("agent_email" in action.payload) {
              state.registrationAuthorization.lastEnteredAgentEmail =
                action.payload.agent_email;
            }
            if ("message" in action.payload) {
              state.registrationAuthorization.message =
                action.payload?.code === "ERR_MAX_ATTEMPTS"
                  ? "incomplete"
                  : (state.registrationAuthorization.message =
                      action.payload["message"]);
            }
            if ("code" in action.payload) {
              if (action.payload["code"] === "ERR_MAX_ATTEMPTS") {
                state.registrationAuthorization.isAtMaxChangeAgentEmailAttempts =
                  true;
              }
            }
          }
        }
      })
      .addCase(getLastEnteredAgentEmail.rejected, (state, action) => {
        state.registrationAuthorization.isError = true;
        state.registrationAuthorization.message = "unknown error";
      });

    // GetAgentProfile
    builder
      .addCase(getAgencyRegistration.pending, (state) => {
        state.agencyProfile.isLoading = true;
        state.agencyProfile.isError = false;
        state.agencyProfile.message = "Loading....";
      })
      .addCase(getAgencyRegistration.fulfilled, (state, action) => {
        state.agencyProfile.isLoading = false;
        state.agencyProfile.isError = false;
        state.agencyProfile.agent_profile =
          action.payload.payload.agent_profile;
        state.agencyProfile.agency_profile =
          action.payload.payload.agency_profile;
      })
      .addCase(getAgencyRegistration.rejected, (state, action) => {
        state.agencyProfile.isLoading = false;
        state.agencyProfile.isError = true;
        state.agencyProfile.agent_profile = null;
        state.agencyProfile.agency_profile = null;
        state.agencyProfile.message = action.payload as string;
      });

    // sendAgentAuthorizationEmail
    builder
      .addCase(sendAgentAuthorizationEmail.fulfilled, (state, action) => {
        if ("status" in action.payload) {
          if (action.payload["status"] === "success") {
            state.registrationAuthorization.lastEnteredAgentEmail =
              action.payload.agent_email;
          } else {
            if ("agent_email" in action.payload) {
              state.registrationAuthorization.lastEnteredAgentEmail =
                action.payload.agent_email;
            }
            if ("message" in action.payload) {
              state.registrationAuthorization.message =
                action.payload?.code === "ERR_MAX_ATTEMPTS"
                  ? "incomplete"
                  : (state.registrationAuthorization.message =
                      action.payload["message"]);
            }
            if ("code" in action.payload) {
              if (action.payload["code"] === "ERR_MAX_ATTEMPTS") {
                state.registrationAuthorization.isAtMaxChangeAgentEmailAttempts =
                  true;
              }
            }
          }
        }
      })
      .addCase(sendAgentAuthorizationEmail.rejected, (state, action) => {
        state.registrationAuthorization.isError = true;
        state.registrationAuthorization.message = "unknown error";
      });

    // getPermissions
    builder

      .addCase(getPermissions.fulfilled, (state, action) => {
        state.scopes = action.payload;
      })
      .addCase(getPermissions.rejected, (state, action) => {
        state.scopes = [];
      });

    builder

      .addCase(alertAccountDeletionRequest.fulfilled, (state, action) => {
        state.accountDeletion.message =
          "Account deletion request submitted. Account will be deleted within 45 days.";
        state.accountDeletion.isLoading = false;
        state.accountDeletion.isError = false;
        state.accountDeletion.isSuccess = true;
      })
      .addCase(alertAccountDeletionRequest.pending, (state) => {
        state.accountDeletion.message = "Processing request...";
        state.accountDeletion.isLoading = true;
        state.accountDeletion.isError = false;
        state.accountDeletion.isSuccess = false;
      })
      .addCase(alertAccountDeletionRequest.rejected, (state, action) => {
        state.accountDeletion.message = action.payload as string;
        state.accountDeletion.isLoading = false;
        state.accountDeletion.isError = true;
        state.accountDeletion.isSuccess = false;
      });

    builder

      .addCase(
        alertAccountDeletionRequestNonAuthenticated.fulfilled,
        (state, action) => {
          state.accountDeletion.message =
            "Account deletion request submitted. Account will be deleted within 45 days.";
          state.accountDeletion.isLoading = false;
          state.accountDeletion.isError = false;
          state.accountDeletion.isSuccess = true;
        },
      )
      .addCase(alertAccountDeletionRequestNonAuthenticated.pending, (state) => {
        state.accountDeletion.message = "Processing request...";
        state.accountDeletion.isLoading = true;
        state.accountDeletion.isError = false;
        state.accountDeletion.isSuccess = false;
      })
      .addCase(
        alertAccountDeletionRequestNonAuthenticated.rejected,
        (state, action) => {
          state.accountDeletion.message = action.payload as string;
          state.accountDeletion.isLoading = false;
          state.accountDeletion.isError = true;
          state.accountDeletion.isSuccess = false;
        },
      );
  },
});

export const {
  reset,
  setUserType,
  setAgentAuthorizedProducer,
  resetAccountDeletionStatus,
} = authSlice.actions;
export default authSlice.reducer;
