import { LGMCommodityType } from "@agdeveloper/domain";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/redux/hooks";
import {
  setLGMDairyMarketingPlan,
  setLGMCornMarketingPlan,
  setLGMSoybeanMarketingPlan,
} from "../../features/LGMSlice";

// const defaultMarketingPlan = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
const soybeanDefaultValue = 0.002;
const cornDefaultValue = 0.014;
const soybeanMinValue = 0.000805;
const cornMinValue = 0.00364;
const LGMDairyCattleMarketingPlan = () => {
  const dispatch = useAppDispatch();

  const { milk_marketing_plan, dairy_corn_meal_feed, dairy_soybean_meal_feed } =
    useAppSelector((state) => state.lgm.input);
  const { target_months } = useAppSelector((state) => state.lgm);
  const { lgm_quote_results, monthly_gross_margins, loading, error } =
    useAppSelector((state) => state.lgm.lgmQuoteResults);

  const [staticMilkMarketingPlan, setStaticMilkMarketingPlan] =
    useState<any>(null);

  useEffect(() => {
    if (lgm_quote_results) {
      setStaticMilkMarketingPlan(
        [2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => {
          return lgm_quote_results[0]["quote_params"]["user_input"][
            `month_${month}_marketed`
          ];
        }),
      );
    }
  }, [lgm_quote_results, monthly_gross_margins]);

  const updateMarketingPlan = (month, marketed) => {
    dispatch(
      setLGMDairyMarketingPlan({
        ...milk_marketing_plan,
        [month]: Math.round(marketed * 100) / 100,
      }),
    );
  };
  const updateCornMarketingPlan = (month, marketed) => {
    dispatch(
      setLGMCornMarketingPlan({
        ...dairy_corn_meal_feed,
        [month]: Math.round(marketed * 100) / 100,
      }),
    );
  };

  const updateSoybeanMarketingPlan = (month, marketed) => {
    dispatch(
      setLGMSoybeanMarketingPlan({
        ...dairy_soybean_meal_feed,
        [month]: marketed,
      }),
    );
  };

  const _populateDefaultFeedValues = () => {
    let tempCornMarketingPlan = { ...dairy_corn_meal_feed };
    let tempSoybeanMarketingPlan = { ...dairy_soybean_meal_feed };

    for (let [month, marketed] of Object.entries(milk_marketing_plan)) {
      let milk_marketed = marketed as number;

      tempCornMarketingPlan[month] =
        Math.round(cornDefaultValue * milk_marketed * 1000) / 1000;
      tempSoybeanMarketingPlan[month] =
        Math.round(soybeanDefaultValue * milk_marketed * 1000) / 1000;

      dispatch(
        setLGMCornMarketingPlan({
          ...dairy_corn_meal_feed,
          ...tempCornMarketingPlan,
        }),
      );

      dispatch(
        setLGMSoybeanMarketingPlan({
          ...dairy_soybean_meal_feed,
          ...tempSoybeanMarketingPlan,
        }),
      );
    }
  };

  const _populateMinimumFeedValues = () => {
    let tempCornMarketingPlan = { ...dairy_corn_meal_feed };
    let tempSoybeanMarketingPlan = { ...dairy_soybean_meal_feed };
    for (let [month, marketed] of Object.entries(milk_marketing_plan)) {
      let milk_marketed = marketed as number;
      if (milk_marketed !== 0) {
        tempCornMarketingPlan[month] =
          Math.round(cornMinValue * milk_marketed * 1000 + 0.5) / 1000;
        tempSoybeanMarketingPlan[month] =
          Math.round(soybeanMinValue * milk_marketed * 1000 + 0.5) / 1000;
      } else {
        tempCornMarketingPlan[month] = 0;
        tempSoybeanMarketingPlan[month] = 0;
      }
    }
    dispatch(
      setLGMCornMarketingPlan({
        ...dairy_corn_meal_feed,
        ...tempCornMarketingPlan,
      }),
    );

    dispatch(
      setLGMSoybeanMarketingPlan({
        ...dairy_soybean_meal_feed,
        ...tempSoybeanMarketingPlan,
      }),
    );
  };

  return (
    <div className="card w-100 my-2">
      <div className="card-body">
        <h5 className="card-title">Dairy Marketing Plan</h5>
        <p className="card-text">
          Enter the amount of milk marketed each month
        </p>
        <div className="table-responsive">
          <table className="table table-fit">
            <thead>
              <tr>
                <th></th>
                {target_months.map((month) => (
                  <th scope="col" key={month}>
                    {month}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" style={{ maxWidth: "12em" }}>
                  Milk Marketed (Hundredweights):
                </th>

                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((idx) => (
                  <td key={idx}>
                    <input
                      value={milk_marketing_plan[target_months[idx]]}
                      onChange={(e) =>
                        updateMarketingPlan(target_months[idx], e.target.value)
                      }
                      type="number"
                      style={{ maxWidth: "6em" }}
                    />
                  </td>
                ))}
              </tr>

              <tr>
                <th scope="row">Corn Equivalent Fed (Tons):</th>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((idx) => (
                  <td key={idx}>
                    <input
                      value={dairy_corn_meal_feed[target_months[idx]]}
                      onChange={(e) =>
                        updateCornMarketingPlan(
                          target_months[idx],
                          e.target.value,
                        )
                      }
                      type="number"
                      style={{ maxWidth: "6em" }}
                    />
                  </td>
                ))}
              </tr>

              <tr>
                <th scope="row">Soybean Meal Equivalent Fed (Tons):</th>

                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((idx) => (
                  <td key={idx}>
                    <input
                      value={dairy_soybean_meal_feed[target_months[idx]]}
                      onChange={(e) =>
                        updateSoybeanMarketingPlan(
                          target_months[idx],
                          e.target.value,
                        )
                      }
                      type="number"
                      style={{ maxWidth: "6em" }}
                    />
                  </td>
                ))}
              </tr>

              {lgm_quote_results &&
                lgm_quote_results[0]["quote_params"]["user_input"][
                  "commodity_type"
                ]["value"] === LGMCommodityType.DAIRY_CATTLE &&
                !error && (
                  <tr>
                    <th>
                      Expected Gross Margins: <br />
                      (Per CWT)
                    </th>
                    {monthly_gross_margins &&
                      monthly_gross_margins.map((item, index) => {
                        return loading ? (
                          <td style={{ textAlign: "center" }} key={index}>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <br />
                            <span>Loading...</span>
                          </td>
                        ) : (
                          <td key={index}>
                            <div style={{ maxWidth: "6em", textAlign: "left" }}>
                              {`$${item.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`}
                            </div>
                            <div style={{ maxWidth: "6em", textAlign: "left" }}>
                              {staticMilkMarketingPlan &&
                              staticMilkMarketingPlan[index] > 0
                                ? `$${(
                                    item / staticMilkMarketingPlan[index]
                                  ).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}`
                                : "-"}
                            </div>
                          </td>
                        );
                      })}
                  </tr>
                )}
              <tr>
                <th scope="row">Actions:</th>
                <td colSpan={2}>
                  <button
                    onClick={() => _populateDefaultFeedValues()}
                    type="button"
                    className="btn btn-primary"
                  >
                    RMA Default Feed Values
                  </button>
                </td>
                <td colSpan={2}>
                  <button
                    onClick={() => _populateMinimumFeedValues()}
                    type="button"
                    className="btn btn-primary"
                  >
                    Minimum Feed Values
                  </button>
                </td>
                <td colSpan={4}></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default LGMDairyCattleMarketingPlan;
