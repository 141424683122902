import React, { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import { getAuthorizedAgentsList } from "../features/adminActions";

import "../../Authentication/styles/authStyles.scss";

// const formInitalValidity = {
//   producer_email: {
//     valid: false,
//     message: null,
//     touched: false,
//     required: true,
//   },
//   agent_email: {
//     valid: false,
//     message: null,
//     touched: false,
//     required: true,
//   },
//   password: {
//     valid: false,
//     messages: null,
//     touched: false,
//     required: true,
//   },
// };
function AuthorizedAgentsList() {
  const dispatch = useAppDispatch();

  const { authorizedEmails } = useAppSelector(
    (state) => state.admin.agentAuthorizationList,
  );
  useEffect(() => {
    const _refresh = () => {
      dispatch(getAuthorizedAgentsList());
    };
    _refresh();
  }, [dispatch]);

  return (
    <div className="mt-4">
      <h5>List of Authorized E-mails</h5>
      <hr className="mb-4" />
      <ul className="list-group">
        {authorizedEmails.map((email) => (
          <li className="list-group-item" key={email}>
            {email}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AuthorizedAgentsList;
