import React from "react";
import useCheckPermsOnLocationChange from "./app/utils/hooks/useCheckPermsOnLocationChange";
import "./App.scss";
function NavWrapper({ children, isOpen }) {
  //Custom hook that must be called within our Router component
  useCheckPermsOnLocationChange();
  return (
    <div
      className={`mx-auto content-wrapper pb-4 ${isOpen ? "open" : "closed"}`}
      style={{ paddingLeft: isOpen ? "300px" : "78px" }}
    >
      {children}
    </div>
  );
}
export default NavWrapper;
