import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/redux/hooks";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { getAllHedgemapInfoForAgent } from "../../../../../ProducerViewHedgeMAPs/features/producerHedgeMAPsActions";
type EditHedgeMAPVisibilityTable = {
  changesMade: boolean;
  setSelectedPolicyProducer: (value: any) => void;
  setSelectedUser: (value: string) => void;
  setShowEditPermissionsModal: (value: boolean) => void;
  handleUnlinkShow: Function;
};
type FarmInfo = {
  [emailAddress: string]: any;
};

type TableData = {
  [policyProducerAggregate: string]: FarmInfo;
};
const EditHedgeMAPVisibilityTable = (props: EditHedgeMAPVisibilityTable) => {
  const {
    changesMade,
    setSelectedPolicyProducer,
    setSelectedUser,
    setShowEditPermissionsModal,
    handleUnlinkShow,
  } = props;

  const dispatch = useAppDispatch();

  // useEffect(() => {
  //   dispatch(getAllHedgemapInfoForAgent({ by_user: true }));
  // }, []);

  const {
    data: tableData,
    isError,
    isLoading,
    message,
  } = useAppSelector((state) => state.producerHedgeMAPs.agentsAssociatedData);

  const { hedgemapData } = useAppSelector(
    (state) => state.producerHedgeMAPs.agentAuthorization,
  );
  const { userData } = useAppSelector(
    (state) => state.producerHedgeMAPs.agentAuthorizeProducerUser,
  );

  const userDataTracker = useRef([...userData].sort());
  const hedgeMAPDataTracker = useRef([...hedgemapData].sort());

  useEffect(() => {
    const shouldDispatch =
      userDataTracker.current.length !== userData.length ||
      !userDataTracker.current.every(
        (value, index) => value === [...userData].sort()[index],
      ) ||
      hedgeMAPDataTracker.current.length !== hedgemapData.length ||
      !hedgeMAPDataTracker.current.every(
        (value, index) => value === [...hedgemapData].sort()[index],
      );

    if (shouldDispatch) {
      dispatch(getAllHedgemapInfoForAgent({ by_user: true }));

      userDataTracker.current = [...userData].sort();
      hedgeMAPDataTracker.current = [...hedgemapData].sort();
    }
  }, [hedgemapData, userData]);

  const [showChangesModal, setShowChangesModal] = useState(false);

  const handleCloseChangesModal = () => setShowChangesModal(false);
  const handleShowChangesModal = () => setShowChangesModal(true);

  const [savedProducerInfo, setSavedProducerInfo] = useState(
    {} as Record<string, string>,
  );

  const prepShowChangesModal = (policyProducerAggregate, emailAddress) => {
    setSavedProducerInfo({
      policyProducerAggregate: policyProducerAggregate,
      emailAddress: emailAddress,
    });
    handleShowChangesModal();
  };

  const _handleSelections = (policyProducerAggregatedName, emailAddress) => {
    setSelectedPolicyProducer({
      DisplayName: policyProducerAggregatedName,
      PolicyProducerAggregateID:
        tableData[emailAddress][policyProducerAggregatedName][
          "PolicyProducerAggregateID"
        ],
    });
    setSelectedUser(emailAddress);
    setShowEditPermissionsModal(true);
  };
  return (
    <div className="mt-5">
      {!isError ? (
        <div>
          {isLoading ? (
            <div
              className="alert alert-secondary"
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
              role="alert"
            >
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>{" "}
              Loading table...
            </div>
          ) : (
            <div className="mt-5">
              {Object.entries(tableData).length > 0 && (
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Email Address</th>
                      <th>Display Name</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* mainIdentifier = email or farm name */}
                    {Object.entries(tableData).map(
                      ([emailAddress, farmInfo]) => {
                        return Object.entries(farmInfo as FarmInfo).map(
                          ([policyProducerAggregate], index) => {
                            return (
                              <tr>
                                {index === 0 && (
                                  <td
                                    rowSpan={
                                      Object.values(farmInfo as FarmInfo).length
                                    }
                                    style={{ verticalAlign: "middle" }}
                                  >
                                    {emailAddress}
                                  </td>
                                )}
                                <td>{policyProducerAggregate}</td>
                                <td style={{ verticalAlign: "middle" }}>
                                  <div className="row">
                                    <div
                                      className="col-6 d-flex justify-content-end align-items-center"
                                      style={{
                                        borderRight: "1px solid gainsboro",

                                        height: "100%",
                                      }}
                                    >
                                      <button
                                        className="btn btn-link p-0"
                                        onClick={() =>
                                          changesMade
                                            ? prepShowChangesModal(
                                                policyProducerAggregate,
                                                emailAddress,
                                              )
                                            : _handleSelections(
                                                policyProducerAggregate,
                                                emailAddress,
                                              )
                                        }
                                      >
                                        View Types
                                      </button>
                                    </div>
                                    <div className="col-6 d-flex justify-content-center align-items-center">
                                      <i
                                        className="bx bx-trash float-right"
                                        onClick={() => {
                                          const hedgemap_info = {
                                            DisplayName:
                                              policyProducerAggregate,
                                            PolicyProducerAggregateID: (
                                              farmInfo as FarmInfo
                                            )[policyProducerAggregate][
                                              "PolicyProducerAggregateID"
                                            ],
                                          };
                                          const payload = {
                                            authorized_email: emailAddress,
                                            hedgemap_info: hedgemap_info,
                                          };
                                          handleUnlinkShow(payload);
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          },
                        );
                      },
                    )}
                  </tbody>
                </table>
              )}
            </div>
          )}
        </div>
      ) : (
        <div>{message}</div>
      )}
      {/* Modal */}
      <Modal show={showChangesModal} onHide={handleCloseChangesModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Discard Changes?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You currently have unsaved HedgeMAP visibility changes for a
            producer. To save your current changes click Cancel then Save
            Changes else click Cancel
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              //handle the changes anyway
              _handleSelections(
                savedProducerInfo.policyProducerAggregate,
                savedProducerInfo.emailAddress,
              );
              handleCloseChangesModal();
            }}
          >
            Discard Changes
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              //noop

              handleCloseChangesModal();
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditHedgeMAPVisibilityTable;
