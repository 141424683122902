import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../../../../app/redux/hooks";
import "./LRPQuoteResultRow.scss";
function LRPQuoteResultRow({ quote, isPerHead }) {
  // console.log(quote);
  const { forecastPrice } = useAppSelector((state) => state.lrp);
  const [indemnity, setIndemnity] = useState<number>(0);
  const [netToProducer, setNetToProducer] = useState<number>(0);

  const formatCoverage = (coverage_level: number) => {
    return (coverage_level * 100).toFixed(2);
  };

  useEffect(() => {
    const updateIndemnity = () => {
      let _indemnity =
        (quote.quote_params.coverage_price - forecastPrice) *
        quote.quote_params.user_input.target_weight *
        quote.quote_params.user_input.number_of_head;

      if (_indemnity < 0) {
        _indemnity = 0;
      }

      let _net_to_producer = _indemnity - quote.producer_premium_amount;

      setIndemnity(_indemnity);
      setNetToProducer(_net_to_producer);
    };
    updateIndemnity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forecastPrice]);

  const _getClassName = (value) => {
    if (value > 0) {
      return `${"positive_val"}`;
    } else if (value < 0) {
      return `${"negative_val"}`;
    } else {
      return `${"neutral_val"}`;
    }
  };

  return (
    <tr>
      {/* Coverage Level Percent */}
      <td>{formatCoverage(quote.coverage_level_percent)}%</td>
      {/* Coverage Price */}
      <td>${quote.quote_params.coverage_price.toFixed(2)}</td>
      <td>
        {/* Producer Premium (Per Head/Per CWT) */}
        {/* $109 */}
        {`$${quote.producer_premium_amount.toLocaleString("en")}`}
        <br />(
        {isPerHead
          ? `$${Math.abs(
              quote.producer_premium_amount /
                quote.quote_params.user_input.number_of_head,
            ).toFixed(2)}`
          : `$${Math.abs(
              quote.producer_premium_amount /
                quote.quote_params.user_input.number_of_head /
                quote.quote_params.user_input.target_weight,
            ).toFixed(2)}`}
        )
      </td>
      {/* <td className={_getClassName(netToProducer)}> */}
      <td>
        {/* Indemnity (Per Head/Per CWT) */}
        {`$${indemnity.toLocaleString("en", {
          minimumFractionDigits: 2,
        })}`}
        <br />(
        {isPerHead
          ? `$${Math.abs(
              indemnity / quote.quote_params.user_input.number_of_head,
            ).toFixed(2)}`
          : `$${Math.abs(
              indemnity /
                quote.quote_params.user_input.number_of_head /
                quote.quote_params.user_input.target_weight,
            ).toFixed(2)}`}
        ){/* ($0.00) */}
      </td>
      <td className={_getClassName(netToProducer)}>
        {/* Expected Net to Producer (Per Head/Per CWT) */}
        {/* $108.9 */}
        {`$${Math.abs(netToProducer).toLocaleString("en", {
          minimumFractionDigits: 2,
        })} `}
        <br />(
        {isPerHead
          ? `$${Math.abs(
              netToProducer / quote.quote_params.user_input.number_of_head,
            ).toFixed(2)}`
          : `$${Math.abs(
              netToProducer /
                quote.quote_params.user_input.number_of_head /
                quote.quote_params.user_input.target_weight,
            ).toFixed(2)}`}
        )
      </td>
    </tr>
  );
}

export default LRPQuoteResultRow;
