import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/redux/hooks";
import { unlinkProducerAggregatedToUser } from "../../../../../ProducerViewHedgeMAPs/features/producerHedgeMAPsActions";
type SelectPolicyProducerProps = {
  policyProducerList: string[];
  selectedPolicyProducer: any;
  setSelectedPolicyProducer: (value: string) => void;
  selectedUser: string;
  handleShow: Function;
  handleUnlinkShow: Function;
};

const SelectPolicyProducer = (props: SelectPolicyProducerProps) => {
  const {
    policyProducerList,
    selectedPolicyProducer,
    setSelectedPolicyProducer,
    selectedUser,
    handleShow,
    handleUnlinkShow,
  } = props;

  const dispatch = useAppDispatch();
  const {
    isLoading: isDeletionLoading,
    // message,
    // isError,
  } = useAppSelector(
    (state) => state.producerHedgeMAPs.unlinkProducersFromUsers,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [unlinkingTarget, setUnlinkingTarget] = useState("");

  useEffect(() => {
    setIsLoading(true);
  }, [selectedUser]);

  useEffect(() => {
    setIsLoading(false);
  }, [policyProducerList]);

  return (
    <>
      {isLoading ? (
        <div className="col-4">
          <div className="list-group">
            <div className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
              Loading...
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-4">
          {policyProducerList && policyProducerList.length > 0 ? (
            <div className="list-group">
              {policyProducerList.map((obj: any, index) => (
                <div
                  key={index}
                  style={{ cursor: "pointer" }}
                  className={`list-group-item list-group-item-action d-flex justify-content-between align-items-center ${
                    selectedPolicyProducer &&
                    selectedPolicyProducer.DisplayName === obj.DisplayName
                      ? "active"
                      : ""
                  }`}
                  onClick={() => {
                    setSelectedPolicyProducer(obj);
                  }}
                >
                  {obj.DisplayName}
                  {isDeletionLoading && obj.DisplayName === unlinkingTarget ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <i
                      className="bx bx-trash float-right"
                      onClick={() => {
                        setUnlinkingTarget(obj.DisplayName);
                        const hedgemap_info = {
                          DisplayName: obj.DisplayName,
                          PolicyProducerAggregateID:
                            obj.PolicyProducerAggregateID,
                        };
                        const payload = {
                          authorized_email: selectedUser,
                          hedgemap_info: hedgemap_info,
                        };
                        handleUnlinkShow(payload);
                      }}
                    ></i>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="custom-list-box overflow-auto p-3 border rounded bg-white">
              {selectedUser
                ? "This user is not currently authorized to view any Policy Producer HedgeMAPs. To authorize, click the 'Add Aggregate' button below."
                : "Please select a user from the dropdown on the left to view their HedgeMAP authorizations."}
            </div>
          )}

          {selectedUser && (
            <div className="mt-4 d-flex justify-content-center align-items-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleShow();
                }}
              >
                Add Aggregate
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SelectPolicyProducer;
