import React, { useRef, useEffect } from "react";

function CoverageSelectionDropdown({
  drpInputsChanged,
  drpQuoteResults,
  salesEffectiveDate,
  selectedCoverage,
  coverage_levels,
  handleSubmit,
}) {
  // FIXME:
  // 1. When moving to another decision tool and coming back, dropdown
  // disappears/sticks on first click of button.

  const dropdownRef: React.MutableRefObject<any> = useRef(null);
  const handleClick = () => {
    if (dropdownRef.current) {
      dropdownRef.current.className = "dropdown-menu show";
    }
  };

  useEffect(() => {
    if (dropdownRef.current) {
      dropdownRef.current.className = "dropdown-menu";
    }
  }, [handleSubmit]);

  useEffect(() => {
    if (dropdownRef.current) {
      dropdownRef.current.className = "dropdown-menu";
    }
  }, []);

  return (
    <div>
      <span className="dropdown">
        <button
          className={`btn btn-secondary dropdown-toggle ${
            drpInputsChanged && "btn-ag-warning"
          }`}
          type="button"
          id="dropdownMenuButton"
          aria-haspopup="true"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          disabled={drpQuoteResults.loading || salesEffectiveDate.length === 0}
          onClick={handleClick}
        >
          {drpQuoteResults.loading ? (
            // Show loading spinner if results are pending
            <>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span> Loading... </span>
            </>
          ) : (
            // Otherwise show selected coverage percentage
            selectedCoverage
          )}
        </button>
        <ul
          className="dropdown-menu"
          aria-labelledby="dropdownMenuButton"
          defaultValue={selectedCoverage}
          ref={dropdownRef}
        >
          {coverage_levels.map((level, index) => {
            return (
              <li
                key={index}
                className="dropdown-item"
                onClick={() => handleSubmit(level)}
                style={{ cursor: "pointer" }}
              >
                {`${level * 100}% coverage`}
              </li>
            );
          })}
        </ul>
      </span>
      {drpInputsChanged && (
        <span
          className="display-inlint-block ms-3"
          style={{
            fontStyle: "italic",
            color: "rgb(142, 39, 54)",
          }}
        >
          Inputs changed. Please select a coverage level to recalculate.
        </span>
      )}
    </div>
  );
}

export default CoverageSelectionDropdown;
