/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { createAgencyProfile } from "../../features/authActions";
import { reset } from "../../features/authSlice";
import authFormValidator from "../../utils/authFormValidator";
import useMounted from "../../../app/utils/hooks/useMounted";
import "../../styles/authStyles.scss";
import FormInput from "../utility/FormInput";

const initalFormValidityState = {
  agent: {
    email: {
      valid: true,
      message: null,
      touched: false,
      required: true,
    },
    first_name: {
      valid: false,
      message: null,
      touched: false,
      required: true,
    },
    last_name: {
      valid: false,
      message: null,
      touched: false,
      required: true,
    },
    mobile_phone: {
      valid: true,
      message: null,
      touched: false,
      required: false,
    },
    mobile_carrier: {
      valid: true,
      message: null,
      touched: false,
      required: false,
    },
  },
  agency: {
    agency_name: {
      valid: false,
      message: null,
      touched: false,
      required: true,
    },
    agency_email: {
      valid: false,
      message: null,
      touched: false,
      required: true,
    },
    agency_primary_phone: {
      valid: false,
      message: null,

      touched: false,
      required: true,
    },
    agency_secondary_phone: {
      valid: true,
      message: null,
      touched: false,
      required: false,
    },
  },
};

const mobile_carriers = [
  "AT&T",
  "Boost Mobile",
  "Cricket Wireless",
  "Sprint",
  "T-Mobile",
  "US Cellular",
  "Verizon",
  "Virgin Mobile",
  "Other Carrier",
];
function AgencyRegistration() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMounted = useMounted();

  const { email, authentication } = useAppSelector((state) => state.auth);

  const [formData, setFormData] = useState({
    agent: {
      email: email ? email : "",
      first_name: "",
      last_name: "",
      mobile_phone: "",
      mobile_carrier: "Select carrier...",
    },
    agency: {
      agency_name: "",
      agency_email: "",
      agency_primary_phone: "",
      agency_secondary_phone: "",
    },
  });

  // const [errorMessage, setErrorMessage] = useState<string>();
  const [disabled, setDisabled] = useState(true);
  const [formValidity, setFormValidity] = useState<any>(
    initalFormValidityState,
  );

  // Reset on navigate
  useEffect(() => {
    dispatch(reset());
    setFormValidity(initalFormValidityState);
  }, [navigate, dispatch]);

  const onChangeAgent = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      agent: {
        ...prevState.agent,
        [e.target.name]: e.target.value,
      },
    }));
    setFormValidity((prevState) => ({
      ...prevState,
      agent: {
        ...prevState.agent,
        [e.target.name]: {
          ...prevState.agent[e.target.name],
          touched: true,
        },
      },
    }));
  };
  const onChangeAgency = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      agency: {
        ...prevState.agency,
        [e.target.name]: e.target.value,
      },
    }));
    setFormValidity((prevState) => ({
      ...prevState,
      agency: {
        ...prevState.agency,
        [e.target.name]: {
          ...prevState.agency[e.target.name],
          touched: true,
        },
      },
    }));
  };
  //Validate Form when it changes
  useEffect(() => {
    if (isMounted) {
      setFormValidity((prevState) => ({
        ...prevState,
        agent: {
          ...prevState.agent,
          email: {
            ...prevState.agent.email,
            ...authFormValidator.emailValidator(formData.agent.email),
          },
          first_name: {
            ...prevState.agent.first_name,
            ...authFormValidator.nameValidator(formData.agent.first_name),
          },
          last_name: {
            ...prevState.agent.last_name,
            ...authFormValidator.nameValidator(formData.agent.last_name),
          },
          mobile_phone: {
            ...prevState.agent.mobile_phone,
            ...authFormValidator.phoneValidator(
              formData.agent.mobile_phone,
              true,
            ),
          },
          mobile_carrier: {
            ...prevState.agent.mobile_carrier,
            ...authFormValidator.mobileCarrierValidator(
              formData.agent.mobile_carrier,
              formData.agent.mobile_phone,
            ),
          },
        },
        agency: {
          ...prevState.agency,
          agency_name: {
            ...prevState.agency.agency_name,
            ...authFormValidator.nameValidator(formData.agency.agency_name),
          },
          agency_email: {
            ...prevState.agency.agency_email,
            ...authFormValidator.emailValidator(formData.agency.agency_email),
          },
          agency_primary_phone: {
            ...prevState.agency.agency_primary_phone,
            ...authFormValidator.phoneValidator(
              formData.agency.agency_primary_phone,
            ),
          },
          agency_secondary_phone: {
            ...prevState.agency.agency_secondary_phone,
            ...authFormValidator.phoneValidator(
              formData.agency.agency_secondary_phone,
              true,
            ),
          },
        },
      }));
    }
  }, [formData, isMounted]);

  useEffect(() => {
    setDisabled(
      !Object.values({
        ...formValidity.agency,
        ...formValidity.agent,
      }).every((entry: any) => entry.valid),
    );
  }, [formValidity]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      Object.values({
        ...formValidity.agency,
        ...formValidity.agent,
      }).every((entry: any) => entry.valid)
    ) {
      const agencyData = {
        agencyName: formData.agency.agency_name,
        agencyEmail: formData.agency.agency_email,
        primaryPhone: formData.agency.agency_primary_phone,
        secondaryPhone: formData.agency.agency_secondary_phone,
      };

      const agencyPrimaryContactData = {
        firstName: formData.agent.first_name,
        lastName: formData.agent.last_name,
        emailAddress: formData.agent.email,
        mobilePhone: formData.agent.mobile_phone,
        mobileCarrier: formData.agent.mobile_carrier,
      };

      dispatch(
        createAgencyProfile({
          agencyData: agencyData,
          agencyPrimaryContactData: agencyPrimaryContactData,
        }),
      );
    } else {
      // setErrorMessage("Please correct the errors above");
    }
  };

  return (
    <div>
      <div className="alert alert-dark m-2 p-4">
        <h4 className="alert-heading">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-exclamation-triangle-fill mx-1"
              viewBox="0 0 16 16"
            >
              <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
            </svg>
            Account Information Incomplete
          </span>
        </h4>
        <p>
          You have not yet completed your Account Information. Please complete
          your Agent and Agency Information (located below) in order to access
          the portal.
        </p>
      </div>
      <div className="row">
        <div className="card mx-auto auth-card col-sm-5">
          <div className="card-body">
            <div className="text-center">
              <h5 className="card-title mb-4 px-3">Agent Information</h5>
            </div>
            <div className="">
              <form onSubmit={onSubmit}>
                <FormInput
                  label={"Email Address"}
                  type={"email"}
                  onChange={onChangeAgent}
                  name={"register_email"}
                  value={formData.agent.email}
                  touched={formValidity.agent.email.touched}
                  message={formValidity.agent.email.message}
                  required={false}
                  id={"register_email"}
                />

                <FormInput
                  label={"First Name"}
                  type={"text"}
                  onChange={onChangeAgent}
                  name={"first_name"}
                  value={formData.agent.first_name}
                  touched={formValidity.agent.first_name.touched}
                  message={formValidity.agent.first_name.message}
                  required={true}
                  id={"first_name"}
                />

                <FormInput
                  label={"Last Name"}
                  type={"text"}
                  onChange={onChangeAgent}
                  name={"last_name"}
                  value={formData.agent.last_name}
                  touched={formValidity.agent.last_name.touched}
                  message={formValidity.agent.last_name.message}
                  required={true}
                  id={"last_name"}
                />

                <FormInput
                  label={"Mobile Phone"}
                  type={"text"}
                  onChange={onChangeAgent}
                  name={"mobile_phone"}
                  value={formData.agent.mobile_phone}
                  touched={formValidity.agent.mobile_phone.touched}
                  message={formValidity.agent.mobile_phone.message}
                  required={false}
                  id={"mobile_phone"}
                />

                <div className="form-group text-left small ">
                  <label htmlFor="password2" className="form-label">
                    Mobile Carrier
                  </label>
                  <div className={`dropdown pb-1`}>
                    <button
                      id="dropdownMenuButton"
                      type="button"
                      className="btn btn-secondary dropdown-toggle"
                      data-bs-toggle="dropdown"
                      // aria-expanded={showDropdown ? "true" : "false"}
                      aria-expanded="false"
                      // aria-haspopup="true"
                      // onClick={toggleDropdown}
                      style={{ width: "100%" }}
                    >
                      {formData.agent.mobile_carrier}
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {mobile_carriers.map((carrier, index) => {
                        return (
                          <li key={index}>
                            <a
                              className="dropdown-item"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                onChangeAgent({
                                  target: {
                                    name: "mobile_carrier",
                                    value: carrier,
                                  },
                                })
                              }
                            >
                              {carrier}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <p className="text-danger validation-error">
                    {formData.agent.mobile_phone.length > 0 &&
                      formValidity.agent.mobile_carrier.message}
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="card mx-auto auth-card col-sm-5">
          <div className="card-body">
            <div className="text-center">
              <h5 className="card-title mb-4 px-3">Agency Information</h5>
            </div>

            <div className="">
              <form onSubmit={onSubmit}>
                <FormInput
                  label={"Agency Name"}
                  type={"text"}
                  onChange={onChangeAgency}
                  name={"agency_name"}
                  value={formData.agency.agency_name}
                  touched={formValidity.agency.agency_name.touched}
                  message={formValidity.agency.agency_name.message}
                  required={true}
                  id={"agency_name"}
                />
                <FormInput
                  label={"Agency Email"}
                  type={"text"}
                  onChange={onChangeAgency}
                  name={"agency_email"}
                  value={formData.agency.agency_email}
                  touched={formValidity.agency.agency_email.touched}
                  message={formValidity.agency.agency_email.message}
                  required={true}
                  id={"agency_email"}
                />

                <FormInput
                  label={"Agency Primary Phone"}
                  type={"text"}
                  onChange={onChangeAgency}
                  name={"agency_primary_phone"}
                  value={formData.agency.agency_primary_phone}
                  touched={formValidity.agency.agency_primary_phone.touched}
                  message={formValidity.agency.agency_primary_phone.message}
                  required={true}
                  id={"agency_primary_phone"}
                />

                <FormInput
                  label={"Agency Secondary Phone"}
                  type={"text"}
                  onChange={onChangeAgency}
                  name={"agency_secondary_phone"}
                  value={formData.agency.agency_secondary_phone}
                  touched={formValidity.agency.agency_secondary_phone.touched}
                  message={formValidity.agency.agency_secondary_phone.message}
                  required={false}
                  id={"agency_secondary_phone"}
                />

                <div className="text-center">
                  <button
                    disabled={disabled || authentication.isLoading}
                    type="submit"
                    className="btn btn-secondary w-75"
                  >
                    {authentication.isLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span> Loading... </span>
                      </>
                    ) : (
                      <>Continue</>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgencyRegistration;
