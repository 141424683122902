import drpService from "./DRPService";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { DRPQuoteParams, DRPStripQuoteParams } from "@agdeveloper/domain";

export const getMostRecentPricesSecure = createAsyncThunk(
  "drp/getmostrecentpricessecure",
  async (state: string, thunkAPI) => {
    try {
      const response = await drpService.sendMostRecentPricesRequest(state);
      const payload = await response.data;

      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue("Unable to fetch most recent prices.");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to fetch most recent prices.");
    }
  },
);

export const getMostRecentDateSecure = createAsyncThunk(
  "drp/getmostrecentdatesecure",
  async (date: string = "", thunkAPI) => {
    try {
      const response = await drpService.sendMostRecentDateRequest(date);
      const payload = await response.data;

      if (response.status === 200) {
        // return new Date(payload.date).toISOString().split('T')[0];
        return payload.date.replaceAll("/", "-");
      } else {
        return thunkAPI.rejectWithValue("Unable to fetch most recent date.");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to fetch most recent date.");
    }
  },
);

export const updateHistoricalPrices = createAsyncThunk(
  "drp/gethistoricalprices_v1",
  async (data: any, thunkAPI) => {
    try {
      const response = await drpService.sendHistoricalPricesRequest(
        data.products,
        data.quarters,
      );
      const payload = await response.data;

      return payload;
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to get historical prices.");
    }
  },
);

export const getDRPQuoteSecure = createAsyncThunk(
  "drp/getdrpquotesecure",
  async (drp_quote_params: DRPQuoteParams, thunkAPI) => {
    try {
      const response = await drpService.sendDRPQuoteRequest(
        drp_quote_params.to_json(),
      );
      const payload = await response.data;

      if (response.status === 200) {
        // Check to see if response contains 'error' message, if so then we reject with that error message.
        if (typeof payload === "object" && "error" in payload) {
          return thunkAPI.rejectWithValue(payload.error);
        }
        return payload;
      } else {
        return thunkAPI.rejectWithValue("Unable to get drp quote.");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to get drp quote.");
    }
  },
);
export const getStripDRPQuoteSecure = createAsyncThunk(
  "drp/drp_strip_quote_secure",
  async (drp_quote_params: DRPStripQuoteParams, thunkAPI) => {
    try {
      const response = await drpService.sendStripDRPQuoteRequest(
        drp_quote_params.to_json(),
      );
      const payload = await response.data;

      if (response.status === 200) {
        // Check to see if response contains 'error' message, if so then we reject with that error message.
        if (typeof payload === "object" && "error" in payload) {
          return thunkAPI.rejectWithValue(payload.error);
        }
        return payload;
      } else {
        return thunkAPI.rejectWithValue("Unable to get drp quote.");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to get drp quote.");
    }
  },
);
export const getYields = createAsyncThunk(
  "drp/getyieldssecure",
  async (data: object, thunkAPI) => {
    try {
      const response = await drpService.sendYieldsRequest(
        data["state_abbr"],
        data["sales_effective_date"],
      );
      const payload = await response.data;

      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue("Failed to get yields data.");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to get yields data.");
    }
  },
);

export const getClassVsComponentDRPQuoteSecure = createAsyncThunk(
  "drp/drpsecure_equalize_premiums",
  async (drp_quote_params: DRPQuoteParams, thunkAPI) => {
    try {
      const response = await drpService.sendClassVsComponentDRPQuoteRequest(
        drp_quote_params.to_json(),
      );
      const payload = await response.data;

      if (response.status === 200) {
        // Check to see if response contains 'error' message, if so then we reject with that error message.
        if ("error" in payload) {
          return thunkAPI.rejectWithValue(payload.error);
        }
        return payload;
      } else {
        return thunkAPI.rejectWithValue("Unable to get drp quote.");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to get drp quote.");
    }
  },
);

export const downloadQuoteResultAsPDF = createAsyncThunk(
  "drp/downloaddrpquotemulti",
  async (data: any, thunkAPI) => {
    try {
      const response = await drpService.sendDownloadDRPQuoteRequest(data);
      const payload = await response;

      return payload;
    } catch (error) {
      return thunkAPI.rejectWithValue("Download Failed. Try agian later.");
    }
  },
);
