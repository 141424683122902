import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/redux/hooks";
import "../ResultTableIndemnities.scss";
import IndemnitySliderButton from "../IndemnitySliderButton";
import {
  setIndemnityValueClassVsComponent,
  setEndorsementValueClassVsComponent,
  setIndexValueClassVsComponent,
} from "../../../features/DRPSlice";
const ResultTableIndemnityClassVsComponent = (props) => {
  const { indemnityBasePrices, drpQuoteResults } = useAppSelector(
    (state) => state.drp,
  );

  const dispatch = useAppDispatch();

  const updateIndemnity = () => {
    const res = drpQuoteResults.data;

    if (Object.keys(res).length > 0) {
      // class pricing
      let ActualMilkRevenueCLPROP =
        Math.round(
          Math.round(
            ((Math.round(
              (Math.round(
                parseFloat(props.projectedPrices.projectedClass3Price) *
                  res["CLPROP"]["quote_params"][
                    "declared_class_price_weighting_factor"
                  ] *
                  10000,
              ) /
                10000 +
                Math.round(
                  parseFloat(props.projectedPrices.projectedClass4Price) *
                    (1 -
                      res["CLPROP"]["quote_params"][
                        "declared_class_price_weighting_factor"
                      ]) *
                    10000,
                ) /
                  10000) *
                10000,
            ) /
              10000) *
              res["CLPROP"]["quote_params"][
                "declared_covered_milk_production"
              ]) /
              100,
          ) * 10000,
        ) / 10000;
      ActualMilkRevenueCLPROP =
        ActualMilkRevenueCLPROP *
        (1 + parseFloat(props.yieldValues.yieldAdjustFactor) / 100);

      const indexValueCLPROP =
        ActualMilkRevenueCLPROP /
        (res["CLPROP"]["quote_params"]["declared_covered_milk_production"] /
          100);

      const indemnityvalueCLPROP = Math.round(
        Math.max(
          res["CLPROP"]["expected_revenue_guarantee"] - ActualMilkRevenueCLPROP,
          0,
        ) *
          res["CLPROP"]["quote_params"]["declared_share"] *
          res["CLPROP"]["quote_params"]["protection_factor"],
      );

      const pvalCLPROP =
        indemnityvalueCLPROP - res["CLPROP"]["producer_premium_amount"];

      const ActualMilkRevenue_c3 = Math.round(
        Math.round(
          (((Math.round(
            (Math.round(
              parseFloat(props.projectedPrices.projectedButterPrice) *
                res["CMPROP"]["quote_params"]["declared_butterfat_test"] *
                10000,
            ) /
              10000 +
              Math.round(
                parseFloat(props.projectedPrices.projectedProteinPrice) *
                  res["CMPROP"]["quote_params"]["declared_protein_test"] *
                  10000,
              ) /
                10000 +
              Math.round(
                parseFloat(props.projectedPrices.projectedOthersolidsPrice) *
                  5.7 *
                  10000,
              ) /
                10000) *
              10000,
          ) /
            10000) *
            res["CMPROP"]["quote_params"]["declared_covered_milk_production"]) /
            100) *
            10000,
        ) / 10000,
      );
      const ActualMilkRevenue_c4 = Math.round(
        Math.round(
          (((Math.round(
            (Math.round(
              parseFloat(props.projectedPrices.projectedButterPrice) *
                res["CMPROP"]["quote_params"]["declared_butterfat_test"] *
                10000,
            ) /
              10000 +
              Math.round(
                parseFloat(props.projectedPrices.projectedNonfatSolidsPrice) *
                  (res["CMPROP"]["quote_params"]["declared_protein_test"] +
                    5.7) *
                  10000,
              ) /
                10000) *
              10000,
          ) /
            10000) *
            res["CMPROP"]["quote_params"]["declared_covered_milk_production"]) /
            100) *
            10000,
        ) / 10000,
      );
      let ActualMilkRevenueCMPROP =
        ActualMilkRevenue_c3 *
          res["CMPROP"]["quote_params"][
            "declared_component_price_weighting_factor"
          ] +
        ActualMilkRevenue_c4 *
          (1 -
            res["CMPROP"]["quote_params"][
              "declared_component_price_weighting_factor"
            ]);

      ActualMilkRevenueCMPROP =
        ActualMilkRevenueCMPROP *
        (1 + parseFloat(props.yieldValues.yieldAdjustFactor) / 100);

      const indexValueCMPROP =
        ActualMilkRevenueCMPROP /
        (res["CMPROP"]["quote_params"]["declared_covered_milk_production"] /
          100);

      const indemnityvalueCMPROP = Math.round(
        Math.max(
          res["CMPROP"]["expected_revenue_guarantee"] - ActualMilkRevenueCMPROP,
          0,
        ) *
          res["CMPROP"]["quote_params"]["declared_share"] *
          res["CMPROP"]["quote_params"]["protection_factor"],
      );

      const pvalCMPROP =
        indemnityvalueCMPROP - res["CMPROP"]["producer_premium_amount"];

      dispatch(
        setIndexValueClassVsComponent({
          CLPROP: indexValueCLPROP,
          CMPROP: indexValueCMPROP,
        }),
      );
      dispatch(
        setIndemnityValueClassVsComponent({
          CLPROP: indemnityvalueCLPROP,
          CMPROP: indemnityvalueCMPROP,
        }),
      );

      dispatch(
        setEndorsementValueClassVsComponent({
          CLPROP: pvalCLPROP,
          CMPROP: pvalCMPROP,
        }),
      );
    }
  };
  // dont trigger with new quote
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateIndemnity, [
    props.projectedPrices.projectedButterPrice,
    props.projectedPrices.projectedNonfatSolidsPrice,
    props.projectedPrices.projectedOthersolidsPrice,
    props.projectedPrices.projectedProteinPrice,
    props.projectedPrices.projectedClass3Price,
    props.projectedPrices.projectedClass4Price,
    props.yieldValues.yieldAdjustFactor,
    dispatch,
  ]);
  useEffect(() => {
    // Reset sliders and values prices when base prices change
    props.sliderMethods.resetAllSliders();
    updateIndemnity();
    // dont trigger with slider method
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indemnityBasePrices]);

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title mb-3">Indemnity Calculations:</h5>
        <div className="card-text mb-3">
          Use the sliders below to see how your endorsement will react under
          various market conditions.
        </div>
        <div className="form-group">
          <table
            style={{ display: "block" }}
            className="table table-sm table-borderless"
          >
            <tbody>
              <tr>
                <th scope="row">Class III:</th>
                <td className="slider-cell">
                  <IndemnitySliderButton
                    name={"Class III - Comp"}
                    value={-1}
                    onClick={props.sliderMethods.incrementSlider}
                    onMouseDown={props.sliderMethods.startIncrementSlider}
                    onMouseUp={props.sliderMethods.stopSliderIncrement}
                  />
                  <input
                    type="range"
                    min={props.slider_consts.class3.min}
                    max={props.slider_consts.class3.max}
                    value={props.sliderValues.class3Slider}
                    name="class3"
                    onChange={(e) => {
                      // props.sliderMethods.setClass3Slider((prevState) => {
                      //   props.sliderValues.oldClass3Slider.current = prevState;
                      //   return Number(e.target.value);
                      // });
                      props.sliderMethods.updateFromClass3Slider_comp(
                        Number(e.target.value),
                      );
                    }}
                  />
                  <IndemnitySliderButton
                    name={"Class III - Comp"}
                    value={1}
                    onClick={props.sliderMethods.incrementSlider}
                    onMouseDown={props.sliderMethods.startIncrementSlider}
                    onMouseUp={props.sliderMethods.stopSliderIncrement}
                  />
                </td>
                <td>
                  <div className="d-inline-block fw-bold">
                    {props.projectedPrices.projectedClass3Price.toFixed(2)}
                  </div>
                </td>
                <td>
                  <div className="fw-normal">
                    ({indemnityBasePrices.class3Price.toFixed(2)})
                  </div>
                </td>
              </tr>

              <tr>
                <th scope="row">Butterfat:</th>
                <td className="slider-cell">
                  <IndemnitySliderButton
                    name={"Butterfat - Comp"}
                    value={-1}
                    onClick={props.sliderMethods.incrementSlider}
                    onMouseDown={props.sliderMethods.startIncrementSlider}
                    onMouseUp={props.sliderMethods.stopSliderIncrement}
                  />
                  <input
                    type="range"
                    min={props.slider_consts.butter.min}
                    max={props.slider_consts.butter.max}
                    value={props.sliderValues.butterSlider}
                    name="butterfat"
                    onChange={(e) => {
                      props.sliderMethods.updateFromButterSlider(
                        Number(e.target.value),
                      );
                    }}
                  />
                  <IndemnitySliderButton
                    name={"Butterfat - Comp"}
                    value={1}
                    onClick={props.sliderMethods.incrementSlider}
                    onMouseDown={props.sliderMethods.startIncrementSlider}
                    onMouseUp={props.sliderMethods.stopSliderIncrement}
                  />
                </td>
                <td>
                  <div className="d-inline-block fw-bold">
                    {props.projectedPrices.projectedButterPrice.toFixed(4)}
                  </div>
                </td>
                <td>
                  <div className="fw-normal">
                    ({indemnityBasePrices.butterfatPrice.toFixed(4)})
                  </div>
                </td>
              </tr>

              <tr>
                <th scope="row">Protein:</th>
                <td className="slider-cell">
                  <IndemnitySliderButton
                    name={"Protein - Comp"}
                    value={-1}
                    onClick={props.sliderMethods.incrementSlider}
                    onMouseDown={props.sliderMethods.startIncrementSlider}
                    onMouseUp={props.sliderMethods.stopSliderIncrement}
                  />
                  <input
                    type="range"
                    min={props.slider_consts.protein.min}
                    max={props.slider_consts.protein.max}
                    value={props.sliderValues.proteinSlider}
                    name="protein"
                    onChange={(e) => {
                      props.sliderMethods.updateFromProteinSlider(
                        Number(e.target.value),
                      );
                    }}
                  />
                  <IndemnitySliderButton
                    name={"Protein - Comp"}
                    value={1}
                    onClick={props.sliderMethods.incrementSlider}
                    onMouseDown={props.sliderMethods.startIncrementSlider}
                    onMouseUp={props.sliderMethods.stopSliderIncrement}
                  />
                </td>
                <td>
                  <div className="d-inline-block fw-bold">
                    {props.projectedPrices.projectedProteinPrice.toFixed(4)}
                  </div>
                </td>
                <td>
                  <div className="fw-normal">
                    ({indemnityBasePrices.proteinPrice.toFixed(4)})
                  </div>
                </td>
              </tr>

              <tr>
                <th scope="row">Other Solids:</th>
                <td className="slider-cell">
                  {/* FIXME: Fix names of components */}
                  <IndemnitySliderButton
                    name={"Other Solids - Comp"}
                    value={-1}
                    onClick={props.sliderMethods.incrementSlider}
                    onMouseDown={props.sliderMethods.startIncrementSlider}
                    onMouseUp={props.sliderMethods.stopSliderIncrement}
                  />
                  <input
                    type="range"
                    min={props.slider_consts.otherSolids.min}
                    max={props.slider_consts.otherSolids.max}
                    value={props.sliderValues.otherSolidsSlider}
                    name="other_solids"
                    onChange={(e) => {
                      props.sliderMethods.updateFromOtherSolidsSlider(
                        Number(e.target.value),
                      );
                    }}
                  />
                  <IndemnitySliderButton
                    name={"Other Solids - Comp"}
                    value={1}
                    onClick={props.sliderMethods.incrementSlider}
                    onMouseDown={props.sliderMethods.startIncrementSlider}
                    onMouseUp={props.sliderMethods.stopSliderIncrement}
                  />
                </td>
                <td>
                  <div className="d-inline-block fw-bold">
                    {props.projectedPrices.projectedOthersolidsPrice.toFixed(4)}
                  </div>
                </td>
                <td>
                  <div className="fw-normal">
                    ({indemnityBasePrices.otherSolidsPrice.toFixed(4)})
                  </div>
                </td>
              </tr>

              <tr>
                <th>&nbsp;</th>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>

              <tr>
                <th scope="row">Class IV:</th>
                <td className="slider-cell">
                  <IndemnitySliderButton
                    name={"Class IV - Comp"}
                    value={-1}
                    onClick={props.sliderMethods.incrementSlider}
                    onMouseDown={props.sliderMethods.startIncrementSlider}
                    onMouseUp={props.sliderMethods.stopSliderIncrement}
                  />
                  <input
                    type="range"
                    min={props.slider_consts.class4.min}
                    max={props.slider_consts.class4.max}
                    value={props.sliderValues.class4Slider}
                    name="class4"
                    onChange={(e) => {
                      // props.sliderMethods.setClass4Slider((prevVal) => {
                      //   props.sliderValues.oldClass4Slider.current = prevVal;
                      //   return Number(e.target.value);
                      // });
                      props.sliderMethods.updateFromClass4Slider_comp(
                        Number(e.target.value),
                      );
                    }}
                  />
                  <IndemnitySliderButton
                    name={"Class IV - Comp"}
                    value={1}
                    onClick={props.sliderMethods.incrementSlider}
                    onMouseDown={props.sliderMethods.startIncrementSlider}
                    onMouseUp={props.sliderMethods.stopSliderIncrement}
                  />
                </td>
                <td>
                  <div className="d-inline-block fw-bold">
                    {props.projectedPrices.projectedClass4Price.toFixed(2)}
                  </div>
                </td>
                <td>
                  <div className="fw-normal">
                    ({indemnityBasePrices.class4Price.toFixed(2)})
                  </div>
                </td>
              </tr>

              <tr>
                <th scope="row">Butterfat:</th>
                <td className="slider-cell">
                  <IndemnitySliderButton
                    name={"Butterfat - Comp"}
                    value={-1}
                    onClick={props.sliderMethods.incrementSlider}
                    onMouseDown={props.sliderMethods.startIncrementSlider}
                    onMouseUp={props.sliderMethods.stopSliderIncrement}
                  />
                  <input
                    type="range"
                    min={props.slider_consts.butter.min}
                    max={props.slider_consts.butter.max}
                    value={props.sliderValues.butterSlider}
                    name="butterfat"
                    onChange={(e) => {
                      props.sliderMethods.updateFromButterSlider(
                        Number(e.target.value),
                      );
                    }}
                  />
                  <IndemnitySliderButton
                    name={"Butterfat - Comp"}
                    value={1}
                    onClick={props.sliderMethods.incrementSlider}
                    onMouseDown={props.sliderMethods.startIncrementSlider}
                    onMouseUp={props.sliderMethods.stopSliderIncrement}
                  />
                </td>
                <td>
                  <div className="d-inline-block fw-bold">
                    {props.projectedPrices.projectedButterPrice.toFixed(4)}
                  </div>
                </td>
                <td>
                  <div className="fw-normal">
                    ({indemnityBasePrices.butterfatPrice.toFixed(4)})
                  </div>
                </td>
              </tr>

              <tr>
                <th scope="row">Nonfat Solids:</th>
                <td className="slider-cell">
                  <IndemnitySliderButton
                    name={"Nonfat Solids - Comp"}
                    value={-1}
                    onClick={props.sliderMethods.incrementSlider}
                    onMouseDown={props.sliderMethods.startIncrementSlider}
                    onMouseUp={props.sliderMethods.stopSliderIncrement}
                  />
                  <input
                    type="range"
                    min={props.slider_consts.nonfat.min}
                    max={props.slider_consts.nonfat.max}
                    value={props.sliderValues.nonfatSolidsSlider}
                    name="nonfat_solids"
                    onChange={(e) => {
                      props.sliderMethods.updateFromNonfatSolidsSlider(
                        Number(e.target.value),
                      );
                    }}
                  />
                  <IndemnitySliderButton
                    name={"Nonfat Solids - Comp"}
                    value={1}
                    onClick={props.sliderMethods.incrementSlider}
                    onMouseDown={props.sliderMethods.startIncrementSlider}
                    onMouseUp={props.sliderMethods.stopSliderIncrement}
                  />
                </td>
                <td>
                  <div className="d-inline-block fw-bold">
                    {props.projectedPrices.projectedNonfatSolidsPrice.toFixed(
                      4,
                    )}
                  </div>
                </td>
                <td>
                  <div className="fw-normal">
                    ({indemnityBasePrices.nonfatPrice.toFixed(4)})
                  </div>
                </td>
              </tr>

              <tr>
                <th>&nbsp;</th>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>

              <tr>
                <th scope="row">Yield Adj.:</th>
                <td className="slider-cell">
                  <IndemnitySliderButton
                    name={"Yield Adj - Comp"}
                    value={-1}
                    onClick={props.sliderMethods.incrementSlider}
                    onMouseDown={props.sliderMethods.startIncrementSlider}
                    onMouseUp={props.sliderMethods.stopSliderIncrement}
                  />
                  <input
                    type="range"
                    min={props.slider_consts.yield.min}
                    max={props.slider_consts.yield.max}
                    name="yaf"
                    value={props.sliderValues.yafSlider}
                    onChange={(e) =>
                      props.sliderMethods.updateFromYAFSlider_comp(
                        Number(e.target.value),
                      )
                    }
                  />

                  <IndemnitySliderButton
                    name={"Yield Adj - Comp"}
                    value={1}
                    onClick={props.sliderMethods.incrementSlider}
                    onMouseDown={props.sliderMethods.startIncrementSlider}
                    onMouseUp={props.sliderMethods.stopSliderIncrement}
                  />

                  <br />
                  <div className="rounded-yield-section">
                    <span className="fw-bold">
                      {props.yieldValues.currentYield.toLocaleString()} &#10230;
                      {props.yieldValues.roundedYieldAdjustment.toLocaleString()}
                    </span>
                    <br />
                    <span className="small">
                      {(
                        props.yieldValues.currentYield / props.daysInQtr
                      ).toFixed(1)}
                      <span>&#10230;</span>
                      {(
                        props.yieldValues.roundedYieldAdjustment /
                        props.daysInQtr
                      ).toFixed(1)}
                    </span>
                  </div>
                </td>
                <td>
                  <div className="d-inline-block fw-bold">
                    {props.yieldValues.yieldAdjustFactor}%
                  </div>
                </td>
                <td>
                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={() => props.sliderMethods.resetYafSlider()}
                  >
                    Reset
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <button
            className="btn btn-secondary btn-sm"
            style={{ marginLeft: "3px" }}
            onClick={props.sliderMethods.resetAllSliders}
          >
            Reset All
          </button>
          <div className="form-check d-inline-block ms-4">
            <input
              type="checkbox"
              id="lockClass3Class4"
              className="form-check-input"
              checked={props.lockButterProtein}
              onChange={() => props.handleLock("comp")}
            />
            <label
              htmlFor="lockClass3Class4"
              className="form-check-label"
              style={{ fontSize: "10pt" }}
            >
              Lock Butter and Protein together
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultTableIndemnityClassVsComponent;
