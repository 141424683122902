import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import { authorizeAgent } from "../features/adminActions";
import authFormValidator from "../../Authentication/utils/authFormValidator";

import "../../Authentication/styles/authStyles.scss";
import AuthorizedAgentsList from "./AuthorizedAgentsList";

const formInitalValidity = {
  email: {
    valid: false,
    message: null,
    touched: false,
    required: true,
  },
};
function AuthorizeAgentEmail() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [formData, setFormData] = useState({
    email: "",
  });

  const [formValidity, setFormValidity] = useState<any>(formInitalValidity);

  const { isLoggedIn } = useAppSelector((state) => state.auth);
  const { isError, message, isSuccess } = useAppSelector(
    (state) => state.admin.authorizeAgent,
  );

  const authorizeEmail = (e) => {
    e.preventDefault();
    dispatch(authorizeAgent(formData.email));
  };
  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    const email_validation = authFormValidator.emailValidator(formData.email);
    setFormValidity((prevState) => ({
      ...prevState,
      email: {
        ...prevState.email,
        valid: email_validation.valid,
        message: email_validation.message,
      },
    }));
  }, [formData]);

  return (
    <div>
      <div className="ms-4 container">
        <div className="mb-4">
          <span
            className="admin-link"
            style={{ cursor: "pointer", fontWeight: "bold" }}
            onClick={() => navigate("/admin")}
          >
            {"<"} Back
          </span>
        </div>
        <hr className="mb-4" />
        <h5>Authorize an E-mail</h5>
        <hr className="mb-4" />
        <div>
          {/* Alerts */}
          {isError && (
            <div className="mt-3">
              <div className="alert alert-danger fade show" role="alert">
                {message}
              </div>
            </div>
          )}
          {isSuccess && (
            <div className="mt-3">
              <div className="alert alert-success fade show" role="alert">
                {message}
              </div>
            </div>
          )}
        </div>

        {/*  Form  */}
        <div>
          <form onSubmit={authorizeEmail}>
            <div className="form-group">
              <label>E-mail to Authorize: </label>
              <div className="input-group">
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  onChange={onChange}
                  id="email"
                  placeholder="someone@example.com"
                />
                <div className="input-group-append">
                  <button
                    disabled={!isLoggedIn || !formValidity.email.valid}
                    className="btn btn-primary"
                    type="submit"
                  >
                    Authorize
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="mt-4">
          <AuthorizedAgentsList />
        </div>
      </div>
    </div>
  );
}

export default AuthorizeAgentEmail;
