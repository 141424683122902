import hedgeMAPService from "./producerHedgeMAPsService";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cloneAllHedgemapTypes,
  setValuesToFalse,
} from "../../Authentication/utils/producerHedgeMAPsUtils";

export const authorizeProducerToViewHedgeMAP = createAsyncThunk(
  "drp/authorizeProducerToViewHedgeMAP",
  async (data: any, thunkAPI) => {
    try {
      const response =
        await hedgeMAPService.sendAuthorizeProducerToViewHedgeMAPRequest(data);
      const payload = await response.data;
      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to authorize user to view hedgeMAP. Please try again later.",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to authorize user to view hedgeMAP. Please try again later.",
      );
    }
  },
);

export const getProducersHedgeMAPs = createAsyncThunk(
  "drp/getProducersHedgeMAPs",
  async (_: void, thunkAPI) => {
    try {
      const response = await hedgeMAPService.sendGetProducersHedgeMAPsRequest();
      const payload = await response.data;

      if (response.status === 200) {
        if (payload.success === true) {
          return payload;
        } else if (payload.success === false && payload.message) {
          return thunkAPI.rejectWithValue(payload.message);
        } else {
          return thunkAPI.rejectWithValue(
            "Unable to get hedgeMAPs. Please try again later.",
          );
        }
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to get hedgeMAPs. Please try again later.",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to get hedgeMAPs. Please try again later.",
      );
    }
  },
);

export const downloadHedgeMAP = createAsyncThunk(
  "drp/downloadHedgeMAP",
  async (data: any, thunkAPI) => {
    try {
      const response = await hedgeMAPService.sendDownloadHedgeMAPRequest(data);
      const payload = await response;

      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue({
          messsage: "Unable to download hedgeMAP. Please try again later.",
          id: data["unique_id"],
        });
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({
        messsage: "Unable to download hedgeMAP. Please try again later.",
        id: data["unique_id"],
      });
    }
  },
);

//Gets associated policy producers for a specific user
export const getUsersAssociatedPolicyProducerAggregates = createAsyncThunk(
  "drp/getUsersAssociatedPolicyProducerAggregates",
  async (user: string, thunkAPI) => {
    try {
      const response =
        await hedgeMAPService.sendGetUsersAssociatedPolicyProducerAggregatesRequest(
          user,
        );
      const payload = await response.data;
      // console.log(payload);
      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to fetch associated policy producers. Please try again later.",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to fetch associated policy producers. Please try again later.",
      );
    }
  },
);

//Gets associated users for a specific policy producer
export const getPolicyProducersAssociatedUsers = createAsyncThunk(
  "drp/getPolicyProducersAssociatedUsers",
  async (user: any, thunkAPI) => {
    try {
      const response =
        await hedgeMAPService.sendGetPolicyProducersAssociatedUsersRequest(
          user,
        );
      const payload = await response.data;
      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to fetch associated policy producers. Please try again later.",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to fetch associated policy producers. Please try again later.",
      );
    }
  },
);

export const linkProducerAggregatedToUser = createAsyncThunk(
  "drp/linkProducerAggregatedToUser",
  async (data: any, thunkAPI) => {
    try {
      const response =
        await hedgeMAPService.sendLinkProducerAggregatedToUserRequest(data);
      const payload = await response.data;

      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to authorize user to view hedgeMAP. Please try again later.",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to authorize user to view hedgeMAP. Please try again later.",
      );
    }
  },
);
export const unlinkProducerAggregatedToUser = createAsyncThunk(
  "drp/unlinkProducerAggregatedToUser",
  async (data: any, thunkAPI) => {
    try {
      data["hedgemap_info"]["SelectedHedgemapTypes"] = setValuesToFalse(
        cloneAllHedgemapTypes(),
      );

      const response =
        await hedgeMAPService.sendAuthorizeProducerToViewHedgeMAPRequest(data);
      const payload = await response.data;

      if (response.status === 200) {
        thunkAPI.dispatch(
          getPolicyProducersAssociatedUsers(data["hedgemap_info"]),
        );

        return payload;
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to unlink user to hedgeMAP. Please try again later.",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to unlink user to hedgeMAP. Please try again later.",
      );
    }
  },
);

export const getAllAgentsProducerHedgemapInfo = createAsyncThunk(
  "drp/linkProducerAggregatedToUser",
  async (_: void, thunkAPI) => {
    try {
      const response =
        await hedgeMAPService.sendGetAllAgentsProducerHedgemapInfoRequest();
      const payload = await response.data;

      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to get hedgeMAPs. Please try again later.",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to get hedgeMAPs. Please try again later.",
      );
    }
  },
);

export const getSpecificHedgemapInfo = createAsyncThunk(
  "drp/getSpecificHedgemapInfo",
  async (data: any, thunkAPI) => {
    try {
      const response =
        await hedgeMAPService.sendGetSpecificHedgemapInfoRequest(data);
      const payload = await response.data;

      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to authorize user to view hedgeMAP. Please try again later.",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to authorize user to view hedgeMAP. Please try again later.",
      );
    }
  },
);

export const getAllHedgemapInfoForAgent = createAsyncThunk(
  "drp/getAllHedgemapInfoForAgent",
  async (data: any, thunkAPI) => {
    try {
      const response =
        await hedgeMAPService.sendGetAllHedgemapInfoForAgentRequest(data);
      const payload = await response.data;

      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to authorize user to view hedgeMAP. Please try again later.",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to authorize user to view hedgeMAP. Please try again later.",
      );
    }
  },
);
