import React, { useEffect } from "react";
import DecisionToolContainer from "../../DecisionToolContainer";
import LRPPolicySelector from "../components/LRPPolicySelector/LRPPolicySelector";
import LRPMarketingPlan from "../components/LRPMarketingPlan/LRPMarketingPlan";
import LRPResultTable from "../components/LRPResultTable/LRPResultTable";

import "./LRPDecisionTool.scss";
import { useAppDispatch } from "../../../app/redux/hooks";
import { resetLRPQuoteResult } from "../components/LRPSlice";

function LRPDecisionTool() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // On page load set first tab to active
    //clear quote results on page load as running classvscomponent/strip, navigating to another page then back would try to load thoe reuslts on single
    dispatch(resetLRPQuoteResult());
  }, [dispatch]);
  return (
    <DecisionToolContainer title={"Livestock Risk Protection Decision Tool"}>
      <div>
        <LRPPolicySelector />
        <LRPMarketingPlan />
        <LRPResultTable />
      </div>
    </DecisionToolContainer>
  );
}

export default LRPDecisionTool;
