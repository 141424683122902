import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/redux/hooks";
import { setSelectedWeek } from "../../LRPSlice";

function LRPWeekSelection({ userInputs, weekList }) {
  const dispatch = useAppDispatch();

  const { selectedWeek } = useAppSelector((state) => state.lrp);

  const [localSelectedWeek, setLocalSelectedWeek] = useState("0");
  const [endDate, setEndDate] = useState("");

  const handleWeekSelection = (e) => {
    setLocalSelectedWeek(e.target.value);
  };

  const addWeeks = (date, weeks) => {
    let end_date = new Date(date);

    end_date.setDate(end_date.getDate() + weeks * 7);
    return end_date;
  };

  useEffect(() => {
    dispatch(setSelectedWeek(localSelectedWeek));
  }, [localSelectedWeek, dispatch]);

  useEffect(() => {
    // Sales effective date + selected week = end date
    let end_date = addWeeks(
      userInputs.sales_effective_date.value.getTime(), // Pass UnixTime number
      parseInt(selectedWeek),
    );

    // Set end date
    setEndDate(
      end_date.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }),
    );

    // stripQuotes selectedWeek dont cause update unless they change week
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWeek]);

  useEffect(() => {
    dispatch(setSelectedWeek(weekList[0]));
    //dont update on weeklist update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div className="table-responsive mb-2" style={{ width: "100%" }}>
      {/* Week Selection */}
      <table className="table">
        <tbody>
          <tr>
            <th colSpan={2}>Select insurance period to view:</th>
          </tr>
          <tr>
            <td
              colSpan={2}
              style={{ borderTop: "none !important" }}
              className="py-3"
            >
              <select
                className="form-select text-white bg-secondary ag-form-focus col-4 pb-1 my-3"
                style={{ minWidth: "150px", maxWidth: "400px" }}
                onChange={(e) => handleWeekSelection(e)}
              >
                {/* <option value="">13 Weeks</option> */}
                {weekList.map((week) => {
                  return (
                    <option value={week} key={week}>
                      {week} Weeks
                    </option>
                  );
                })}
              </select>
            </td>
          </tr>
          <tr className="border-top">
            <th style={{ width: "15%" }} className="py-3">
              End Date:{" "}
            </th>
            <td>{endDate}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default LRPWeekSelection;
