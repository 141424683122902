import lgmService from "./LGMService";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getMostRecentDateSecureLGM = createAsyncThunk(
  "drp/getmostrecentdatesecurelgm",
  async (_: void, thunkAPI) => {
    try {
      const response = await lgmService.sendGetMostRecentDateSecureLGMRequest();
      const payload = await response.data;

      if (response.status === 200) {
        const date = payload.date;
        return date;
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to fetch most recent sales effective date.",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to fetch most recent sales effective date.",
      );
    }
  },
);
export const getInitalExpectedGrossMargins = createAsyncThunk(
  "drp/getinitallgmexpectedgrossmargins",
  async (data: any, thunkAPI) => {
    try {
      const response =
        await lgmService.sendGetInitalExpectedGrossMarginsRequest(data);
      const payload = await response.data;

      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to fetch expected gross margins.",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to fetch expected gross margins.",
      );
    }
  },
);

export const getMonthsToShow = createAsyncThunk(
  "drp/getcurrentmonthslgm",
  async (data: string, thunkAPI) => {
    try {
      const response = await lgmService.sendGetMonthsToShowRequest();
      const payload = await response.data;

      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue("Unable to fetch most recent prices.");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to fetch most recent prices.");
    }
  },
);

// export const getLGMQuoteAuth = createAsyncThunk(
//   "drp/getlgmquoteauth",
//   async (data: object, thunkAPI) => {
//     try {
//       const response = await lgmService.sendLGMQuoteRequest(data);
//       const payload = await response.json();

//       if (response.status === 200) {
//         return payload;
//       } else {
//         return thunkAPI.rejectWithValue("Unable to fetch most recent prices.");
//       }
//     } catch (error) {
//       return thunkAPI.rejectWithValue("Unable to fetch most recent prices.");
//     }
//   }
// );
export const getLGMQuoteSecure = createAsyncThunk(
  "drp/getlgmquotesecure",
  async (data: any, thunkAPI) => {
    try {
      // console.log(data.lgm_quote_params);
      const response = await lgmService.sendLGMQuoteRequest(
        data.lgm_quote_params,
      );
      const payload = await response.data;

      if (response.status === 200) {
        console.log(payload);
        // Check to see if response contains 'error' message, if so then we reject with that error message.
        if (
          payload?.payload?.status === "error" &&
          payload?.payload?.description
        ) {
          const desc_arr = payload["payload"].description.split("-");
          if (desc_arr[0] === "corn") {
            return thunkAPI.rejectWithValue(
              `Invalid corn feed entry for the month of: ${
                data.months[desc_arr[1]]
              }. Corn feed ratio must be between (0.00364-0.0381).`,
            );
          } else if (desc_arr[0] === "soybean") {
            return thunkAPI.rejectWithValue(
              `Invalid soybean feed entry for the month of: ${
                data.months[desc_arr[1]]
              }. Soybean feed ratio must be between (0.000805-0.013).`,
            );
          }
        }

        if (
          payload?.payload?.status === "error" &&
          payload?.payload?.messages
        ) {
          return thunkAPI.rejectWithValue(payload?.payload?.messages);
        } else {
          if (payload.lgm_quote_results) {
            if (Object.keys(payload["lgm_quote_results"]).length === 0) {
              /** Check to see if the returned lgm_quote_results is empty (i.e RMA
               * did not release data for a specific commodity) and reject if so.
               */
              return thunkAPI.rejectWithValue(
                "Unable to get LGM Quote. Please try again later.",
              );
            }
            return payload;
          }
          return thunkAPI.rejectWithValue(
            "Error occurred. Please verify you entered valid numeric inputs under your marketing plan.",
          );
        }
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to get LGM Quote. Please try again later.",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to get LGM Quote. Please try again later.",
      );
    }
  },
);
