import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/redux/hooks";
import { setLGMSwineMarketingPlan } from "../../features/LGMSlice";
import { LGMCommodityType } from "@agdeveloper/domain";

const LGMSwineMarketingPlan = () => {
  const dispatch = useAppDispatch();

  const { type, swine_marketing_plan } = useAppSelector(
    (state) => state.lgm.input,
  );
  const { target_months } = useAppSelector((state) => state.lgm);

  const { isError, isLoading, expectedGrossMargins } = useAppSelector(
    (state) => state.lgm.expectedGrossMarginsLGM,
  );
  const [marketingPlan, setMarketingPlan] = useState(swine_marketing_plan);

  const updateMarketingPlan = (month, marketed) => {
    setMarketingPlan({
      ...swine_marketing_plan,
      [month]: marketed,
    });
    // setTypeName(type_name);
  };

  useEffect(() => {
    setMarketingPlan(swine_marketing_plan);
  }, [swine_marketing_plan]);

  useEffect(() => {
    dispatch(setLGMSwineMarketingPlan(marketingPlan));
  }, [marketingPlan, dispatch]);

  return (
    <div className="card w-100 my-2">
      <div className="card-body">
        <h5 className="card-title">Insured Swine</h5>
        <p className="card-text">
          Enter the number of swine insured each month
        </p>
        <div className="table-responsive">
          <table className="table table-fit">
            <thead>
              <tr>
                <th></th>
                {target_months.slice(0, 5).map((month) => (
                  <th scope="col" key={month}>
                    {month}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" style={{ maxWidth: "8em", minWidth: "8em" }}>
                  Swine Marketed:
                </th>
                <td>
                  <input
                    value={swine_marketing_plan[target_months[0]]}
                    onChange={(e) =>
                      updateMarketingPlan(target_months[0], e.target.value)
                    }
                    type="number"
                    style={{ maxWidth: "6em" }}
                  />
                </td>
                <td>
                  <input
                    value={swine_marketing_plan[target_months[1]]}
                    onChange={(e) =>
                      updateMarketingPlan(target_months[1], e.target.value)
                    }
                    type="number"
                    style={{ maxWidth: "6em" }}
                  />
                </td>
                <td>
                  <input
                    value={swine_marketing_plan[target_months[2]]}
                    onChange={(e) =>
                      updateMarketingPlan(target_months[2], e.target.value)
                    }
                    type="number"
                    style={{ maxWidth: "6em" }}
                  />
                </td>
                <td>
                  <input
                    value={swine_marketing_plan[target_months[3]]}
                    onChange={(e) =>
                      updateMarketingPlan(target_months[3], e.target.value)
                    }
                    type="number"
                    style={{ maxWidth: "6em" }}
                  />
                </td>
                <td>
                  <input
                    value={swine_marketing_plan[target_months[4]]}
                    onChange={(e) =>
                      updateMarketingPlan(target_months[4], e.target.value)
                    }
                    type="number"
                    style={{ maxWidth: "6em" }}
                  />
                </td>
              </tr>
              {expectedGrossMargins?.[LGMCommodityType.SWINE]?.[type?.value] &&
                expectedGrossMargins?.[LGMCommodityType.SWINE]?.[type?.value]
                  .length === 5 &&
                !isError && (
                  <tr>
                    <th>
                      Expected Gross Margins: <br />
                      (Per Head)
                    </th>
                    {isLoading ? (
                      <td>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading ...
                      </td>
                    ) : (
                      <>
                        {expectedGrossMargins?.[LGMCommodityType.SWINE][
                          type?.value
                        ].map((item, index) => {
                          return (
                            <td key={index}>
                              <div
                                style={{ maxWidth: "7em", textAlign: "left" }}
                              >
                                {swine_marketing_plan[target_months[index]] > 0
                                  ? `$${(
                                      item *
                                      swine_marketing_plan[target_months[index]]
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}`
                                  : "-"}
                              </div>
                              <div
                                style={{ maxWidth: "7em", textAlign: "left" }}
                              >
                                {`($${item.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })})`}
                              </div>
                            </td>
                          );
                        })}
                      </>
                    )}
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default LGMSwineMarketingPlan;
