import React, { useEffect, useState } from "react";
import Collapse from "react-bootstrap/esm/Collapse";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
// import { resetDownloadHedgeMAP } from "../../features/hedgeMAPs/hedgeMAPsSlice";
import { downloadHedgeMAP } from "../features/producerHedgeMAPsActions";
import { resetDownloadHedgeMAP } from "../features/producerHedgeMAPsSlice";
import "./ProducerHedgeMAPsListing.scss";

//listing ={'ProducerUser1': {'LGM': {'SWINE': {'SEW_PIG_FINISHING': ['ProducerUser1BusinessName_LGM_Swine_Finishing SEW_HedgeMAP_202310.pdf']}}}}
const HedgeMAPListing = ({ listing }) => {
  const dispatch = useAppDispatch();
  const {
    // message,
    isLoading,
    fileName,
    isError,
    id: failedHedgeMAPdownloadID,
  } = useAppSelector((state) => state.producerHedgeMAPs.downloadHedgeMAP);

  const [downloadID, setDownloadID] = useState("");

  const policyProducerName = Object.keys(listing)[0];
  console.log(policyProducerName);

  const producerContents = listing[policyProducerName];

  const [producerExpanded, setProducerExpanded] = useState(false);
  const [dateExpanded, setDateExpanded] = useState(
    Object.fromEntries(
      Object.keys(producerContents).map((dateKey) => [dateKey, false]),
    ),
  );

  const [insuranceTypeExpanded, setInsuranceTypeExpanded] = useState({});
  const [commodityTypeExpanded, setCommodityTypeExpanded] = useState({});
  const [commoditySubTypeExpanded, setCommoditySubTypeExpanded] = useState({});

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        dispatch(resetDownloadHedgeMAP());
      }, 2000);
    }
  }, [isError, dispatch]);

  // obj: to traverse - ./..args equates to kwargs in python. args is the nested keys to dig down into
  const safelyGetNested = (obj, ...args) =>
    //for each key in args array, check if the key exists in the object. If it does, return the value of that key. If it doesn't, return null
    args.reduce(
      (accumlated, current_val) =>
        accumlated && accumlated[current_val] ? accumlated[current_val] : null,
      obj,
    );

  const renderDates = (producerContents) => {
    const dateKeys = Object.keys(producerContents);

    return (
      <>
        {Object.keys(producerContents)
          .sort()
          .reverse()
          .map((dateKey) => {
            const insuranceTypes = producerContents[dateKey];
            return (
              <>
                {dateKeys.length > 1 ? (
                  <div className="indent">
                    <span
                      onClick={() =>
                        setDateExpanded((prev) => ({
                          ...prev,
                          [dateKey]: !prev[dateKey],
                        }))
                      }
                      className="list-group-item maroon-border-left rounded-0 list-group-link"
                    >
                      <span id="chevron-arrow-right"></span>
                      {dateKey}
                    </span>
                    <Collapse in={dateExpanded[dateKey]}>
                      <div className="list-group">
                        {renderInsuranceTypes(dateKey, insuranceTypes)}
                      </div>
                    </Collapse>
                  </div>
                ) : (
                  renderInsuranceTypes(dateKey, insuranceTypes)
                )}
              </>
            );
          })}
      </>
    );
  };

  const renderInsuranceTypes = (dateKey, insuranceTypes) => {
    const insuranceTypeKeys = Object.keys(insuranceTypes);
    return (
      <>
        {Object.keys(insuranceTypes).map((insuranceKey) => {
          const commodityTypes = insuranceTypes[insuranceKey];
          return (
            <>
              {insuranceTypeKeys.length > 1 ? (
                <div className="indent">
                  <span
                    onClick={() =>
                      setInsuranceTypeExpanded((prev) => ({
                        ...prev,
                        [dateKey]: {
                          ...(prev[dateKey] || {}),
                          [insuranceKey]: !(
                            prev[dateKey] && prev[dateKey][insuranceKey]
                          ),
                        },
                      }))
                    }
                    className="list-group-item maroon-border-left rounded-0 list-group-link"
                  >
                    <span id="chevron-arrow-right"></span>
                    {insuranceKey}
                  </span>
                  <Collapse
                    in={
                      insuranceTypeExpanded[dateKey] &&
                      insuranceTypeExpanded[dateKey][insuranceKey]
                    }
                  >
                    <div className="list-group">
                      {renderCommodityTypes(
                        dateKey,
                        insuranceKey,
                        commodityTypes,
                      )}
                    </div>
                  </Collapse>
                </div>
              ) : (
                renderCommodityTypes(dateKey, insuranceKey, commodityTypes)
              )}
            </>
          );
        })}
      </>
    );
  };

  const renderCommodityTypes = (dateKey, insuranceKey, commodityTypes) => {
    const commodityTypeKeys = Object.keys(commodityTypes);

    return (
      <>
        {Object.keys(commodityTypes).map((commodityTypeKey) => {
          const detailSubTypes = commodityTypes[commodityTypeKey];
          return (
            <>
              {commodityTypeKeys.length > 1 ? (
                <div className="indent">
                  <span
                    onClick={() => {
                      const currentExpandedValue = safelyGetNested(
                        commodityTypeExpanded,
                        dateKey,
                        insuranceKey,
                        commodityTypeKey,
                      );

                      setCommodityTypeExpanded((prev) => ({
                        ...prev,
                        [dateKey]: {
                          ...(prev[dateKey] || {}),
                          [insuranceKey]: {
                            ...(safelyGetNested(prev, dateKey, insuranceKey) ||
                              {}),
                            [commodityTypeKey]: !currentExpandedValue,
                          },
                        },
                      }));
                    }}
                    className="list-group-item maroon-border-left rounded-0 list-group-link"
                  >
                    <span id="chevron-arrow-right"></span>
                    {commodityTypeKey}
                  </span>
                  <Collapse
                    in={
                      safelyGetNested(
                        commodityTypeExpanded,
                        dateKey,
                        insuranceKey,
                        commodityTypeKey,
                      ) === true
                    }
                  >
                    <div className="list-group indent">
                      {renderCommoditySubTypes(
                        dateKey,
                        insuranceKey,
                        commodityTypeKey,
                        detailSubTypes,
                      )}
                    </div>
                  </Collapse>
                </div>
              ) : (
                renderCommoditySubTypes(
                  dateKey,
                  insuranceKey,
                  commodityTypeKey,
                  detailSubTypes,
                )
              )}
            </>
          );
        })}
      </>
    );
  };
  const renderCommoditySubTypes = (
    dateKey,
    insuranceKey,
    commodityTypeKey,
    detailSubTypes,
  ) => {
    const commoditySubTypeKeys = Object.keys(detailSubTypes);

    return (
      <>
        {Object.keys(detailSubTypes).map((commoditySubTypeKey) => {
          const hedgemapFiles = detailSubTypes[commoditySubTypeKey];
          return (
            <>
              {commoditySubTypeKeys.length > 1 ? (
                <div className="indent">
                  <span
                    onClick={() => {
                      const currentExpandedValue = safelyGetNested(
                        commoditySubTypeExpanded,
                        dateKey,
                        insuranceKey,
                        commodityTypeKey,
                        commoditySubTypeKey,
                      );
                      setCommoditySubTypeExpanded((prev) => ({
                        ...prev,
                        [dateKey]: {
                          ...(prev[dateKey] || {}),
                          [insuranceKey]: {
                            ...(safelyGetNested(prev, dateKey, insuranceKey) ||
                              {}),
                            [commodityTypeKey]: {
                              ...(safelyGetNested(
                                prev,
                                dateKey,
                                insuranceKey,
                                commodityTypeKey,
                              ) || {}),
                              [commoditySubTypeKey]: !currentExpandedValue,
                            },
                          },
                        },
                      }));
                    }}
                    className="list-group-item maroon-border-left rounded-0 list-group-link"
                  >
                    <span id="chevron-arrow-right"></span>
                    {commoditySubTypeKey}
                  </span>
                  <Collapse
                    in={
                      safelyGetNested(
                        commoditySubTypeExpanded,
                        dateKey,
                        insuranceKey,
                        commodityTypeKey,
                        commoditySubTypeKey,
                      ) === true
                    }
                  >
                    <div className="list-group indent">
                      {renderHedgemapFiles(
                        dateKey,
                        insuranceKey,
                        commodityTypeKey,
                        commoditySubTypeKey,
                        hedgemapFiles,
                      )}
                    </div>
                  </Collapse>
                </div>
              ) : (
                renderHedgemapFiles(
                  dateKey,
                  insuranceKey,
                  commodityTypeKey,
                  commoditySubTypeKey,
                  hedgemapFiles,
                )
              )}
            </>
          );
        })}
      </>
    );
  };

  const ButtonDownload = ({
    fileName,
    isError,
    isLoading,
    uniqueId,
    ...props
  }) => {
    //compex button state create component
    //set our intital states

    const onDownloadPress = async () => {
      setDownloadID(uniqueId);

      await props.dispatch(
        downloadHedgeMAP({
          file_name: fileName,
          commodity_sub_type: props.commoditySubTypeKey,
          commodity_type: props.commodityTypeKey,
          insurance_type: props.insuranceKey,
          date: props.dateKey,
          policy_producer: props.policyProducerName,
          unique_id: uniqueId,
        }),
      );

      setDownloadID("");
    };

    const isCurrentLoading = downloadID === uniqueId;
    const isCurrentError = failedHedgeMAPdownloadID === uniqueId;

    useEffect(() => {
      if (isCurrentError) {
        setTimeout(() => {
          dispatch(resetDownloadHedgeMAP());
        }, 2000);
      }
    }, [failedHedgeMAPdownloadID, dispatch]);

    let buttonText: any = "Download";
    let buttonClass = "btn btn-success";
    let isDisabled = isLoading;

    if (isCurrentError) {
      buttonText = "Error. Try again later.";
      buttonClass = "btn btn-danger";
      isDisabled = true;
    } else if (isCurrentLoading) {
      buttonText = (
        <div style={{ whiteSpace: "nowrap" }}>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>{" "}
          Downloading...
        </div>
      );
      buttonClass = "btn btn-secondary";
      isDisabled = true;
    }

    return (
      <button
        onClick={onDownloadPress}
        className={buttonClass}
        disabled={isDisabled}
      >
        {buttonText}
      </button>
    );
  };

  const renderHedgemapFiles = (
    dateKey,
    insuranceKey,
    commodityTypeKey,
    commoditySubTypeKey,
    hedgemapFiles,
  ) => {
    return (
      <>
        {hedgemapFiles.map((hedgemap_file, index) => (
          <div className="indent" key={index}>
            <div className="list-group-item maroon-border-left rounded-0 list-group-link d-flex align-items-center">
              <div className="flex-grow-1">
                <div>{hedgemap_file}</div>
                <div className="text-muted small">
                  HedgeMAP generated on: {dateKey}
                </div>
              </div>
              <ButtonDownload
                fileName={hedgemap_file}
                isError={isError}
                commoditySubTypeKey={commoditySubTypeKey}
                commodityTypeKey={commodityTypeKey}
                insuranceKey={insuranceKey}
                dateKey={dateKey}
                policyProducerName={policyProducerName}
                dispatch={dispatch}
                isLoading={isLoading}
                globalFileName={fileName}
                uniqueId={`${commodityTypeKey}-${commoditySubTypeKey}-${insuranceKey}-${dateKey}`}
              />
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <div
      className="listing-component"
      style={{ minWidth: 600, cursor: "pointer" }}
    >
      <span
        onClick={() => setProducerExpanded(!producerExpanded)}
        className="maroon-border-left list-group-item rounded-0 list-group-link"
      >
        <span id="chevron-arrow-right"></span>
        {policyProducerName}
      </span>
      {/* {dateKeys.length > 1 ? ( */}
      <Collapse in={producerExpanded}>
        <div className="list-group">{renderDates(producerContents)}</div>
      </Collapse>
    </div>
  );
};

export default HedgeMAPListing;
