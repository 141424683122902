import lrpService from "./LRPService";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { LRPQuoteUserInputs } from "@agdeveloper/domain";

const verifySwineInputs = (lrp_user_inputs: LRPQuoteUserInputs) => {
  let errors: Array<string> = [];
  if (Number(lrp_user_inputs.number_of_head) < 1) {
    errors.push("You must input at least one head of Swine.");
  }
  if (
    Number(lrp_user_inputs.target_weight) > 2.6 ||
    Number(lrp_user_inputs.target_weight) < 1.4
  ) {
    errors.push("Target Weight must be between 1.4 and 2.6 CWT.");
  }
  if (Number(lrp_user_inputs.number_of_head) % 1 !== 0) {
    errors.push("Number of Head must be a whole number.");
  }
  if (
    Number(lrp_user_inputs.insured_share) *
      Number(lrp_user_inputs.number_of_head) >
    40000
  ) {
    errors.push(
      "Insured Share * Number of Head must be 40000 or below. (Insured Share is defaulted to 1)",
    );
  }
  if (
    Number(lrp_user_inputs.insured_share) < 0.0001 ||
    Number(lrp_user_inputs.insured_share) > 1
  ) {
    errors.push("Insured Share Must be between .0001 and 1.");
  }
  return errors;
};

const verifyFedCattleInputs = (lrp_user_inputs: LRPQuoteUserInputs) => {
  let errors: Array<string> = [];
  if (Number(lrp_user_inputs.number_of_head) < 1) {
    errors.push("You must input at least one head of Fed Cattle.");
  }
  if (
    Number(lrp_user_inputs.target_weight) < 10 ||
    Number(lrp_user_inputs.target_weight) > 16
  ) {
    errors.push("Target Weight must be between 10 and 16 CWT.");
  }
  if (Number(lrp_user_inputs.number_of_head) % 1 !== 0) {
    errors.push("Number of Head must be a whole number.");
  }
  if (
    Number(lrp_user_inputs.insured_share) *
      Number(lrp_user_inputs.number_of_head) >
    6000
  ) {
    errors.push(
      "Insured Share * Number of Head must be 6000 or below. (Insured Share is defaulted to 1)",
    );
  }
  if (
    Number(lrp_user_inputs.insured_share) < 0.0001 ||
    Number(lrp_user_inputs.insured_share) > 1
  ) {
    errors.push("Insured Share Must be between .0001 and 1.");
  }
  return errors;
};

const verifyFeederCattleInputs = (lrp_user_inputs: LRPQuoteUserInputs) => {
  let errors: Array<string> = [];
  if (Number(lrp_user_inputs.number_of_head) < 1) {
    errors.push("You must input at least one head of Feeder Cattle.");
  }
  if (
    (lrp_user_inputs.quote_type.value === "BRAHMAN_WEIGHT_1" ||
      lrp_user_inputs.quote_type.value === "DAIRY_WEIGHT_1" ||
      lrp_user_inputs.quote_type.value === "HEIFERS_WEIGHT_1" ||
      lrp_user_inputs.quote_type.value === "STEERS_WEIGHT_1" ||
      lrp_user_inputs.quote_type.value === "UNBORN_BRAHMAN" ||
      lrp_user_inputs.quote_type.value === "UNBORN_DAIRY" ||
      lrp_user_inputs.quote_type.value === "UNBORN_STEERS_AND_HEIFERS") &&
    (Number(lrp_user_inputs.target_weight) > 5.99 ||
      Number(lrp_user_inputs.target_weight) < 1)
  ) {
    errors.push("Target Weight must be between 1 and 5.99 CWT.");
  }
  if (
    (lrp_user_inputs.quote_type.value === "BRAHMAN_WEIGHT_2" ||
      lrp_user_inputs.quote_type.value === "DAIRY_WEIGHT_2" ||
      lrp_user_inputs.quote_type.value === "HEIFERS_WEIGHT_2" ||
      lrp_user_inputs.quote_type.value === "STEERS_WEIGHT_2") &&
    (Number(lrp_user_inputs.target_weight) > 10 ||
      Number(lrp_user_inputs.target_weight) < 6)
  ) {
    errors.push("Target Weight must be between 6 and 10 CWT.");
  }
  if (Number(lrp_user_inputs.number_of_head) % 1 !== 0) {
    errors.push("Number of Head must be a whole number.");
  }
  if (
    Number(lrp_user_inputs.insured_share) *
      Number(lrp_user_inputs.number_of_head) >
    6000
  ) {
    errors.push(
      "Insured Share * Number of Head must be 6000 or below. (Insured Share is defaulted to 1)",
    );
  }
  if (
    Number(lrp_user_inputs.insured_share) < 0.0001 ||
    Number(lrp_user_inputs.insured_share) > 1
  ) {
    errors.push("Insured Share Must be between .0001 and 1.");
  }
  return errors;
};

const verifyUserInputs = (lrp_user_inputs: LRPQuoteUserInputs) => {
  try {
    let errors: Array<string> = [];
    if (lrp_user_inputs.commodity_type.value === "SWINE") {
      errors = verifySwineInputs(lrp_user_inputs);
    } else if (lrp_user_inputs.commodity_type.value === "FED_CATTLE") {
      errors = verifyFedCattleInputs(lrp_user_inputs);
    } else if (lrp_user_inputs.commodity_type.value === "FEEDER_CATTLE") {
      errors = verifyFeederCattleInputs(lrp_user_inputs);
    } else {
      return ["Unable to get lrp quote. Please try again later."];
    }
    return errors;
  } catch (error) {
    return ["Unable to get lrp quote. Please try again later."];
  }
};

export const getLRPQuoteAuth = createAsyncThunk(
  "lrp/getlrpquoteauth",
  async (lrp_user_inputs: LRPQuoteUserInputs, thunkAPI) => {
    try {
      const errors = verifyUserInputs(lrp_user_inputs);

      if (errors.length > 0) {
        return thunkAPI.rejectWithValue(errors);
      }
      const json_string = lrp_user_inputs.to_json();

      const response = await lrpService.sendLRPQuoteRequest(json_string);
      const payload = await response.data;

      if (Object.keys(JSON.parse(payload)["quote_result_dict"]).length === 0) {
        /** Check to see if the returned quote_result_dict is empty (i.e RMA
         * did not release data for a specific commodity) and reject if so.
         */
        return thunkAPI.rejectWithValue(
          `Unable to get lrp quote. Please try again later.`,
        );
      }

      return payload;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to get lrp quote. Please try again later.",
      );
    }
  },
);

export const getMostRecentDateSecureLRP = createAsyncThunk(
  "lrp/getmostrecentdatesecurelrp",
  async (_: void, thunkAPI) => {
    try {
      const response = await lrpService.sendMostRecentDateLRPRequest();
      const payload = await response.data;

      if (response.status === 200) {
        const split_date = payload.date.split("/");
        // Format date from mm/dd/yyyy to yyyy-mm-dd
        const reordered_date = "".concat(
          split_date[2],
          "-",
          split_date[0],
          "-",
          split_date[1],
        );
        // return instance of SalesEffectiveDate domain obj
        // const date_obj = new SalesEffectiveDate(reordered_date);
        // console.log("Date Obj: ", date_obj);

        // return date_obj.value;
        // return payload.date.replaceAll("/", "-");
        return reordered_date;
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to fetch most recent LRP date.",
        );
      }
    } catch {
      return thunkAPI.rejectWithValue("Unable to fetch most recent LRP date.");
    }
  },
);
