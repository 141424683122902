import React from "react";
import { makeTitle } from "../../lrp_utils/utils";

function LRPQuoteDetails({ userInputs }) {
  return (
    <div className="table-responsive mb-4" style={{ width: "100%" }}>
      {/* Quote Details */}
      <table className="table" style={{ width: "100%" }}>
        <tbody style={{ width: "100%" }}>
          <tr>
            <th scope="row" style={{ width: "15%" }}>
              Commodity Selection:
            </th>
            <td>
              <label htmlFor="">
                {/* {lrpQuoteResults.data["commodity_label"]} */}
                {makeTitle(userInputs.commodity_type.value)}
              </label>
            </td>
          </tr>
          <tr>
            <th scope="row" style={{ width: "15%" }}>
              Type Selection:
            </th>
            <td>
              <label htmlFor="">
                {/* {lrpQuoteResults.data["type_label"]} */}
                {makeTitle(userInputs.quote_type.value)}
              </label>
            </td>
          </tr>
          <tr>
            <th scope="row" style={{ width: "15%" }}>
              Number of Head
            </th>
            <td>
              <label htmlFor="">
                {userInputs.number_of_head} {"Head"}
              </label>
            </td>
          </tr>
          <tr>
            <th scope="row" style={{ width: "15%" }}>
              Target Weight
            </th>
            <td>
              <label htmlFor="">{userInputs.target_weight} CWT</label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default LRPQuoteDetails;
