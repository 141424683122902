import { createSlice } from "@reduxjs/toolkit";
// import { LRPQuoteParams, SalesEffectiveDate } from "@agdeveloper/domain";
import {
  getLRPQuoteAuth,
  getMostRecentDateSecureLRP,
} from "../features/LRPActions";
import * as globals from "../../../globals";
import { LRPAggregatedQuoteResult } from "@agdeveloper/domain";

const defaultLRPQuoteResults = {
  data: null as any,
  loading: false,
  error: false,
  message: "" as string | Array<string>,
};

const initialState = {
  policy: "",

  headCount: 0,
  CWT: "0.0",
  type: "NO_TYPE_SPECIFIED",

  lrpSalesEffectiveDate: "" as any,
  lrpQuoteResults: defaultLRPQuoteResults,

  selectedWeek: "0",
  indexPrice: 0.0,
  forecastPrice: 0.0,

  inputsChanged: false,
};

export const LRPSlice = createSlice({
  name: "LRP",
  initialState,
  reducers: {
    setLRPPolicy: (state, action) => {
      state.policy = action.payload;
      state.inputsChanged = true;
    },
    setHeadCount: (state, action) => {
      state.headCount = action.payload;
      state.inputsChanged = true;
    },
    setCWT: (state, action) => {
      state.CWT = action.payload;
      state.inputsChanged = true;
    },
    setType: (state, action) => {
      state.type = action.payload;
      state.inputsChanged = true;
    },
    setSelectedWeek: (state, action) => {
      state.selectedWeek = action.payload;
    },
    setIndexPrice: (state, action) => {
      state.indexPrice = action.payload;
    },
    setForecastPrice: (state, action) => {
      state.forecastPrice = action.payload;
    },
    resetLRPParams: (state) => {
      state.headCount = 0;
      state.CWT = "0.0";
      state.type = "NO_TYPE_SPECIFIED";
    },
    resetLRPQuoteResult: (state) => {
      state.lrpQuoteResults.data = null;
    },
  },

  extraReducers: (builder) => {
    // Get LRP Most Recent Date
    builder.addCase(getMostRecentDateSecureLRP.fulfilled, (state, action) => {
      state.lrpSalesEffectiveDate = action.payload;
    });

    // Get LRP Quote
    builder.addCase(getLRPQuoteAuth.fulfilled, (state, action) => {
      state.lrpQuoteResults.data = globals.use_domain_objects_lrp
        ? LRPAggregatedQuoteResult.from_json(action.payload)
        : action.payload;

      state.lrpQuoteResults.error = false;
      state.lrpQuoteResults.loading = false;
      state.lrpQuoteResults.message = "";
    });

    builder.addCase(getLRPQuoteAuth.pending, (state, action) => {
      state.lrpQuoteResults.error = false;
      state.lrpQuoteResults.loading = true;
      state.lrpQuoteResults.message = "";
      state.inputsChanged = false;
    });

    builder.addCase(getLRPQuoteAuth.rejected, (state, action) => {
      state.lrpQuoteResults.data = null;
      state.lrpQuoteResults.error = true;
      state.lrpQuoteResults.loading = false;
      state.lrpQuoteResults.message = Array.isArray(action.payload)
        ? action.payload
        : (action.payload as string);
    });
  },
});

export const {
  setLRPPolicy,
  // setFedCattleCount,
  // setFedCattleCWT,

  // setFeederCattleCount,
  // setFeederCattleCWT,
  // setSwineType,
  // setSwineCount,
  // setSwineCWT,
  setHeadCount,
  setCWT,
  setType,
  setIndexPrice,
  setForecastPrice,
  setSelectedWeek,
  resetLRPParams,
  resetLRPQuoteResult,
} = LRPSlice.actions;

export default LRPSlice.reducer;
