import React, { useEffect } from "react";
import { ListGroup } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import HedgeMAPListing from "../components/ProducerHedgeMAPsListing";
import { getProducersHedgeMAPs } from "../features/producerHedgeMAPsActions";
// import "./ViewHedgeMAPs.scss";
const ViewHedgeMAPs = () => {
  const dispatch = useAppDispatch();
  const { hedgemapData, message, isLoading, isError } = useAppSelector(
    (state) => state.producerHedgeMAPs.producerHedgeMAPs,
  );
  useEffect(() => {
    dispatch(getProducersHedgeMAPs());
  }, [dispatch]);

  return (
    <div
      className="container-fluid ps-4 pe-4 pl-md-1 pr-md-1"
      style={{ maxWidth: 1140 }}
    >
      {" "}
      <h5>View Your HedgeMAP(s)</h5>
      <hr className="mb-4"></hr>
      <ListGroup>
        {!isLoading ? (
          !isError ? (
            hedgemapData &&
            hedgemapData.map((listing) => <HedgeMAPListing listing={listing} />)
          ) : (
            <span
              className="display-inlint-block ms-2"
              style={{
                color: "rgb(142, 39, 54)",
              }}
            >
              {message}
            </span>
          )
        ) : (
          <div>
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span> {message} </span>
          </div>
        )}
      </ListGroup>
    </div>
  );
};
export default ViewHedgeMAPs;
