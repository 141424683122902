import { useAppSelector } from "../../app/redux/hooks";

import React, { useEffect, useState } from "react";
import {
  alertAccountDeletionRequest,
  getAgencyRegistration,
} from "../features/authActions";
import { resetAccountDeletionStatus } from "../features/authSlice";
import { useAppDispatch } from "../../app/redux/hooks";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const AccountSettings = () => {
  const dispatch = useAppDispatch();

  const { userType, isLoggedIn } = useAppSelector((state) => state.auth);
  const {
    isError: isAcountDeletionError,
    message: accountDeletionMessage,
    isLoading: accountDeletionIsLoading,
    isSuccess: isAccountDeletionSuccess,
  } = useAppSelector((state) => state.auth.accountDeletion);

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const submitDeleteAccount = () => {
    dispatch(alertAccountDeletionRequest());
  };

  return (
    <>
      {isLoggedIn && (
        <div>
          <h4 className="mt-3">Account Settings:</h4>

          <div className="mt-3">
            <table>
              <thead></thead>
              <tbody>
                <tr className="d-flex align-items-center">
                  <td
                    style={{
                      fontWeight: "bold",
                      textAlign: "right",
                      verticalAlign: "top",
                    }}
                  >
                    Delete Account:
                  </td>
                  <td className="ps-4 ">
                    <Button
                      variant="danger"
                      onClick={() => {
                        // setShowDeleteAccountConfirmationModal(
                        //   (prevState) => !prevState,
                        // )
                        dispatch(resetAccountDeletionStatus());
                        handleShowModal();
                      }}
                    >
                      Delete Account
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br></br>
          <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Delete Account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isAcountDeletionError || isAccountDeletionSuccess ? (
                accountDeletionMessage
              ) : (
                <>
                  <p>
                    You are initiating a request to delete your Ag Hedge Desk{" "}
                    {userType === "AGENT" ? "Portal" : "Dashboard"} Account.
                    Proceeding with this request will delete your account and
                    all associated data within 5 business days. You will no
                    longer be able to access the Ag Hedge Desk{" "}
                    {userType === "AGENT" ? "Portal" : "Dashboard"} after your
                    account deletion request has been procesed. Do you wish to
                    continue?
                  </p>
                  <div className="d-flex justify-content-center">
                    {accountDeletionIsLoading && (
                      <div>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span> Loading... </span>
                      </div>
                    )}
                  </div>
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                {isAccountDeletionSuccess ? "Close" : "Cancel"}
              </Button>
              {!isAccountDeletionSuccess && (
                <Button
                  disabled={accountDeletionIsLoading}
                  variant="danger"
                  onClick={() => {
                    submitDeleteAccount();
                  }}
                >
                  Delete Account
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
};
export default AccountSettings;
