import React, { useState, useEffect } from "react";
import { useAppDispatch } from "../../../../app/redux/hooks";
import { setLRPPolicy } from "../LRPSlice";

import { LRPCommodityType } from "@agdeveloper/domain";

import { makeTitle } from "../lrp_utils/utils";

const policyDisplayNames = Object.fromEntries(
  LRPCommodityType.allowed_values.map((key: string) => [key, makeTitle(key)]),
);

const DEFAULT_POLICY = LRPCommodityType.allowed_values.sort()[0];

const defaultPolicySelection = Object.fromEntries(
  LRPCommodityType.allowed_values.map((key: string) => [key, false]),
);

function LRPPolicySelector() {
  const dispatch = useAppDispatch();

  const [selectedPolicy, setSelectedPolicy] = useState(defaultPolicySelection);
  const [policyName, setPolicyName] = useState("");

  const updatePolicy = (policy_name) => {
    setSelectedPolicy({
      ...defaultPolicySelection,
      [policy_name]: true,
    });
    setPolicyName(policy_name);
  };

  const changePolicy = (e) => {
    updatePolicy(String(e.target.name));
  };

  useEffect(() => {
    updatePolicy(DEFAULT_POLICY);
  }, []);

  useEffect(() => {
    dispatch(setLRPPolicy(policyName));
  }, [policyName, dispatch]);

  return (
    <div className="card w-100">
      <div className="card-body">
        <h5 className="card-title">Policy Selection</h5>
        <p className="card-text">Please select your livestock type.</p>
        <form>
          <div className="form-group">
            {LRPCommodityType.allowed_values.sort().map((type) => {
              return (
                <div key={type}>
                  <input
                    type="radio"
                    style={{ marginRight: 10 }}
                    id={type}
                    name={type}
                    checked={selectedPolicy[`${type}`]}
                    onChange={(e) => changePolicy(e)}
                  />
                  <label htmlFor={type} className="form-check-label">
                    {policyDisplayNames[type]}
                  </label>
                </div>
              );
            })}
          </div>
        </form>
      </div>
    </div>
  );
}

export default LRPPolicySelector;
