import React, { useEffect, useState } from "react";
// import { ListGroup } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../../app/redux/hooks";
import {
  authorizeProducerToViewHedgeMAP,
  // getAllAgentsUsersAssociatedPolicyProducerAggregates,
  getUsersAssociatedPolicyProducerAggregates,
  getAllAgentsProducerHedgemapInfo,
  getAllHedgemapInfoForAgent,
  unlinkProducerAggregatedToUser,
} from "../../../../ProducerViewHedgeMAPs/features/producerHedgeMAPsActions";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import SelectUser from "./SelectUser/SelectUser";
import "./AgentAuthorizeByUser.scss";
import SelectPolicyProducer from "./SelectPolicyProducer/SelectPolicyProducer";
import SelectHedgeMAPTypes from "../AuthorizeHedgeMAPTypes/AuthorizeHedgeMAPTypes";
import {
  cloneAllHedgemapTypes,
  notEmpty,
} from "../../../utils/producerHedgeMAPsUtils";
import { resetPolicyProducerByUser } from "../../../../ProducerViewHedgeMAPs/features/producerHedgeMAPsSlice";
import EditHedgeMAPVisibilityTableByUser from "./EditHedgeMAPVisibilityTableByUser/EditHedgeMAPVisibilityTableByUser";

type ModalData = {
  userEmailAddress: any;
  policyProducerName: any;
  policyProducerAggregateID: any;
};

const AgentAuthorizeByUser = () => {
  const dispatch = useAppDispatch();

  const { userData, policyProducerData, isError, message, isLoading } =
    useAppSelector((state) => state.producerHedgeMAPs.agentAuthorization);

  //Object containing result of fetching assoicated Policy Producers 'linked' to a user through user_view_hedgemap_permissions table
  //this is updated when a new user in dropdwon selected
  const policyProducerAggregateDataForSelectedUser = useAppSelector(
    (state) =>
      state.producerHedgeMAPs.agentAuthorizeUserProducer.policyProducerData,
  );

  useEffect(() => {
    dispatch(getAllAgentsProducerHedgemapInfo());

    return () => {
      //cleanup
      dispatch(resetPolicyProducerByUser());
    };
  }, []);

  const authorizeUserToViewPolicyProducer = () => {
    const hedgemap_info = {
      DisplayName: linkedPolicyProducer.DisplayName,
      PolicyProducerAggregateID: linkedPolicyProducer.PolicyProducerAggregateID,
      SelectedHedgemapTypes: cloneAllHedgemapTypes(),
    };

    dispatch(
      authorizeProducerToViewHedgeMAP({
        authorized_email: selectedUser,
        hedgemap_info: hedgemap_info,
      }),
    );
  };

  const [linkedPolicyProducer, setLinkedPolicyProducer] = useState({} as any);

  const [selectedPolicyProducer, setSelectedPolicyProducer] = useState(
    {} as any,
  );
  const [showEditPermissionsModal, setShowEditPermissionsModal] =
    useState(false);

  const [selectedUser, setSelectedUser] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (selectedUser) {
      dispatch(getUsersAssociatedPolicyProducerAggregates(selectedUser));
    }
  }, [selectedUser]);

  const [changesMade, setChangesMade] = useState(false);

  const [modalData, setModalData] = useState<ModalData | null>(null);
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);

  const handleUnlinkShow = (payload) => {
    setModalData({
      userEmailAddress: payload.authorized_email,
      policyProducerName: payload.hedgemap_info.DisplayName,
      policyProducerAggregateID:
        payload.hedgemap_info.PolicyProducerAggregateID,
    });
    setShowUnlinkModal(true);
  };
  return (
    <div className="mt-4">
      {!isError ? (
        // !isLoading ? (
        policyProducerData &&
        policyProducerData.length > 0 &&
        userData &&
        userData.length > 0 ? (
          <div className="container">
            <div className="row g-3 mt-1">
              <div className="col-4">Select User:</div>
              {selectedUser && (
                <div className="col-4">
                  Policy Producer Aggregates Viewable By {selectedUser}:
                </div>
              )}
              {/* {selectedUser &&
                selectedPolicyProducer &&
                selectedPolicyProducer.DisplayName && (
                  <div className="col-4">
                    Edit Permissions for: {selectedUser}
                  </div>
                )} */}
            </div>
            <div className="row g-3 mt-1">
              {console.debug(userData)}
              <SelectUser
                userList={userData}
                setSelectedUser={setSelectedUser}
                selectedUser={selectedUser}
              />

              <SelectPolicyProducer
                policyProducerList={policyProducerAggregateDataForSelectedUser}
                selectedPolicyProducer={selectedPolicyProducer}
                setSelectedPolicyProducer={setSelectedPolicyProducer}
                selectedUser={selectedUser}
                handleShow={handleShow}
                handleUnlinkShow={handleUnlinkShow}
              />

              {policyProducerAggregateDataForSelectedUser &&
                policyProducerAggregateDataForSelectedUser.length > 0 &&
                selectedPolicyProducer &&
                notEmpty(selectedPolicyProducer) && (
                  <div className="col-4">
                    {policyProducerAggregateDataForSelectedUser
                      .filter(
                        (obj: any, index) =>
                          obj.DisplayName ===
                          selectedPolicyProducer.DisplayName,
                      )
                      .map((obj: any, index) => {
                        return (
                          <SelectHedgeMAPTypes
                            policyProducer={obj}
                            user={selectedUser}
                            setChangesMade={setChangesMade}
                            showEditPermissionsModal={showEditPermissionsModal}
                            setShowEditPermissionsModal={
                              setShowEditPermissionsModal
                            }
                          />
                        );
                      })}
                  </div>
                )}
            </div>

            <Modal show={show} onHide={handleClose} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Confirm Authorization</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  Linking this portal user to a Policy Producer will authorize
                  the user to view all HedgeMAPs associated with that Policy
                  Producer. To restrict this user to only certain types of
                  HedgeMAPs, continue with the authorization by clicking
                  'Confirm' and then restrict permissions on the next page by
                  clicking 'View Types' next to the User / Policy Producer
                  combination you would like to restrict.
                </p>

                <div className="row g-3 mt-1">
                  {/* Labels row */}
                  <div className="col-4 d-flex justify-content-center">
                    <label htmlFor="user-select">User</label>
                  </div>
                  <div className="col-4 d-flex align-items-center justify-content-center">
                    {/* This is just the space for the arrow. No label needed. */}
                  </div>
                  <div className="col-4 d-flex justify-content-center">
                    <label htmlFor="user-select">Producer</label>
                  </div>

                  {/* Dropdowns row */}
                  <div className="col-4">
                    <div>{selectedUser}</div>
                  </div>
                  <div className="col-4 d-flex align-items-center justify-content-center">
                    <span>⇄</span>
                  </div>
                  <div className="col-4">
                    <select
                      name="user-select"
                      id="user-select"
                      className="form-control ag-form-focus"
                      defaultValue=""
                      onChange={(e) => {
                        const newData = policyProducerData.find(
                          (item: any) => item.DisplayName === e.target.value,
                        );

                        setLinkedPolicyProducer(newData);
                      }}
                    >
                      <option value="" disabled hidden>
                        Select Producer
                      </option>
                      {policyProducerData
                        .slice()
                        .sort((a: any, b: any) => {
                          if (a.DisplayName < b.DisplayName) {
                            return -1;
                          }
                          if (a.DisplayName > b.DisplayName) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((obj: any, index) => (
                          <option
                            key={index}
                            value={obj.DisplayName}
                            disabled={
                              policyProducerAggregateDataForSelectedUser.filter(
                                (item) => item.DisplayName === obj.DisplayName,
                              ).length > 0
                                ? true
                                : false
                            }
                          >
                            {obj.DisplayName}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  disabled={!notEmpty(linkedPolicyProducer)}
                  variant="primary"
                  onClick={() => {
                    authorizeUserToViewPolicyProducer();

                    handleClose();
                  }}
                >
                  Confirm
                </Button>
              </Modal.Footer>
            </Modal>

            {/* Modal for confiring they want to unlink producer */}
            <Modal
              show={showUnlinkModal}
              onHide={() => {
                setShowUnlinkModal(false);

                setModalData(null);
              }}
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title>Confirm Removal</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  If you proceed portal user{" "}
                  {modalData && modalData.userEmailAddress} will no longer be
                  able to view any HedgeMAPs for Policy Producer{" "}
                  {modalData && modalData.policyProducerName}. Are you sure you
                  want to <strong>remove</strong> all HedgeMAP permissions for{" "}
                  <strong>{modalData && modalData.userEmailAddress}</strong> on{" "}
                  <strong>{modalData && modalData.policyProducerName}</strong>?
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowUnlinkModal(false);
                    setModalData(null);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  // disabled={!notEmpty(linkedPolicyProducer)}
                  variant="primary"
                  onClick={() => {
                    const hedgemap_info = {
                      DisplayName: modalData && modalData.policyProducerName,
                      PolicyProducerAggregateID:
                        modalData && modalData.policyProducerAggregateID,
                    };
                    const payload = {
                      authorized_email: modalData && modalData.userEmailAddress,
                      hedgemap_info: hedgemap_info,
                    };

                    dispatch(unlinkProducerAggregatedToUser(payload));

                    setModalData(null);
                    setShowUnlinkModal(false);
                  }}
                >
                  Yes, remove
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        ) : (
          // If hedgemap length less than 1 then show no hedgemaps available else show no producers avaiable

          <div>
            {userData && userData.length < 1 ? (
              <div className="row g-3 mt-1">
                <div className="col-12">
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {"  "}
                      <span>Loading users...</span>
                    </>
                  ) : (
                    <p>
                      No users available to authorize. You may only authorize
                      users who have designated you as their Agent.
                    </p>
                  )}
                </div>
              </div>
            ) : (
              <div className="row g-3 mt-1">
                <div className="col-12">
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {"  "}
                      <span>Loading users...</span>
                    </>
                  ) : (
                    <p>
                      You are not listed as an Agent on any available HedgeMAPs.
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        )
      ) : (
        <div>{message}</div>
      )}
      <EditHedgeMAPVisibilityTableByUser
        changesMade={changesMade}
        setSelectedPolicyProducer={setSelectedPolicyProducer}
        setSelectedUser={setSelectedUser}
        setShowEditPermissionsModal={setShowEditPermissionsModal}
        handleUnlinkShow={handleUnlinkShow}
      />
    </div>
  );
};
export default AgentAuthorizeByUser;
