import React, { useState, useEffect, useReducer } from "react";
import { setForecastPrice } from "../../LRPSlice";
import { useAppSelector, useAppDispatch } from "../../../../../app/redux/hooks";
import IndemnitySliderButton from "../../../../DRP/components/ResultTableIndemnities/IndemnitySliderButton";
import useInterval from "../../../../../app/utils/hooks/useInterval";

const initalState = {
  incrButtonDown: false,
  targetSlider: "",
  incrementBy: 0.5,
};

const localReducer = (state, action) => {
  if (action.type === "start") {
    return {
      incrButtonDown: true,
      targetSlider: action.slider,
      incrementBy: action.incrementBy,
    };
  }
  if (action.type === "stop") {
    return {
      incrButtonDown: false,
      targetSlider: "",
      incrementBy: 0.5,
    };
  }

  throw Error("Unknown action.");
};
function LRPIndemnityCalculator({ stripQuotes }) {
  const [state, localDispatch] = useReducer(localReducer, initalState);

  const dispatch = useAppDispatch();
  const { indexPrice, forecastPrice } = useAppSelector((state) => state.lrp);

  const [localForecastPrice, setLocalForecastPrice] = useState(0);
  const [sliderRange, setSliderRange] = useState({
    MIN: -40,
    MAX: 40,
  });

  const incrementSlider = (slider: string, value: number) => {
    let func: Function = (_: number) => {};
    switch (slider) {
      case "LRP Forecast Price":
        func = (value) => {
          updateFromForecastSlider(localForecastPrice + value);
        };
        break;
    }
    func(Number(value));
  };

  const updateFromForecastSlider = (value: number) => {
    setLocalForecastPrice(value);
  };

  const startIncrementSlider = (slider: string, value: number) => {
    localDispatch({ type: "start", slider: slider, incrementBy: value });
  };
  const stopSliderIncrement = () => {
    localDispatch({ type: "stop" });
  };
  const resetSlider = () => {
    setLocalForecastPrice(Number(indexPrice));
  };

  useEffect(() => {
    setLocalForecastPrice(indexPrice);
    setSliderRange({
      MIN: indexPrice - 40,
      MAX: indexPrice + 40,
    });
  }, [indexPrice]);

  useEffect(() => {
    dispatch(setForecastPrice(localForecastPrice));
  }, [localForecastPrice, dispatch]);

  useInterval(() => {
    if (state.incrButtonDown && state.targetSlider) {
      incrementSlider(state.targetSlider, state.incrementBy);
    }
    // Set interval to run while button is pressed.
  }, [state.incrButtonDown, state.targetSlider]);

  return (
    <div
      className="card"
      // className={`card ${
      //   (!lrpQuoteResults.data || lrpQuoteResults.loading) && "hidden"
      // }`}
      style={{ display: "block", minWidth: "352px" }}
    >
      <div className="card-body">
        <h5 className="card-title">Indemnity Calculator</h5>
        <p className="card-text">
          Use the slider below to see how your endorsement will react under
          various market conditions.
        </p>
        <div className="form-group">
          <table>
            <thead></thead>
            <tbody></tbody>
          </table>
          <table
            className="table table-sm table-borderless"
            style={{ display: "block" }}
          >
            <tbody>
              <tr>
                <td>
                  <p>Index Price:</p>
                </td>
                <td
                  className="fw-bold"
                  colSpan={2}
                  style={{
                    textAlign: "left",
                    color: "rgb(31, 103, 189)",
                  }}
                >
                  {forecastPrice.toFixed(2)}
                </td>
              </tr>
              <tr>
                <th scope="row">Forecast Price:</th>
                <td className="button-container">
                  <IndemnitySliderButton
                    name={"LRP Forecast Price"}
                    value={-0.5}
                    onClick={incrementSlider}
                    onMouseDown={startIncrementSlider}
                    onMouseUp={stopSliderIncrement}
                  />
                </td>
                <td>
                  <input
                    type="range"
                    value={localForecastPrice}
                    min={sliderRange.MIN}
                    max={sliderRange.MAX}
                    onChange={(e) =>
                      updateFromForecastSlider(Number(e.target.value))
                    }
                    step={state.incrementBy}
                  />
                </td>
                <td className="button-container">
                  <IndemnitySliderButton
                    name={"LRP Forecast Price"}
                    value={0.5}
                    onClick={incrementSlider}
                    onMouseDown={startIncrementSlider}
                    onMouseUp={stopSliderIncrement}
                  />
                </td>
                <td>
                  <div className="fw-normal">({indexPrice.toFixed(2)})</div>
                </td>
              </tr>
            </tbody>
          </table>
          <button
            className="btn btn-secondary btn-sm"
            style={{ marginLeft: "3px" }}
            onClick={resetSlider}
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
}

export default LRPIndemnityCalculator;
