import {
  sendDownloadPodcastRequest,
  sendViewHedgeMAPsRequest,
} from "../../app/utils/sendRequest";
import * as globals from "../../globals";

//get all podcasts from the server
const sendGetPodcastsRequest = async () => {
  const apifunc = `service-get-podcasts/get-all-podcasts`;
  const args = {};

  return sendViewHedgeMAPsRequest(apifunc, args);
};

//endpoint for downloading podcast all at once
const sendPodcastDownloadRequest = async (name: string, dispatch: any) => {
  const apifunc = `service-get-podcasts/download_podcast`;
  const args = {
    name: name,
    src: globals.src,
  };

  return sendDownloadPodcastRequest(apifunc, args, dispatch);
};

// const sendGetStreamURLRequest = async (name: string) => {
//   const apifunc = `service-get-podcasts/get_streamable_url`;

//   const args = {
//     name: name,
//     src: globals.src,
//   };

//   return sendViewHedgeMAPsRequest(apifunc, args);
// };

const sendGetStreamTokenRequest = async (name: string) => {
  const apifunc = `service-get-podcasts/get_stream_token`;
  const args = {
    name: name,
    src: globals.src,
  };

  return sendViewHedgeMAPsRequest(apifunc, args);
};

const hedgeMAPsService = {
  sendGetPodcastsRequest,
  sendPodcastDownloadRequest,
  // sendGetStreamURLRequest,
  sendGetStreamTokenRequest,
};

export default hedgeMAPsService;
