import React, { useEffect, useState } from "react";
import * as d3Format from "d3-format";
import * as d3TimeFormat from "d3-time-format";
import * as d3Array from "d3-array";

import "./HistoricalPriceChart.scss";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import { useAppSelector } from "../../../../app/redux/hooks";
import useWindowSize from "../../../../app/utils/hooks/useWindowSize";

const MIN_WIDTH = 990; // Min width before number of ticks on X Axis is reduced.

const formatPrice = (product: any, price: number) => {
  let priceFormatter: any;

  switch (product) {
    case "Class III":
    case "Class IV":
      priceFormatter = d3Format.format("$.2f");
      break;
    case "Butterfat":
    case "Protein":
    case "Other Solids":
    case "Nonfat Solids":
      priceFormatter = d3Format.format("$.4f");
      break;
  }

  return priceFormatter(price);
  // return priceFormatter;
};

function generateTicks(startDate: number): Array<number> {
  let _ticks = [] as any;
  let _startDate = new Date(startDate);
  _ticks.push(startDate);

  for (let i = 0; i < 18; i++) {
    // Push 17 following months starting from first month
    _ticks.push(new Date(_startDate).setMonth(_startDate.getMonth() + i));
  }
  return _ticks;
}

function CustomToolTip({ active, payload, label, product }: any) {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        <div className="tooltip-price">
          {formatPrice(product, payload[0].value)}
        </div>

        <div>
          {new Date(payload[0].payload.date).toLocaleDateString("en-US", {
            dateStyle: "medium",
          })}
        </div>
      </div>
    );
  }
  return null;
}

const parseDate = d3TimeFormat.timeParse("%Y/%m/%d");
const monthFormatter = d3TimeFormat.timeFormat("%b");

function HistoricalPriceChart() {
  const { historicalPrices, chartData, isChartLoading } = useAppSelector(
    (state) => state.drp,
  );
  const { quarter, product } = chartData;
  const windowSize = useWindowSize();

  const [priceData, setPriceData] = useState<any>(null);
  const [data, setData] = useState<any>([]);
  const [xticks, setTicks] = useState<Array<number>>([]);
  const [yticks, setYTicks] = useState<Array<number>>([]);

  const [interval, setInterval] = useState<number>(0);
  const [domain, setDomain] = useState<Array<number>>([0, 0]);
  const [yAxisRange, setYAxisRange] = useState<Array<any>>([0, "auto"]);

  const [noData, setNoData] = useState(false);

  useEffect(() => {
    // Get relevant price data for selected quarter/product
    // when chartData changes.

    setPriceData(historicalPrices[product + "_" + quarter]);
    // dont trigger with histroical Prices
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  useEffect(() => {
    if (priceData) {
      if (priceData.prices.values.length === 0) {
        // Set no data = true if no values in prices
        setNoData(true);
      } else {
        setNoData(false);
        let _data = [] as any;
        for (let i = 0; i < priceData.prices.dates.length; i++) {
          _data.push({
            date: Number(parseDate(priceData.prices.dates[i])), // Parse and store dates as Unix Epochs
            price: priceData.prices.values[i],
          });
        }

        setData(_data);
        let _ticks = generateTicks(_data.length > 0 && _data[0].date) as any;
        setTicks(_ticks);

        setDomain([_ticks[0], _ticks[_ticks.length - 1]]);
        defineParameters(product, priceData.prices.values);
      }
    }
    // dont trigger with new product data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceData]);

  // Reduce count of X Axis ticks when window width below MIN_WIDTH
  useEffect(() => {
    if (windowSize.width! < MIN_WIDTH && interval === 0) {
      setInterval(3);
    } else if (windowSize.width! >= MIN_WIDTH && interval === 3) {
      setInterval(0);
    }
    // dont trigger on interval change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.width]);

  const defineParameters = (product: any, data: Array<number>) => {
    let yMin;
    let yMax;
    switch (product) {
      case "Class III":
      case "Class IV":
        yMin = 15;
        yMax = 19;
        break;
      case "Butterfat":
      case "Protein":
        yMax = 4;
        break;
      case "Other Solids":
        yMax = 0.5;
        yMin = 0;
        break;
      case "Nonfat Solids":
        break;
    }

    const extent = d3Array.extent(data, (d) => d);
    const min = extent[0] as number;
    const max = extent[1] as number;

    yMin = Math.round((yMin < min ? yMin : min * 0.95) * 1000) / 1000;
    yMax = Math.round((yMax > max ? yMax : max * 1.05) * 1000) / 1000;

    let _yticks = [] as any;

    for (let i = Math.floor(yMin); i <= Math.ceil(yMax); i += 0.5) {
      _yticks.push(i);
    }
    setYTicks(_yticks);

    if (yMax === -1) {
      yMax = "auto";
    }

    setYAxisRange([yMin, yMax]);
  };

  return (
    <>
      {isChartLoading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading Chart...</span>
          </div>
        </div>
      ) : priceData && !noData ? (
        <ResponsiveContainer width={"99%"} height={400}>
          <LineChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="5 5" vertical={false} />
            <XAxis
              dataKey="date"
              angle={-45}
              textAnchor="end"
              type="number"
              domain={[domain[0], domain[1]]}
              tickFormatter={monthFormatter}
              ticks={xticks}
              interval={interval}
            ></XAxis>
            <YAxis
              tickFormatter={(value) => `$${value.toFixed(2)}`}
              type="number"
              domain={yAxisRange}
              ticks={yticks}
              // tickCount={10}
              // allowDecimals={false}
            ></YAxis>
            <Tooltip
              isAnimationActive={false}
              content={<CustomToolTip product={product} />}
            />
            {/* <Legend verticalAlign="top" height={36} /> */}
            <Line
              type="monotone"
              dot={false}
              strokeWidth={3}
              activeDot={{
                stroke: "black",
                strokeWidth: 4,
                r: 2,
              }}
              dataKey="price"
              stroke="#6495ed"
              fill="#6495ed"
              isAnimationActive={false}
            />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <div className="d-flex justify-content-center">No Data Available</div>
      )}
    </>
  );
}

// export default HistoricalPriceChart;
export const MemoizedHistoricalPriceChart = React.memo(HistoricalPriceChart);
