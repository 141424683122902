import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/redux/hooks";
import { setProtectionFactor } from "../../features/DRPSlice";

function ProtectionFactor(props) {
  const dispatch = useAppDispatch();
  const { protectionFactor } = useAppSelector((state) => state.drp);

  const [slider, setSlider] = useState(protectionFactor.slider);

  useEffect(() => {
    // Input value validation
    if (slider > 10) {
      setSlider(10);
    }
    if (slider < 0) {
      setSlider(0);
    }
    // setCurrentFactor();
    dispatch(
      setProtectionFactor({
        value: (1 + (0.5 / 10) * slider).toFixed(2),
        slider: slider,
      }),
    );
  }, [slider, dispatch]);

  const updateSlider = (e) => {
    setSlider(e.target.value);
  };

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Protection Factor</h5>
        <p className="card-text">
          {props.flag === "class-vs-component"
            ? "Locked at 1.5x for quote comparison."
            : "Select your protection factor."}
        </p>
        <p className="text-center fw-bold">
          {props.flag === "class-vs-component"
            ? "1.50"
            : protectionFactor.value}
        </p>
        <div className="form-group text-center">
          <div className="d-inline-block">
            1.0x
            <br />
          </div>
          <div className="d-inline-block">
            <input
              onChange={updateSlider}
              type="range"
              name="range"
              id="range"
              min="0"
              max="10"
              value={props.flag === "class-vs-component" ? 10 : slider}
              disabled={props.flag === "class-vs-component" ? true : false}
            />

            <br />
          </div>
          <div className="d-inline-block">
            1.5x
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProtectionFactor;
