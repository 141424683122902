import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <div>
        <div className="text-center my-4">
          <h4>SUPPORT</h4>
        </div>

        <div className="text-center">
          <p>For app support please contact &lt;support@aghedgedesk.com&gt;.</p>
        </div>
        <div className="text-center">
          &copy; 2024 Ag Hedge Desk
          <br />
          All rights reserved.
        </div>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
