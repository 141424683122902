import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/redux/hooks";
import { unlinkProducerAggregatedToUser } from "../../../../../ProducerViewHedgeMAPs/features/producerHedgeMAPsActions";
import { notEmpty } from "../../../../utils/producerHedgeMAPsUtils";

type SelectPolicyProducerProps = {
  usersList: string[];
  selectedUser: any;
  setSelectedUser: (value: string) => void;
  selectedPolicyProducerAggregate: any;
  handleShow: Function;
  handleUnlinkShow: Function;
};

const SelectUsersAuthorizedForPolicyProducer = (
  props: SelectPolicyProducerProps,
) => {
  const {
    usersList,
    selectedUser,
    setSelectedUser,
    selectedPolicyProducerAggregate,
    handleShow,
    handleUnlinkShow,
  } = props;

  // console.log(usersList);
  const dispatch = useAppDispatch();

  const {
    isLoading: isDeletionLoading,
    // message,
    // isError,
  } = useAppSelector(
    (state) => state.producerHedgeMAPs.unlinkProducersFromUsers,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [unlinkingTarget, setUnlinkingTarget] = useState("");

  useEffect(() => {
    setIsLoading(true);
  }, [selectedPolicyProducerAggregate]);

  useEffect(() => {
    setIsLoading(false);
  }, [usersList]);

  return (
    <>
      {isLoading ? (
        <div className="col-4">
          <div className="list-group">
            <div className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
              Loading...
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-4">
          {usersList && usersList.length > 0 ? (
            <div className="list-group">
              {usersList.map((email, index) => (
                <div
                  key={index}
                  style={{ cursor: "pointer", overflow: "hidden" }}
                  className={`list-group-item list-group-item-action d-flex justify-content-between align-items-center ${
                    selectedUser === email ? "active" : ""
                  }`}
                  onClick={() => {
                    setSelectedUser(email);
                  }}
                >
                  <span
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {email}
                  </span>

                  {isDeletionLoading && email === unlinkingTarget ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <i
                      className="bx bx-trash float-right"
                      onClick={() => {
                        setUnlinkingTarget(email);
                        const hedgemap_info = {
                          DisplayName:
                            selectedPolicyProducerAggregate.DisplayName,
                          PolicyProducerAggregateID:
                            selectedPolicyProducerAggregate.PolicyProducerAggregateID,
                        };
                        const payload = {
                          authorized_email: email,
                          hedgemap_info: hedgemap_info,
                        };
                        handleUnlinkShow(payload);
                      }}
                    ></i>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="custom-list-box overflow-auto p-3 border rounded bg-white">
              {selectedPolicyProducerAggregate.DisplayName
                ? "No Portal Users can currently view this Policy Producer's HedgeMAPs. To authorize a user to view this Policy Producer's HedgeMAPs, click 'Add User'"
                : "Please select a Policy Producer from the dropdown to view the users that can see their HedgeMAPs"}
            </div>
          )}

          {notEmpty(selectedPolicyProducerAggregate) && (
            <div className="mt-4 d-flex justify-content-center align-items-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleShow();
                }}
              >
                Add User
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SelectUsersAuthorizedForPolicyProducer;
