import { createSlice } from "@reduxjs/toolkit";
import * as globals from "../../../globals";
import {
  getMostRecentDateSecure,
  getMostRecentPricesSecure,
  updateHistoricalPrices,
  getDRPQuoteSecure,
  getYields,
  getClassVsComponentDRPQuoteSecure,
  getStripDRPQuoteSecure,
  downloadQuoteResultAsPDF,
} from "./DRPActions";
import {
  DRPQuoteResult,
  DRPStripQuoteResult,
  DRPStripQuoteParams,
} from "@agdeveloper/domain";
const defaultDRPResults = {
  data: null as any,
  loading: false,
  error: false,
  message: "",
};

const _handleDownload = (res) => {
  // let regexp = /filename=(.*?)$/;
  let saving_file_name = res.headers["content-disposition"].split("'")[1];

  const fileURL = window.URL.createObjectURL(res.data);

  let alink = document.createElement("a");
  alink.href = fileURL;
  alink.download = saving_file_name;
  alink.click();
  return saving_file_name;
};

const initialState = {
  currentState: globals.default_state,
  isError: false,
  message: "",
  quarterProduction: "3,000,000",
  quarterLabels: [],
  quarterPriceList: {},
  selectedQuarter: "",
  coverageType: "CLPROP",
  coverageLevel: 0.95,
  historicalPrices: {},
  isChartLoading: true,
  salesEffectiveDate: "",

  drpInputsChanged: true,

  // DRP Quote Results
  drpQuoteResults: defaultDRPResults,

  chartData: {
    quarter: "",
    product: "",
  },

  // Class Based Weighing Defaults
  classWeighting: {
    values: {
      class3: 100,
      class4: 0,
    },
    slider: 0,
  },

  // Protection Factor Defaults
  protectionFactor: {
    value: 1.0,
    slider: 0,
  },

  // Component Based Weighing Defaults
  componentWeighting: {
    values: {
      butterfat: (3.25).toFixed(2),
      protein: (2.75).toFixed(2),
      nonfat: 0,
      cmp_price_wf: (1).toFixed(2),
    },
    sliders: {
      butterfat: { current: 5, max: 45 },
      protein: { current: 5, max: 35 },
      nonfat: { current: 0, max: 20 },
    },
  },

  // Yields
  yields: {},

  // Indemnity Variables:
  indemnityBasePrices: {
    class3Price: 0,
    class4Price: 0,
    butterfatPrice: 0,
    nonfatPrice: 0,
    otherSolidsPrice: 0,
    proteinPrice: 0,
  },
  indemnityType: "CLPROP",

  nonfatExtras: {
    componentPriceWeightingFactorRestrictedValue: "-",
    isNonfatLocked: false,
    // componentPriceRestrictedValues: {},
  },
  classWeightingExtras: {
    classPriceWeightingFactorRestrictedValue: "-",
    isClassLocked: false,
    // componentPriceRestrictedValues: {},
  },
  // Indemnity
  single: {
    estimatedIndemnity: 0.0,
    estimatedEndorsementValue: 0.0,
  },
  classVsComponent: {
    estimatedIndemnity: { CLPROP: 0.0, CMPROP: 0.0 },
    estimatedEndorsementValue: { CLPROP: 0.0, CMPROP: 0.0 },
    indexValue: { CLPROP: 0.0, CMPROP: 0.0 },
  },

  quoteDownload: {
    projectedPrices: {} as any,
    loading: false,
    error: false,
    message: "",
    success: false,
  },
};

export const DRPSlice = createSlice({
  name: "DRP",
  initialState,
  reducers: {
    setDRPMessage: (state, action) => {
      state.message = action.payload;
    },
    setCurrentState: (state, action) => {
      state.currentState = action.payload;
      state.drpInputsChanged = true;
    },
    setQuarterProduction: (state, action) => {
      state.quarterProduction = action.payload;
      state.drpInputsChanged = true;
    },
    setQuarterLabels: (state, action) => {
      state.quarterLabels = action.payload;
      state.drpInputsChanged = true;
    },
    setSelectedQuarter: (state, action) => {
      state.selectedQuarter = action.payload;
      state.drpInputsChanged = true;
    },
    setClassBasedWeighting: (state, action) => {
      state.classWeighting = { ...action.payload };
      state.drpInputsChanged = true;
    },
    setProtectionFactor: (state, action) => {
      state.protectionFactor = { ...action.payload };
      state.drpInputsChanged = true;
    },
    setCoverageType: (state, action) => {
      state.coverageType = action.payload;
      state.drpInputsChanged = true;
    },
    setCoverageLevel: (state, action) => {
      state.coverageLevel = action.payload;
      state.drpInputsChanged = true;
    },
    setComponentWeighting: (state, action) => {
      state.componentWeighting = { ...action.payload };
      state.drpInputsChanged = true;
    },
    setChartData: (state, action) => {
      state.chartData = { ...action.payload };
    },
    setIndemnityBasePrices: (state, action) => {
      state.indemnityBasePrices = {
        ...action.payload,
      };
    },
    setIndemnityValueSingle: (state, action) => {
      state.single.estimatedIndemnity = action.payload;
    },
    setEndorsementValueSingle: (state, action) => {
      state.single.estimatedEndorsementValue = action.payload;
    },
    setIndemnityValueClassVsComponent: (state, action) => {
      state.classVsComponent.estimatedIndemnity = action.payload;
    },
    setEndorsementValueClassVsComponent: (state, action) => {
      state.classVsComponent.estimatedEndorsementValue = action.payload;
    },
    setIndexValueClassVsComponent: (state, action) => {
      state.classVsComponent.indexValue = action.payload;
    },
    resetDRPResults: (state) => {
      state.drpQuoteResults.data = null;
    },
    setNonfatExtras: (state, action) => {
      state.nonfatExtras = action.payload;
    },
    setClassWeightingExtras: (state, action) => {
      state.classWeightingExtras = action.payload;
    },
    setProjectedPrices: (state, action) => {
      state.quoteDownload.projectedPrices = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get Most Recent Prices Secure
    builder
      .addCase(getMostRecentPricesSecure.fulfilled, (state, action) => {
        state.isError = false;
        state.quarterPriceList = action.payload;
      })
      .addCase(getMostRecentPricesSecure.rejected, (state, action) => {
        state.isError = true;
        state.message = action.payload as string;
      });

    // Get/Update Historical Prices
    builder
      .addCase(updateHistoricalPrices.fulfilled, (state, action) => {
        state.historicalPrices = action.payload;
        state.isChartLoading = false;
      })
      .addCase(updateHistoricalPrices.rejected, (state, action) => {
        state.message = action.payload as string;
        state.isChartLoading = false;
      })
      .addCase(updateHistoricalPrices.pending, (state) => {
        state.isChartLoading = true;
      });

    // Get/Update most recent date
    builder.addCase(getMostRecentDateSecure.fulfilled, (state, action) => {
      state.salesEffectiveDate = action.payload;
    });

    // Get DRP Quote
    builder
      .addCase(getDRPQuoteSecure.fulfilled, (state, action) => {
        state.drpQuoteResults.data = DRPQuoteResult.from_json(action.payload);
        state.drpQuoteResults.loading = false;
        state.drpQuoteResults.error = false;
        state.drpQuoteResults.message = "";
        state.drpInputsChanged = false;
        state.indemnityType =
          state.drpQuoteResults.data["quote_params"]["quote_type"]["value"];
      })
      .addCase(getDRPQuoteSecure.pending, (state) => {
        state.drpQuoteResults.loading = true;
      })
      .addCase(getDRPQuoteSecure.rejected, (state, action) => {
        state.drpQuoteResults.data = null;
        state.drpQuoteResults.loading = false;
        state.drpQuoteResults.error = true;
        state.drpQuoteResults.message = action.payload as string;
      });
    // Get Class Vs Component DRP Quote
    builder
      .addCase(getClassVsComponentDRPQuoteSecure.fulfilled, (state, action) => {
        state.drpQuoteResults.data = {
          CLPROP: DRPQuoteResult.from_json(action.payload["CLPROP"]),
          CMPROP: DRPQuoteResult.from_json(action.payload["CMPROP"]),
        };
        state.drpQuoteResults.loading = false;
        state.drpQuoteResults.error = false;
        state.drpQuoteResults.message = "";
        state.drpInputsChanged = false;
        state.indemnityType = "CMPROP";
      })
      .addCase(getClassVsComponentDRPQuoteSecure.pending, (state) => {
        state.drpQuoteResults.loading = true;
      })
      .addCase(getClassVsComponentDRPQuoteSecure.rejected, (state, action) => {
        state.drpQuoteResults.data = null;
        state.drpQuoteResults.loading = false;
        state.drpQuoteResults.error = true;
        state.drpQuoteResults.message = action.payload as string;
      });

    // Get DRP Strip Quote
    builder
      .addCase(getStripDRPQuoteSecure.fulfilled, (state, action) => {
        state.drpQuoteResults.data = {
          drp_strip_quote_result: DRPStripQuoteResult.from_json(
            action.payload["drp_strip_quote_result"],
          ),
          drp_strip_quote_params: DRPStripQuoteParams.from_json(
            action.payload["drp_strip_quote_params"],
          ),
        };

        state.drpQuoteResults.loading = false;
        state.drpQuoteResults.error = false;
        state.drpQuoteResults.message = "";
        state.drpInputsChanged = false;
        state.indemnityType = action.payload["TypeAbbreviation"];
      })
      .addCase(getStripDRPQuoteSecure.pending, (state) => {
        state.drpQuoteResults.loading = true;
      })
      .addCase(getStripDRPQuoteSecure.rejected, (state, action) => {
        state.drpQuoteResults.data = null;
        state.drpQuoteResults.loading = false;
        state.drpQuoteResults.error = true;
        state.drpQuoteResults.message = action.payload as string;
      });
    // Get yields data
    // TODO: Yields error handling
    builder.addCase(getYields.fulfilled, (state, action) => {
      state.yields = action.payload;
    });

    // Get/Update Historical Prices
    builder
      .addCase(downloadQuoteResultAsPDF.fulfilled, (state, action) => {
        state.quoteDownload.error = false;
        state.quoteDownload.message = "";
        state.quoteDownload.loading = false;
        state.quoteDownload.success = true;
        _handleDownload(action.payload);
      })
      .addCase(downloadQuoteResultAsPDF.rejected, (state, action) => {
        state.quoteDownload.error = true;
        state.quoteDownload.message = "Download Failed. Try agian later.";
        state.quoteDownload.loading = false;
        state.quoteDownload.success = false;
      })
      .addCase(downloadQuoteResultAsPDF.pending, (state) => {
        state.quoteDownload.loading = true;
        state.quoteDownload.error = false;
        state.quoteDownload.message = "";
        state.quoteDownload.success = false;
      });
  },
});

export const {
  setDRPMessage,
  setCurrentState,
  setClassBasedWeighting,
  setProtectionFactor,
  setCoverageType,
  setCoverageLevel,
  setComponentWeighting,
  setQuarterProduction,
  setQuarterLabels,
  setSelectedQuarter,
  setChartData,
  setIndemnityBasePrices,
  setIndemnityValueSingle,
  setEndorsementValueSingle,
  setIndemnityValueClassVsComponent,
  setEndorsementValueClassVsComponent,
  setNonfatExtras,
  setProjectedPrices,
  setClassWeightingExtras,
  setIndexValueClassVsComponent,
  resetDRPResults,
} = DRPSlice.actions;
export default DRPSlice.reducer;
