import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import {
  requestVerificationEmail,
  checkEmailVerification,
} from "../../features/authActions";
import { reset } from "../../features/authSlice";

function EmailVerification() {
  const dispatch = useAppDispatch();
  const { emailVerification, email } = useAppSelector((state) => state.auth);

  const [mailSent, setMailSent] = useState(false);
  const [mailVerified, setMailVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [firstCheck, setFirstCheck] = useState(true);
  const [recheckingStatus, setRecheckingStatus] = useState(false);
  const sendEmail = () => {
    dispatch(requestVerificationEmail());
  };
  const [sendEmailConfirmationDisabled, setSendEmailConfirmationDisabled] =
    useState(false);

  const disableSendButtonTemporarily = () => {
    // Disable the button
    setSendEmailConfirmationDisabled(true);

    // Enable the button after 1 minute
    const timer = setTimeout(() => {
      setSendEmailConfirmationDisabled(false);
    }, 90000); // 1 minute 30 seconds

    // Cleanup the timer when the component unmounts or the dependency array changes
    return () => clearTimeout(timer);
  };

  const checkVerification = async () => {
    if (firstCheck) {
      setFirstCheck(false);
    }
    setRecheckingStatus(true);

    await dispatch(checkEmailVerification());
    setRecheckingStatus(false);
  };

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  useEffect(() => {
    console.info(emailVerification);
    if (emailVerification.isVerificationSent) {
      setMailSent(true);
      setErrorMessage("");
    }

    if (
      !emailVerification.isVerificationSent &&
      emailVerification.isError &&
      emailVerification.message
    ) {
      disableSendButtonTemporarily();
      setErrorMessage(emailVerification.message);
    }
  }, [
    emailVerification.isVerificationSent,
    emailVerification.isError,
    emailVerification.message,
  ]);

  useEffect(() => {
    if (emailVerification.isEmailVerified) {
      setMailVerified(true);
    }
  }, [emailVerification.isEmailVerified]);

  return (
    <div className="alert alert-dark m-2 p-4">
      {mailVerified ? (
        <>
          <h4 className="alert-heading">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-check-circle-fill mx-1"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              Email confirmed!
            </span>
          </h4>
          <p>{email} has been verified!</p>
        </>
      ) : (
        <>
          <h4 className="alert-heading">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-exclamation-triangle-fill mx-1"
                viewBox="0 0 16 16"
              >
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
              </svg>
              Confirm Your Email Address
            </span>
          </h4>
          <p>
            You have not yet confirmed your email address. Please confirm your
            email address in order to access the portal. (If you have already
            clicked the confirmation link sent to your email address, click
            "Re-check" or log out and log back in to the portal to refresh your
            verification status.)
          </p>
          <button
            disabled={
              mailSent ||
              emailVerification.isLoading ||
              sendEmailConfirmationDisabled
            }
            className="btn btn-success mx-1"
            onClick={sendEmail}
          >
            {emailVerification.isLoading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span> Sending... </span>
              </>
            ) : (
              <>Send Email Confirmation</>
            )}
          </button>
          <button
            className="btn btn-danger mx-1"
            onClick={checkVerification}
            disabled={emailVerification.isVerifyingEmail}
          >
            {emailVerification.isVerifyingEmail ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span> Checking... </span>
              </>
            ) : (
              <>Re-check Email Status</>
            )}
          </button>
        </>
      )}

      <div id="messages">
        {mailSent && (
          <p>
            <i>
              Email confirmation has been sent to <b>{email}</b>.
            </i>
          </p>
        )}
        {!firstCheck &&
        !emailVerification.isEmailVerified &&
        !recheckingStatus ? (
          <p className="text-danger">
            <i>
              <b>{email}</b> is still unverified!
            </i>
          </p>
        ) : (
          <p></p>
        )}
        <p>
          <i className="text-danger">{errorMessage}</i>
        </p>
      </div>
    </div>
  );
}

export default EmailVerification;
