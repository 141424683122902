import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { registerAgent } from "../../features/authActions";
import { reset } from "../../features/authSlice";
import authFormValidator from "../../utils/authFormValidator";
import useMounted from "../../../app/utils/hooks/useMounted";
import "../../styles/authStyles.scss";

import company_logo from "../../../app/assets/csc_logo_transparent.png";

const formInitalValidity = {
  register_email: {
    valid: false,
    message: null,
    touched: false,
    required: true,
  },
  password1: {
    valid: false,
    messages: null,
    touched: false,
    required: true,
  },
  password2: {
    valid: false,
    messages: null,
    touched: false,
    required: true,
  },
};
function AgentRegistration() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { email, isLoggedIn, authentication } = useAppSelector(
    (state) => state.auth,
  );

  const [formData, setFormData] = useState({
    register_email: "",
    password1: "",
    password2: "",
  });

  const [errorMessage, setErrorMessage] = useState<string>();
  const [disabled, setDisabled] = useState(true);

  const [formValidity, setFormValidity] = useState<any>(formInitalValidity);

  const isMounted = useMounted();

  // Reset on navigate
  useEffect(() => {
    dispatch(reset());
    // setErrorMessage("");
    setFormValidity(formInitalValidity);

    setDisabled(true);
    setErrorMessage("");
  }, [navigate, dispatch]);

  useEffect(() => {
    if (isLoggedIn && email) {
      navigate("/loading-screen");
    }

    if (authentication.isError && authentication.message) {
      setErrorMessage(authentication.message);
    } else {
      setErrorMessage("");
    }
  }, [
    authentication.isSuccess,
    authentication.isRegistrationComplete,
    isLoggedIn,
    email,
    authentication.message,
    authentication.isError,
    authentication.isLoading,
    navigate,
  ]);

  useEffect(() => {
    if (isMounted) {
      setDisabled(
        !(formValidity.register_email.valid && formValidity.password1.valid),
      );
    }
  }, [formValidity, isMounted]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const onBlur = (e) => {
    if (isMounted) {
      setFormValidity((prevState) => ({
        ...prevState,
        [e.target.name]: {
          ...prevState[e.target.name],
          touched: true,
        },
      }));
    }
  };
  // Validate email
  useEffect(() => {
    if (isMounted) {
      const email_validation = authFormValidator.emailValidator(
        formData.register_email,
      );

      setFormValidity((prevState) => ({
        ...prevState,
        register_email: {
          ...prevState.register_email,
          valid: email_validation.valid,
          message: email_validation.message,
        },
      }));
    }
  }, [formData.register_email, isMounted]);

  // Validate passwords
  useEffect(() => {
    if (isMounted) {
      const password_validation = authFormValidator.passwordValidator(
        formData.password1,
        formData.password2,
      );

      setFormValidity((prevState) => ({
        ...prevState,
        password1: {
          ...prevState.password1,
          valid: password_validation.valid,
          messages: password_validation.messages,
        },
      }));
    }
  }, [formData.password1, formData.password2, isMounted]);

  const onSubmit = (e) => {
    e.preventDefault();
    const email_validation = authFormValidator.emailValidator(
      formData.register_email,
    );
    const password_validation = authFormValidator.passwordValidator(
      formData.password1,
      formData.password2,
    );

    if (email_validation.valid && password_validation.valid) {
      // setErrorMessage("Passwords do not match!");
      dispatch(
        registerAgent({
          email: formData.register_email,
          password: formData.password1,
        }),
      );
    } else {
      setFormValidity((prevState) => ({
        ...prevState,
        register_email: {
          ...prevState.register_email,
          message: email_validation.message,
        },
        password1: {
          ...prevState.password1,
          messages: password_validation.messages,
        },
      }));
    }
  };

  return (
    <div className="card mx-auto auth-card">
      <div className="card-body">
        <div className="w-100 text-center mb-2">
          <img
            src={company_logo}
            alt="CSC AG Logo"
            className="mt-2 mb-4 mx-auto company-logo"
          />
        </div>
        <div className="text-center">
          <h5 className="card-title mb-4 px-3">
            Register a New <br />
            Agent Portal Acount
          </h5>
        </div>
        <div className="mt-2">
          <form onSubmit={onSubmit}>
            <div className="form-group text-left small">
              <label htmlFor="email" className="form-label">
                Email Address
              </label>
              <input
                className="form-control"
                onChange={onChange}
                type="email"
                name="register_email"
                id="register_email"
                value={formData.register_email}
                placeholder="someone@example.com"
                onBlur={onBlur}
              />
              <p className="text-danger validation-error">
                {formValidity.register_email.touched &&
                  formValidity.register_email.message}
              </p>
            </div>
            <div className="form-group text-left small">
              <div>
                <p className="info-text">Minimum Password Requirements:</p>
                <ul className="info-list">
                  <li>At least 10 characters</li>
                  <li>Contains both UPPERCASE and lowercase letters</li>
                </ul>
              </div>
              <label htmlFor="password1" className="form-label">
                Password
              </label>
              <input
                className="form-control"
                onChange={onChange}
                type="password"
                name="password1"
                id="password1"
                value={formData.password1}
                placeholder="password"
                autoComplete="off"
                onBlur={onBlur}
              />
            </div>
            <div className="form-group text-left small mt-2">
              <label htmlFor="password2" className="form-label">
                Confirm Password
              </label>
              <input
                className="form-control"
                onChange={onChange}
                type="password"
                name="password2"
                id="password2"
                value={formData.password2}
                placeholder="password"
                autoComplete="off"
                onBlur={onBlur}
              />
            </div>
            <ul className="error-list">
              {formValidity.password1.messages &&
                formValidity.password1.touched &&
                formValidity.password1.messages.map((error, index) => (
                  <li key={index} className="text-danger">
                    {error}
                  </li>
                ))}
            </ul>
            <div className="text-center">
              <button
                disabled={disabled || authentication.isLoading}
                type="submit"
                className="btn btn-secondary w-75"
              >
                {authentication.isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span> Loading... </span>
                  </>
                ) : (
                  <>Continue</>
                )}
              </button>
            </div>
          </form>
        </div>
        <div className="text-center mt-4">
          {/* Error message */}
          <p className="text-danger">{errorMessage}</p>
        </div>
        <div className="mt-4 w-100 small text-center">
          <Link style={{ textDecoration: "none" }} to="/login">
            <small>{"<< Back to Login"}</small>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AgentRegistration;
