import React, { useState, useEffect, useReducer } from "react";
import LGMResultTableIndemnity from "./LGMResultTable/LGMResultTableIndemnity";
import { useAppSelector, useAppDispatch } from "../../../app/redux/hooks";
import {
  setIndemnityBasePrices,
  setIndemnityValues,
  setExpectedNetToProducerValues,
  setIndexValue,
} from "../features/LGMSlice";

import { LGMCommodityType } from "@agdeveloper/domain";
import useInterval from "../../../app/utils/hooks/useInterval";

const CATTLE_slider_consts = {
  min: -500,
  max: 400,
  inc: 0.01,
  default_slider: 0,
};

const SWINE_slider_consts = {
  min: -500,
  max: 400,
  inc: 0.01,
  default_slider: 0,
};

const MILK_slider_consts = {
  min: -300,
  max: 300,
  inc: 0.01,
  default_slider: 0,
};

const FEEDER_CATTLE_slider_consts = {
  min: -200,
  max: 200,
  inc: 0.01,
  default_slider: 0,
};

const CORN_slider_consts = {
  min: -120,
  max: 120,
  inc: 0.001,
  default_slider: 0,
};

const SOYBEAN_slider_consts = {
  min: -1000,
  max: 800,
  inc: 0.001,
  default_slider: 0,
};

const initalState = {
  incrButtonDown: false,
  targetSlider: "",
  incrementBy: 1,
};
const localReducer = (state, action) => {
  if (action.type === "start") {
    return {
      incrButtonDown: true,
      targetSlider: action.slider,
      incrementBy: action.incrementBy,
    };
  }
  if (action.type === "stop") {
    return {
      incrButtonDown: false,
      targetSlider: "",
      incrementBy: 1,
    };
  }

  throw Error("Unknown action.");
};

function ResultTableIndemnitiesParent() {
  const [state, localDispatch] = useReducer(localReducer, initalState);

  const dispatch = useAppDispatch();
  const { lgm_quote_results, loading, extra_data } = useAppSelector(
    (state) => state.lgm.lgmQuoteResults,
  );
  const { indemnityBasePrices, indexValue } = useAppSelector(
    (state) => state.lgm,
  );

  const { sales_effective_date } = useAppSelector((state) => state.lgm);

  const {
    cattle_corn_target_weight,
    cattle_feeder_cattle_target_weight,
    cattle_live_cattle_target_weight,
  } = useAppSelector((state) => state.lgm.input);

  const [slider_consts, setSliderConsts] = useState<any>(undefined);
  // Slider States
  const [cattleSlider, setCattleSlider] = useState<number>(
    CATTLE_slider_consts.default_slider,
  );
  const [swineSlider, setSwineSlider] = useState<number>(
    SWINE_slider_consts.default_slider,
  );
  const [milkSlider, setMilkSlider] = useState<number>(
    MILK_slider_consts.default_slider,
  );
  const [feederCattleSlider, setFeederCattleSlider] = useState<number>(
    FEEDER_CATTLE_slider_consts.default_slider,
  );
  const [cornSlider, setCornSlider] = useState<number>(
    CORN_slider_consts.default_slider,
  );
  const [soybeanSlider, setSoybeanSlider] = useState<number>(
    SOYBEAN_slider_consts.default_slider,
  );

  //-------------------GROUP START -----------------------
  useInterval(() => {
    if (state.incrButtonDown && state.targetSlider) {
      incrementSlider(state.targetSlider, state.incrementBy);
    }
    // Set interval to run while button is pressed.
  }, [state.incrButtonDown, state.targetSlider]);
  //-------------------GROUP END -----------------------

  // Projected Price States
  const [projectedCattlePrice, setProjectedCattlePrice] = useState<number>(0);
  const [projectedSwinePrice, setProjectedSwinePrice] = useState<number>(0);
  const [projectedMilkPrice, setProjectedMilkPrice] = useState<number>(0);
  const [projectedFeederCattlePrice, setProjectedFeederCattlePrice] =
    useState<number>(0);
  const [projectedCornPrice, setProjectedCornPrice] = useState<number>(0);
  const [projectedSoybeanPrice, setProjectedSoybeanPrice] = useState<number>(0);
  // Slider Methods
  const incrementSlider = (slider: string, value: number) => {
    let func: Function = (_: number) => {};
    switch (slider) {
      case "Cattle":
        func = (value) => {
          updateFromCattleSlider(cattleSlider + value);
        };
        break;
      case "Swine":
        func = (value) => {
          updateFromSwineSlider(swineSlider + value);
        };
        break;
      case "Milk":
        func = (value) => {
          updateFromMilkSlider(milkSlider + value);
        };
        break;
      case "Feeder Cattle":
        func = (value) => {
          updateFromFeederCattleSlider(feederCattleSlider + value);
        };
        break;
      case "Corn":
        func = (value) => {
          updateFromCornSlider(cornSlider + value);
        };
        break;
      case "Soybean":
        func = (value) => {
          updateFromSoybeanSlider(soybeanSlider + value);
        };
        break;
    }

    func(Number(value));
  };

  const resetAllSliders = () => {
    if (slider_consts) {
      setCattleSlider(slider_consts.cattle.default_slider);
      setSwineSlider(slider_consts.swine.default_slider);
      setMilkSlider(slider_consts.milk.default_slider);
      setFeederCattleSlider(slider_consts.feeder_cattle.default_slider);
      setCornSlider(slider_consts.corn.default_slider);
      setSoybeanSlider(slider_consts.soybean.default_slider);
      resetAllProjected();
      updateIndemnity();
    }
  };

  useEffect(() => {
    resetAllSliders();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slider_consts]);

  const resetAllProjected = () => {
    setProjectedCattlePrice(indemnityBasePrices.cattle);
    setProjectedSwinePrice(indemnityBasePrices.swine);
    setProjectedMilkPrice(indemnityBasePrices.milk);
    setProjectedFeederCattlePrice(indemnityBasePrices.feeder_cattle);
    setProjectedCornPrice(indemnityBasePrices.corn);
    setProjectedSoybeanPrice(indemnityBasePrices.soybean);
  };

  const updateFromCattleSlider = (newSliderValue: number) => {
    setCattleSlider(Math.round(newSliderValue * 100) / 100);
    setProjectedCattlePrice(Math.round(newSliderValue * 100) / 100);
  };

  const updateFromSwineSlider = (newSliderValue: number) => {
    setSwineSlider(Math.round(newSliderValue * 100) / 100);
    setProjectedSwinePrice(Math.round(newSliderValue * 100) / 100);
  };

  const updateFromMilkSlider = (newSliderValue: number) => {
    setMilkSlider(Math.round(newSliderValue * 100) / 100);
    setProjectedMilkPrice(Math.round(newSliderValue * 100) / 100);
  };

  const updateFromFeederCattleSlider = (newSliderValue: number) => {
    setFeederCattleSlider(Math.round(newSliderValue * 100) / 100);
    setProjectedFeederCattlePrice(Math.round(newSliderValue * 100) / 100);
  };

  const updateFromCornSlider = (newSliderValue: number) => {
    setCornSlider(Math.round(newSliderValue * 100) / 100);
    setProjectedCornPrice(Math.round(newSliderValue * 100) / 100);
  };

  const updateFromSoybeanSlider = (newSliderValue: number) => {
    setSoybeanSlider(Math.round(newSliderValue * 100) / 100);
    setProjectedSoybeanPrice(Math.round(newSliderValue * 100) / 100);
  };

  const calculateCattleIndemnity = (
    policy,
    type,
    marketing_plan,
    gross_margin_guarantees,
    deductibles,
    producer_premiums,
  ) => {
    let actualGrossMargin;
    if (type === "YEARLING_FINISHING") {
      actualGrossMargin =
        12.5 * Number(projectedCattlePrice) -
        7.5 * Number(projectedFeederCattlePrice) -
        50 * Number(projectedCornPrice);
    } else if (type === "CALF_FINISHING") {
      actualGrossMargin =
        11.5 * Number(projectedCattlePrice) -
        5.5 * Number(projectedFeederCattlePrice) -
        52 * Number(projectedCornPrice);
    }
    let actualTotalGrossMargin = 0;
    for (const marketed in marketing_plan) {
      const marketed_num = Number(marketing_plan[marketed]);
      actualTotalGrossMargin += marketed_num * actualGrossMargin;
    }

    dispatch(setIndexValue(Math.round(actualGrossMargin * 100) / 100));

    let indemnityvalues = [] as number[];
    for (const guar of gross_margin_guarantees) {
      const sub = guar - actualTotalGrossMargin;
      if (sub < 0) {
        indemnityvalues.push(0);
      } else {
        indemnityvalues.push(guar - actualTotalGrossMargin);
      }
    }

    //START --------------------------------

    let indemnities = {};
    let exp_nets_to_producer = {};

    for (const index in gross_margin_guarantees) {
      indemnities[deductibles[index]] = indemnityvalues[index];

      // indemnities[index]["value"] = indemnityvalues[index];

      const exp_net_to_prod =
        Math.round(
          (Math.max(
            gross_margin_guarantees[index] - actualTotalGrossMargin,
            0,
          ) -
            producer_premiums[index] +
            Number.EPSILON) *
            100,
        ) / 100;

      exp_nets_to_producer[deductibles[index]] = exp_net_to_prod;

      // exp_nets_to_producer[index]["value"] = exp_net_to_prod;
    }
    dispatch(setIndemnityValues(indemnities));
    dispatch(setExpectedNetToProducerValues(exp_nets_to_producer));
    //END --------------------------------
  };

  const calculateCattleIndemnityPost2025CropYear = (
    policy,
    type,
    marketing_plan,
    gross_margin_guarantees,
    deductibles,
    producer_premiums,
  ) => {
    let actualTotalGrossMargin = 0;
    let totalMarketed = 0;
    let actualGrossMargin =
      cattle_live_cattle_target_weight * projectedCattlePrice -
      cattle_feeder_cattle_target_weight * projectedFeederCattlePrice -
      cattle_corn_target_weight * projectedCornPrice;

    for (const marketed in marketing_plan) {
      const marketed_num = Number(marketing_plan[marketed]);

      totalMarketed += marketed_num;
      actualTotalGrossMargin += actualGrossMargin * marketed_num;
    }
    //console.log(actualTotalGrossMargin);
    dispatch(setIndexValue(Math.round(actualGrossMargin * 100) / 100));

    let indemnityvalues = [] as number[];
    for (const guar of gross_margin_guarantees) {
      const sub = guar - actualTotalGrossMargin;
      if (sub < 0) {
        indemnityvalues.push(0);
      } else {
        indemnityvalues.push(guar - actualTotalGrossMargin);
      }
    }

    //START --------------------------------

    let indemnities = {};
    let exp_nets_to_producer = {};

    for (const index in gross_margin_guarantees) {
      indemnities[deductibles[index]] = indemnityvalues[index];

      // indemnities[index]["value"] = indemnityvalues[index];

      const exp_net_to_prod =
        Math.round(
          (Math.max(
            gross_margin_guarantees[index] - actualTotalGrossMargin,
            0,
          ) -
            producer_premiums[index] +
            Number.EPSILON) *
            100,
        ) / 100;

      exp_nets_to_producer[deductibles[index]] = exp_net_to_prod;

      // exp_nets_to_producer[index]["value"] = exp_net_to_prod;
    }
    dispatch(setIndemnityValues(indemnities));
    dispatch(setExpectedNetToProducerValues(exp_nets_to_producer));
    //END --------------------------------
  };

  const calculateSwineIndemnity = (
    policy,
    type,
    marketing_plan,
    gross_margin_guarantees,
    deductibles,
    producer_premiums,
  ) => {
    let actualGrossMargin;
    if (type === "FARROW_TO_FINISH") {
      actualGrossMargin =
        0.74 * 2.6 * Number(projectedSwinePrice) -
        12 * Number(projectedCornPrice) -
        (138.55 / 2000) * Number(projectedSoybeanPrice);
    } else if (type === "FINISHING") {
      actualGrossMargin =
        0.74 * 2.6 * Number(projectedSwinePrice) -
        9 * Number(projectedCornPrice) -
        (82 / 2000) * Number(projectedSoybeanPrice);
    } else if (type === "SEW_PIG_FINISHING") {
      actualGrossMargin =
        0.74 * 2.6 * Number(projectedSwinePrice) -
        9.05 * Number(projectedCornPrice) -
        (91 / 2000) * Number(projectedSoybeanPrice);
    }
    let actualTotalGrossMargin = 0;
    for (const marketed in marketing_plan) {
      const marketed_num = Number(marketing_plan[marketed]);
      actualTotalGrossMargin += marketed_num * actualGrossMargin;
    }

    dispatch(setIndexValue(Math.round(actualGrossMargin * 100) / 100));

    let indemnityvalues = [] as number[];
    for (const guar of gross_margin_guarantees) {
      const sub = guar - actualTotalGrossMargin;
      if (sub < 0) {
        indemnityvalues.push(0);
      } else {
        indemnityvalues.push(guar - actualTotalGrossMargin);
      }
    }

    //START --------------------------------

    let indemnities = {};
    let exp_nets_to_producer = {};

    for (const index in gross_margin_guarantees) {
      indemnities[deductibles[index]] = indemnityvalues[index];

      // indemnities[index]["value"] = indemnityvalues[index];

      const exp_net_to_prod =
        Math.round(
          (Math.max(
            gross_margin_guarantees[index] - actualTotalGrossMargin,
            0,
          ) -
            producer_premiums[index] +
            Number.EPSILON) *
            100,
        ) / 100;

      exp_nets_to_producer[deductibles[index]] = exp_net_to_prod;

      // exp_nets_to_producer[index]["value"] = exp_net_to_prod;
    }
    dispatch(setIndemnityValues(indemnities));
    dispatch(setExpectedNetToProducerValues(exp_nets_to_producer));
    //END --------------------------------
  };

  const calculateDairyIndemnity = (
    policy,
    type,
    marketing_plan,
    dairy_corn_meal_feed,
    dairy_soybean_meal_feed,
    gross_margin_guarantees,
    deductibles,
    producer_premiums,
  ) => {
    let totalMarketed = 0;
    let actualTotalGrossMargin = 0;
    for (const i in marketing_plan) {
      const marketed_num = Number(marketing_plan[i]);
      // console.info("first", dairy_corn_meal_feed[i]);
      // console.info(projectedCornPrice);
      // console.info(dairy_soybean_meal_feed[i]);
      // console.info(projectedSoybeanPrice);
      const actualFeedCost =
        dairy_corn_meal_feed[i] * (2000 / 56) * projectedCornPrice +
        dairy_soybean_meal_feed[i] * projectedSoybeanPrice;
      const actualGrossMargin =
        marketed_num * projectedMilkPrice - actualFeedCost;

      totalMarketed += marketed_num;
      actualTotalGrossMargin += actualGrossMargin;
    }

    dispatch(
      setIndexValue(
        Math.round((actualTotalGrossMargin / totalMarketed) * 100) / 100,
      ),
    );

    let indemnityvalues = [] as number[];
    for (const guar of gross_margin_guarantees) {
      const sub = guar - actualTotalGrossMargin;
      if (sub < 0) {
        indemnityvalues.push(0);
      } else {
        indemnityvalues.push(guar - actualTotalGrossMargin);
      }
    }

    //START --------------------------------

    let indemnities = {};
    let exp_nets_to_producer = {};

    for (const index in gross_margin_guarantees) {
      indemnities[deductibles[index]] = indemnityvalues[index];

      // indemnities[index]["value"] = indemnityvalues[index];

      const exp_net_to_prod =
        Math.round(
          (Math.max(
            gross_margin_guarantees[index] - actualTotalGrossMargin,
            0,
          ) -
            producer_premiums[index] +
            Number.EPSILON) *
            100,
        ) / 100;

      exp_nets_to_producer[deductibles[index]] = exp_net_to_prod;

      // exp_nets_to_producer[index]["value"] = exp_net_to_prod;
    }
    dispatch(setIndemnityValues(indemnities));
    dispatch(setExpectedNetToProducerValues(exp_nets_to_producer));
    //END --------------------------------
  };
  // Indemnity Methods
  // TODO: Move this method to each individual indemnity component
  const updateIndemnity = () => {
    if (lgm_quote_results) {
      const gross_margin_guarantees = lgm_quote_results.map(
        (lgm_quote_result) => lgm_quote_result.guaranteed_gross_margin_amount,
      );
      const deductibles = lgm_quote_results.map(
        (lgm_quote_result) =>
          lgm_quote_result.quote_params.user_input.deductible,
      );
      //console.log(deductibles);
      const producer_premiums = lgm_quote_results.map(
        (lgm_quote_result) => lgm_quote_result.producer_premium_amount,
      );
      if (
        lgm_quote_results[0]["quote_params"]["user_input"]["commodity_type"][
          "value"
        ] === LGMCommodityType.CATTLE
      ) {
        const cattle_marketing_plan = [
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_2_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_3_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_4_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_5_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_6_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_7_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_8_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_9_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_10_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_11_marketed"
          ],
        ];
        const calculateCattleIndemnityFunction =
          sales_effective_date.value >= new Date("2024-07-01")
            ? calculateCattleIndemnityPost2025CropYear
            : calculateCattleIndemnity;

        calculateCattleIndemnityFunction(
          lgm_quote_results[0]["quote_params"]["user_input"]["commodity_type"][
            "value"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"]["quote_type"][
            "value"
          ],
          cattle_marketing_plan,
          gross_margin_guarantees,
          deductibles,
          producer_premiums,
        );
      } else if (
        lgm_quote_results[0]["quote_params"]["user_input"]["commodity_type"][
          "value"
        ] === LGMCommodityType.SWINE
      ) {
        const swine_marketing_plan = [
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_2_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_3_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_4_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_5_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_6_marketed"
          ],
        ];

        calculateSwineIndemnity(
          lgm_quote_results[0]["quote_params"]["user_input"]["commodity_type"][
            "value"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"]["quote_type"][
            "value"
          ],
          swine_marketing_plan,
          gross_margin_guarantees,
          deductibles,
          producer_premiums,
        );
      } else if (
        lgm_quote_results[0]["quote_params"]["user_input"]["commodity_type"][
          "value"
        ] === LGMCommodityType.DAIRY_CATTLE
      ) {
        const milk_marketing_plan = [
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_2_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_3_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_4_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_5_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_6_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_7_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_8_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_9_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_10_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_11_marketed"
          ],
        ];

        const corn_marketing_plan = [
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_2_corn_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_3_corn_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_4_corn_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_5_corn_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_6_corn_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_7_corn_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_8_corn_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_9_corn_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_10_corn_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_11_corn_marketed"
          ],
        ];

        const soybean_marketing_plan = [
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_2_soybean_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_3_soybean_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_4_soybean_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_5_soybean_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_6_soybean_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_7_soybean_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_8_soybean_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_9_soybean_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_10_soybean_marketed"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"][
            "month_11_soybean_marketed"
          ],
        ];
        calculateDairyIndemnity(
          lgm_quote_results[0]["quote_params"]["user_input"]["commodity_type"][
            "value"
          ],
          lgm_quote_results[0]["quote_params"]["user_input"]["quote_type"][
            "value"
          ],
          milk_marketing_plan,
          corn_marketing_plan,
          soybean_marketing_plan,
          gross_margin_guarantees,
          deductibles,
          producer_premiums,
        );
      }
    }
  };

  useEffect(
    updateIndemnity,

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      projectedCattlePrice,
      projectedSwinePrice,
      projectedMilkPrice,
      projectedFeederCattlePrice,
      projectedCornPrice,
      projectedSoybeanPrice,
      lgm_quote_results,
    ],
  );

  useEffect(() => {
    if (extra_data) {
      dispatch(
        setIndemnityBasePrices({
          cattle: extra_data["CattleDefaultValue"],
          swine: extra_data["SwineDefaultValue"],
          milk: extra_data["MilkDefaultValue"],
          feeder_cattle: extra_data["FeederCattleDefaultValue"],
          corn: extra_data["CornDefaultValue"],
          soybean: extra_data["SoybeanDefaultValue"],
        }),
      );

      let CATTLE_slider_consts = {
        min: Number(
          Math.max(
            Number(extra_data["CattleDefaultValue"]) -
              Number(extra_data["CattleDefaultValue"] * 0.5),
            0,
          ),
        ),
        max:
          Number(extra_data["CattleDefaultValue"]) +
          Number(extra_data["CattleDefaultValue"] * 0.5),
        inc: 1,
        default_slider: Number(extra_data["CattleDefaultValue"]),
      };

      let SWINE_slider_consts = {
        min: Number(
          Math.max(
            Number(extra_data["SwineDefaultValue"]) -
              Number(extra_data["SwineDefaultValue"] * 0.5),
            0,
          ),
        ),
        max:
          Number(extra_data["SwineDefaultValue"]) +
          Number(extra_data["SwineDefaultValue"] * 0.5),
        inc: 1,
        default_slider: Number(extra_data["SwineDefaultValue"]),
      };

      let MILK_slider_consts = {
        min:
          Number(extra_data["MilkDefaultValue"]) * 0.5 < 10
            ? Math.max(Number(extra_data["MilkDefaultValue"]) * 0.5, 0.01)
            : 10,
        max:
          Number(extra_data["MilkDefaultValue"]) * 1.5 > 25
            ? Math.max(Number(extra_data["MilkDefaultValue"]) * 1.5, 0.01)
            : 25,
        inc: 0.1,
        default_slider: Number(extra_data["MilkDefaultValue"]),
      };

      let FEEDER_CATTLE_slider_consts = {
        min: Number(
          Math.max(
            Number(extra_data["FeederCattleDefaultValue"]) -
              Number(extra_data["FeederCattleDefaultValue"] * 0.5),
            0,
          ),
        ),
        max: Number(
          Number(extra_data["FeederCattleDefaultValue"]) +
            Number(extra_data["FeederCattleDefaultValue"] * 0.5),
        ),
        inc: 1,
        default_slider: Number(extra_data["FeederCattleDefaultValue"]),
      };

      let CORN_slider_consts = {
        min: Number(
          Number(extra_data["CornDefaultValue"]) * 0.5 < 2
            ? Math.max(Number(extra_data["CornDefaultValue"]) * 0.5, 0.01)
            : 2,
        ),
        max:
          Number(extra_data["CornDefaultValue"]) * 1.5 > 8
            ? Math.max(Number(extra_data["CornDefaultValue"]) * 1.5, 0.01)
            : 8,
        inc: 0.1,
        default_slider: Number(extra_data["CornDefaultValue"]),
      };

      let SOYBEAN_slider_consts = {
        min: Number(
          Number(extra_data["SoybeanDefaultValue"]) * 0.5 < 200
            ? Math.max(Number(extra_data["SoybeanDefaultValue"]) * 0.5, 0.01)
            : 200,
        ),
        max:
          Number(extra_data["SoybeanDefaultValue"]) * 1.5 > 600
            ? Math.max(Number(extra_data["SoybeanDefaultValue"]) * 1.5, 0.01)
            : 600,
        inc: 1,
        default_slider: Number(extra_data["SoybeanDefaultValue"]),
      };

      setCattleSlider(extra_data["CattleDefaultValue"]);
      setSwineSlider(extra_data["SwineDefaultValue"]);
      setMilkSlider(extra_data["MilkDefaultValue"]);
      setFeederCattleSlider(extra_data["FeederCattleDefaultValue"]);
      setCornSlider(extra_data["CornDefaultValue"]);
      setSoybeanSlider(extra_data["SoybeanDefaultValue"]);

      setSliderConsts({
        cattle: CATTLE_slider_consts,
        swine: SWINE_slider_consts,
        milk: MILK_slider_consts,
        feeder_cattle: FEEDER_CATTLE_slider_consts,
        corn: CORN_slider_consts,
        soybean: SOYBEAN_slider_consts,
      });

      resetAllSliders();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extra_data, dispatch]);

  useEffect(() => {
    // Reset sliders and values prices when base prices change
    resetAllSliders();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indemnityBasePrices]);

  if (!loading && lgm_quote_results && slider_consts) {
    // Display only if drp results are available
    const sliderValues = {
      cattleSlider: cattleSlider,
      swineSlider: swineSlider,
      milkSlider: milkSlider,
      feederCattleSlider: feederCattleSlider,
      cornSlider: cornSlider,
      soybeanSlider: soybeanSlider,
    };

    const sliderMethods = {
      setCattleSlider: setCattleSlider,
      setSwineSlider: setSwineSlider,
      setMilkSlider: setMilkSlider,
      setFeederCattleSlider: setFeederCattleSlider,
      setCornSlider: setCornSlider,
      setSoybeanSlider: setSoybeanSlider,

      updateFromCattleSlider: updateFromCattleSlider,
      updateFromSwineSlider: updateFromSwineSlider,
      updateFromMilkSlider: updateFromMilkSlider,
      updateFromFeederCattleSlider: updateFromFeederCattleSlider,
      updateFromCornSlider: updateFromCornSlider,
      updateFromSoybeanSlider: updateFromSoybeanSlider,

      resetAllSliders: resetAllSliders,

      incrementSlider: incrementSlider,
      startIncrementSlider: (slider: string, value: number) =>
        localDispatch({ type: "start", slider: slider, incrementBy: value }),
      stopSliderIncrement: () => localDispatch({ type: "stop" }),
    };

    const projectedPrices = {
      projectedCattlePrice: projectedCattlePrice,
      projectedSwinePrice: projectedSwinePrice,
      projectedMilkPrice: projectedMilkPrice,
      projectedFeederCattlePrice: projectedFeederCattlePrice,
      projectedCornPrice: projectedCornPrice,
      projectedSoybeanPrice: projectedSoybeanPrice,
    };

    return (
      <>
        <LGMResultTableIndemnity
          slider_consts={slider_consts}
          sliderValues={sliderValues}
          sliderMethods={sliderMethods}
          projectedPrices={projectedPrices}
          indexValue={indexValue}
        />
      </>
    );
  } else {
    return <></>;
  }
}

export default ResultTableIndemnitiesParent;
