import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { setLGMCattleliveCattleTargetWeight } from "../features/LGMSlice";
import { LGMQuoteType } from "@agdeveloper/domain";

const TARGET_WEIGHT_MAP = {
  [LGMQuoteType.CALF_FINISHING]: {
    min: 11,
    max: 13,
  },
  [LGMQuoteType.YEARLING_FINISHING]: {
    min: 12,
    max: 15,
  },
};
function LGMCattleLiveCattleTargetWeight() {
  const dispatch = useAppDispatch();
  const [targetWeight, setTargetWeight] = useState(0);
  const [warningMessage, setWarningMessage] = useState("");
  const [touched, setTouched] = useState(false);

  const updateLiveCattleTargetWeight = (event) => {
    setTouched(true);

    const val = event.target.value.replace(/[^0-9.]/g, "");
    setTargetWeight(val);
  };
  const { type } = useAppSelector((state) => state.lgm.input);

  useEffect(() => {
    dispatch(setLGMCattleliveCattleTargetWeight(targetWeight));
  }, [targetWeight, dispatch]);

  useEffect(() => {
    const currentTargetWeight = parseFloat(targetWeight.toString());
    if (
      (currentTargetWeight < TARGET_WEIGHT_MAP[type.value]["min"] ||
        currentTargetWeight > TARGET_WEIGHT_MAP[type.value]["max"]) &&
      touched
    ) {
      setWarningMessage(
        `Target Weight must be between ${TARGET_WEIGHT_MAP[type.value]["min"]} and ${TARGET_WEIGHT_MAP[type.value]["max"]} CWT.`,
      );
    } else {
      setWarningMessage("");
    }
    // dispatch(setCWT(current_CWT.toFixed(2)));
  }, [targetWeight, dispatch, type]);

  return (
    <div className="card w-100">
      <div style={{ minWidth: 300 }}>
        <div className="card-body">
          <h5 className="card-title">Live Cattle Target Weight Quantity</h5>
          <p className="card-text">
            Enter a live cattle target weight between{" "}
            {TARGET_WEIGHT_MAP[type.value]["min"]} and{" "}
            {TARGET_WEIGHT_MAP[type.value]["max"]} CWT.{" "}
          </p>
          <div className="input-group has-validation">
            <input
              type="text"
              name="cwt"
              id="cwt"
              className={`form-control ag-form-focus ${
                warningMessage !== "" && "is-invalid"
              }`}
              value={targetWeight}
              onChange={updateLiveCattleTargetWeight}
            />
            <span className="input-group-text">CWT</span>
            <div className="invalid-feedback">
              <p>{warningMessage}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LGMCattleLiveCattleTargetWeight;
