import React, { useState, useEffect } from "react";
import { useAppDispatch } from "../../../../../../app/redux/hooks";
import { setType } from "../../../LRPSlice";

import { makeTitle } from "../../../lrp_utils/utils";

function LRPFeederCattlePracticeAndTypeSelection({ types }) {
  const dispatch = useAppDispatch();
  const [selectedType, setSelectedType] = useState("");

  const handleSelect = (e) => {
    setSelectedType(e.target.value);
  };

  useEffect(() => {
    // Initilization
    setSelectedType(makeTitle(types[0]));
    //dont trgger on types update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setType(selectedType));
  }, [selectedType, dispatch]);

  return (
    <div className="card w-100 p-3">
      <h5 className="card-title">Type</h5>
      <p className="card-text">Please select your insurance type.</p>
      <label>Type:</label>
      <select
        name="cattle_type"
        id="cattle_type"
        className="form-select text-white bg-secondary ag-form-focus col-4 pb-1 my-3"
        onChange={handleSelect}
        style={{ maxWidth: 360, minWidth: 150 }}
      >
        {types.map((type) => {
          return (
            <option value={makeTitle(type)} key={type}>
              {makeTitle(type)}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default LRPFeederCattlePracticeAndTypeSelection;
